/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderNew from "../components/LoaderNew";
function DynamicPage() {
  const navigate = useNavigate();
  const { type, type_id, request_id } = useParams();
  const acceptInvitation = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/invite/accept`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          req_uniq_id: request_id,
          type: type,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        navigate("/template");
      } else {
        // navigate("/template");
        // promt the error
        alert(response.data.message);
        // timeout the 5 seconds and navigate to /templtae
        // setTimeout(() => {
        navigate("/template");
        // }, 5000);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("login_token")) {
      navigate("/", {
        state: {
          from: "dynamic_page",
          url: `workspace/${type}/${type_id}/${request_id}`,
        },
      });
    } else {
      acceptInvitation();
      // console.log("I am came back");
    }
  }, []);

  return (
    <>
      <LoaderNew from="dynamic_page" />
    </>
  );
}

export default DynamicPage;
