import axios from "axios";
import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ThreeDotsLoader from "./ThreeDotsLoader";

function FailureModal({
  open,
  removeModal,
  successFailureMessage,
  from,
  email,
}) {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const loginButton = async (e) => {
    setloading(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: email,
        },
      });
      if (response.data.success) {
        navigate("/login-otp", {
          replace: true,
          state: {
            email: email,
            from: "login",
            url: "template",
          },
        });
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        show={open === "dismissible"}
        size="lg"
        onClose={() => removeModal()}
        zIndex="1000000"
        className="test-modal"
      >
        <Modal.Body className="p-0 rounded-[8px]">
          <div className="flex justify-center items-center bg-[#ED400A] py-3">
            <svg className="icon text-[white] text-[50px]">
              <use href="#icon_faield"></use>
            </svg>
          </div>
          <div className="p-4 py-8">
            <h3 className="text-center font-bold text-[26px]">Sorry</h3>
            <p className="text-center font-bold text-[20px] text-[#7D7D7D]">
              {successFailureMessage}
            </p>
            <div className="pt-4 flex justify-center">
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-[#ED400A] text-white hover:bg-[#C83E12]"
                disabled={loading}
                onClick={() => {
                  if (from && from === "signup") {
                    loginButton();
                  } else {
                    removeModal();
                  }
                }}
              >
                {from && from === "signup" ? "Please Login" : "TRY AGAIN"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FailureModal;
