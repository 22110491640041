/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function BlogComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
  topic,
  company_name,
  description,
  outline_type,
  target_audience,
  no_of_titles,
  blog_title,
  keyword_include,
  para_length,
  blog_topic,
  no_of_outlines,
  setselectedtoneFn,
  stopGenerateButtonClicked,
  no_of_words,
  number_of_words,
  setnumber_of_words,
  scrollLeftRef,
  getInputData,
  additional_information,
  conclusion,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });

  const [inputData, setInputData] = useState({
    no_of_titles: "1",
    topic: "",
    tone: "",
    language: "English",
    no_of_outlines: "",
    title: "",
    type: "",
    keywords: "",
    keyword: "",
    add_info: "",
    target_audience: "",
    length: "",
    description: "",
    c_name: "",
    company_name: "",
    content_title: selectedFile?.content_title,
    conclusion: "Yes",
  });
  const [editFile, seteditFile] = useState(false);

  const chooseSocialMedia = "Choose Media";

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const getModifiedDate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    // return;
    // console.log(from, "from");
    let data = {};
    let titleTemplate = {
      "Blog Title": "get_titles",
      "Blog Outlines": "get_outlines",
      "Three Step Blog": "get_titles",
      "Blog Section": "blog_section",
      "Blog Ideas": "blog_idea",
      "One Step Blog": "get_titles",
    };
    if (from == "Blog Title") {
      data = {
        no_of_titles: inputData.no_of_titles,
        topic: inputData.topic,
        tone: inputData.tone,
        language: inputData.language,
        keyword: inputData.keyword,
        add_info: inputData.add_info,
        target_audience: inputData.target_audience,
        template: "titles",
        content_template: "Blog Title",
        content_title: inputData?.content_title || "",
      };
    } else if (from == "Blog Outlines") {
      data = {
        no_of_outlines: inputData.no_of_outlines,
        title: inputData.topic,
        type: inputData.type,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData?.content_title || "",
        content_template: "Blog Outlines",
      };
      if (inputData.type == "Type 1") {
        data["model"] = "model_2";
      }
    } else if (from == "Two Step Blog") {
      data = {
        no_of_titles: inputData.no_of_titles,
        topic: inputData.topic,
        tone: inputData.tone,
        language: inputData.language,
        keywords: inputData.keyword,
        content_template: "Blog",
        content_title: "Two Step Blog",
      };
      data["template"] = "two step";
      postWebsocketDetails(data, "get_titles");
    } else if (from === "Three Step Blog") {
      // console.log("CAME HERE>>>");
      data = {
        no_of_titles: "5",
        topic: inputData.topic,
        tone: inputData.tone,
        language: inputData.language,
        keywords: inputData.keyword,
        content_template: "Three Step Blog",
        content_title: inputData?.content_title || "",
        contents: selectedFile?.contents,
      };
      data["template"] = "two step";
      getInputData(data);
    } else if (from == "One Step Blog") {
      data = {
        no_of_titles: inputData.no_of_titles,
        topic: inputData.topic,
        tone: inputData.tone,
        language: inputData.language,
        keywords: inputData.keyword,
        target_audience: inputData.target_audience,
        template: "one step",
        add_info: inputData.add_info,
        content_template: "One Step Blog",
        content_title: inputData?.content_title || "",
        number_of_words: number_of_words,
        conclusion: inputData.conclusion,
        contents: selectedFile?.contents,
      };
      getInputData(data);
    } else if (from == "Blog Section") {
      data = {
        title: inputData.title,
        keywords: inputData.keyword,
        length: inputData.length,
        tone: inputData.tone,
        language: inputData.language,
        add_info: inputData.add_info,
        content_template: "Blog Section",
        content_title: inputData?.content_title || "",
      };
    } else if (from == "Blog Ideas") {
      data = {
        n: "1",
        c_name: inputData.company_name,
        description: inputData.description,
        target_audience: inputData.target_audience,
        language: inputData.language,
        add_info: inputData.add_info,
        content_template: "Blog Ideas",
        content_title: inputData?.content_title || "",
      };
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      // callUpdateToFileApi(data, selectedFile ? selectedFile?.contents : "");
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
      setTimeout(() => {
        if (from === "Three Step Blog") {
          const scrollAmount = scrollLeftRef.current.offsetWidth / 4;
          const currentScrollLeft = scrollLeftRef.current.scrollLeft;
          const targetScrollLeft = currentScrollLeft + scrollAmount;

          scrollLeftRef.current.scrollTo({
            left: targetScrollLeft,
            behavior: "smooth", // This enables smooth scrolling
          });
        }
      }, 200);
    }
  };

  useEffect(() => {
    if (inputData.date != "") {
      let modifiedDate = getModifiedDate(inputData.date);
      setInputData({ ...inputData, modified_date: modifiedDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData.date]);
  useEffect(() => {
    if (from == "Two Step Blog" || from == "Three Step Blog") {
      setselectedtoneFn(inputData.tone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData.tone]);
  useEffect(() => {
    if (from == "Two Step Blog" || from == "Three Step Blog") {
      setInputData({ ...inputData, no_of_titles: "5" });
    } else if (from == "One Step Blog") {
      setInputData({ ...inputData, no_of_titles: "1" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(selectedFile, "selected file");
  useEffect(() => {}, []);

  useEffect(() => {
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        // let item = selectedFile?.content_settings?.find(
        //   (item) => item?.content_key == key
        // );
        // return item?.content_value;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "Blog Outlines") {
        setInputData({
          ...inputData,
          no_of_outlines: getSettingKey("no_of_outlines"),
          topic: getSettingKey("title"),
          type: getSettingKey("type"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Blog Outlines",
        });
      } else if (selectedFile?.content_template == "Blog Title") {
        setInputData({
          ...inputData,
          no_of_titles: getSettingKey("no_of_titles"),
          topic: getSettingKey("topic"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          keyword: getSettingKey("keyword"),
          add_info: getSettingKey("add_info"),
          target_audience: getSettingKey("target_audience"),
          content_title: selectedFile?.content_title,
          content_template: "Blog Title",
        });
      } else if (selectedFile?.content_template == "Blog Ideas") {
        setInputData({
          ...inputData,
          company_name: getSettingKey("c_name"),
          description: getSettingKey("description"),
          target_audience: getSettingKey("target_audience"),
          language: getSettingKey("language"),
          add_info: getSettingKey("add_info"),
          content_title: selectedFile?.content_title,
          content_template: "Blog Ideas",
        });
      } else if (selectedFile?.content_template == "Blog Section") {
        setInputData({
          ...inputData,
          title: getSettingKey("title"),
          keyword: getSettingKey("keywords"),
          length: getSettingKey("length"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          add_info: getSettingKey("add_info"),
          content_title: selectedFile?.content_title,
          content_template: "Blog Section",
        });
      } else if (selectedFile?.content_template == "One Step Blog") {
        setInputData({
          ...inputData,
          no_of_titles: getSettingKey("no_of_titles") || "1",
          topic: getSettingKey("topic") || "",
          keyword: getSettingKey("keywords") || "",
          target_audience: getSettingKey("target_audience") || "",
          template: "one step",
          add_info: getSettingKey("add_info") || "",
          tone: getSettingKey("tone") || "",
          language: getSettingKey("language") || "English",
          content_title: selectedFile?.content_title,
          content_template: "One Step Blog",
          conclusion: getSettingKey("conclusion") || "Yes",
        });
        setnumber_of_words(getSettingKey("number_of_words") || "");
      } else if (selectedFile?.content_template == "Three Step Blog") {
        setInputData({
          ...inputData,
          no_of_titles: "5",
          topic: getSettingKey("topic") || "",
          keyword: getSettingKey("keywords") || "",
          tone: getSettingKey("tone") || "",
          language: getSettingKey("language") || "English",
          content_title: selectedFile?.content_title,
          content_template: "Three Step Blog",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    //  if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(selectedFile, "selected file in blog page...");
  // console.log(inputData, "input data in blog page...");

  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn("generate");
          }}
        >
          {topic && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="topic"
              >
                Blog Post Title / Topic
              </label>
              <input
                type="text"
                id="topic"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Blog Post Title / Topic"
                value={inputData.topic}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {blog_topic && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="topic"
              >
                Blog Topic
              </label>
              {/* <input
                type="text"
                id="topic"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Blog Topic"
                value={inputData.topic}
                onChange={handleChange}
                required
              /> */}
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[80px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="topic"
                name="msgarea"
                placeholder="Enter Blog Topic"
                onChange={handleChange}
                value={inputData.topic}
                required
              ></textarea>
            </div>
          )}
          {blog_title && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="title"
              >
                Blog Title
              </label>
              <input
                type="text"
                id="title"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Blog Title"
                value={inputData.title}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {keyword_include && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="keyword"
              >
                Keyword Include
              </label>
              {/* <input
                type="text"
                id="keyword"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter keyword"
                value={inputData.keyword}
                onChange={handleChange}
                required
              /> */}
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[80px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="keyword"
                name="msgarea"
                placeholder="Enter keywords"
                onChange={handleChange}
                value={inputData.keyword}
                required
              ></textarea>
            </div>
          )}
          {para_length && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="length"
              >
                Ideal Paragraph Length
              </label>
              <input
                type="text"
                id="length"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter number of words for a paragraph"
                value={inputData.length}
                onChange={handleChange}
                required
              />
              <p>According to SEO guidelines, the suggested ideal paragraph length is 150-200 words</p>
            </div>
          )}
          {company_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="company_name"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company_name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company Name"
                value={inputData.company_name}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {description && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="description"
              >
                Product / Service Description
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="description"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.description}
                required
              ></textarea>
            </div>
          )}
          {target_audience && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="target_audience"
              >
                Target Audience
              </label>
              <input
                type="text"
                id="target_audience"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Target Audience"
                value={inputData.target_audience}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {no_of_titles && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_of_titles"
              >
                Number of Titles
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="lang"
                id="no_of_titles"
                value={inputData.no_of_titles}
                required
                onChange={handleChange}
              >
                <option value="">Choose No of Titles</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
          {no_of_outlines && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_of_outlines"
              >
                Number of Outlines
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="lang"
                id="no_of_outlines"
                value={inputData.no_of_outlines}
                required
                onChange={handleChange}
              >
                <option value="">Choose No of Outlines</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
          {no_of_words && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="number_of_words"
              >
                Number of words
              </label>
              <input
                type="number"
                id="number_of_words"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Number of words"
                value={number_of_words}
                onChange={(e) => {
                  setnumber_of_words(e.target.value);
                }}
                required
              />
            </div>
          )}

          {additional_information && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="add_info"
              >
                Additional Information
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="add_info"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.add_info}
                required
              ></textarea>
            </div>
          )}
          {conclusion && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="add_info"
              >
                Conclusion
              </label>
              <div className="flex items-center space-x-4 px-1">
                <div>
                  <input
                    type="radio"
                    id="conclusionYes"
                    name="conclusion"
                    value="Yes"
                    onChange={(e) => {
                      setInputData({ ...inputData, conclusion: "Yes" });
                    }}
                    checked={inputData.conclusion === "Yes"}
                  />
                  <label htmlFor="conclusionYes" className="ml-2">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="conclusionNo"
                    name="conclusion"
                    value="No"
                    onChange={(e) => {
                      setInputData({ ...inputData, conclusion: "No" });
                    }}
                    checked={inputData.conclusion === "No"}
                  />
                  <label htmlFor="conclusionNo" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
          )}

          {from != "Blog Ideas" && (
            <div className="pb-3">
              <label class="block mb-2 font-semibold text-[18px]" for="tone">
                Choose Tone
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="cars"
                id="tone"
                value={inputData.tone}
                required
                onChange={handleChange}
              >
                <option value="">Choose Tone</option>
                <option value="Assertive">Assertive</option>
                <option value="Appreciative">Appreciative</option>
                <option value="Awestruck">Awestruck</option>
                <option value="Candid">Candid</option>
                <option value="Casual">Casual</option>
                <option value="Cautionary">Cautionary</option>
                <option value="Convincing">Convincing</option>
                <option value="Critical">Critical</option>
                <option value="Earnest">Earnest</option>
                <option value="Enthusiastic">Enthusiastic</option>
                <option value="Formal">Formal</option>
                <option value="Funny">Funny</option>
                <option value="Humble">Humble</option>
                <option value="Humorous">Humorous</option>
                <option value="Informative">Informative</option>
                <option value="Inspirational">Inspirational</option>
                <option value="Joyful">Joyful</option>
                <option value="Passionate">Passionate</option>
                <option value="Thoughtful">Thoughtful</option>
                <option value="Urgent">Urgent</option>
                <option value="Worried">Worried</option>
                <option value="Bold">Bold</option>
                <option value="Persuasive">Persuasive</option>
                <option value="Exciting">Exciting</option>
              </select>
            </div>
          )}

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              required
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>

          {outline_type && (
            <div className="pb-3">
              <label class="block mb-2 font-semibold text-[18px]" for="type">
                Outline Type
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="lang"
                id="type"
                value={inputData.type}
                required
                onChange={handleChange}
              >
                <option value="">Choose outline type</option>
                <option value="Type 1">Type 1</option>
                <option value="Type 2">Type 2</option>
              </select>
              <div className="mt-1 flex item-center g-2 ">
                <div>Type 1: It Will Create Roman Letter Outlines</div>
                <div>
                  <button
                    type="button"
                    className="p-0 m-0 border-0 pl-2"
                    style={{
                      background: "none",
                    }}
                  >
                    <svg className="icon fs-18 ">
                      <use href="#icon_info"></use>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="mt-1 flex item-center g-2">
                <div>Type 2: It Will Create Normal Outlines</div>
                <div>
                  <button
                    type="button"
                    className="p-0 m-0 border-0 pl-2"
                    style={{
                      background: "none",
                    }}
                  >
                    <svg className="icon fs-18">
                      <use href="#icon_info"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={
              from == "Two Step Blog" || from == "Three Step Blog"
                ? "Step One (Title)"
                : mainTitle
            }
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
            stopGenerateButtonClicked={stopGenerateButtonClicked}
            scrollLeftRef={scrollLeftRef}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default BlogComponent;
