/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import gameStartMusic from "../assets/audio/Gamebeginning.mp3";
import progressingScreen from "../assets/audio/Progressingscreen.mp3";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";

function Game() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(20);
  const [progressingMessage, setprogressingMessage] = useState("");

  const [firstModal, setfirstModal] = useState(false);
  const [secondModal, setsecondModal] = useState(false);
  const [thirdModal, setthirdModal] = useState(false);
  const [progressModal, setprogressModal] = useState(false);
  const [lastModal, setlastModal] = useState(false);
  const [file, setfile] = useState(null);
  const [ws, setWs] = useState(null);
  const [fileDetails, setfileDetails] = useState({});
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  // const [questionsData, setquestionsData] = useState([
  //   {
  //     id: 1,
  //     q: "Why are wax-based perfumes an eco-friendly choice?",
  //     a: "Wax-based perfumes, especially those with a beeswax base, are an eco-friendly option because they don't involve any harmful chemical processes. The beeswax used in these fragrances aligns with sustainable practices, making it a green and guilt-free fragrance choice.",
  //     extra_question: "",
  //     a_length: 38,
  //   },
  //   {
  //     id: 2,
  //     q: "Can you reveal the secret of solid perfumes\u2019 longevity?",
  //     a: "Certainly, the secret to the longevity of solid perfumes lies in their concentrated formulation and the wax base. The beeswax or other wax forms a shield, holding the fragrance close to your skin and releasing it gradually over an extended period of time, thereby ensuring the perfume's long-lasting effect.",
  //     extra_question: "",
  //     a_length: 49,
  //   },
  //   {
  //     id: 3,
  //     q: "What is one of the standout features of unisex wax-based scents?",
  //     a: "Unisex wax-based scents are revolutionary because they break down traditional fragrance stereotypes. They offer a wide spectrum of notes, from warm and spicy to fresh and citrusy, that can be appreciated by all, demonstrating the celebration of individuality.",
  //     extra_question: "",
  //     a_length: 38,
  //   },
  //   // {
  //   //   id: 4,
  //   //   q: "Write an outline Benefits of Solid Perfumes for blog title: Exploring the Marvels of Solid Perfumes",
  //   //   a: "Delving further into the world of solid perfumes, we find numerous attributes that make them particularly irresistible to fragrance enthusiasts. Chief among these benefits is their long-lasting scent. Contrary to conventional liquid perfumes that can disappear within just a few hours, these solid counterparts promise a fragrant trail that lingers the entire day.\n\nInfused with natural apothecary, these perfumes not only add a scent but double up as a skincare savior, imbuing your precious skin with moisturizing properties. When it comes to convenience, their small, compact, and spill-proof nature makes them profoundly travel-friendly, able to safely accompany you on all your adventures.\n\nThe application of these scents is an appealingly tactile experience, reaching an epitome of simplicity yet personal connection. Since they are alcohol-free, they are a safer choice for your skin and hair, and also provide a more subtle, personal fragrance that invites someone to come closer to notice, rather than overwhelming them from a distance. [150 words]",
  //   //   extra_question:
  //   //     'You are an SEO Generate outline content given keywords and end it for number of words. \nNote :- Not to exceed the given number of words.\n\nThis outline is one of many outlines in an blog, so the content you generated is related to it\'s given keywords only.\n\nblog name - Exploring the Marvels of Solid Perfumes\n\nAll outlines:-\n {"Introduction": [["solid perfumes", "unique fragrance products", "rising popularity", "natural ingredients", "portability", "sustainability"],{"percentage": "13"}],"Benefits of Solid Perfumes": [["long-lasting scent", "moisturizing properties", "travel-friendly", "easy application", "alcohol-free", "subtle fragrance"],{"percentage": "58"}],"How to Choose and Use Solid Perfumes": [["fragrance preferences", "skin type considerations", "application techniques", "layering scents", "storage tips"],{"percentage": "29"}]}\n\nGenerate content of outline name:- Benefits of Solid Perfumes\nkeywords:- [\'long-lasting scent\', \'moisturizing properties\', \'travel-friendly\', \'easy application\', \'alcohol-free\', \'subtle fragrance\']\nnumber of words = 150\nTone = Exploring the Marvels of Solid Perfumes\n',
  //   //   a_length: 159,
  //   // },
  //   // {
  //   //   id: 5,
  //   //   q: "Write 1 catchy instagram caption about Exploring the Marvels of Solid Perfumes with Hilarious tone.",
  //   //   a: '"Solid perfumes: Because dabbing is back, but this time it\'s not a dance move! \ud83d\ude02 #ScentedMarvels #SmellGoodFeelGood"\n',
  //   //   extra_question: "",
  //   //   a_length: 17,
  //   // },
  // ]);
  const [questionsData, setquestionsData] = useState([]);
  const [selectedQuestion, setselectedQuestion] = useState(0);
  const [writing_style, setwriting_style] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [questionsLoading, setquestionsLoading] = useState(false);
  const [openModal, setopenModal] = useState(undefined);
  const [questionsCameFromBackend, setquestionsCameFromBackend] =
    useState(false);
  const audioRef = React.createRef();
  const audioProgessRef = React.createRef();

  // textarea

  const calcHeight = (value) => {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    const newHeight = 170 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
  };
  const togglePlay = () => {
    if (isPlaying) {
      audioProgessRef.current.pause();
      audioRef.current.pause();
    } else {
      if (progressModal || questionsLoading) {
        audioProgessRef.current.currentTime = 0;
        audioProgessRef.current.volume = 0.1;
        audioProgessRef.current.loop = true;
        audioProgessRef.current.play();
      } else {
        audioRef.current.currentTime = 0;
        audioRef.current.volume = 0.1;
        audioRef.current.loop = true;
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };
  const handleChangeFileFun = async (e) => {
    // console.log(selectedContainerId)
    // return
    const files = Array.from(e.target.files);
    // console.log(files, "files");
    // console.log(uploadedItem, "uploaded item");
    if (files?.length > 0) {
      let element = files[0];
      setfile(element);
    }
    e.target.value = null; // Reset the file input value
    // setData(updatedData);
  };
  const saveCurrentStep = async (
    current_step,
    from = "first",
    close = false
  ) => {
    let questions_data;
    if (from != "first") {
      // console.log(current_step, "current_step came here");
      questions_data = questionsData?.map((item, index) => {
        return {
          ...item,
          step: index <= current_step ? true : false,
        };
      });
    } else {
      questions_data = questionsData?.map((item, index) => {
        return {
          ...item,
          step: false,
        };
      });
    }
    let data;
    if (current_step == 4) {
      data = {
        id: fileDetails?.id,
        question_data: questions_data,
        writing_style: writing_style,
        x_final: true,
      };
    } else {
      data = {
        id: fileDetails?.id,
        question_data: questions_data,
        writing_style: writing_style,
        x_final: false,
      };
    }

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/training/module/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      console.log(response, "response");
      if (!response.data.error) {
        setquestionsData(questions_data);
        if (current_step == 4) {
          setthirdModal(false);
          setprogressModal(false);
          setlastModal(true);
          setTimeout(() => {
            setlastModal(false);
            navigate("/template");
          }, 5000);
        }
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }

      console.log(error);
    }
  };
  const postWebsocketDetails = (data) => {
    if (ws) {
      ws?.close(); // Close the existing WebSocket connection if it exists
      setWs(null); // Reset the WebSocket state
    }
    setquestionsLoading(true);
    setselectedQuestion(0);
    setquestionsData([]);
    setProgress(20);
    setprogressingMessage("PROCESSING...");
    setTimeout(() => {
      setProgress(40);
      setprogressingMessage("ANALYZING...");
    }, 2000);
    setTimeout(() => {
      setProgress(60);
      setprogressingMessage("LEARNING YOUR STYLE...");
    }, 4000);
    setTimeout(() => {
      setProgress(80);
      setprogressingMessage("TAKING YOU TO STEP 2...");
    }, 6000);
    // const newWs = new WebSocket(
    //   `wss://model.dev-vison.infiniticube.in/generate_qa`
    // );
    const newWs = new WebSocket(
      `${process.env.REACT_APP_BASE_URL}/generate_qa`
    );

    setWs(newWs);
    let new_data = {
      token: localStorage.getItem("login_token"),
      content: data,
    };
    newWs.onopen = function open() {
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      const word = data.data;
      // const correctedJsonString = word.replace(/'/g, '"');
      const jsonData = JSON.parse(word || "[]");
      if (jsonData?.error) {
        setquestionsLoading(false);
        setfirstModal(false);
        setsecondModal(true);
        setthirdModal(false);
        setprogressModal(false);
        setlastModal(false);
        setsuccessFailureMessage(
          jsonData?.error || "Something went wrong, Please try again"
        );
        setopenFailureModal("dismissible");
        return;
      }

      if (jsonData?.length == 1 && "writing_style" in jsonData[0]) {
        // console.log(word, "word");
        // console.log(jsonData, "json data");
        setwriting_style(jsonData[0]?.writing_style);
        setquestionsLoading(false);
      } else {
        setquestionsData((prev) => {
          if (prev.length == 0) {
            return [...jsonData];
          } else {
            let sort = jsonData?.sort((a, b) => a.a_length - b.a_length);
            return [...prev, ...sort];
          }
        });
      }

      setTimeout(() => {
        setprogressModal(false);
        setthirdModal(true);
      }, 200);

      if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
      } else {
      }
    };

    newWs.onclose = () => {
      setquestionsLoading(false);
      console.log("WebSocket connection closed.");
    };

    newWs.onerror = (error) => {
      setquestionsLoading(false);
      console.error("WebSocket error:", error);
    };
  };
  const handleUploadFn = async () => {
    // postWebsocketDetails();
    // return;
    if (!file) {
      return;
    }
    const formdata = new FormData();
    let element = file;
    formdata.append("vsn_file", element, element?.name);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/training/module/upload`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: formdata,
      });
      if (!response.data.error) {
        setfileDetails(response.data);
        postWebsocketDetails(response.data.data);
      } else {
        setfirstModal(false);
        setprogressModal(false);
        setsecondModal(true);
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setfirstModal(false);
      setprogressModal(false);
      setsecondModal(true);
    }
  };
  const checkQuestionExists = () => {
    if (
      selectedQuestion < 0 ||
      selectedQuestion > questionsData?.length - 1 ||
      questionsData?.length == 0
    ) {
      return false;
    }
    return true;
  };
  const getQuestionsData = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/training/module/pending`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
      // setfirstModal(true);
      // setlastModal(false);
      // return;
      if (!response.data.error) {
        let question_data = response?.data?.usermodel?.question_data;
        setfileDetails({
          ...response?.data?.usermodel,
          id: response?.data?.usermodel?._id,
        });
        setquestionsData(question_data || []);
        setwriting_style(response?.data?.usermodel?.writing_style);
        let findStepFalse = 0;
        if (Array.isArray(question_data) && question_data.length === 5) {
          let trueCount = 0;
          for (let i = 0; i < question_data.length; i++) {
            const element = question_data[i];
            if (element.step == true) {
              trueCount++;
            }
          }
          for (let i = 0; i < question_data.length; i++) {
            const element = question_data[i];
            if (element.step == false) {
              findStepFalse = i;
              break;
            }
          }
          if (trueCount == 5 && !response?.data?.usermodel?.job_id) {
            setselectedQuestion(4);
            setfirstModal(false);
            setsecondModal(false);
            setthirdModal(true);
            setquestionsCameFromBackend(true);
            return;
          }
          if (trueCount == 5) {
            setlastModal(true);
            return;
          }
          setselectedQuestion(findStepFalse);
          setfirstModal(false);
          setsecondModal(false);
          setthirdModal(true);
          setquestionsCameFromBackend(true);
        } else {
          setquestionsData([]);
          setTimeout(() => {
            postWebsocketDetails(response.data?.usermodel?.contents);
            setfirstModal(false);
            setsecondModal(false);
            setthirdModal(true);
          }, 200);
          setquestionsCameFromBackend(false);
        }
      } else {
        setfirstModal(true);
      }
    } catch (error) {
      setfirstModal(true);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }

      console.log(error);
    }
  };
  // console.log(questionsData, "questions data");
  useEffect(() => {
    getQuestionsData();
  }, []);
  useEffect(() => {
    if (questionsData?.length > 0) {
      audioRef?.current?.pause();
      audioProgessRef?.current?.pause();
      setIsPlaying(false);
    }
    // return;
    if (
      questionsData?.length == 5 &&
      !questionsLoading &&
      !questionsCameFromBackend
    ) {
      let findStepFalse = 0;
      let trueCount = 0;
      for (let i = 0; i < questionsData.length; i++) {
        const element = questionsData[i];
        if (element?.step == true) {
          trueCount++;
        }
      }
      if (trueCount == 5) {
        setlastModal(true);
        return;
      }

      for (let i = 0; i < questionsData.length; i++) {
        const element = questionsData[i];
        if (!element.step || element.step == false) {
          findStepFalse = i;
          break;
        }
      }
      // console.log(findStepFalse, "findStepFalse");
      if (findStepFalse == 0) {
        saveCurrentStep(findStepFalse, "first", false);
      } else {
        saveCurrentStep(findStepFalse - 1, "second", false);
      }
    }
  }, [questionsData?.length, questionsLoading, questionsCameFromBackend]);

  useEffect(() => {
    if (secondModal) {
      audioRef?.current?.pause();
      audioProgessRef?.current?.pause();
      setIsPlaying(false);
    }
  }, [secondModal]);

  return (
    <>
      <audio ref={audioRef} src={gameStartMusic} loop />
      <audio ref={audioProgessRef} src={progressingScreen} loop />
      {/* Screen - 0 */}
      <div className="main-body">
        <div className="main-wrapper" hidden1>
          <div className="relative">
            <div className="cloud-box">
              <div id="clouds">
                <div className="cloud x1">
                  <img src="images/cloud1.svg" alt="cloud" />
                </div>
                <div className="cloud x2">
                  <img src="images/cloud2.svg" alt="cloud" />
                </div>
                <div className="cloud x3">
                  <img src="images/cloud3.svg" alt="cloud" />
                </div>
              </div>
            </div>
          </div>

          <div className={`popup-match ${firstModal && "active"}`}>
            <div className="pop-wrapper">
              <div className="popup-inner">
                <div className="content-box">
                  <div className="modal-bg text-center text-[white]">
                    <div className="flex justify-center mt-[-25px] training-bg">
                      <img
                        src="images/model-training-text.svg"
                        className="w-[280px]"
                      />
                    </div>
                    <h1 className="text-[22px] font-[700]">
                      Train Your Model In Easy 5 Steps
                    </h1>

                    <button
                      className="close-btn"
                      onClick={() => {
                        navigate("/template");
                      }}
                    >
                      <img src="images/close.png" className="" />
                    </button>

                    <div className="flex justify-center relative">
                      <div className="break-all text-center px-[6.5%] absolute top-[17%]">
                        <p className="text-[#FFFA84] text-[20px] font-[900] italic">
                          Validate or Correct The Generated Answers, If The
                          Generated Answers Are Not In Your Writing Style.
                        </p>
                      </div>
                      <div className="content-inner">
                        <div className="content-col">
                          <div className="imgcard-wrapper">
                            <div className="img-card">
                              <div className="dark-blue">
                                <div className="flex justify-center w-full relative">
                                  <div className="upload-box w-full mt-6 h-full flex flex-col justify-center">
                                    <div className="px-4 text-center pt-6">
                                      <span className="text-[18px] font-[700] text-[white]">
                                        Upload Your Best Blog
                                      </span>
                                    </div>
                                    <div className="flex justify-center pb-3">
                                      <div className="in-f-box max-w-[68px]">
                                        <input type="file" />
                                        <span>
                                          <img src="images/file-upload-btn.svg" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="img-card">
                              <div className="light-blue">
                                <div className="text-center w-full">
                                  <svg className="icon text-[50px] my-6">
                                    <use href="#icon_one"></use>
                                  </svg>
                                  <p className="text-[26px] font-[700]">
                                    Question
                                  </p>
                                  <p className="text-[26px] font-[700]">&</p>
                                  <p className="text-[26px] font-[700]">
                                    Answer
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="img-card">
                              <div className="light-blue">
                                <div className="text-center w-full">
                                  <svg className="icon text-[50px] my-6">
                                    <use href="#icon_two"></use>
                                  </svg>
                                  <p className="text-[26px] font-[700]">
                                    Question
                                  </p>
                                  <p className="text-[26px] font-[700]">&</p>
                                  <p className="text-[26px] font-[700]">
                                    Answer
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="img-card">
                              <div className="light-blue">
                                <div className="text-center w-full">
                                  <svg className="icon text-[50px] my-6">
                                    <use href="#icon_three"></use>
                                  </svg>
                                  <p className="text-[26px] font-[700]">
                                    Question
                                  </p>
                                  <p className="text-[26px] font-[700]">&</p>
                                  <p className="text-[26px] font-[700]">
                                    Answer
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="img-card">
                              <div className="light-blue">
                                <div className="text-center w-full">
                                  <svg className="icon text-[50px] my-6">
                                    <use href="#icon_four"></use>
                                  </svg>
                                  <p className="text-[26px] font-[700]">
                                    Question
                                  </p>
                                  <p className="text-[26px] font-[700]">&</p>
                                  <p className="text-[26px] font-[700]">
                                    Answer
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="img-card">
                              <div className="light-blue">
                                <div className="text-center w-full">
                                  <svg className="icon text-[50px] my-6">
                                    <use href="#icon_five"></use>
                                  </svg>
                                  <p className="text-[26px] font-[700]">
                                    Question
                                  </p>
                                  <p className="text-[26px] font-[700]">&</p>
                                  <p className="text-[26px] font-[700]">
                                    Answer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="button-image mt-[35px]"
                      onClick={() => {
                        setfirstModal(false);
                        setsecondModal(true);
                        setprogressModal(false);
                      }}
                    >
                      <img src="images/button-start.svg" alt="start button" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`popup-match ${secondModal && "active"}`}>
            <div className="pop-wrapper">
              <div className="popup-inner">
                <div className="content-box">
                  <div className="modal-bg text-center text-[white]">
                    <div className="flex justify-center mt-[-25px] training-bg">
                      <img
                        src="images/model-training-text.svg"
                        className="w-[280px]"
                        alt="model-training"
                      />
                    </div>
                    <h1 className="text-[22px] font-[700]">
                      Upload your best blog
                      <br />
                      Please upload a blog post containing more than 300 words
                      but fewer than 1500 words.
                    </h1>

                    <div className="step-box flex">
                      <img src="images/step.svg" alt="step" />
                      <span className="text-[22px] font-[700] text-[white] ms-4">
                        Step 01
                      </span>
                    </div>

                    <button
                      className="close-btn"
                      onClick={() => {
                        navigate("/template");
                      }}
                    >
                      <img src="images/close.png" className="" alt="close" />
                    </button>

                    <div
                      className="flex justify-center"
                      style={{
                        marginTop: "-20px",
                      }}
                    >
                      <div className="content-inner">
                        <div className="content-col">
                          <div className="imgcard-wrapper">
                            <div className="flex justify-center max-h-[300px] w-full relative">
                              <img src="images/upload-bg.png" alt="upload" />
                              <div className="upload-box mt-4">
                                <div className="flex justify-center py-3">
                                  <div className="in-f-box">
                                    <input
                                      type="file"
                                      accept=".doc,.docx"
                                      onChange={handleChangeFileFun}
                                    />
                                    <span>
                                      <img
                                        src="images/file-upload-btn.svg"
                                        alt="upload"
                                      />
                                    </span>
                                  </div>
                                </div>
                                {!file ? (
                                  <span className="text-[22px] font-[700] text-[white]">
                                    Upload Your Best Blog
                                  </span>
                                ) : (
                                  <div className="flex items-center text-[22px] font-[700] text-[white]">
                                    {file?.name}
                                    <button
                                      className="h-[24px] w-[24px] ms-3"
                                      onClick={() => {
                                        setfile(null);
                                      }}
                                    >
                                      <img
                                        src="images/close.png"
                                        className=""
                                        alt="close"
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="button-image mt-[10px]"
                      onClick={() => {
                        if (!file) {
                          return;
                        }
                        setfirstModal(false);
                        setsecondModal(false);
                        setopenModal("dismissible");
                        // audioProgessRef.current.currentTime = 0;
                        // audioProgessRef.current.volume = 0.1;
                        // audioProgessRef.current.loop = true;
                        // audioProgessRef.current.play();
                        // setIsPlaying(true);
                        // handleUploadFn();
                        // setfirstModal(false);
                        // setsecondModal(false);
                        // setprogressModal(true);
                      }}
                    >
                      <img src="images/upload-btn.svg" alt="start button" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`popup-match ${thirdModal && "active"}`}>
            <div className="pop-wrapper">
              {checkQuestionExists() ? (
                <div className="popup-inner">
                  <div className="content-box">
                    <div className="modal-bg text-center text-[white]">
                      <div className="flex justify-center mt-[-25px] training-bg">
                        <img
                          src="images/model-training-text.svg"
                          className="w-[280px]"
                          alt="training"
                        />
                      </div>
                      <div className="break-all text-center px-[6.5%]">
                        <h1 className="text-[26px] font-[700]">
                          {selectedQuestion <= questionsData?.length - 1
                            ? questionsData[selectedQuestion]?.q
                            : ""}
                        </h1>
                      </div>

                      <div className="step-box flex">
                        <img src="images/step.svg" alt="step" />
                        <span className="text-[22px] font-[700] text-[white] ms-4">
                          Step 0{selectedQuestion + 2}/ 06
                        </span>
                      </div>

                      <button
                        className="close-btn"
                        onClick={() => {
                          navigate("/template");
                        }}
                      >
                        <img src="images/close.png" className="" alt="close" />
                      </button>

                      <div className="flex justify-center">
                        <div className="content-inner">
                          <div className="content-col">
                            <div className="imgcard-wrapper textarea-content">
                              <div className="flex justify-center w-full relative">
                                <div className="text-box max-w-[840px]">
                                  <textarea
                                    className="resize-ta mt-[-200px]"
                                    onChange={(e) => {
                                      setquestionsData((prev) =>
                                        prev?.map((item) => {
                                          if (
                                            item?.q ===
                                            questionsData[selectedQuestion]?.q
                                          ) {
                                            return {
                                              ...item,
                                              a: e.target.value,
                                            };
                                          }
                                          return item;
                                        })
                                      );
                                    }}
                                    style={{
                                      height:
                                        calcHeight(
                                          selectedQuestion <=
                                            questionsData?.length - 1
                                            ? questionsData[selectedQuestion]?.a
                                            : ""
                                        ) + "px",
                                    }}
                                    value={
                                      selectedQuestion <=
                                      questionsData?.length - 1
                                        ? questionsData[selectedQuestion]?.a
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                              <div className="break-all text-center px-[4.5%]">
                                <p className="text-[#FFFA84] text-[20px] font-[900] italic">
                                  Validate or Correct The Generated Answers, If
                                  The Generated Answers Are Not In Your Writing
                                  Style.
                                </p>
                              </div>
                              <div className="flex justify-center w-full mt-4 correction-btn">
                                {selectedQuestion > 0 && (
                                  <button
                                    type="button"
                                    className="me-3"
                                    onClick={() => {
                                      if (selectedQuestion == 0) {
                                        return;
                                      }
                                      saveCurrentStep(
                                        selectedQuestion - 1,
                                        "second",
                                        false
                                      );
                                      setselectedQuestion((prev) => prev - 1);
                                    }}
                                  >
                                    <img
                                      src="images/backbutton.svg"
                                      alt="correct"
                                    />
                                  </button>
                                )}

                                <button
                                  type="button"
                                  className="ms-3"
                                  onClick={() => {
                                    // if (
                                    //   selectedQuestion ==
                                    //   questionsData.length - 1
                                    // ) {
                                    //   return;
                                    // }
                                    saveCurrentStep(
                                      selectedQuestion,
                                      "second",
                                      false
                                    );
                                    setselectedQuestion((prev) => prev + 1);
                                  }}
                                >
                                  <img
                                    src="images/submit.svg"
                                    alt="not correct"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedQuestion == 4 && (
                        <div className="flex justify-center msg-bg">
                          <img src="images/msg-bg.svg" className="" alt="msg" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="popup-inner">
                  <div className="flex justify-center relative">
                    <div className="animation">
                      <div className="test spin-test"></div>
                      <div className="one spin-one"></div>
                      <div className="two spin-two"></div>
                      <div className="three spin-one"></div>
                      <div className="four spin-one"></div>
                      <div className="five spin-one"></div>
                    </div>
                  </div>

                  <div className="progressing-bar">
                    <div
                      className="progressing-in"
                      style={{ width: `${80}%` }}
                    />
                    {/* <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={progress}
                                    onChange={handleInputChange}
                                />
                                <h2>{`${progress}%`}</h2> */}
                    <h1 className="pt-6 text-[50px] text-[white]">
                      {selectedQuestion > 4
                        ? "SUBMITTING..."
                        : "TAKING YOU TO NEXT STEP..."}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`popup-match ${progressModal && "active"}`}>
            <div className="pop-wrapper">
              <div className="popup-inner">
                <div className="flex justify-center relative">
                  <div className="animation">
                    <div className="test spin-test"></div>
                    <div className="one spin-one"></div>
                    <div className="two spin-two"></div>
                    <div className="three spin-one"></div>
                    <div className="four spin-one"></div>
                    <div className="five spin-one"></div>
                  </div>
                </div>

                <div className="progressing-bar">
                  <div
                    className="progressing-in"
                    style={{ width: `${progress}%` }}
                  />
                  {/* <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={progress}
                                    onChange={handleInputChange}
                                />
                                <h2>{`${progress}%`}</h2> */}
                  <h1 className="pt-6 text-[50px] text-[white]">
                    {progressingMessage}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className={`popup-match ${lastModal && "active"}`}>
            <div className="pop-wrapper">
              <div className="popup-inner">
                <div className="content-box">
                  <div className="modal-bg text-center text-[white]">
                    <div className="flex justify-center">
                      <div className="content-inner">
                        <div className="content-col">
                          <div className="imgcard-wrapper textarea-content">
                            <div className="flex justify-center w-full relative">
                              <div className="flex justify-center mt-[8%] training-bg">
                                <img
                                  src="images/ready.png"
                                  className="w-[280px]"
                                  alt="ready"
                                />
                              </div>
                            </div>
                            <div className="flex text-center w-full mt-6 correction-btn">
                              <h3 className="text-[28px] font-[800]">
                                The model training will run in background and we
                                will notify you when{" "}
                                <span className="text-[#F4EA00]">
                                  your own exclusive model is ready
                                </span>{" "}
                                to use.
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isPlaying ? (
          <button
            type="button"
            className="mute-btn w-[60px] h-[60px] fixed top-[2%] right-[2%] z-[1000] overflow-hidden transition duration-700 ease-in-out hover:scale-125"
            onClick={togglePlay}
          >
            <img
              src="images/unmute.png"
              className="w-full object-contain"
              alt="mute"
            />
          </button>
        ) : (
          <button
            type="button"
            className="mute-btn w-[60px] h-[60px] fixed top-[2%] right-[2%] z-[1000] overflow-hidden transition duration-700 ease-in-out hover:scale-125"
            onClick={togglePlay}
          >
            <img
              src="images/mute.png"
              className="w-full object-contain"
              alt="mute"
            />
          </button>
        )}
      </div>
      <div className={`popup-match ${openModal && "active"}`}>
        <div className="pop-wrapper">
          <div className="popup-inner">
            <div className="content-box">
              <div className="modal-permission-bg text-center text-[white]">
                <div className="flex justify-center training-bg">
                  <img src="images/areyousure.svg" className="w-[280px]" />
                </div>
                <div className="break-all text-center px-[22%]">
                  <h1 className="text-[22px] font-[700] pt-4">
                    You Want To Upload This File, Once Uploaded, <br />
                    You Can't Undo This Section{" "}
                  </h1>
                </div>
                <div className="flex justify-center">
                  <div className="content-inner">
                    <div className="content-col">
                      <div className="imgcard-wrapper textarea-content">
                        <div className="flex justify-center w-full mt-4 correction-btn">
                          <button
                            type="button"
                            className="me-3"
                            onClick={() => {
                              setopenModal(undefined);
                              setsecondModal(true);
                            }}
                          >
                            <img src="images/cancel.svg" />
                          </button>
                          <button
                            type="button"
                            className="ms-3"
                            onClick={() => {
                              setopenModal(undefined);
                              audioRef?.current?.pause();
                              audioProgessRef.current.currentTime = 0;
                              audioProgessRef.current.volume = 0.1;
                              audioProgessRef.current.loop = true;
                              audioProgessRef.current.play();
                              setIsPlaying(true);
                              handleUploadFn();
                              setfirstModal(false);
                              setsecondModal(false);
                              setprogressModal(true);
                            }}
                          >
                            <img src="images/submit.svg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={() => {
          setopenModal(undefined);
          setsecondModal(true);
        }}
        zIndex={10000}
      >
        <Modal.Body className="p-0">
          <div className="p-4 pb-8 pt-10">
            <h3 className="text-center font-bold text-[26px]">Are You Sure?</h3>
            <p className="text-center font-bold text-[20px] text-[#7D7D7D]">
              You want to Upload this file, once uploaded, you can't undo this
              action.
            </p>
            <div className="pt-4 flex justify-between px-4">
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold text-black hover:text-[white] border border-[black] hover:border-[#54BD3A] hover:bg-[#54BD3A]"
                onClick={() => {
                  setopenModal(undefined);
                  setsecondModal(true);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold text-[white] border border-[#ED400A] bg-[#ED400A]"
                onClick={() => {
                  setopenModal(undefined);
                  audioProgessRef.current.currentTime = 0;
                  audioProgessRef.current.volume = 0.1;
                  audioProgessRef.current.loop = true;
                  audioProgessRef.current.play();
                  setIsPlaying(true);
                  handleUploadFn();
                  setfirstModal(false);
                  setsecondModal(false);
                  setprogressModal(true);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default Game;
