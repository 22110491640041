/* eslint-disable eqeqeq */
import WebSocket from "isomorphic-ws";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import axios from "axios";
import useCommonFunctions from "./common-functions";
const useWebsocketHook = () => {
  const [loader, setloader] = useState(false);
  const [stringWords, setstringWords] = useState("");
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings, contentEditableRef,savingFileData, setsavingFileData } =
    context;
  const { replacingWordCount, getPlanDetails } = useCommonFunctions();

  const navigate = useNavigate();
  // console.log(params,"params in useWebsocketHook");
  const [ws, setWs] = useState(null);

  const callUpdateToFileApi = async (
    data,
    string,
    correctedJsonString,
    selected_file_data,
    selectedFile
  ) => {
    // let replace1 = string.replaceAll("&&", "<br/>");
    let replace = string.replaceAll("####", "<br/>");
    try {
      let data_incoming1 = { ...data };
      delete data_incoming1?.content_title;
      delete data_incoming1?.content_template;
      delete data_incoming1?.contents;
      data_incoming1.language = data_incoming1.language || "English";
      let workspace_id = JSON.parse(
        localStorage.getItem("selected_workspace")
      )?.workspace_id;
      let contentData = {
        user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user?.id,
        content_id:
          selected_file_data.content_id || selected_file_data.id || "", // if available other wise send blank
        workspace_id: workspace_id, //mandatory of selected workspace
        space_id: selected_file_data?.space_id || "", // if available other wise send blank
        folder_id: selected_file_data?.folder_id || "", // if available other wise send blank
        content_title: data?.content_title,
        content_template: data?.content_template,
        contents: replace,
        content_settings: data_incoming1,
        ...correctedJsonString,
        blog_step: "",
        outline_type: "",
        no_of_outline: "",
        outline_text: {},
      };
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: contentData,
      });
      // console.log(response, "response");
      const selectedFilePresent = JSON.parse(
        localStorage.getItem("selected_file")
      );

      if (!response.data.error) {
        let content_idPresent =
          selected_file_data.content_id || selected_file_data.id || "";
        let content_idFromResponse = response.data.content_id;
        if (content_idPresent == content_idFromResponse) {
          localStorage.setItem(
            "selected_file",
            JSON.stringify({
              ...selectedFile,
              ...response.data,
              contents: replace,
              content_settings: data_incoming1,
            })
          );
        }
        replacingWordCount(response.data.bal_template_word);
        setselectedFile(response.data);
        console.log(response, "response...");
        setsavingFileData(false);
      }else{
        setsavingFileData(false);
      }
      
    } catch (error) {
      setsavingFileData(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  // console.log(ws, "ws");
  const postWebsocketDetails = (data_incoming, api) => {
    console.log(data_incoming, "data_incoming api");

    if (data_incoming?.content_template !== "Three Step Blog") {
      setstringWords("");
    }
    if (ws) {
      ws?.close();
      setWs(null);
    }
    const newWs = new WebSocket(`${process.env.REACT_APP_BASE_URL}/${api}`);

    setWs(newWs);
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));

    let selected_file_data = {
      content_id: selectedFile?.content_id || selectedFile?.id || "",
      space_id: selectedFile?.space_id || "",
      folder_id: selectedFile?.folder_id || "",
    };
    let string = "";
    newWs.onopen = function open() {
      setloader(true);
      let new_data = {
        ...data_incoming,
        token: localStorage.getItem("login_token"),
      };
      // let selected_file_data = {
      //   content_id: selectedFile?.content_id || selectedFile?.id || "",
      //   space_id: selectedFile?.space_id || "",
      //   folder_id: selectedFile?.folder_id || "",
      //   content_title: data_incoming?.content_title || "",
      //   content_template: data_incoming?.content_template || "",
      // };
      // new_data["return_data"]=selected_file_data;
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (!new_data.language) {
        new_data.language = language || "English";
      }
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          new_data["model"] = model_id;
        } else {
          new_data["model"] = "model_3";
        }
      }
      delete new_data?.content_template;
      delete new_data?.content_title;
      delete new_data?.number_of_words;
      delete new_data?.contents;
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    };
    setstringWords("");

    newWs.onmessage = function incoming(data) {
      console.log("received", data.data);

      const word = data.data;
      if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
        if (data_incoming?.content_template !== "One Step Blog") {
          if (contentEditableRef && contentEditableRef.current) {
            contentEditableRef.current.style.border = "2px solid #ccc";
          }
        }
        setloader(false);
        const correctedJsonString = word.replace(/'/g, '"');
        console.log(
          JSON.parse(correctedJsonString),
          "corrected json string..."
        );
        if (
          data_incoming?.content_template != "One Step Blog" &&
          data_incoming?.content_template != "Three Step Blog"
        ) {
          setselectedContentSettings(data_incoming);
          localStorage.setItem(
            "selectedContentSettings",
            JSON.stringify(data_incoming)
          );
          callUpdateToFileApi(
            data_incoming,
            string,
            JSON.parse(correctedJsonString),
            selected_file_data,
            selectedFile
          );
        } else {
          callUpdateToFileApi(
            data_incoming,
            data_incoming?.contents||"",
            JSON.parse(correctedJsonString),
            selected_file_data,
            selectedFile
          );
        }
        // if (data_incoming?.template === "two step") {
        //   data_incoming.three_step_titles = string;
        //   contentEditableRef.current.innerHTML = "";
        //   callUpdateToFileApi(
        //     data_incoming,
        //     "",
        //     JSON.parse(correctedJsonString),
        //     selected_file_data
        //   );
        //   setselectedContentSettings(data_incoming);
        //   localStorage.setItem(
        //     "selectedContentSettings",
        //     JSON.stringify(data_incoming)
        //   );
        // }
      } else {
        if (word?.trim() == "Token Expired") {
          localStorage.removeItem("login_token");
          localStorage.removeItem("login_data");
          navigate("/");
        }
        if (contentEditableRef && contentEditableRef.current) {
          contentEditableRef.current.style.border =
            "2px solid rgb(56, 140, 197)";
        }
        localStorage.setItem(
          "selected_file",
          JSON.stringify({
            ...selectedFile,
            content_settings: {
              ...selectedFile?.content_settings,
              media_arr: [],
              posted_tweets_arr: [],
            },
            contents: null,
          })
        );
        string += word;
        if (data_incoming?.content_template === "Three Step Blog") {
          string = string.replaceAll("\n", "<br/>");
        }
        setstringWords(string);
      }
    };

    newWs.onclose = () => {
      setselectedContentSettings(data_incoming);
      localStorage.setItem(
        "selectedContentSettings",
        JSON.stringify(data_incoming)
      );
      setloader(false);
      console.log("WebSocket connection closed.");
    };

    newWs.onerror = (error) => {
      setselectedContentSettings(data_incoming);
      localStorage.setItem(
        "selectedContentSettings",
        JSON.stringify(data_incoming)
      );
      setloader(false);
      console.error("WebSocket error:", error);
    };
  };
  const closeFunction = () => {
    if (ws&&ws.readyState==WebSocket.OPEN) {
      setsavingFileData(true);
      const dataToSend = JSON.stringify({
        stop: "True",
      });
      ws.send(dataToSend);
      // ws.close(); // Close the existing WebSocket connection if it exists
    }
  };

  return {
    postWebsocketDetails,
    closeFunction,
    loader,
    stringWords,
    setstringWords,
    ws,
  };
};
export default useWebsocketHook;
