/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

function LoaderNew({from="normal"}) {
  const contentsArray = [
    {
      content_title:
        "AI should be used to help reduce some of the world’s worst inequities",
      content_author: "Bill Gates",
    },
    {
      content_title:
        "Artificial Intelligence is one of the most profound things we’re working on as humanity. it is more profound than fire or electricity",
      content_author: "Sundar Pichai",
    },
    {
      content_title:
        "As more and more AI entering into the world, more and more emotional intelligence must enter into leadership.",
      content_author: "Amit roy",
    },
  ];
  const selectedTemplate=0;

  useEffect(() => {
    if(from==="dynamic_page"){
      return;
    }
  }, []);


  return (
    <>
      <div className="h-screen">
        <img
          className="loginfram w-full h-full absolute z-[-10] object-fill"
          src={`${window.location.origin}/images/loader-bg.png`}
          alt="loader"
        />
        <img
          className="h-full absolute right-0 z-[-9]"
          src={`${window.location.origin}/images/asid-logo.png`}
          alt="logo"
        />
        <img
          className="h-[120px] w-[300px] absolute left-0 bottom-0 z-[-9]"
          src={`${window.location.origin}/images/left-circle.png`}
          alt="circle"
        />
        <img
          className=" absolute left-[50px] top-[40px] z-[-9]"
          src={`${window.location.origin}/images/arrow.png`}
          alt="circle"
        />
        <div className="w-full h-full px-[180px] flex items-center l">
          <div className="w-full">
            <div className="py-4 relative">
              <div>
                <svg className="icon text-[38px] absolute text-[#097BA9] right-[350px] top-[-50px]">
                  <use href="#icon_close"></use>
                </svg>

                <svg className="icon text-[38px] absolute text-[#9F3782] left-[80px] top-[0px]">
                  <use href="#icon_close"></use>
                </svg>

                <svg className="icon text-[38px] absolute text-[#9F3782] right-[180px] bottom-[-10px]">
                  <use href="#icon_close"></use>
                </svg>

                <svg className="icon text-[38px] absolute text-[#097BA9] left-[150px] bottom-[-50px]">
                  <use href="#icon_close"></use>
                </svg>
              </div>
              <h6 className="font-bold text-[40px] pb-4 pt-8 leading-[40px] text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784]">
                &quot;
                {contentsArray[selectedTemplate]?.content_title}
                {/* {displayAuthor && <>&quot;</>}
                {!displayAuthor && (
                  <>
                    {showCursor && <span style={{ color: "#1A71A7" }}>|</span>}
                  </>
                )} */}
              </h6>
              {/* {displayAuthor && ( */}
              <h6 className="font-bold text-[24px] text-end">
                {contentsArray[selectedTemplate]?.content_author}
              </h6>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoaderNew;
