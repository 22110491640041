/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar2 from "../components/NavBar2";
import WebSocket from "isomorphic-ws";
import moment from "moment";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import TextEditor from "../components/TextEditor.js";
import { Tooltip } from "react-tooltip";
import useCommonApi from "../hooks/common-apis.js";
import UserContext from "../context/UserContext.js";
import useCommonFunctions from "../hooks/common-functions.js";
import { useLocation } from "react-router-dom";
import { Remarkable } from "remarkable";
import { memo } from "react";
import PopupModals from "../components/PopupModals.js";
import axios from "axios";
import SuccessModal from "../components/SuccessModal.js";
import FailureModal from "../components/FailureModal.js";
import { Modal } from "flowbite-react";
import Joyride from "react-joyride";
import JoyrideComponent from "../components/JoyrideComponent.js";
const Chat = memo(function Chat() {
  const textareaRef = useRef(null);
  const viewFilesRef = useRef(null);
  const fileInfoRef = useRef(null);
  const [videoUrl, setvideoUrl] = useState("");
  const [chatData, setChatData] = useState([]);
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const { callSaveNewChat, getChatDetails } = useCommonApi();
  const {
    getDate,
    replacingWordCount,
    gettemplateWordCount,
    getPlanDetails,
    getFileIcon,
  } = useCommonFunctions();
  const context = useContext(UserContext);
  const { chatCalledOnce, loadingChat, setloadingChat, allrefs } = context;
  const location = useLocation();
  const [mounted, setmounted] = useState(false);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [displayEditor, setdisplayEditor] = useState(false);
  const [popupMessage, setpopupMessage] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [fileInfoApi, setFileInfoApi] = useState(null);
  const [fileInfoApiWs, setFileInfoApiWs] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadOpen, setuploadOpen] = useState(false);
  const [viewFilesClicked, setviewFilesClicked] = useState(false);
  const [openFileUrlClicked, setopenFileUrlClicked] = useState(false);
  const [urlInput, seturlInput] = useState("");
  const [chatFiles, setchatFiles] = useState([]);
  const buttonRef = useRef(null);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [showimgpop, setShowimgpop] = useState(false);
  const [imgURL, setImgURL] = useState({});
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [steps, setsteps] = useState([]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    // console.log(data, "data in joyride callback");
    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prevIndex) => prevIndex + 1); // Increment stepIndex on "next"
      } else if (action === "prev") {
        setStepIndex((prevIndex) => prevIndex - 1); // Decrement stepIndex on "back"
      }
    }
    // Handle when Joyride is finished or skipped
    if (status === "finished" || status === "skipped" || action === "close") {
      setRun(false); // Stop the tour
      setStepIndex(0); // Optionally reset the step index to 0
      if (allrefs?.asideRef?.current) {
        setTimeout(() => {
          allrefs.asideRef.current.style.overflowY = "auto";
        }, 500);
      }
    }
  };
  // useEffect(() => {
  //   const { trainingJson, settrainingJson } = allrefs;
  //   if (Object.keys(trainingJson || {}).length > 0) {
  //     let stepsArray = [];
  //     let keys = Object.keys(trainingJson);
  //     let { templates, youtube, chat, training } = trainingJson;
  //     let { aside, chatinputbox } = chat;
  //     if (!chatinputbox) {
  //       stepsArray.push({
  //         target: textareaRef?.current,
  //         content: "chat here with your query",
  //         disableBeacon: true,
  //       });
  //       stepsArray.push({
  //         target: buttonRef?.current,
  //         content: "click here to upload files and ask your query",
  //         disableBeacon: true,
  //       });
  //     }
  //     setsteps(stepsArray);
  //     setRun(stepsArray.length > 0);
  //   }
  // }, [allrefs?.trainingJson]);
  console.log(steps, "steps");
  // const [chatData, setChatData] = useState([
  //   {
  //     type: "sender",
  //     message: "Hello",
  //     timestamp: "2024-02-21T08:41:42.340Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Hi there! How can I assist you today?",
  //     timestamp: "2024-02-21T08:42:22.745Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you tel me the summary of the video?",
  //     timestamp: "2024-02-21T08:42:43.306Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'Based on the context provided, the video seems to be a tutorial on creating a progress bar in a web application. The speaker demonstrates how to add a progress bar that transitions from one step to another. They explain the process of creating a div with the class name "progress bar" and another div inside it for the actual progress bar. The speaker also mentions aligning the progress bar behind the steps and ensuring it remains aligned even when the page is resized. Additionally, they mention a link in the video description to check out Odin School and a card above their head to access the complete playlist.',
  //     timestamp: "2024-02-21T08:43:09.717Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you give one example based on he given?",
  //     timestamp: "2024-02-21T08:43:32.937Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Based on the given context, let's say we have a progress bar with 4 steps. The current step is 2 and the progress for step 2 is complete. The progress for step 1 is also complete. The progress for steps 3 and 4 is not complete yet.\n\nIn this example, the progress bar would look like this:\n\nStep 1: Complete\nStep 2: Complete\nStep 3: Incomplete\nStep 4: Incomplete\n\nThe progress bar would have a gray background color and the progress for completed steps would be green. The text color would be white. Additionally, there would be a tick mark next to the completed steps.",
  //     timestamp: "2024-02-21T08:44:03.408Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "j",
  //     timestamp: "2024-02-21T08:45:26.629Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I'm sorry, but I'm not sure what you're asking. Can you please provide more information or ask a specific question?",
  //     timestamp: "2024-02-21T08:45:34.389Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "kio",
  //     timestamp: "2024-02-21T08:45:41.354Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I'm sorry, but I'm not sure what you're asking. Can you please provide more information or ask a specific question?",
  //     timestamp: "2024-02-21T08:45:50.764Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "hello world",
  //     timestamp: "2024-02-21T08:46:40.813Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Hi there! How can I assist you today?",
  //     timestamp: "2024-02-21T08:46:45.132Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "what is your name?",
  //     timestamp: "2024-02-21T08:46:54.009Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I am an AI language model developed by OpenAI. I don't have a personal name, but you can call me Assistant. How can I assist you today?",
  //     timestamp: "2024-02-21T08:47:03.285Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can I name you?",
  //     timestamp: "2024-02-21T08:48:06.937Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I am an AI language model developed by OpenAI. I don't have a personal name, but you can call me Assistant. How can I assist you today?",
  //     timestamp: "2024-02-21T08:48:15.983Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "I will name you as akmal",
  //     timestamp: "2024-02-21T08:48:38.298Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Sure, you can call me Akmal. How can I assist you today?",
  //     timestamp: "2024-02-21T08:48:44.514Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you tell me summary in points?",
  //     timestamp: "2024-02-21T08:51:08.556Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'Sure! Here is a summary of the video in points:\n\n1. The video is a tutorial on creating a progress bar in a web application.\n2. The speaker demonstrates how to add a progress bar that transitions from one step to another.\n3. The process involves creating a div with the class name "progress bar" and another div inside it for the actual progress bar.\n4. The progress bar is aligned behind the steps and remains aligned even when the page is resized.\n5. The video description includes a link to check out Odin School and there is a card above the speaker\'s head to access the complete playlist.',
  //     timestamp: "2024-02-21T08:51:35.962Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "how to align progress bar in points?",
  //     timestamp: "2024-02-21T08:52:37.195Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'To align the progress bar, you can follow these steps:\n\n1. Create a div with the class name "progress bar".\n2. Inside the "progress bar" div, create another div for the actual progress bar.\n3. Apply the following styles to the "progress bar" div:\n   - Set the position to absolute.\n   - Use the top and margin-right properties to align it behind the steps.\n4. Resize the page and ensure that the progress bar remains aligned.\n\nBy following these steps, you should be able to align the progress bar in your web application.',
  //     timestamp: "2024-02-21T08:53:03.114Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "explain payment in points?",
  //     timestamp: "2024-02-21T08:55:37.838Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Here is an explanation of the payment step in points:\n\n1. The payment step is a part of the checkout process in e-commerce websites.\n2. After the customer enters their information and shipping details, they proceed to the payment step.\n3. In this step, the customer selects their preferred payment method, such as credit card, debit card, or online payment platforms like PayPal.\n4. The customer provides the necessary payment details, including card number, expiration date, and CVV code.\n5. Once the payment information is entered, the website securely processes the payment transaction.\n6. The customer may also have the option to save their payment information for future purchases.\n7. After the payment is successfully processed, the customer moves on to the final step of the checkout process, which is the product delivery.\n\nI hope this helps! Let me know if you have any more questions.",
  //     timestamp: "2024-02-21T08:56:16.292Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "explain customer info in points from video?",
  //     timestamp: "2024-02-21T08:58:53.012Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Here is an explanation of the customer information step in points from the video:<br/><br/>1. The video does not specifically mention the customer information step.<br/>2. However, based on common e-commerce practices, the customer information step typically involves collecting details such as name, email address, and shipping address.<br/>3. This information is necessary for processing the order and delivering the product to the customer.<br/>4. The customer may also have the option to create an account or log in if they are a returning customer.<br/>5. The customer information step usually comes before the payment step in the checkout process.<br/><br/>I hope this helps! Let me know if you have any more questions.",
  //     timestamp: "2024-02-21T08:59:22.216Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "It helps thank you",
  //     timestamp: "2024-02-21T08:59:52.620Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "You're welcome! I'm glad I could help. If you have any more questions, feel free to ask.",
  //     timestamp: "2024-02-21T09:00:01.040Z",
  //   },
  // ]);
  const [chatQuery, setChatQuery] = useState("");
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingChatCompleted, setisLoadingChatCompleted] = useState(false);

  const [stringWords, setstringWords] = useState("");
  const [ws, setWs] = useState(null);
  const chatLoadingRef = useRef();
  const lastChatRef = useRef();

  async function handleChatSend(e) {
    if (chatQuery?.trim() === "") return;
    // e.preventDefault();

    setIsLoadingChat(true);
    setisLoadingChatCompleted(true);
    setloadingChat(true);
    let newChatData1 = [...chatData];
    newChatData1 = newChatData1.filter((item) => item?.type !== "loader");
    let senderDiv = document.createElement("div");
    if (fileInfo) {
      // let fileDiv = document.querySelector(".file-card");
      let fileDiv = fileInfoRef.current?.cloneNode(true);
      fileDiv?.querySelector("button")?.remove();
      senderDiv.appendChild(fileDiv);

      // newChatData1.push({
      //   type: "doc",
      //   message: fileInfo?.path,
      //   timestamp: new Date(),
      // });
    }
    let chatQueryDiv = document.createElement("div");
    chatQueryDiv.innerHTML = getContent(chatQuery?.replaceAll(/\n/g, "<br/>"));
    senderDiv.appendChild(chatQueryDiv);
    newChatData1.push({
      type: "sender",
      message: senderDiv.outerHTML,
      timestamp: new Date(),
    });
    console.log(senderDiv, "div");
    let length = newChatData1.length;
    setChatData(newChatData1);

    let localFile = JSON.parse(localStorage.getItem("chat_file"));
    if (!localFile) {
      let generalFolders = chatCalledOnce?.general_folders;
      let todays_date = getDate();
      let todaysDateFolders = generalFolders?.find(
        (item) => item?.title == todays_date
      );
      let files = todaysDateFolders?.submenu;
      let fileName = "Untitled Chat_";
      let count = 0;
      for (let i = 0; i < files?.length; i++) {
        const element = files[i];
        if (element.title?.includes("Untitled")) {
          let split = element.title?.split("_");
          let fileNumber = parseInt(split[1]);
          count = Math.max(count, fileNumber);
        }
      }
      count = count + 1;
      fileName = fileName + count;
      let data = {
        thread_folder: getDate(),
        thread_name: fileName,
        thread_id: "",
        chat_time: new Date(),
        chat_type: "ask",
        chat_content: senderDiv.outerHTML,
        thread_type: "general",
      };
      let thread_id = await callSaveNewChat(data, "general");
      // if (fileInfo) {
      //   let data = {
      //     thread_folder: getDate(),
      //     thread_name: fileName,
      //     thread_id: thread_id,
      //     chat_time: new Date(),
      //     chat_type: "doc",
      //     chat_content: fileInfo?.path,
      //     thread_type: "general",
      //   };
      //   await callSaveNewChat(data, "general");
      // }
    } else {
      console.log("fileInfo", fileInfo);
      // if (fileInfo) {
      //   let data = {
      //     thread_folder: getDate(),
      //     thread_name: localFile?.thread_name,
      //     thread_id: localFile?.thread_id,
      //     chat_time: new Date(),
      //     chat_type: "doc",
      //     chat_content: fileInfo?.path,
      //     thread_type: "general",
      //   };
      //   await callSaveNewChat(data, "general");
      // }
      let data = {
        thread_folder: getDate(),
        thread_name: localFile?.thread_name,
        thread_id: localFile?.thread_id,
        chat_time: new Date(),
        chat_type: "ask",
        chat_content: senderDiv.outerHTML,
        thread_type: "general",
      };
      console.log("data", data);
      callSaveNewChat(data, "general");
    }

    setChatQuery("");
    // return;

    setFileInfo(null);
    setFileInfoApi(null);
    // Scroll to the bottom
    let newWs = ws;
    if (
      !newWs ||
      newWs?.readyState == newWs?.CLOSED ||
      newWs?.readyState == newWs?.CLOSING ||
      newWs?.readyState == newWs?.CONNECTING
    ) {
      console.log("websocket is closed condition");
      newWs = new WebSocket(
        `${process.env.REACT_APP_CHAT_BASE_URL}/chat_general`
      );
      setWs(newWs);
    }

    if (newWs?.readyState == newWs?.OPEN) {
      let id = JSON.parse(
        localStorage.getItem("chat_file")
      )?.thread_id?.toString();
      let new_data = {
        usr_id: JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: id,
        query: chatQuery,
        token: localStorage.getItem("login_token"),
        file_id: "",
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          new_data["model"] = model_id;
        } else {
          new_data["model"] = "model_3";
        }
      }
      // if(fileInfo){
      //   new_data["file_id"] = fileInfoApi?.file_id?.toString();
      // }
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    }
    let string = "";
    let thread_name = JSON.parse(
      localStorage.getItem("chat_file")
    )?.thread_name;
    let thread_id = JSON.parse(localStorage.getItem("chat_file"))?.thread_id;
    newWs.onopen = function open() {
      console.log("websocket opened");
      let id = JSON.parse(
        localStorage.getItem("chat_file")
      )?.thread_id?.toString();
      let new_data = {
        usr_id: JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: id,
        query: chatQuery,
        token: localStorage.getItem("login_token"),
        file_id: "",
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          new_data["model"] = model_id;
        } else {
          new_data["model"] = "model_3";
        }
      }
      // if(fileInfo){
      //   new_data["file_id"] = fileInfoApi?.file_id?.toString();
      // }
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
      console.log("sent to websocket on open", dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      const word = data.data;
      if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
        const correctedJsonString = word.replace(/'/g, '"');
        let data = {
          thread_folder: getDate(),
          thread_name: thread_name,
          thread_id: thread_id,
          chat_time: new Date(),
          chat_type: "ans",
          chat_content: string,
          thread_type: "general",
          ...JSON.parse(correctedJsonString),
        };
        setIsLoadingChat(false);
        setisLoadingChatCompleted(false);
        callSaveNewChat(data, "general");
        setloadingChat(false);
      } else {
        string = string + word;
        // string = string.replaceAll("\n", "<br/>");
        // console.log(string, "string...");
        if (string?.trim()?.length > 0) {
          setmounted(true);
          setChatData((prev) => {
            const updatedChatData = [...prev].filter(
              (item) => item != "loader"
            ); // Create a copy of the existing array
            updatedChatData[length] = {
              type: "receiver",
              message: string,
              timestamp: new Date(),
            };
            return updatedChatData;
          });
          setIsLoadingChat(false);
        }
      }

      // Scroll to the bottom
    };

    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
      setIsLoadingChat(false);
      setisLoadingChatCompleted(false);
      setChatData((prev) => prev.filter((item) => item?.type !== "loader"));
      setWs(null);
      setloadingChat(false);
    };
    newWs.onerror = (error) => {
      setChatData((prev) => prev.filter((item) => item?.type !== "loader"));
      setIsLoadingChat(false);
      setisLoadingChatCompleted(false);
      console.error("WebSocket error:", error);
      setWs(null);
      setloadingChat(false);
    };
  }
  console.log("chatData", chatData);
  const getChatFileTime = (dateStr) => {
    const formattedDate = moment(dateStr).format("DD MMMM, YYYY | hh:mm A");
    return formattedDate;
  };
  // Function to extract the desired part of the filename
  const extractFilename = (url) => {
    // Split the URL by '/' and get the last part (the filename)
    const ext = url.split(".").pop();
    if (
      ext === "pdf" ||
      ext === "doc" ||
      ext === "docx" ||
      ext === "xls" ||
      ext === "xlsx" ||
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png"
    ) {
      const parts = url.split("/");
      const fullFilename = parts[parts.length - 1];

      // Split the filename by '-' and remove the extension
      const [filename] = fullFilename.split("-");

      return filename;
    } else {
      return url;
    }
  };

  const getContent = (message) => {
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
    });

    const rendered = md.render(message || "");

    // Wrap code blocks with a div, add language label, and a copy button
    const wrappedCode = rendered.replace(
      /<pre><code class="language-([^"]+)">([\s\S]*?)<\/code><\/pre>/g,
      (match, p1, p2) => {
        // const encodedCode = encodeURIComponent(p2);
        const encodedCode = p2;
        return `
          <div class="code-block">
            <div class="code-header">
              <span class="language-label">${p1}</span>

              <button class="copy-btn" data-code="${encodedCode}">
                <svg class="icon">
                  <use href="#icon_copy"></use>
                </svg>
                Copy code
              </button>
            </div>
            <pre><code class="language-${p1}">${p2}</code></pre>
          </div>
        `;
      }
    );

    return wrappedCode;
  };
  const callDeleteFileApiNode = async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/delete/upload_file`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
    } catch (error) {
      console.log(error, "error");
    }
  };
  const callDeleteFileApiChat = async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/chat/delete`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
    } catch (error) {
      console.log(error, "error");
    }
  };
  const callDeleteFileApiWs = async (data) => {
    let newWs = new WebSocket(
      `${process.env.REACT_APP_CHAT_BASE_URL}/delete_file`
    );
    if (newWs?.readyState == newWs?.OPEN) {
      const dataToSend = JSON.stringify(data);
      newWs.send(dataToSend);
    }
    newWs.onopen = function open() {
      console.log("websocket opened");
      const dataToSend = JSON.stringify(data);
      newWs.send(dataToSend);
      console.log("sent to websocket on open", dataToSend);
    };
    newWs.onmessage = function incoming(data) {
      const word = data.data;
    };
    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
    };
  };
  const postFileApi = async (file) => {
    // return;
    let localFile = JSON.parse(localStorage.getItem("chat_file"));
    let ext = getFileIcon(file?.file_path).ext;
    let data = {
      file_id: file?.file_id?.toString(),
      file_type: ext,
      file_url: file?.file_path,
      user_id: JSON.parse(
        localStorage.getItem("login_data")
      )?.resp_user?.id?.toString(),
      thread_id: file?.chatres?.thread_id?.toString(),
      file_name: file?.file_name,
    };
    console.log(data, "akmal data");

    let newWs = new WebSocket(
      `${process.env.REACT_APP_CHAT_BASE_URL}/doc_insert`
    );

    console.log(newWs, "ready state...");
    if (newWs?.readyState == newWs?.OPEN) {
      const dataToSend = JSON.stringify(data);
      newWs.send(dataToSend);
    }
    newWs.onopen = function open() {
      console.log("websocket opened");
      const dataToSend = JSON.stringify(data);
      newWs.send(dataToSend);
      console.log("sent to websocket on open", dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      const word = data.data;
      const correctedJsonString = word.replace(/'/g, '"');
      const jsonData = JSON.parse(correctedJsonString);
      console.log(jsonData, "json data");
      if (jsonData?.status == "successful") {
        setUploading(false);
        setFileInfoApiWs(jsonData);
        setsuccessFailureMessage("Document added successfully");
        setopenSuccessModal("dismissible");
      } else {
        // return;
        setsuccessFailureMessage(jsonData?.message);
        setopenFailureModal("dismissible");
        setUploading(false);
        callDeleteFileApiNode({
          file_path: file?.file_path,
          doc_id: file?.doc_id,
        });
        setFileInfoApiWs(null);
        setChatData((prev) =>
          prev.filter((item) => item?.message != file?.file_path)
        );
        let chatData = JSON.parse(localStorage.getItem("chat_details")) || [];
        chatData = chatData.filter(
          (item) => item?.chat_content != file?.file_path
        );
        localStorage.setItem("chat_details", JSON.stringify(chatData));
        setFileInfo(null);
        setFileInfoApi(null);
        setFileInfoApiWs(null);
      }
    };

    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
      setUploading(false);
    };
    newWs.onerror = (error) => {
      console.error("WebSocket error:", error);
      setFileInfoApiWs(null);
      setUploading(false);
      setsuccessFailureMessage(error?.message || "Something went wrong");
      setopenFailureModal("dismissible");
    };
  };
  const generateRandomString = () => {
    return Math.random().toString(36).substring(2, 8);
  };

  const postFileUrlApi = async () => {
    // return;
    setopenFileUrlClicked(false);
    let localFile = JSON.parse(localStorage.getItem("chat_file"));
    let postData = {};
    if (!localFile) {
      let generalFolders = chatCalledOnce?.general_folders;
      let todays_date = getDate();
      let todaysDateFolders = generalFolders?.find(
        (item) => item?.title == todays_date
      );
      let files = todaysDateFolders?.submenu;
      let fileName = "Untitled Chat_";
      let count = 0;
      for (let i = 0; i < files?.length; i++) {
        const element = files[i];
        if (element.title?.includes("Untitled")) {
          let split = element.title?.split("_");
          let fileNumber = parseInt(split[1]);
          count = Math.max(count, fileNumber);
        }
      }
      count = count + 1;
      fileName = fileName + count;
      let data = {
        thread_folder: getDate(),
        thread_name: fileName,
        thread_id: "",
        chat_time: new Date(),
        chat_type: "doc",
        chat_content: urlInput,
        thread_type: "general",
      };
      setChatData((prev) => [
        ...prev,
        {
          type: "doc",
          message: urlInput,
          timestamp: data?.chat_time,
        },
      ]);
      let response = await callSaveNewChat(data, "general");
      let thread_id = response?.thread_id;
      let doc_id = response?.data?._id;
      postData = {
        file_id: generateRandomString(),
        file_type: "url",
        file_url: urlInput,
        user_id: JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: thread_id?.toString(),
        file_name: "",
      };
      setFileInfo({
        file_id: postData?.file_id,
        file_path: urlInput,
        file_name: "",
        name: urlInput,
        icon: getFileIcon(urlInput).icon,
      });
      setFileInfoApi({
        type: "url",
        file_id: postData?.file_id,
        doc_id: doc_id,
        file_path: urlInput,
      });
    } else {
      let data = {
        thread_folder: getDate(),
        thread_name: localFile?.thread_name,
        thread_id: localFile?.thread_id,
        chat_time: new Date(),
        chat_type: "doc",
        chat_content: urlInput,
        thread_type: "general",
      };
      setChatData((prev) => [
        ...prev,
        {
          type: "doc",
          message: urlInput,
          timestamp: data?.chat_time,
        },
      ]);
      let response = await callSaveNewChat(data, "general");
      let thread_id = response?.thread_id;
      let doc_id = response?.data?._id;
      postData = {
        file_id: generateRandomString(),
        file_type: "url",
        file_url: urlInput,
        user_id: JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: localFile?.thread_id?.toString(),
        file_name: "",
      };
      setFileInfo({
        file_id: postData?.file_id,
        file_path: urlInput,
        file_name: "",
        name: urlInput,
        icon: getFileIcon(urlInput).icon,
      });
      setFileInfoApi({
        type: "url",
        file_id: postData?.file_id,
        doc_id: doc_id,
        file_path: urlInput,
      });
    }
    setUploading(true);
    console.log(postData, "akmal data");

    let newWs = new WebSocket(
      `${process.env.REACT_APP_CHAT_BASE_URL}/doc_insert`
    );

    console.log(newWs, "ready state...");
    if (newWs?.readyState == newWs?.OPEN) {
      const dataToSend = JSON.stringify(postData);
      newWs.send(dataToSend);
    }
    newWs.onopen = function open() {
      console.log("websocket opened");
      const dataToSend = JSON.stringify(postData);
      newWs.send(dataToSend);
      console.log("sent to websocket on open", dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      const word = data.data;
      const correctedJsonString = word.replace(/'/g, '"');
      const jsonData = JSON.parse(correctedJsonString);
      console.log(jsonData, "json data");
      if (jsonData?.status == "successful") {
        setUploading(false);
        setFileInfoApiWs(jsonData);
        setsuccessFailureMessage("URL added successfully");
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(jsonData?.message);
        setopenFailureModal("dismissible");
        setUploading(false);
      }
    };

    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
      setUploading(false);
    };
    newWs.onerror = (error) => {
      console.error("WebSocket error:", error);
      setFileInfoApiWs(null);
      setUploading(false);
      setsuccessFailureMessage(error?.message || "Something went wrong");
      setopenFailureModal("dismissible");
    };
  };

  useEffect(() => {
    // Scroll to the last point of the container
    if (chatLoadingRef.current) {
      const container = chatLoadingRef.current;
      const lastElement = container?.lastElementChild;
      lastElement?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatData, chatData.length, lastChatRef]);
  useEffect(() => {
    setmounted(false);
  }, [location.search]);
  useEffect(() => {
    if (localStorage.getItem("chat_details")) {
      let chatData = JSON.parse(localStorage.getItem("chat_details")) || [];
      if (chatData?.length == 0) {
        setChatData([]);
        return;
      }
      if (mounted) {
        return;
      }
      let modifiedChatData = chatData?.map((item) => {
        const getChatType = (type) => {
          if (type == "ask") {
            return "sender";
          } else if (type == "ans") {
            return "receiver";
          } else if (type == "doc") {
            return "doc";
          } else {
            return "loader";
          }
        };
        return {
          type: getChatType(item?.chat_type),
          message: item?.chat_content,
          timestamp: item?.chat_time,
          file_name: item?.file_name,
        };
      });
      setChatData(modifiedChatData);
    }
  }, [location.search, mounted]);

  useEffect(() => {
    setWs(null);
  }, [location.search]);
  const isToday = (date) => {
    const today = moment().format("DD-MM-YYYY");
    return today == date;
  };
  const isYesterday = (date) => {
    const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
    return yesterday == date;
  };

  const getChatDates = () => {
    let chatDates = [];
    for (let i = 0; i < chatData.length; i++) {
      let date = moment(chatData[i].timestamp).format("DD-MM-YYYY");
      if (chatDates.indexOf(date) == -1) {
        chatDates.push(date);
      }
    }
    return chatDates;
  };
  const getChatsData = (date) => {
    let chatsData = [];
    for (let i = 0; i < chatData.length; i++) {
      let dateChat = moment(chatData[i].timestamp).format("DD-MM-YYYY");
      if (dateChat == date) {
        chatsData.push(chatData[i]);
      }
    }
    return chatsData;
  };
  const getDayOfWeek = (date) => {
    let dateString;
    if (isToday(date)) {
      dateString = "Today";
    } else if (isYesterday(date)) {
      dateString = "Yesterday";
    } else {
      dateString = date;
    }
    return dateString;
  };
  const handleFileUpload = async (event) => {
    if (gettemplateWordCount() <= 0) {
      setpopupMessage("Generate Content");
      setshowModal({
        first: true,
        second: false,
        third: false,
      });
      return;
    }
    const file = event.target.files[0];
    const maxFileSize = 2 * 1024 * 1024; // 2MB
    if (file) {
      if (file.size > maxFileSize) {
        setuploadOpen(false);
        setsuccessFailureMessage("Please upload a file less than 2MB");
        setopenFailureModal("dismissible");
        return;
      }
      setUploading(true);
      setFileInfo({
        name: file.name,
        icon: getFileIcon(file.name).icon,
        file: file,
        ext: getFileIcon(file.name).ext,
      });
      setviewFilesClicked(false);
      uploadFileToBlobStorage(file);
      setuploadOpen(false);
    }
    event.target.value = "";
  };
  const removeFile = async () => {
    if (fileInfoApi?.type && fileInfoApi?.type == "url") {
      callDeleteFileApiWs({ file_id: fileInfoApi?.file_id?.toString() });
      callDeleteFileApiChat({
        doc_id: fileInfoApi?.doc_id,
      });
    } else {
      callDeleteFileApiNode({
        file_path: fileInfoApi?.file_path,
        doc_id: fileInfoApi?.doc_id,
      });
      callDeleteFileApiWs({ file_id: fileInfoApi?.file_id?.toString() });
    }
    setChatData((prev) =>
      prev.filter((item) => item?.message != fileInfoApi?.file_path)
    );
    let chatData = JSON.parse(localStorage.getItem("chat_details")) || [];
    chatData = chatData.filter(
      (item) => item?.chat_content != fileInfoApi?.file_path
    );
    localStorage.setItem("chat_details", JSON.stringify(chatData));
    setFileInfo(null);
    setFileInfoApi(null);
    setFileInfoApiWs(null);
  };
  console.log(fileInfoApi, "fileInfoApi");

  const uploadFileToBlobStorage = async (file) => {
    console.log(file, "file upload");
    // return;
    const formData = new FormData();
    formData.append("vsn_file", file);
    let localFile = JSON.parse(localStorage.getItem("chat_file"));
    let global_file_name = "";
    if (!localFile) {
      let generalFolders = chatCalledOnce?.general_folders;
      let todays_date = getDate();
      let todaysDateFolders = generalFolders?.find(
        (item) => item?.title == todays_date
      );
      let files = todaysDateFolders?.submenu;
      let fileName = "Untitled Chat_";
      let count = 0;
      for (let i = 0; i < files?.length; i++) {
        const element = files[i];
        if (element.title?.includes("Untitled")) {
          let split = element.title?.split("_");
          let fileNumber = parseInt(split[1]);
          count = Math.max(count, fileNumber);
        }
      }
      count = count + 1;
      fileName = fileName + count;
      global_file_name = fileName;
      formData.append("thread_name", fileName);
      formData.append("thread_id", "");
    } else {
      global_file_name = localFile?.thread_name;
      formData.append("thread_name", localFile?.thread_name);
      formData.append("thread_id", localFile?.thread_id);
    }

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/chatfile`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
      });

      if (!response?.data?.error) {
        const data = response.data;
        postFileApi(data, global_file_name);
        if (!localFile) {
          getChatDetails("general");
          localStorage.setItem(
            "chat_file",
            JSON.stringify({
              thread_name: global_file_name,
              thread_id: data?.chatres?.thread_id,
            })
          );
        }
        setChatData((prev) => [
          ...prev,
          {
            type: "doc",
            message: data?.chatres?.chat_content,
            timestamp: data?.chatres?.chat_time,
            file_name: file?.name,
          },
        ]);
        let chatData = JSON.parse(localStorage.getItem("chat_details")) || [];
        chatData.push({
          chat_content: data?.chatres?.chat_content,
          chat_time: data?.chatres?.chat_time,
          chat_type: "doc",
          thread_name: global_file_name,
          thread_id: data?.chatres?.thread_id,
          _id: data?.chatres?._id,
          file_name: file?.name,
        });
        localStorage.setItem("chat_details", JSON.stringify(chatData));
        console.log("data", data);
        setFileInfo((prev) => ({ ...prev, path: data.file_path }));
        setFileInfoApi(data);
      } else {
        setsuccessFailureMessage(response.message);
        setFileInfo(null);
        setFileInfoApi(null);
        setUploading(false);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setUploading(false);
      setFileInfo(null);
      setFileInfoApi(null);
      console.error("Upload failed:", error);
      setsuccessFailureMessage(error?.message);
      setopenFailureModal("dismissible");
    }
  };
  const getFilesFromChat = () => {
    let files = [];
    for (let i = 0; i < chatData?.length; i++) {
      if (chatData[i]?.type == "doc") {
        files.push(chatData[i]);
      }
    }
    setchatFiles(files);
  };
  // console.log("chatData", chatData);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
    if (viewFilesRef.current && textareaRef.current) {
      let height = Math.min(textareaRef.current.scrollHeight, 280) + 60;
      viewFilesRef.current.style.bottom = `${height}px`; // Adjust the position of view files section
    }
  }, [chatQuery, viewFilesClicked]);
  useEffect(() => {
    const handleCopyButtonClick = (e) => {
      if (e.target.classList.contains("copy-btn")) {
        const code = decodeURIComponent(e.target.getAttribute("data-code"));
        const textarea = document.createElement("textarea");
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("Code copied to clipboard!");
      }
    };

    document.body.addEventListener("click", handleCopyButtonClick);

    return () => {
      document.body.removeEventListener("click", handleCopyButtonClick);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setuploadOpen(false);
    }
  };

  useEffect(() => {
    if (uploadOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [uploadOpen]);
  // useEffect(() => {
  //   return () => {
  //     if (ws) {
  //       ws.close();
  //     }
  //   };
  // }, [ws]);
  console.log(fileInfo, "fileInfo");
  console.log(chatFiles, "chatFiles");

  return (
    <div className="wrapper">
      <div className="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2 title="Chat" />
        <div
          className={`w-[100%] h-[75%] ${
            displayEditor && "md:w-1/2  border-r "
          } px-4 pb-2 relative`}
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <div className={`overflow-auto h-[74%] `}>
            {getChatDates()?.map((date, date_index, date_arr) => (
              <>
                <div className="title title--center-line flex font-normal text-[12px] my-2">
                  {getDayOfWeek(date)}
                </div>
                <div ref={chatLoadingRef}>
                  {getChatsData(date)?.map((chat, chat_index) => {
                    if (chat?.type == "sender") {
                      return (
                        <div
                          className="pb-2 sender"
                          ref={
                            chat_index === chatData.length - 1
                              ? lastChatRef
                              : undefined
                          }
                        >
                          <div className="flex justify-end">
                            <div
                              className="max-w-[75%] bg-[#FAFBFC] py-[8px] px-[12px] rounded-tr-[15px] rounded-l-[15px] text-[14px] font-semibold pt-[0.5em] pb-[0.5em]"
                              data-time={moment(chat?.timestamp).format(
                                "h:mm A"
                              )}
                              // dangerouslySetInnerHTML={{
                              //   __html: getContent(
                              //     chat?.message?.replaceAll(/\n/g, "<br/>")
                              //   ),
                              // }}
                              dangerouslySetInnerHTML={{
                                __html: chat?.message,
                              }}
                            />
                            {/* {chat?.message} */}
                            {/* </div> */}
                          </div>
                          <div className="flex">
                            <p className="ms-auto text-[10px] font-semibold pt-1">
                              {moment(chat?.timestamp).format("h:mm A")}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (chat?.type == "receiver") {
                      return (
                        <div
                          className="pb-2 receiver"
                          ref={
                            chat_index === chatData.length - 1
                              ? lastChatRef
                              : undefined
                          }
                        >
                          <div className="flex justify-start">
                            <div
                              className="w-[75%] bg-[#F2FAFF] py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-editor"
                              data-time={moment(chat?.timestamp).format(
                                "h:mm A"
                              )}
                              dangerouslySetInnerHTML={{
                                __html: getContent(chat?.message || ""),
                              }}
                            />
                          </div>
                          <div className="flex w-[75%]">
                            <p className="me-auto text-[10px] font-semibold pt-1">
                              {moment(chat?.timestamp).format("h:mm A")}
                            </p>
                            <p>
                              <button
                                data-tooltip-id="pencil"
                                data-tooltip-content="Copy to Editor"
                                type="button"
                                onClick={() => {
                                  setdisplayEditor(true);
                                  setstringWords(chat?.message);
                                }}
                              >
                                <svg className="icon">
                                  <use href="#icon_pencil"></use>
                                </svg>
                              </button>
                            </p>
                          </div>
                        </div>
                      );
                    } else if (chat?.type == "error") {
                      return (
                        <div
                          className="pb-2 receiver"
                          ref={
                            chat_index === chatData.length - 1
                              ? lastChatRef
                              : undefined
                          }
                        >
                          <div className="flex justify-start">
                            <div className="w-[70%]  py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                              {chat?.message}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (chat?.type == "loader") {
                      return (
                        <div
                          className="pb-2 receiver"
                          ref={
                            chat_index === chatData.length - 1
                              ? lastChatRef
                              : undefined
                          }
                        >
                          <div className="flex justify-start">
                            <div className="py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                              <ThreeDotsLoader />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {isLoadingChat && date_arr.length - 1 === date_index && (
                    <div className="pb-2 receiver">
                      <div className="flex justify-start">
                        <div className="py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                          <ThreeDotsLoader />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
          <div className="relative h-[25%] mt-4">
            <div className="w-full chat-field bg-[#FAFBFC] rounded-[15px] p-[8px] absolute bottom-0 border border-[#E9EBF0] z-40">
              <div className="bg-[#fff] rounded-[15px]">
                {fileInfo && (
                  <div
                    ref={fileInfoRef}
                    className="file-card flex items-center border mb-4 border-[#E9EBF0] rounded-[10px] p-[8px] mr-2 relative chatfile-div"
                  >
                    <div className="flex items-center ">
                      {uploading ? (
                        <div className="loader_file mr-2"></div>
                      ) : (
                        <>
                          <img
                            src={fileInfo?.icon}
                            className="w-[34px] me-3"
                            alt="doc"
                          />
                        </>
                      )}
                      <span>{fileInfo?.name}</span>
                    </div>
                    {fileInfo?.link && (
                      <a
                        href={fileInfo?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2"
                      >
                        Download
                      </a>
                    )}
                    {!uploading && (
                      <button
                        type="button"
                        className="remove-file absolute justify-center items-center right-[5px] p-1"
                        onClick={removeFile}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-400 hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                )}
                <div className="relative">
                  <textarea
                    ref={textareaRef}
                    className="w-full h-[auto] m-0 rounded-[15px] custom-textarea"
                    placeholder="Enter your question here..."
                    id="textofComment"
                    value={chatQuery}
                    onChange={(e) => {
                      setuploadOpen(false);
                      setChatQuery(e.target.value);
                    }}
                    style={{
                      resize: "none",
                      maxHeight: "280px",
                      overflow: "auto",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (gettemplateWordCount() <= 0) {
                          setpopupMessage("Generate Content");
                          setshowModal({
                            first: true,
                            second: false,
                            third: false,
                          });
                          return;
                        }
                        if (chatQuery?.trim()?.length == 0) {
                          return;
                        }
                        handleChatSend();
                      }
                    }}
                    onMouseDown={() => {
                      setuploadOpen(false);
                    }}
                  ></textarea>
                  {!uploadOpen && (
                    <button
                      type="button"
                      className={`sendbtn w-[34px] h-[34px] absolute flex justify-center items-center top-[16px] right-[16px] cursor-pointer border-none text-white rounded-full bg-[#4158D0] transition duration-700 ease-in-out`}
                      title="Send"
                      id="sendComment"
                      disabled={isLoadingChatCompleted}
                      onClick={() => {
                        if (gettemplateWordCount() <= 0) {
                          setpopupMessage("Generate Content");
                          setshowModal({
                            first: true,
                            second: false,
                            third: false,
                          });
                          return;
                        }
                        if (chatQuery?.trim()?.length == 0) {
                          return;
                        }
                        handleChatSend();
                      }}
                    >
                      <svg className="icon">
                        <use href="#icon_send"></use>
                      </svg>
                    </button>
                  )}
                </div>
                <div className="flex items-center justify-between px-2 py-4 border-t-[1px] border-[#ECECEC] relative">
                  <button
                    type="button"
                    className="py-2 invisible rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px]"
                    disabled
                  >
                    <svg className="icon me-2 text-[20px]">
                      <use href="#icon_prompts"></use>
                    </svg>
                    <span>Browse Prompts</span>
                  </button>
                  <div ref={buttonRef}>
                    <button
                      type="button"
                      className="py-2 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px]"
                      onClick={() => {
                        setuploadOpen(!uploadOpen);
                        setopenFileUrlClicked(false);
                        setviewFilesClicked(false);
                      }}
                    >
                      <svg className="icon me-2 text-[20px]">
                        <use href="#icon_uploadchat"></use>
                      </svg>
                      <span>Upload Files</span>
                    </button>
                    {uploadOpen && (
                      <div className="absolute right-[10px] bottom-[80px] z-10">
                        {/* <button
                          type="button"
                          className="py-2 mb-4 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px]"
                          onClick={() => {
                            setopenFileUrlClicked(true);
                            setuploadOpen(false);
                            setviewFilesClicked(false);
                            seturlInput("");
                          }}
                        >
                          <svg className="icon me-2 text-[20px] font-bold">
                            <use href="#icon_url1"></use>
                          </svg>
                          <span>Add Open Link / URL</span>
                        </button> */}
                        <input
                          id="file-upload"
                          type="file"
                          className="hidden"
                          accept=".pdf,.doc,.docx,"
                          onChange={handleFileUpload}
                        />
                        <label
                          htmlFor="file-upload"
                          className="cursor-pointer py-2 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px] mb-4"
                        >
                          <svg className="icon me-2 text-[20px]">
                            <use href="#icon_uploadchat"></use>
                          </svg>
                          <span>Upload Files</span>
                        </label>
                        <button
                          type="button"
                          className="py-2 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px]"
                          onClick={() => {
                            getFilesFromChat();
                            setviewFilesClicked(true);
                            setuploadOpen(false);
                          }}
                        >
                          <svg className="icon me-2 text-[20px]">
                            <use href="#icon_view"></use>
                          </svg>
                          <span>View Files</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {viewFilesClicked && (
              <div
                ref={viewFilesRef}
                className="absolute left-0 rounded-[15px] bottom-[140px] w-full shadow drop-shadow-2xl bg-[#fff] z-0 pb-[40px]"
              >
                <div
                  className="flex px-4 pt-4"
                  onClick={() => {
                    setviewFilesClicked(false);
                    setuploadOpen(false);
                    setopenFileUrlClicked(false);
                  }}
                >
                  <button type="button" className="ms-auto hover:text-[red]">
                    <svg className="icon text-[32px]">
                      <use href="#icon_close"></use>
                    </svg>
                  </button>
                </div>
                <h4 className="text-[20px] font-semibold pb-2 px-4">
                  View Files
                </h4>
                {console.log(imgURL, "imgURL")}
                <div className="overflow-y-auto max-h-[200px] px-4">
                  {chatFiles?.map((file, index) => (
                    <>
                      <div className="flex items-center text-[18px] font-[600] py-4">
                        <img
                          src={getFileIcon(file?.message).icon}
                          className="w-[34px] me-3"
                          alt={getFileIcon(file?.message).ext}
                        />
                        <span className="w-[40%] break-words flex items-center">
                          {/* {extractFilename(file?.message)} */}
                          {file?.file_name
                            ? file?.file_name
                            : extractFilename(file?.message)}
                          <>
                            {!file?.file_name &&
                              getFileIcon(file?.message).ext != "url" && (
                                <>.{getFileIcon(file?.message).ext}</>
                              )}
                          </>
                          <button
                            type="button"
                            className="ms-auto hover:text-[#1A71A7]"
                            onClick={() => {
                              // window.open(file?.message, "_blank");
                              let file_name = file?.file_name
                                ? file?.file_name
                                : extractFilename(file?.message);
                              if (getFileIcon(file?.message).ext == "pdf") {
                                setImgURL({
                                  url: file?.message,
                                  name: file_name,
                                });
                              } else {
                                setImgURL({
                                  url:
                                    "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                    file?.message,
                                  name: file_name,
                                });
                              }
                              setShowimgpop(true);
                            }}
                          >
                            <svg className="icon text-[20px]">
                              <use href="#icon_view"></use>
                            </svg>
                          </button>
                        </span>
                        <span className="px-3"> | </span>
                        {getChatFileTime(file?.timestamp)}
                      </div>
                      {index !== chatFiles.length - 1 && <hr />}
                    </>
                  ))}
                </div>
              </div>
            )}
            {openFileUrlClicked && (
              <div
                ref={viewFilesRef}
                className="absolute left-0 rounded-[15px] bottom-[140px] w-full shadow drop-shadow-2xl bg-[#fff] z-0 pb-[40px]"
              >
                <div
                  className="flex px-4 pt-4"
                  onClick={() => {
                    setopenFileUrlClicked(false);
                    setuploadOpen(false);
                  }}
                >
                  <button type="button" className="ms-auto hover:text-[red]">
                    <svg className="icon text-[32px]">
                      <use href="#icon_close"></use>
                    </svg>
                  </button>
                </div>
                <h4 className="text-[20px] font-semibold pb-2 px-4">
                  Add Open Link / URL
                </h4>
                <p className="my-2 px-4">
                  Please provide an open URL, such as a blog, article, or news
                  piece, that doesn't require a login to view the content.
                </p>
                <div className="overflow-y-auto max-h-[200px] px-4 my-4">
                  <input
                    type="text"
                    className="w-full h-[40px] border-[1px] border-[#E9EBF0] rounded-full px-4 my-2"
                    placeholder="Enter URL"
                    value={urlInput}
                    onChange={(e) => seturlInput(e.target.value)}
                  />
                  <div
                    className="my-4 flex justify-end"
                    onClick={() => {
                      if (!urlInput?.trim()) {
                        return;
                      }
                      postFileUrlApi();
                    }}
                  >
                    <button
                      type="button"
                      disabled={uploading}
                      className="py-2 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 flex items-center justify-center w-[212px]"
                    >
                      <div></div>
                      <div>{!uploading ? "Submit" : "Submitting"}</div>
                      <div className="">{uploading && <ThreeDotsLoader />}</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {displayEditor && (
          <div
            className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
            style={{ height: "calc(100vh - 98px)" }}
          >
            <div className=" h-full overflow-auto pb-2">
              <div className="w-full flex justify-between items-center">
                <h2 className="font-bold text-[20px]">Editor</h2>
                <div className="ms-auto flex items-center gap-2">
                  <div
                    onClick={() => {
                      setdisplayEditor(false);
                      setfullscreenClicked(false);
                    }}
                  >
                    <svg className="icon text-[16px]  cursor-pointer">
                      <use href="#icon_close1"></use>
                    </svg>
                  </div>
                  {!fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(true)}>
                      <svg className="icon text-[14px]  cursor-pointer">
                        <use href="#icon_fullscreen"></use>
                      </svg>
                    </div>
                  )}
                  {fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(false)}>
                      <svg className="icon text-[14px] cursor-pointer">
                        <use href="#icon_fullexit"></use>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <TextEditor stringOutput={stringWords} from="chat" />
            </div>
          </div>
        )}
      </div>
      <Tooltip
        id="pencil"
        // style={{ backgroundColor: "#7b7b7b", color: "white" }}
      />
      <PopupModals
        showModal={showModal}
        setshowModal={setshowModal}
        popupMessage={popupMessage}
      />
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <Modal
        show={showimgpop}
        onClose={() => {
          setShowimgpop(false);
          setImgURL({});
        }}
        dismissible
      >
        <Modal.Header className="py-2">{imgURL?.name}</Modal.Header>
        <Modal.Body>
          <>
            {!imgURL || Object.keys(imgURL || {})?.length == 0 ? (
              <h4
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No file found!
              </h4>
            ) : (
              // eslint-disable-next-line jsx-a11y/iframe-has-title
              <iframe
                style={{
                  // height: "100%",
                  minHeight: "70vh",
                  width: "100%",
                  borderRadius: "5px",
                }}
                src={imgURL?.url}
              ></iframe>
            )}
          </>
        </Modal.Body>
      </Modal>
      <JoyrideComponent
        steps={steps}
        stepIndex={stepIndex}
        handleJoyrideCallback={handleJoyrideCallback}
        run={run}
        setStepIndex={setStepIndex}
      />
    </div>
  );
});

export default Chat;
