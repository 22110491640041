import React from 'react'

function FileSave({editFile,seteditFile,inputData, handleChange,onClickSubmitFn}) {
  return (
    <div>
      {!editFile ? (
        <h1
          className="font-bold text-[20px] py-2 my-2"
          onClick={() => seteditFile(true)}
        >
          {inputData?.content_title}
          <span>
            <button
              type="button"
              className="p-0 m-0 border-0 pl-2"
              style={{
                background: "none",
              }}
            >
              <svg className="icon fs-18">
                <use href="#icon_edit"></use>
              </svg>
            </button>
          </span>
        </h1>
      ) : (
        <div className="mb-5">
          <div>
            <label class="block font-semibold text-[18px]" htmlFor="topic">
              File Name
            </label>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div>
              <input
                type="text"
                id="content_title"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter File Name"
                value={inputData?.content_title}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  onClickSubmitFn("save");
                  seteditFile(false);
                }}
                className="text-center py-2 w-[100px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FileSave
