/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function SeoComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
  keywords,
  page_title,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [inputData, setInputData] = useState({
    n: "3",
    title: "",
    keywords: "",
    language: "English",
    content_title: selectedFile?.content_title,
  });
  const [editFile, seteditFile] = useState(false);
  const chooseSocialMedia = "Choose Media";
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    let data = {};
    let titleTemplate = {
      "SEO Meta Title": "seo_meta_title",
      "SEO Meta Description": "seo_meta_description",
    };
    if (from == "SEO Meta Title") {
      data = {
        n: inputData.n,
        keywords: inputData.keywords,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "SEO Meta Title",
      };
      // postWebsocketDetails(data, "seo_meta_title");
    } else if (from == "SEO Meta Description") {
      data = {
        n: inputData.n,
        title: inputData.title,
        keywords: inputData.keywords,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "SEO Meta Description",
      };
      // postWebsocketDetails(data, "seo_meta_description");
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
    }
  };
  useEffect(() => {
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "SEO Meta Title") {
        setInputData({
          ...inputData,
          n: getSettingKey("n") || "3",
          keywords: getSettingKey("keywords"),
          language: getSettingKey("language") || "English",
          content_title: selectedFile?.content_title,
          content_template: "SEO Meta Title",
        });
      } else if (selectedFile?.content_template == "SEO Meta Description") {
        setInputData({
          ...inputData,
          n: getSettingKey("n") || "3",
          title: getSettingKey("title"),
          keywords: getSettingKey("keywords"),
          language: getSettingKey("language") || "English",
          content_title: selectedFile?.content_title,
          content_template: "SEO Meta Description",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    //  if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
  }, []);

  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn();
          }}
        >
          {page_title && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="title"
              >
                Page Title
              </label>
              <input
                type="text"
                id="title"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter page title"
                value={inputData.title}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {keywords && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="keywords"
              >
                Keyword Phase To Rank
              </label>
              <input
                type="text"
                id="keywords"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter keyword phase to rank"
                value={inputData.keywords}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              required
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default SeoComponent;
