// export const languages = [
//   "Albanian",
//   "Arabic",
//   "Armenian",
//   "Awadhi",
//   "Azerbaijani",
//   "Bashkir",
//   "Basque",
//   "Belarusian",
//   "Bengali",
//   "Bhojpuri",
//   "Bosnian",
//   "Brazilian Portuguese",
//   "Bulgarian",
//   "Cantonese (Yue)",
//   "Catalan",
//   "Chhattisgarhi",
//   "Chinese",
//   "Croatian",
//   "Czech",
//   "Danish",
//   "Dogri",
//   "Dutch",
//   "English",
//   "Estonian",
//   "Faroese",
//   "Finnish",
//   "French",
//   "Galician",
//   "Georgian",
//   "German",
//   "Greek",
//   "Gujarati",
//   "Haryanvi",
//   "Hindi",
//   "Hungarian",
//   "Indonesian",
//   "Irish",
//   "Italian",
//   "Japanese",
//   "Javanese",
//   "Kannada",
//   "Kashmiri",
//   "Kazakh",
//   "Konkani",
//   "Korean",
//   "Kyrgyz",
//   "Latvian",
//   "Lithuanian",
//   "Macedonian",
//   "Maithili",
//   "Malay",
//   "Maltese",
//   "Mandarin",
//   "Mandarin Chinese",
//   "Marathi",
//   "Marwari",
//   "Min Nan",
//   "Moldovan",
//   "Mongolian",
//   "Montenegrin",
//   "Nepali",
//   "Norwegian",
//   "Oriya",
//   "Pashto",
//   "Persian (Farsi)",
//   "Polish",
//   "Portuguese",
//   "Punjabi",
//   "Rajasthani",
//   "Romanian",
//   "Russian",
//   "Sanskrit",
//   "Santali",
//   "Serbian",
//   "Sindhi",
//   "Sinhala",
//   "Slovak",
//   "Slovene",
//   "Slovenian",
//   "Ukrainian",
//   "Urdu",
//   "Uzbek",
//   "Vietnamese",
//   "Welsh",
//   "Wu",
// ];
export const languages = [
  "Spanish",
  "Portuguese",
  "Italian",
  "Dutch",
  "English",
  "French",
  "German",
  "Greek",
  "Hungarian",
  "Polish",
  "Czech",
  "Danish",
  "Arabic",
];
