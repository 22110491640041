/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Cookies from "js-cookie";
import useCommonApi from "../hooks/common-apis";
import { useNavigate } from "react-router-dom";

const withExpirationCheck = (WrappedComponent) => {
  return (props) => {
    const {callExpireDateCheckApi}=useCommonApi();
    const navigate = useNavigate();

    useEffect(() => {
      let login_token=localStorage.getItem("login_token");
      if(!login_token){
        navigate("/")
      }
    //   console.log("useCheckExpirationAndCallApi");
      const checkExpirationAndCallApi = async () => {
        const expirationDate = Cookies.get("expirationDate");
        if (expirationDate) {
          const currentDate = new Date();
          const expiration = new Date(expirationDate);

          if (currentDate > expiration) {
            try {
              callExpireDateCheckApi();
              Cookies.remove("expirationDate");
            } catch (error) {
              console.error("API call failed", error);
            }
          }
        }
      };

      checkExpirationAndCallApi();
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withExpirationCheck;
