/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Button, Modal, Tabs } from "flowbite-react";
import useCommonApi from "../hooks/common-apis";
import UserContext from "../context/UserContext";
import CreateNewSpaceModal from "../components/CreateNewSpaceModal";
import axios from "axios";
import useCommonFunctions from "../hooks/common-functions";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import { useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import AreYouSure from "../components/AreYouSure";
import RemoveMember from "../components/RemoveMember";
import PopupModals from "../components/PopupModals";

function Managespace() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState();
  const [openModalAreYouSure, setOpenModalAreYouSure] = useState();
  const [openModalRemove, setOpenModalRemove] = useState();
  const [openModalRemoveMember, setOpenModalRemoveMember] = useState();
  const [modelopen, setModelopen] = useState({});
  const [dropdownopen, setdropdownopen] = useState(false);
  const [privatepublic, setprivatepublic] = useState({
    private: false,
    public: true,
  });
  const [selectedMemberList, setselectedMemberList] = useState([]);
  const [selectedPrivateSpace, setselectedPrivateSpace] = useState({});
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setloading] = useState(false);
  const [submittingAccess, setsubmittingAccess] = useState(false);
  const [selectedAccess, setselectedAccess] = useState({
    from: "",
    to: "",
  });
  const [selectedMember, setselectedMember] = useState({});
  const [removingMember, setremovingMember] = useState(false);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  // const [search, setSearch] = useState("");
  const { getSpaceList, getWorkSpaceMembers } = useCommonApi();

  const context = useContext(UserContext);
  const {
    userSpaces,
    selectedFolderStructure,
    setselectedFolderStructure,
    selectedWorkspace,
  } = context;
  const {
    getLetters,
    getBackgroundColor,
    checkWorkspaceEnabled,
    checkInviteEnabled,
  } = useCommonFunctions();
  // console.log(userSpaces, "userspaces,,, in manage space");
  const handleCloseSpaceModal = () => {
    setOpenModalRemove(undefined);
  };
  const [membersList, setmembersList] = useState([]);
  const [membersListTotal, setmembersListTotal] = useState({});
  const [selectedSpace, setselectedSpace] = useState({});
  const containerRef = useRef([]);
  const documentBodyRef = useRef(null);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [search, setsearch] = useState("");
  const [searchSpaces, setsearchSpaces] = useState("");
  const [popupMessage, setpopupMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const handleInputChange = (event) => {
    setCurrentEmail(event.target.value);
    // Validate email format
    if (event.target.value == "") {
      setIsValidEmail(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(event.target.value));
  };

  const handleInputKeyDown = (event) => {
    if (!isValidEmail) {
      return;
    }
    // Handle "Enter" key to add the email
    if (event.key === "Enter" && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };
  const inviteEmail = () => {
    if (isValidEmail && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };
  let membersList1 = [
    {
      id: 1,
      user_workspace: {
        first_name: "chanakya",
        last_name: "sai",
        email: "c@gmail.com",
      },
    },
    {
      id: 2,
      user_workspace: {
        first_name: "monu",
        last_name: "alam",
        email: "ma@gmail.com",
      },
    },
    {
      id: 3,
      user_workspace: {
        first_name: "milan",
        last_name: "kumar",
        email: "mk@gmail.com",
      },
    },
    {
      id: 4,
      user_workspace: {
        first_name: "usman",
        last_name: "malik",
        email: "u@gmail.com",
      },
    },
    {
      id: 5,
      user_workspace: {
        first_name: "sudhir",
        last_name: "singh",
        email: "ss@gmail.com",
      },
    },
    {
      id: 6,
      user_workspace: {
        first_name: "Akmal",
        last_name: "A",
        email: "aa@gmail.com",
      },
    },
    {
      id: 7,
      user_workspace: {
        first_name: "vineet",
        last_name: "joshi",
        email: "vj@gmail.com",
      },
    },
    {
      id: 8,
      user_workspace: {
        first_name: "nithish",
        last_name: "kumar",
        email: "nk@gmail.com",
      },
    },
  ];
  // const getBackgroundColor = (letter) => {
  //   console.log(letter, "letter");
  //   switch (letter.toUpperCase()) {
  //     case "M":
  //       return "#FBC575";
  //     case "V":
  //       return "#60CFFF";
  //     case "B":
  //       return "#F99A71";
  //     // Add more cases for other letters as needed
  //     default:
  //       // Default color if the letter doesn't match any case
  //       return "red"; // You can set any default color here
  //   }
  // };
  const submitSpaceTeamMembers = async () => {
    try {
      setloading(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/invite`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          invitation_emails: emails,
          type: "workspace_space", //"workspace_space"
          user_type_id: null, //null
          workspace_id: JSON.parse(localStorage.getItem("selected_workspace"))
            ?.workspace_id,
          space_id: selectedPrivateSpace?.space_id,
        },
      });
      setloading(false);
      console.log(response, "response");
      if (!response.data.error) {
        // navigate("/template");
        setOpenModal(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setloading(false);

      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const getMembers = async () => {
    let membersResult = await getWorkSpaceMembers(
      JSON.parse(localStorage.getItem("selected_workspace"))?.workspace_id
    );
    setmembersList(membersResult?.members?.members);
    setmembersListTotal(membersResult?.members);
  };
  const submitWorkspace = async () => {
    setselectedFolderStructure({
      ...selectedFolderStructure,
      loading: true,
      error: null,
    });
    let emails = [];
    for (let i = 0; i < selectedMemberList.length; i++) {
      const element = selectedMemberList[i];
      emails.push(element?.user_workspace?.email);
    }
    let data = {};
    if (selectedFolderStructure?.mainHeading == "Update Space") {
      data = {
        workspace_id: JSON.parse(localStorage.getItem("selected_workspace"))
          ?.workspace_id,
        space_name: selectedFolderStructure?.value,
        space_id: selectedFolderStructure?.space_id,
        space_type: privatepublic.public ? 0 : 1,
        space_icon: "",
        space_avtar: "",
        invitation_emails:
          selectedFolderStructure?.space_type == "0" ? [] : emails,
      };
    } else {
      data = {
        workspace_id: JSON.parse(localStorage.getItem("selected_workspace"))
          ?.workspace_id,
        space_name: selectedFolderStructure?.value,
        space_type: privatepublic.public ? 0 : 1,
        space_icon: "",
        space_avtar: "",
        invitation_emails: privatepublic.public ? [] : emails,
      };
    }
    try {
      let response = await axios({
        url:
          selectedFolderStructure?.mainHeading == "Update Space"
            ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/update`
            : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      // console.log(response, "response");
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: false,
        error: null,
      });
      if (!response.data.error) {
        getSpaceList();
        handleCloseSpaceModal();
        setselectedMemberList([]);
        setprivatepublic({
          ...privatepublic,
          public: true,
          private: false,
        });
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setselectedFolderStructure({
          ...selectedFolderStructure,
          loading: false,
          error: response.data.message,
        });
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: true,
        error: error.message,
      });
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const checkFound = (item) => {
    let found = false;
    for (let i = 0; i < selectedMemberList.length; i++) {
      const element = selectedMemberList[i];
      if (element?.user_workspace?.email == item?.user_workspace?.email) {
        found = true;
        break;
      }
    }
    return found;
  };
  const deleteFromSelectedMemberList = (item) => {
    setselectedMemberList((prev) =>
      prev?.filter(
        (member) => member?.user_workspace?.email != item?.user_workspace?.email
      )
    );
  };
  // console.log(selectedPrivateSpace, "selectedPrivateSpace");
  const removeMemberApi = async () => {
    setremovingMember(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/member/remove`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          guest_user_id: selectedMember?.user_id,
          space_id: selectedMember?.space_id,
        },
      });
      // console.log(response, "response");
      setremovingMember(false);
      if (!response.data.error) {
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        setOpenModalRemoveMember(undefined);
        getSpaceList();
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setremovingMember(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };

  useEffect(() => {
    getSpaceList();
    getMembers();
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      containerRef.current.forEach((ref, index) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setModelopen((prevState) => ({
            ...prevState,
            [index]: false,
          }));
          // setModelopen(false);
        }
      });
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);

  return (
    <>
      <h6 className="font-bold text-[24px]">Manage Spaces</h6>
      <div className="flex py-4">
        <div>
          <input
            type="text"
            name="search"
            className="mt-1 w-[400px] rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
            placeholder="Search By Name"
            value={searchSpaces}
            onChange={(e) => setsearchSpaces(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="ms-auto flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
          onClick={() => {
            setselectedFolderStructure({
              type: "space",
              value: "",
              title: "Space Name",
              placeholder: "Enter your space name",
              mainHeading: "Create Space",
              loading: false,
              error: null,
            });
            setOpenModalRemove("dismissible");
          }}
        >
          <span>+ Create New Space</span>
        </button>
      </div>

      {/* start tabs */}
      <div className="tabs-box pt-4">
        {/* eslint-disable-next-line react/style-prop-object */}
        {/* <Tabs.Group aria-label="Pills" style="pills">
          <Tabs.Item
            active
            title={`Spaces (${
              userSpaces?.filter((user_space) => {
                if (
                  user_space?.user_workspace_space?.space_name
                    ?.toLowerCase()
                    .includes(searchSpaces.toLowerCase())
                ) {
                  return true;
                } else {
                  return false;
                }
              })?.length
            })`}
          >
            
          </Tabs.Item>
        </Tabs.Group> */}
        <p className="font-bold text-[16px] py-3">
          Spaces{" "}
          {
            userSpaces?.filter((user_space) => {
              if (
                user_space?.user_workspace_space?.space_name
                  ?.toLowerCase()
                  .includes(searchSpaces.toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            })?.length
          }
        </p>
        <div className="tbl-scroller">
          <table className="table">
            <thead className="bg-[red]">
              <tr>
                <th>Space Name</th>
                <th>Owner</th>
                <th>Type</th>
                <th>Share With</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <td colSpan="4"></td>
            </tbody>
            <tbody>
              {userSpaces
                ?.filter((user_space) => {
                  if (
                    user_space?.user_workspace_space?.space_name
                      ?.toLowerCase()
                      .includes(searchSpaces.toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
                ?.map((user_space, user_space_index) => {
                  const index = user_space_index; // Ensure stable reference for closure
                  containerRef.current[index] =
                    containerRef?.current[index] || React.createRef();
                  return (
                    <tr>
                      <td style={{ borderRadius: "25px" }}>
                        <div className="flex ps-16 items-center">
                          <button type="button">
                            <div
                              style={{
                                backgroundColor: getBackgroundColor(
                                  user_space?.user_workspace_space?.space_name?.charAt(
                                    0
                                  )
                                ),
                              }}
                              className="w-[40px] h-[40px] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center me-3"
                            >
                              {getLetters(
                                user_space?.user_workspace_space?.space_name
                              )}
                            </div>
                          </button>
                          {user_space?.user_workspace_space?.space_name}
                          <button type="button" className="ms-[5px] mt-[-20px]">
                            <svg className="icon text-[12px]">
                              <use href="#icon_link"></use>
                            </svg>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="flex ps-16 items-center">
                          <button type="button">
                            <div
                              style={{
                                backgroundColor: getBackgroundColor(
                                  user_space?.space_owner?.first_name?.charAt(0)
                                ),
                              }}
                              className="w-[40px] h-[40px] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center me-3"
                            >
                              {getLetters(
                                user_space?.space_owner?.first_name +
                                  " " +
                                  user_space?.space_owner?.last_name
                              )}
                            </div>
                          </button>
                          {user_space?.space_owner?.first_name}&nbsp;
                          {user_space?.space_owner?.last_name}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <Dropdown
                            className="tbl-dropdown"
                            disabled={
                              user_space?.created_user_id == null ||
                              user_space?.created_user_id != user_space?.user_id
                            }
                            label={
                              user_space?.user_workspace_space?.space_type == 0
                                ? "Public"
                                : "Private"
                            }
                          >
                            <ul>
                              {user_space?.user_workspace_space?.space_type ==
                              0 ? (
                                <li
                                  onClick={() => {
                                    setprivatepublic({
                                      public: false,
                                      private: true,
                                    });
                                    setselectedFolderStructure({
                                      ...selectedFolderStructure,
                                      mainHeading: "Update Space",
                                      title: "Space Name",
                                      value:
                                        user_space?.user_workspace_space
                                          ?.space_name,
                                      space_type: 1,
                                      space_id:
                                        user_space?.user_workspace_space
                                          ?.space_id,
                                    });
                                    setOpenModalRemove("dismissible");
                                  }}
                                >
                                  <button type="button">Private</button>
                                </li>
                              ) : (
                                <li
                                  onClick={() => {
                                    setprivatepublic({
                                      public: true,
                                      private: false,
                                    });
                                    setselectedFolderStructure({
                                      ...selectedFolderStructure,
                                      mainHeading: "Update Space",
                                      title: "Space Name",
                                      value:
                                        user_space?.user_workspace_space
                                          ?.space_name,
                                      space_type: 0,
                                      space_id:
                                        user_space?.user_workspace_space
                                          ?.space_id,
                                    });
                                    setOpenModalRemove("dismissible");
                                  }}
                                >
                                  <button type="button">Public</button>
                                </li>
                              )}
                            </ul>
                          </Dropdown>
                        </div>
                      </td>
                      <td style={{ borderRadius: "25px" }}>
                        <div className="flex items-center relative">
                          {user_space?.user_workspace_space?.space_type == 0 ? (
                            <div className="mx-auto">
                              <p className="font-semibold">Everyone</p>
                            </div>
                          ) : (
                            <div className="mx-auto flex">
                              {user_space?.privatspace
                                ?.slice(0, 2)
                                ?.map((item, index) => (
                                  <div
                                    style={{
                                      backgroundColor: getBackgroundColor(
                                        item?.space_user?.first_name?.charAt(0)
                                      ),
                                    }}
                                    className={`w-[35px] h-[35px] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ${
                                      index != 0 && "ml-[-10px]"
                                    } `}
                                  >
                                    {getLetters(
                                      item?.space_user?.first_name +
                                        " " +
                                        item?.space_user?.last_name
                                    )}
                                  </div>
                                ))}
                              {user_space?.privatspace?.length > 2 && (
                                <div className="w-[35px] h-[35px] bg-[#6AC89B] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ml-[-10px]">
                                  +{user_space?.privatspace?.slice(2)?.length}
                                </div>
                              )}
                              <div
                                className="w-[35px] h-[35px] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ml-[-10px] bg-gradient-to-r from-[#097BA9] to-[#9B3785] relative cursor-pointer"
                                onClick={() => {
                                  setselectedPrivateSpace(user_space);
                                  setOpenModal("dismissible");
                                }}
                              >
                                <svg className="icon text-[24px]">
                                  <use href="#icon_rounduser"></use>
                                </svg>
                                <button
                                  type="button"
                                  className="absolute bottom-[-5px] right-[-10px] bg-[#5ADD6F] rounded-full w-[20px] h-[20px] flex items-center justify-center"
                                >
                                  <svg className="icon text-[10px]">
                                    <use href="#icon_plus"></use>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {/* end tabs */}

      {/* Modal Invite */}
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={() => {
          setselectedMemberList([]);
          setOpenModal(undefined);
        }}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Share this space</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p className="py-2">Invite member to collaborate in techonology </p>
          <div className="py-[5px] my-2 px-3 rounded-full border-dotted border-2 border-[#236DA5] text-[#236DA5] flex items-center mb-2">
            <svg className="icon me-2">
              <use href="#icon_security"></use>
            </svg>
            This is a private space
          </div>
          <div className="pb-3">
            <label for="email">Email Address</label>
            <div
              className={`flex flex-wrap gap-2 items-center ${
                emails?.length > 0 && "py-2"
              }`}
            >
              {emails.map((email, index) => (
                <div
                  key={index}
                  className="border rounded-md p-1 flex items-center bg-gray-200"
                >
                  <span>{email}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail(index)}
                    className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    &#x2715;
                  </button>
                </div>
              ))}
            </div>
            <input
              type="email"
              name="email"
              value={currentEmail}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onBlur={() => {
                if (currentEmail == "") {
                  setIsValidEmail(true);
                }
              }}
              autoComplete="off"
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Email multiple addresses"
              required
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-1">
                Please enter a valid email address
              </p>
            )}
            {currentEmail !== "" && isValidEmail && (
              <p
                className="border rounded-md pt-1 pb-1 pl-2 pr-2 inline-block bg-gray-200 cursor-pointer mt-1"
                onClick={inviteEmail}
              >
                Invite {currentEmail}
              </p>
            )}
          </div>
          <div className="">
            <label for="role">Shared With</label>
            <button
              type="button"
              className="flex w-full items-center border border-[#E9EBF0] bg-[#FAFBFC] rounded-full py-1 px-4"
              onClick={() => setdropdownopen(!dropdownopen)}
            >
              <div className="flex items-center">
                {!dropdownopen && (
                  <div>
                    <svg className="icon me-2">
                      <use href="#icon_dropclose"></use>
                    </svg>
                  </div>
                )}
                {dropdownopen && (
                  <div>
                    <svg className="icon me-2">
                      <use href="#icon_dropopen"></use>
                    </svg>
                  </div>
                )}
                Members
              </div>
              <div className="ms-auto flex">
                {selectedMemberList?.slice(0, 2)?.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: getBackgroundColor(
                        item?.user_workspace?.first_name?.charAt(0)
                      ),
                    }}
                    className={`w-[35px] h-[35px] bg-[${getBackgroundColor(
                      item?.user_workspace?.first_name?.charAt(0)
                    )}] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ${
                      index != 0 && "ml-[-10px]"
                    } `}
                  >
                    {getLetters(
                      item?.user_workspace?.first_name +
                        " " +
                        item?.user_workspace?.last_name
                    )}
                  </div>
                ))}
                {selectedMemberList?.length > 2 && (
                  <div className="w-[35px] h-[35px] bg-[#6AC89B] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ml-[-10px]">
                    +{selectedMemberList?.slice(2)?.length}
                  </div>
                )}
              </div>
            </button>
          </div>
          {dropdownopen && (
            <div className="w-full items-center border border-[#E9EBF0] bg-[#FAFBFC] rounded-[20px] py-3 px-3 mt-3">
              {/* <div className="flex items-center pb-1">
                <svg className="icon text-[14px] me-2">
                  <use href="#icon_search"></use>
                </svg>
                <input
                  type="text"
                  className="p-0 m-0 border-0 focus:ring-0 focus:ring-offset-0"
                  placeholder="Search By Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </div> */}
              <div className="max-h-[130px] overflow-y-auto pr-2">
                {selectedPrivateSpace?.privatspace?.map((item, index) => (
                  <div
                    className="flex items-center mb-3"
                    onClick={() => {
                      deleteFromSelectedMemberList(item);
                    }}
                  >
                    <div className="flex items-center cursor-pointer">
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            item?.space_user?.first_name?.charAt(0)
                          ),
                        }}
                        // className="w-[35px] h-[35px] bg-[#FBC575] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center me-2"
                        className={`w-[35px] h-[35px] bg-[${getBackgroundColor(
                          item?.space_user?.first_name?.charAt(0)
                        )}] text-[#fff] font-semibold text-[12px] rounded-full flex items-center justify-center`}
                      >
                        {getLetters(
                          item?.space_user?.first_name +
                            " " +
                            item?.space_user?.last_name
                        )}
                      </div>
                      <span className="ms-2">
                        {item?.space_user?.first_name}{" "}
                        {item?.space_user?.last_name}
                      </span>
                    </div>
                    {selectedPrivateSpace?.created_user_id != item?.user_id && (
                      <div className="ms-auto">
                        <button
                          type="button"
                          className=""
                          onClick={() => {
                            setselectedMember(item);
                            setOpenModal(undefined);
                            setOpenModalRemoveMember("dismissible");
                          }}
                        >
                          <svg className="icon text-[18px]">
                            <use href="#icon_closered"></use>
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex pt-4">
            <div className="ms-auto">
              <button
                type="buttonn"
                className="border border-[#2A2A2A] rounded-full px-8 py-1 me-6 hover:text-[#236DA5] hover:border-[#236DA5]"
                onClick={() => setOpenModal(undefined)}
              >
                Make Public
              </button>
              <button
                type="buttonn"
                className="bg-[#85D977] hover:bg-[#55b943] rounded-full text-[#fff] px-8 py-[5px]"
                onClick={() => {
                  if (!checkInviteEnabled()) {
                    setpopupMessage("Invite a Member");
                    setOpenModal(undefined);
                    setshowModal({
                      first: false,
                      second: true,
                      third: false,
                    });
                    return;
                  }
                  if (emails.length == 0) {
                    return;
                  }
                  submitSpaceTeamMembers();
                }}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div>Inviting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Invite"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CreateNewSpaceModal
        selectedMemberList={selectedMemberList}
        setselectedMemberList={setselectedMemberList}
        openModalRemove={openModalRemove}
        privatepublic={privatepublic}
        setprivatepublic={setprivatepublic}
        handleCloseSpaceModal={handleCloseSpaceModal}
        selectedFolderStructure={selectedFolderStructure}
        setselectedFolderStructure={setselectedFolderStructure}
        membersList={membersList}
        submitWorkspace={submitWorkspace}
        setpopupMessage={setpopupMessage}
        setshowModal={setshowModal}
      />
      <RemoveMember
        selectedMember={selectedMember}
        documentBodyRef={documentBodyRef}
        openModalRemove={openModalRemoveMember}
        setOpenModalRemove={openModalRemoveMember}
        removeMemberApi={removeMemberApi}
        onClose={() => {
          setremovingMember(false);
          setOpenModalRemoveMember(undefined);
        }}
        removingMember={removingMember}
        from="Space"
      />
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <PopupModals
        showModal={showModal}
        setshowModal={setshowModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default Managespace;
