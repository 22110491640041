/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import FileSave from "./FileSave";
import useCommonApi from "../hooks/common-apis";
import useCommonFunctions from "../hooks/common-functions";
import PopupModals from "./PopupModals";

function JobDescriptionComponent({
  loader,
  postWebsocketDetails,
  closeFunction,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [inputData, setInputData] = useState({
    c_name: "",
    position: "",
    info: "",
    skill_exp: "",
    tone: "",
    language: "English",
    content_title: selectedFile?.content_title,
    content_template: "Job Description",
  });
  const [editFile, seteditFile] = useState(false);
  const chooseSocialMedia="Choose Media";

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      postWebsocketDetails(inputData, "job_description");
    }
  };
  useEffect(() => {
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "Job Description") {
        setInputData({
          ...inputData,
          c_name: getSettingKey("c_name"),
          position: getSettingKey("position"),
          info: getSettingKey("info"),
          skill_exp: getSettingKey("skill_exp"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Job Description",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    // if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
  }, []);

  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">Job Description</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn();
          }}
        >
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="c_name">
              Company Name
            </label>
            <input
              type="text"
              id="c_name"
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Company Name"
              value={inputData.c_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="position">
              Job Role Or Position To Be Filled
            </label>
            <input
              type="text"
              id="position"
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter here"
              value={inputData.position}
              onChange={handleChange}
              required
            />
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="info">
              Basic Info About The Role
            </label>

            <textarea
              className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              id="info"
              name="msgarea"
              placeholder="Enter here."
              value={inputData.info}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="skill_exp">
              Skillset And Experience
            </label>

            <textarea
              className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              id="skill_exp"
              name="msgarea"
              placeholder="Enter here."
              value={inputData.skill_exp}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="tone">
              Choose Tone
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="tone"
              value={inputData.tone}
              onChange={handleChange}
              required
            >
              <option value="">Choose Tone</option>
              <option value="Assertive">Assertive</option>
              <option value="Appreciative">Appreciative</option>
              <option value="Awestruck">Awestruck</option>
              <option value="Candid">Candid</option>
              <option value="Casual">Casual</option>
              <option value="Cautionary">Cautionary</option>
              <option value="Convincing">Convincing</option>
              <option value="Critical">Critical</option>
              <option value="Earnest">Earnest</option>
              <option value="Enthusiastic">Enthusiastic</option>
              <option value="Formal">Formal</option>
              <option value="Funny">Funny</option>
              <option value="Humble">Humble</option>
              <option value="Humorous">Humorous</option>
              <option value="Informative">Informative</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Joyful">Joyful</option>
              <option value="Passionate">Passionate</option>
              <option value="Thoughtful">Thoughtful</option>
              <option value="Urgent">Urgent</option>
              <option value="Worried">Worried</option>
              <option value="Bold">Bold</option>
              <option value="Persuasive">Persuasive</option>
              <option value="Exciting">Exciting</option>
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              onChange={handleChange}
              required
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}
            has set as default language, you can change it in settings
          </div>

          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from="Job Description"
            postWebsocketDetails={postWebsocketDetails}
            mainTitle="Job Description"
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default JobDescriptionComponent;
