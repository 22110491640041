export const initialState = {
  loading: false,
  error: false,
  successMessage: "",
  errorMessage: "",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...initialState,
        loading: true,
        error: false,
        successMessage: "",
        errorMessage: "",
      };
    case "SUCCESS":
      return {
        ...initialState,
        loading: false,
        error: false,
        successMessage: action.data.successMessage,
        errorMessage: "",
      };
    case "ERROR":
      return {
        ...initialState,
        loading: false,
        error: true,
        successMessage: "",
        errorMessage: action.data.errorMessage,
      };
    case "RESET":
      return {
        loading: false,
        error: false,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};
