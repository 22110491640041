import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import UserContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import Feedback from "./Feedback";
import useCommonApi from "../hooks/common-apis";
import useCommonFunctions from "../hooks/common-functions";
function NavBar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [greeting, setGreeting] = useState("");
  const context = useContext(UserContext);
  const { setOpenModal, setchoosedTemplate, allrefs } = context;
  const { getBillingDetails } = useCommonApi();
  const { getDateTrial }=useCommonFunctions();
  const [billingData, setbillingData] = useState({});
  const updateGreeting = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    let newGreeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Good Afternoon";
    } else {
      newGreeting = "Good Evening";
    }

    let first_name = JSON.parse(localStorage.getItem("login_data"))?.resp_user
      ?.first_name;
    let last_name = JSON.parse(localStorage.getItem("login_data"))?.resp_user
      ?.last_name;
    newGreeting += " " + first_name + " " + last_name;

    setGreeting(newGreeting);
  };
  useEffect(() => {
    updateGreeting(); // Initial update when the component mounts
  }, []);
  const getBillingDetailsFn = async () => {
    try {
      const response = await getBillingDetails();
      setbillingData(response || {});
    } catch (error) {
      console.log("error", error);
    }
  };
  const getTotalAllowdWords = () => {
    let totalWords = 0;
    billingData?.usersetting?.forEach((item) => {
      if (item.key_name == "template_word") {
        totalWords =
          parseInt(item.current_value || 0) + parseInt(item.key_value || 0);
      }
    });
    return totalWords;
  };

  useEffect(() => {
    getBillingDetailsFn();
  }, []);
  return (
    <>
      <header className="flex border-b">
        <div className="capitalize">{greeting}</div>
        {getDateTrial() !== "NA" && (
          <div className="mx-auto text-[red]">
            Your trial will expire on <span className="">{getDateTrial()}</span>
          </div>
        )}
        {/* <div className="mx-auto">
        Consumed words / Total allowed words :{" "}
        {
          billingData?.usersetting?.find(
            (item) => item?.key_name == "template_word"
          )?.current_value
        }{" "}
        / {getTotalAllowdWords()}
      </div> */}
        <div className="ms-auto flex">
          <button
            ref={allrefs?.feedback}
            type="button"
            className="me-8 flex items-center text-[20px] font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784]"
            onClick={() => setIsOpen(true)}
          >
            <svg className="icon text-[28px] me-2">
              <use href="#icon_feedback"></use>
            </svg>
            Feedback
          </button>

          <button
            ref={allrefs?.subscription}
            type="button"
            className="py-2 me-8 px-10 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
            onClick={() => {
              navigate("/subscription");
            }}
          >
            Subscription
          </button>
          <button
            ref={allrefs?.generate}
            type="button"
            className="flex items-center py-2 px-10 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
            onClick={() => {
              localStorage.setItem("template_selected", "from_asidebar");
              setchoosedTemplate("Blog");
              setOpenModal("dismissible");
            }}
          >
            <span>Generate</span>
            <svg className="icon ms-2 text-[20px]">
              <use href="#icon_arrow"></use>
            </svg>
          </button>
        </div>
      </header>
      <Feedback isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default NavBar;
