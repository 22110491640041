/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import DiagramComponent from "../components/DiagramComponent";
import MerMaidComponent from "../components/MerMaidComponent";
import { toPng } from "html-to-image";
import useCommonFunctions from "../hooks/common-functions";

function MermaidPage() {
  const [inputBoxDescription, setinputBoxDescription] = useState("Description");
  console.log(inputBoxDescription);
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const { postWebsocketDetails, closeFunction, loader, stringWords,ws } =
    useWebsocketHook();
  const { getDescription } = useCommonFunctions();
  const [stringOutput, setstringOutput] = useState("");
  const [closeOpen, setcloseOpen] = useState(false);
  const [inputData, setInputData] = useState({
    title: "",
    steps: "",
    type: "",
    content_title: "",
    content_template: "",
  });

  const onchangeDescription = (e) => {
    setinputBoxDescription(e.target.value);
  };
  const stringOutputModify = () => {
    setcloseOpen(false);
    setstringOutput("");
  };
  useEffect(() => {
    return () => {
      if (ws) {
        // console.log("unmounting");
        closeFunction();
      }
    };
  }, [ws, closeFunction]);
  const getTitle = () => {
    let type = inputData.type;
    switch (type) {
      case "flowchart":
        return "Flowchart";
      case "sequence":
        return "Sequence Diagram";
      case "class":
        return "Class Diagram";
      case "state":
        return "State Diagram";
      case "gantt":
        return "Gantt Diagram";
      case "pie":
        return "Pie Chart";
      default:
        return "";
    }
  };

  const divRef = useRef(null);

  const handleDownload = () => {
    if (divRef.current === null) {
      return;
    }

    toPng(divRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "flowchart.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error("oops, something went wrong!", err);
      });
  };

  useEffect(() => {
    let replace1 = stringWords.replaceAll("&&", "<p></p>");
    let replace = replace1.replaceAll("####", "<p></p><p></p>");

    setstringOutput(replace);
  }, [stringWords]);
  useEffect(() => {
    if (stringOutput != "") {
      setTimeout(() => {
        setcloseOpen(true);
      }, 200);
    }
  }, [stringOutput]);

  useEffect(() => {
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    if (selectedFile && selectedFile?.contents) {
      setstringOutput(selectedFile?.contents);
    }
  }, []);

  // console.log(stringWords, "stringwords");
  return (
    <div className="wrapper">
      <div class="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2
          title="Diagram and Charts"
          description={getDescription("Business", "Flow Chart")}
        />

        <div
          className="w-full md:w-1/2 px-4 border-r"
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <MerMaidComponent
            onchangeDescription={onchangeDescription}
            from="Flow Chart"
            mainTitle="Diagram and Charts"
            loader={loader}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
            keywords
            stringOutputModify={stringOutputModify}
            inputData={inputData}
            setInputData={setInputData}
          />
        </div>

        <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full overflow-auto pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">{getTitle()}</h2>
              <div className="ms-auto flex items-center gap-3">
                {stringOutput?.length > 0 && (
                  <div onClick={() => handleDownload()}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_download"></use>
                    </svg>
                  </div>
                )}
                {!fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(true)}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_fullscreen"></use>
                    </svg>
                  </div>
                )}
                {fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(false)}>
                    <svg className="icon text-[14px] cursor-pointer">
                      <use href="#icon_fullexit"></use>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {/* <button onClick={genarateFromAnArray}>Diagram</button> */}
            {closeOpen && (
              <div ref={divRef}>
                <DiagramComponent
                  chart={stringOutput}
                  // chart={`
                  //   graph TD;
                  //     A[Start] --> B(Hello World);
                  //     B --> C{Is it working?};
                  //     C --> D[Yes];
                  //     C --> E[No];

                  //     D --> F[End];
                  //     E --> B;
                  //     E --> F;
                  // `}
                  // chart={
                  //   `
                  //   sequenceDiagram
                  //   participant User
                  //   participant Power Button
                  //   participant BIOS
                  //   participant OS
                  //   participant Desktop
                  //   User->>Power Button: Presses Power Button
                  //   Power Button->>BIOS: Sends signal to start BIOS
                  //   BIOS->>OS: Loads Operating System
                  //   OS->>Desktop: Shows Desktop
                  //   `
                  // }
                  // chart={`
                  // gantt
                  // dateFormat  YYYY-MM-DD
                  // title Vison project components
                  // section Frontend
                  // Work : 2022-05-14, 2022-05-28
                  // section Backend
                  // Work : 2022-05-01, 2022-05-20
                  // section middleware
                  // Work : 2022-05-15, 2022-06-08
                  // `}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MermaidPage;
