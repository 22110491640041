/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import TooltipComponent from "./TooltipComponent";
import "../../src/index.css";
import UserContext from "../context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useCommonApi from "../hooks/common-apis";
import { Modal } from "flowbite-react";
import { Remarkable } from "remarkable";
import wordsCount from "words-count";

const TextEditor = ({ stringOutput, from = "template", setstringOutput }) => {
  const imageRef = useRef(null);
  const [tooltip, setTooltip] = useState(null);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingTimeout = 300; // Set the timeout value in milliseconds (adjust as needed)
  const context = useContext(UserContext);
  const [savedSelection, setSavedSelection] = useState(null);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSavedSelection(range);
    }
  };

  const restoreSelection = () => {
    if (savedSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(savedSelection);
    }
  };

  // console.log(stringOutput, "string out in text editor");
  //   let stringOutput = `**Definition of Artificial Intelligence (AI)**
  //   - AI is a branch of computer science that aims to build machines capable of intelligent behavior.
  //   - It involves the development of algorithms that enable computers to perform tasks that typically require human intelligence.
  // - **Key Concepts in AI**
  //   - *Machine Learning (ML)*
  //     - ML is a subset of AI that allows machines to learn from data without being explicitly programmed.
  //     - It is crucial for enabling AI systems to improve their performance on a task as they are exposed to more data.
  //   - *Neural Networks*
  //     - Neural networks are a type of ML model inspired by the structure of the human brain.
  //     - They are particularly effective for tasks such as image and speech recognition.
  //   - *Deep Learning*
  //     - Deep learning is a subfield of ML that uses neural networks with many layers (deep neural networks).
  //     - It has fueled significant advances in AI, particularly in areas like natural language processing and computer vision.
  //   - *Natural Language Processing (NLP)*
  //     - NLP is the field of AI that focuses on enabling computers to understand, interpret, and generate human language.
  //     - It is essential for applications like chatbots, language translation, and text analysis.
  //   - *Computer Vision*
  //     - Computer vision is the area of AI that enables machines to interpret and understand the visual world.
  //     - It is crucial for tasks such as facial recognition, object detection, and autonomous driving.`;
  const {
    setselectedFile,
    setselectedContentSettings,
    selectedWorkspace,
    setselectedWorkspace,
    contentEditableRef,
  } = context;
  const selectedContentSettings = JSON.parse(
    localStorage.getItem("selectedContentSettings")
  );
  const documentBodyRef = useRef(null);
  const [openModal, setopenModal] = useState(undefined);
  const [imageUrl, setimageUrl] = useState("");
  const navigate = useNavigate();
  const { getRecentFiles, callUpdateToFileApi } = useCommonApi();
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [displayChooseFile, setdisplayChooseFile] = useState(true);
  const [imageWH, setimageWH] = useState({
    width: "",
    height: "",
  });
  const [wordsCharactersCount, setwordsCharactersCount] = useState({
    words: 0,
    characters: 0,
  });
  const [totalText, settotalText] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [startIndex, setStartIndex] = useState("");
  const [endIndex, setEndIndex] = useState("");
  const [ws, setWs] = useState(null);
  const [loader, setloader] = useState(false);
  const [reWriteWords, setreWriteWords] = useState("");
  const [rewriting, setrewriting] = useState(false);
  const [rewritingClicked, setrewritingClicked] = useState(false);

  const openModalFn = () => {
    // Save the current cursor position
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSavedSelection(selection.getRangeAt(0).cloneRange());
    }

    // Open the modal
    setopenModal("dismissible");
  };
  const getDataType = (type) => {
    if (type == "Operations") {
      return "";
    } else if (type == "Sales") {
    } else {
    }
  };
  // Example function of remarkable
  const getRemarkableText = () => {
    // let string=stringOutput?.replaceAll(/\n/g, "<br/>");
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
      langPrefix: "language-",
    });
    const html = md.render(stringOutput || "");
    return html;
  };
  const getWordsCharacters = () => {
    const contentEditableElement = contentEditableRef?.current;
    const content = contentEditableElement?.innerHTML?.trim();

    if (!content) return setwordsCharactersCount({ words: 0, characters: 0 });

    // Use wordsCount to get the number of words
    const words = wordsCount(content);

    // Count characters including spaces
    const characters = content.length;

    setwordsCharactersCount({ words, characters });
  };

  const applyStyleSubFn = async (style, value = null, image_id = null) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      const wrapSelectedText = (element) => {
        element.appendChild(document.createTextNode(selectedText));
        range.deleteContents();
        range.insertNode(element);
      };

      const createStyledElement = (tag, styles = {}, classNames = []) => {
        const element = document.createElement(tag);
        Object.assign(element.style, styles);
        element.className = classNames.join(" ");
        return element;
      };

      const applyListStyle = (listType) => {
        const listElement = document.createElement(listType);
        const listItem = document.createElement("li");
        listItem.appendChild(document.createTextNode(selectedText));
        listElement.appendChild(listItem);
        range.deleteContents();
        range.insertNode(listElement);
      };

      switch (style) {
        case "bold":
        case "italic":
        case "underline":
          document.execCommand(style, false, null);
          break;
        case "heading":
          const headingClasses = {
            h1: "text-5xl font-extrabold dark:text-white",
            h2: "text-4xl font-bold dark:text-white",
            h3: "text-3xl font-bold dark:text-white",
          };
          const heading = createStyledElement(value, {}, [
            headingClasses[value],
          ]);
          wrapSelectedText(heading);
          break;
        case "fontSize":
          const fontSizeNode = createStyledElement("span", { fontSize: value });
          wrapSelectedText(fontSizeNode);
          break;
        case "unlist":
          applyListStyle("ul");
          break;
        case "orlist":
          applyListStyle("ol");
          break;
        case "align":
          const parentNode = range.commonAncestorContainer.parentNode;
          if (parentNode && parentNode.nodeType === Node.ELEMENT_NODE) {
            parentNode.style.textAlign = value;
          } else {
            const alignNode = createStyledElement("div", { textAlign: value });
            wrapSelectedText(alignNode);
          }
          break;
        case "normal":
          const normalText = document.createTextNode(selectedText);
          const parentNodeNormal = range.commonAncestorContainer.parentNode;
          if (parentNodeNormal && parentNodeNormal.hasAttribute("className")) {
            parentNodeNormal.removeAttribute("className");
          }
          if (parentNodeNormal && parentNodeNormal.hasAttribute("class")) {
            parentNodeNormal.removeAttribute("class");
          }
          range.deleteContents();
          range.insertNode(normalText);
          break;
        case "image":
          const range1 =
            selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
          const imgContainer = createStyledElement("div", {}, [
            "relative",
            "inline-block",
            "m-0",
            "text-center",
            "flex",
            "justify-center",
            "items-center",
            "image-container",
          ]);
          imgContainer.setAttribute("data-image-id", image_id);
          imgContainer.setAttribute("contenteditable", "false");

          const imgElement = createStyledElement("img", {
            width:
              imageWH.width?.trim() !== ""
                ? imageWH.width?.trim() + "px"
                : "250px",
            height:
              imageWH.height?.trim() !== ""
                ? imageWH.height?.trim() + "px"
                : "250px",
            objectFit: "cover",
          });
          imgElement.src = !image_id ? image_id : imageUrl;
          imgElement.className = "block m-auto";

          const hoverButtons = createStyledElement("div", {}, [
            "hover-buttons",
            "absolute",
            "inset-0",
            "flex",
            "items-center",
            "justify-center",
            "hidden",
            "group-hover:flex",
          ]);

          const createHoverButton = (innerHtml, positionClass, onClick) => {
            const button = createStyledElement("div", {}, [
              "absolute",
              "cursor-pointer",
              "bg-black",
              "bg-opacity-50",
              "text-white",
              "p-1",
              "rounded-full",
              positionClass,
            ]);
            button.innerHTML = innerHtml;
            button.onclick = onClick;
            return button;
          };

          const closeBtn = createHoverButton("x", "top-1 right-1", () => {
            let dataImageId = imgContainer.getAttribute("data-image-id");
            console.log(dataImageId, "data image id");
            deleteImage(dataImageId);
            imgContainer.remove();
          });
          const topLeftBtn = createHoverButton(
            "&#8593;",
            "top-1 left-1",
            () => {
              const newLine = document.createElement("div");
              newLine.innerHTML = "<br>";
              imgContainer.parentNode.insertBefore(newLine, imgContainer);
              const newRange = document.createRange();
              newRange.setStartBefore(imgContainer);
              newRange.setEndBefore(imgContainer);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          );
          const bottomRightBtn = createHoverButton(
            "&#8595;",
            "bottom-1 right-1",
            () => {
              const newLine = document.createElement("div");
              newLine.innerHTML = "<br>";
              imgContainer.parentNode.insertBefore(
                newLine,
                imgContainer.nextSibling
              );
              const newRange = document.createRange();
              newRange.setStartAfter(imgContainer);
              newRange.setEndAfter(imgContainer);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          );

          hoverButtons.appendChild(closeBtn);
          hoverButtons.appendChild(topLeftBtn);
          hoverButtons.appendChild(bottomRightBtn);

          imgContainer.appendChild(imgElement);
          imgContainer.appendChild(hoverButtons);
          imgContainer.classList.add("group");

          // Add event listener to prevent deletion with backspace or delete
          imgContainer.addEventListener("keydown", (e) => {
            if (e.key === "Backspace" || e.key === "Delete") {
              e.preventDefault();
              const newRange = document.createRange();
              const parentDiv = imgContainer.parentNode;
              if (e.key === "Backspace") {
                newRange.setStartBefore(imgContainer);
                newRange.setEndBefore(imgContainer);
              } else if (e.key === "Delete") {
                newRange.setStartAfter(imgContainer);
                newRange.setEndAfter(imgContainer);
              }
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          });
          range1.deleteContents();
          range1.insertNode(imgContainer);

          setimageUrl("");
          setrawFile(null);
          setpreviewUrl(null);
          setdisplayChooseFile(false);
          setTimeout(() => {
            setdisplayChooseFile(true);
          }, 10);
          setimageWH({
            width: "",
            height: "",
          });
          break;
        default:
          break;
      }
      if (
        style !== "bold" &&
        style !== "italic" &&
        style !== "underline" &&
        style !== "image"
      ) {
        handleContentChange({
          target: {
            innerHTML: contentEditableRef.current.innerHTML,
          },
        });
      }
      // Reinitialize interact.js for images after inserting
      // initializeInteract();
    }

    contentEditableRef.current.focus();
  };
  const applyStyle = async (style, value = null) => {
    let image_id = null;
    if (style == "image" && rawFile && previewUrl) {
      await uploadIconFn(style, value)?.data;
    } else {
      applyStyleSubFn(style, value, null);
    }
    setopenModal(undefined);
  };

  const handleKeyDown = (event) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const node = range.startContainer;

    // Function to check if the cursor is next to an image
    const isCursorNextToImage = (range) => {
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;
      const textNode = range.startContainer;

      // Check previous sibling
      if (
        startOffset === 0 &&
        textNode.previousSibling &&
        textNode.previousSibling.nodeName === "IMG"
      ) {
        return true;
      }

      // Check next sibling
      if (
        endOffset === textNode.length &&
        textNode.nextSibling &&
        textNode.nextSibling.nodeName === "IMG"
      ) {
        return true;
      }

      return false;
    };

    // Handling backspace and delete keys
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      isCursorNextToImage(range)
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  useEffect(() => {
    getWordsCharacters();
  }, [stringOutput, contentEditableRef?.current?.innerText]);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();

      if (
        selection &&
        selection.rangeCount > 0 &&
        contentEditableRef.current.contains(
          selection.getRangeAt(0).commonAncestorContainer
        )
      ) {
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();

        if (selectedText.trim() !== "") {
          // Determine the position of the selection
          const rect = range.getBoundingClientRect();

          // Calculate the center of the bounding box
          const center = {
            top: rect.top + window.scrollY + rect.height / 2,
            left: rect.left + window.scrollX + rect.width / 2,
          };

          // Display tooltip at the center of the selected content
          setTooltip({
            text: selection.toString(), // You can customize the tooltip text
            position: { top: rect.top, left: center.left - 355 },
          });
        } else {
          // If no text is selected, hide the tooltip
          setTooltip(null);
        }
      } else {
        // If the selection is outside the TextEditor, hide the tooltip
        setTooltip(null);
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);
  function extractContext(sentence, keywordArray) {
    // const words = sentence.split(" ");
    const words = sentence.split(/[\s\n]+/);
    // console.log(words, "words...");
    let keywordIndex = -1;

    // Find the index of the first word of the keyword in the sentence
    for (let i = 0; i <= words.length - keywordArray.length; i++) {
      if (words[i]?.trim() === keywordArray[0]?.trim()) {
        let match = true;
        // Check if the subsequent words match the keyword
        for (let j = 1; j < keywordArray.length; j++) {
          if (words[i + j]?.trim() !== keywordArray[j]?.trim()) {
            match = false;
            break;
          }
        }
        if (match) {
          keywordIndex = i;
          break;
        }
      }
    }
    // console.log(keywordIndex,"keywordIndex...")

    let before = "";
    let after = "";
    if (keywordIndex !== -1) {
      const start = Math.max(0, keywordIndex - 5);
      const end = Math.min(
        words.length,
        keywordIndex + keywordArray.length + 6
      );

      before = words.slice(start, keywordIndex).join(" ");
      after = words.slice(keywordIndex + keywordArray.length, end).join(" ");
    }

    return { before, after };
  }

  function handleSelectionChange1() {
    const selection = window.getSelection();
    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      if (contentEditableRef) {
        const selectedContent = window.getSelection().toString();
        // const stringWithSpaceBeforeNewline =
        // contentEditableRef.current.innerHTML.replace(/(\n+)/g, " $1");
        // const stringWithSpaceBeforeNewline = contentEditableRef.current.innerText.replace(/\n+/g, ' \n');
        const stringWithSpaceBeforeNewline =
          contentEditableRef.current.innerText.replace(/[\s\n]+/g, " \n");
        let startEnd = extractContext(
          stringWithSpaceBeforeNewline,
          selectedContent?.split(" ")
        );
        const startIndex =
          contentEditableRef.current.innerHTML.indexOf(selectedContent);
        const endIndex = startIndex + selectedContent.length;

        console.log(selectedContent, "selected content");
        console.log(startEnd, "startEnd");
        console.log(startIndex, "start index");
        console.log(endIndex, "end index");
        setSelectedContent(selectedContent);
        setStart(startEnd.before);
        setEnd(startEnd.after);
        setStartIndex(startIndex);
        setEndIndex(endIndex);
      }
    }
  }
  // function handleSelectionChange() {
  //   const selection = window.getSelection();
  //   if (
  //     selection &&
  //     selection.rangeCount > 0 &&
  //     contentEditableRef.current.contains(
  //       selection.getRangeAt(0).commonAncestorContainer
  //     )
  //   ) {
  //     if (contentEditableRef) {
  //       const selectedContent = window.getSelection().toString();
  //       console.log(selectedContent, "selected content");

  //       const fullText = contentEditableRef.current.textContent;
  //       const startIndex = selection.anchorOffset;
  //       const endIndex = startIndex + selectedContent.length;

  //       const wordsBefore = fullText.slice(0, startIndex).split(/\s+/);
  //       const wordsAfter = fullText.slice(endIndex).split(/\s+/);

  //       const maxWordsBefore = wordsBefore.slice(-5).join(" ");
  //       const maxWordsAfter = wordsAfter.slice(0, 5).join(" ");

  //       const startEnd = {
  //         before: maxWordsBefore,
  //         after: maxWordsAfter,
  //       };

  //       console.log(startEnd, "startEnd");
  //       console.log(startIndex, "start index");
  //       console.log(endIndex, "end index");
  //     }
  //   }
  // }
  function handleSelectionChange() {
    const selection = window.getSelection();
    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      const selectedContent = selection.toString();
      console.log(selectedContent, "selected content");

      // Get the HTML content of the contentEditable div
      const fullHtml = contentEditableRef.current.innerHTML;

      // Get the start and end index of the selected content in the HTML
      const startIndex = fullHtml.indexOf(selectedContent);
      const endIndex = startIndex + selectedContent.length;

      // Split the HTML content around the selected content
      const beforeHtml = fullHtml.slice(0, startIndex);
      const afterHtml = fullHtml.slice(endIndex);

      // Get the text content and split into words
      const beforeWords = beforeHtml
        .split(/<[^>]*>|&nbsp;|[^\w]+/)
        .filter(Boolean);
      const afterWords = afterHtml
        .split(/<[^>]*>|&nbsp;|[^\w]+/)
        .filter(Boolean);

      // Get the max 5 words before and after
      const maxWordsBefore = beforeWords.slice(-5).join(" ");
      const maxWordsAfter = afterWords.slice(0, 5).join(" ");

      const startEnd = {
        before: maxWordsBefore,
        after: maxWordsAfter,
      };

      // console.log(startEnd, "startEnd");
      // console.log(startIndex, "start index");
      // console.log(endIndex, "end index");
      // setSelectedContent(selectedContent);
      // setStart(startEnd.before);
      // setEnd(startEnd.after);
    }
  }

  const uploadIconFn = async (style, value) => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/editorimage`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        applyStyleSubFn(style, value, response.data.file_path);

        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  const deleteImage = async (dataImageId) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/delete/upload_file`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: {
          file_path: dataImageId,
        },
      });

      if (!response.data.error) {
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postWebsocketDetailsRewrite = () => {
    const selection = window.getSelection();
    const selectedContent = selection.toString().replace(/\s+/g, " ");
    // Get the HTML content of the contentEditable div
    const fullHtml = contentEditableRef.current.innerHTML;

    // Get the start and end index of the selected content in the HTML
    const startIndex = fullHtml.indexOf(selectedContent);
    const endIndex = startIndex + selectedContent.length;
    // console.log(startIndex, "startIndex");
    // console.log(endIndex, "endIndex");

    // Split the HTML content around the selected content
    const beforeHtml = fullHtml.slice(0, startIndex);
    const afterHtml = fullHtml.slice(endIndex);
    // console.log(beforeHtml, "beforeHtml");
    // console.log(afterHtml, "afterHtml");
    // Create a temporary container to get the innerText
    const tempContainerBefore = document.createElement('div');
    tempContainerBefore.innerHTML = beforeHtml;
    let beforeText = tempContainerBefore.innerText;
    const tempContainerAfter = document.createElement('div');
    tempContainerAfter.innerHTML = afterHtml;
    let afterText = tempContainerAfter.innerText;

    // Get the text content and split into words
    const beforeWords = beforeText
      .split(/<[^>]*>|&nbsp;|[^\w]+/)
      .filter(Boolean);
    const afterWords = afterText.split(/<[^>]*>|&nbsp;|[^\w]+/).filter(Boolean);

    // Get the max 5 words before and after
    const maxWordsBefore = beforeWords.slice(-5).join(" ");
    const maxWordsAfter = afterWords.slice(0, 5).join(" ");

    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      if (ws) {
        ws.close(); // Close the existing WebSocket connection if it exists
        setWs(null); // Reset the WebSocket state
      }
      const newWs = new WebSocket(`${process.env.REACT_APP_BASE_URL}/rewrite`);
      setWs(newWs);

      newWs.onopen = function open() {
        setloader(true);
        setreWriteWords("");
        let new_data = {
          START: maxWordsBefore,
          PARA: selection.toString(),
          END: maxWordsAfter,
          model: "model_1",
          token: localStorage.getItem("login_token"),
        };
        setrewriting(true);
        const dataToSend = JSON.stringify(new_data);
        newWs.send(dataToSend);
      };

      newWs.onmessage = function incoming(data) {
        // console.log("received", data.data);

        const word = data.data;
        const fullHtml = contentEditableRef.current.innerHTML;

        if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
          setloader(false);
          setrewriting(false);
        } else {
          const match = word.match(/START:(.*?)PARA:(.*?)END:/s);

          if (match) {
            setTooltip(null);
            const [, startContent, paraContent] = match;
            // console.log("START:", startContent);
            // console.log("PARA:", paraContent);
            const trimmedParaContent = paraContent.replace(/\s+$/, ""); // Trim trailing spaces
            const startIndex = fullHtml.indexOf(selection.toString());
            const endIndex = startIndex + selection.toString().length;

            // Log to verify indices and current content
            console.log("startIndex:", startIndex);
            console.log("endIndex:", endIndex);
            console.log("fullHtml:", fullHtml);

            console.log(
              fullHtml.slice(0, startIndex),
              "fullHtml.substring(0, startIndex)"
            );
            console.log(trimmedParaContent, "trimmedParaContent");
            console.log(
              fullHtml.substring(endIndex),
              "fullHtml.substring(endIndex)"
            );
            // return;
            contentEditableRef.current.innerHTML =
              fullHtml.slice(0, startIndex) +
              trimmedParaContent +
              fullHtml.substring(endIndex);
            const selectedFile = JSON.parse(
              localStorage.getItem("selected_file")
            );
            let selected_file_data = {
              content_id: selectedFile?.content_id || selectedFile?.id || "",
              space_id: selectedFile?.space_id || "",
              folder_id: selectedFile?.folder_id || "",
            };
            let selectedFileContentSettings = {
              ...selectedFile?.content_settings,
            };
            selectedFileContentSettings["content_title"] =
              selectedFile?.content_title;
            selectedFileContentSettings["content_template"] =
              selectedFile?.content_template;
            callUpdateToFileApi(
              selectedFileContentSettings,
              contentEditableRef.current.innerHTML,
              {},
              selected_file_data,
              selectedFile
            );
          }
        }
      };
      newWs.onclose = () => {
        setloader(false);
        setrewriting(false);
        console.log("WebSocket connection closed.");
      };

      newWs.onerror = (error) => {
        setloader(false);
        setrewriting(false);
        console.error("WebSocket error:", error);
      };
    }
  };
  const postWebsocketDetailsPlagiarism = async () => {
    const selection = window.getSelection();

    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/copydup/search`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: localStorage.getItem("login_token"),
          },
          data: {
            textsearch: selectedContent,
            content_id:
              JSON.parse(localStorage.getItem("selected_file")).content_id ||
              JSON.parse(localStorage.getItem("selected_file")).id,
          },
        });
        console.log(response, "response");
        if (!response.data.error) {
        } else {
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("login_token");
          localStorage.removeItem("login_data");
          navigate("/");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (typingTimer) {
        clearTimeout(typingTimer);
      }
    };
  }, [typingTimer]);

  const handleContentChange = (event) => {
    const updatedContent = event.target.innerHTML;

    // Clear the previous timer
    if (typingTimer) {
      clearTimeout(typingTimer);
    }

    // Set a new timer to call the save API after typingTimeout milliseconds
    const newTypingTimer = setTimeout(() => {
      saveContentToApi(updatedContent);
    }, typingTimeout);

    setTypingTimer(newTypingTimer);
  };

  const saveContentToApi = async (content) => {
    // Create a temporary element to hold the HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = contentEditableRef.current.innerHTML;

    // Get all elements with class 'hover-buttons' and remove them
    let hoverButtons = tempElement.querySelectorAll(".hover-buttons");
    hoverButtons.forEach((button) => button.parentNode.removeChild(button));

    // Get the cleaned HTML
    let cleanedHTML = tempElement.innerHTML;

    // console.log(cleanedHTML);

    // console.log(contentEditableRef.current.innerHTML, "content...");
    if (from == "chat") {
      return;
    }
    // Call your save API with the updated content
    // console.log("Saving to API:", content);
    if (content && content != "") {
      // callUpdateToFileApi(selectedContentSettings, content);
      const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
      let selected_file_data = {
        content_id: selectedFile?.content_id || selectedFile?.id || "",
        space_id: selectedFile?.space_id || "",
        folder_id: selectedFile?.folder_id || "",
      };
      let selectedFileContentSettings = { ...selectedFile?.content_settings };
      selectedFileContentSettings["content_title"] =
        selectedFile?.content_title;
      selectedFileContentSettings["content_template"] =
        selectedFile?.content_template;

      // console.log(
      //   selectedFileContentSettings,
      //   "selectedFileContentSettings",
      //   selectedFile,
      //   selected_file_data
      // );
      // setstringOutput(content);
      callUpdateToFileApi(
        selectedFileContentSettings,
        contentEditableRef.current.innerHTML,
        {},
        selected_file_data,
        selectedFile
      );
    }
  };

  return (
    <>
      <div>
        {tooltip && from !== "chat" && (
          <TooltipComponent
            text={tooltip.text}
            position={tooltip.position}
            postWebsocketDetailsRewrite={postWebsocketDetailsRewrite}
            postWebsocketDetailsPlagiarism={postWebsocketDetailsPlagiarism}
          />
        )}
        <div className="flex items-center gap-2 bg-[#F9F9F9] py-1 px-1">
          <div className="flex items-center gap-4 w-full border-r-2 border-[black] pr-[10px]">
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("bold", "bold")}
            >
              B
            </button>
            <button
              className="italic text-[22px]"
              onClick={() => applyStyle("italic", "italic")}
            >
              I
            </button>
            <button
              className="underline text-[22px]"
              onClick={() => applyStyle("underline", "underline")}
            >
              U
            </button>
            {/* <button className="text-[22px] border border-[black] px-2 rounded-[8px]">
              Aa
            </button> */}
          </div>
          <div className="flex items-center gap-4 w-full border-r-2 border-[black] pr-[10px]">
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h1")}
            >
              H1
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h2")}
            >
              H2
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h3")}
            >
              H3
            </button>
            <button
              className="text-[22px]"
              onClick={() => applyStyle("normal")}
            >
              Normal
            </button>
          </div>
          <div className="flex items-center gap-7 w-full border-r-2 border-[black] pr-[10px]">
            <button onClick={() => applyStyle("unlist", "insertUnorderedList")}>
              <svg className="icon text-[34px]">
                <use href="#icon_list"></use>
              </svg>
            </button>
            <button onClick={() => applyStyle("orlist", "insertOrderedList")}>
              <svg className="icon text-[26px]">
                <use href="#icon_order-list"></use>
              </svg>
            </button>
          </div>
          {/* <div className="flex items-center gap-7 w-full pr-[10px]">
            <button onClick={() => applyStyle("align", "left")}>Left</button>
            <button onClick={() => applyStyle("align", "center")}>
              Center
            </button>
            <button onClick={() => applyStyle("align", "right")}>Right</button>
            <button onClick={() => applyStyle("align", "justify")}>
              Justify
            </button>
          </div> */}
          {/* <div
            className="flex items-center gap-4 w-full"
            onClick={() => {
              contentEditableRef.current.focus();
              saveSelection();
              setTimeout(() => {
                openModalFn();
              }, 200);
            }}
          >
            <button>
              <svg className="icon text-[20px]">
                <use href="#icon_insert-img"></use>
              </svg>
            </button>
          </div> */}
        </div>
        <div
          ref={contentEditableRef}
          contentEditable
          style={{
            border: "1px solid #ccc",
            minHeight: "100px",
            padding: "5px",
            fontSize: "16px",
            maxHeight: "70vh",
            overflow: "auto",
            outline: "none", // Remove the default focus outline
            width: "100%",
          }}
          onInput={handleContentChange}
          onMouseUp={handleSelectionChange}
          className="text-lg text-editor"
          dangerouslySetInnerHTML={{ __html: getRemarkableText() }}
          // dangerouslySetInnerHTML={{ __html: stringOutput }}
          onFocus={() => {
            // Change the border color to red when focused
            contentEditableRef.current.style.border =
              "2px solid rgb(56, 140, 197)";
          }}
          onBlur={() => {
            // Reset the border color when focus is lost
            contentEditableRef.current.style.border = "1px solid #ccc";
          }}
        />
      </div>
      <div className="flex justify-end w-full mt-1">
        <div className="text-editor-footer-count flex space-x-4">
          <div>
            <span className="font-bold">Words:</span>{" "}
            {wordsCharactersCount.words}
          </div>
          <div>
            <span className="font-bold">Characters:</span>{" "}
            {wordsCharactersCount.characters}
          </div>
        </div>
      </div>

      <Modal
        root={documentBodyRef?.current}
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={() => setopenModal(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Insert/Edit Image</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="pb-3 pt-4">
            <label htmlFor="file">Image url</label>
            <input
              type="text"
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Image url"
              value={imageUrl}
              onChange={(e) => {
                setimageUrl(e.target.value);
                imageRef.current.value = null;
                if (rawFile) {
                  setrawFile(null);
                  setpreviewUrl(null);
                  imageRef.current.value = null;
                  setdisplayChooseFile(false);
                  setTimeout(() => {
                    setdisplayChooseFile(true);
                  }, 10);
                }
              }}
            />
          </div>
          <div className="text-center pb-3">or</div>
          <div className="pb-3">
            <label htmlFor="file">Upload From Local</label>
            <input
              type="file"
              id="uploaadfile"
              name="file"
              ref={imageRef}
              className="mt-1 w-full rounded-full bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Upload From Local"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                setimageUrl("");
                setrawFile(e.target.files[0]);
              }}
            />
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="file">Width (in px)</label>
              <input
                type="text"
                className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
                placeholder="Width"
                value={imageWH?.width}
                onChange={(e) =>
                  setimageWH({
                    ...imageWH,
                    width: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="file">Height (in px)</label>
              <input
                type="text"
                className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
                placeholder="Height"
                value={imageWH?.height}
                onChange={(e) =>
                  setimageWH({
                    ...imageWH,
                    height: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="pt-4 flex justify-end">
            <button
              type="button"
              className="flex w-[130px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#ed1512] text-white  hover:from-[#9d3742] hover:to-[#1A71A7] me-6"
              onClick={() => {
                contentEditableRef.current.focus();
                setopenModal(undefined);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="flex w-[130px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => {
                if (imageRef.current) {
                  imageRef.current.value = null; // Clear the input field
                }
                setimageUrl("");
                setpreviewUrl(null);
                setrawFile(null);
                restoreSelection(); // Restore the saved selection
                contentEditableRef.current.focus();
                applyStyle("image");
              }}
              disabled={imageUrl == "" && !previewUrl}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TextEditor;
