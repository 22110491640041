/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function LinkedInComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
  target,
  product_name,
  description,
  date,
  problem,
  solution,
  other_info,
  cta,
  company_name,
  product_service,
  benifits,
  target_customer,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [inputData, setInputData] = useState({
    company_name: "",
    description: "",
    target_audience: "",
    tone: "",
    language: "English",
    problem: "",
    solution: "",
    info: "",
    company: "",
    product: "",
    benefits: "",
    content_title: selectedFile?.content_title,
  });
  const [editFile, seteditFile] = useState(false);

  const chooseSocialMedia = "Choose Media";

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const getModifiedDate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (inputData.date != "") {
      let modifiedDate = getModifiedDate(inputData.date);
      setInputData({ ...inputData, modified_date: modifiedDate });
    }
  }, [inputData.date]);
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    let data = {};
    let titleTemplate = {};
    if (from == "LinkedIn Single Image Ad/Post") {
      data = {
        company_name: inputData.company_name,
        description: inputData.description,
        target_audience: inputData.target_audience,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "LinkedIn Single Image and Post",
      };
      postWebsocketDetails(data, "linkedin_single_image_post");
    } else if (
      from == "LinkedIn Personal Post (Get Max Reach And Engagement)"
    ) {
      data = {
        problem: inputData.problem,
        solution: inputData.solution,
        target_audience: inputData.target_audience,
        info: inputData.info,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template:
          "LinkedIn Personal Post (Get Max Reach And Engagement)",
      };
      postWebsocketDetails(data, "linkedin_personal_post");
    } else if (from == "LinkedIn Company Post (Get Max Reach And Engagement)") {
      data = {
        company: inputData.company,
        product: inputData.product,
        benefits: inputData.benefits,
        target_audience: inputData.target_audience,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template:
          "LinkedIn Company Post (Get Max Reach And Engagement)",
      };
      postWebsocketDetails(data, "linkedin_company_post");
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
    }
  };
  useEffect(() => {
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "LinkedIn Single Image and Post") {
        setInputData({
          ...inputData,
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          target_audience: getSettingKey("target_audience"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "LinkedIn Single Image and Post",
        });
      } else if (
        selectedFile?.content_template ==
        "LinkedIn Personal Post (Get Max Reach And Engagement)"
      ) {
        setInputData({
          ...inputData,
          problem: getSettingKey("problem"),
          solution: getSettingKey("solution"),
          target_audience: getSettingKey("target_audience"),
          info: getSettingKey("info"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template:
            "LinkedIn Personal Post (Get Max Reach And Engagement)",
        });
      } else if (
        selectedFile?.content_template ==
        "LinkedIn Company Post (Get Max Reach And Engagement)"
      ) {
        setInputData({
          ...inputData,
          company: getSettingKey("company"),
          product: getSettingKey("product"),
          benefits: getSettingKey("benefits"),
          target_audience: getSettingKey("target_audience"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template:
            "LinkedIn Company Post (Get Max Reach And Engagement)",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    if (selectedFile) {
      if (selectedFile?.content_title) {
        setInputData({
          ...inputData,
          content_title: selectedFile.content_title,
        });
      }
    }
  }, []);

  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn();
          }}
        >
          {product_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="company_name"
              >
                Company / Product Name
              </label>
              <input
                type="text"
                id="company_name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company / Product Name"
                value={inputData.company_name}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {company_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="company"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company / Product Name"
                value={inputData.company}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {product_service && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="product"
              >
                Product / Service
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="product"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.product}
                required
              ></textarea>
            </div>
          )}
          {benifits && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="benefits"
              >
                Benefits Of Service / Product
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="benefits"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.benefits}
                required
              ></textarea>
            </div>
          )}
          {problem && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="problem"
              >
                Problem
              </label>
              <input
                type="text"
                id="problem"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Problem"
                value={inputData.problem}
                onChange={handleChange}
                required
              />
            </div>
          )}

          {solution && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="solution"
              >
                Solution
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="solution"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.solution}
                required
              ></textarea>
            </div>
          )}
          {other_info && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="info"
              >
                Other Info To Add
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="info"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.info}
                required
              ></textarea>
            </div>
          )}
          {description && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="description"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.description}
                required
              ></textarea>
            </div>
          )}

          {target && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="target_audience"
              >
                Target Audience
              </label>
              <input
                type="text"
                id="target_audience"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Target Audience"
                value={inputData.target_audience}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {target_customer && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="target_audience"
              >
                Target Customer
              </label>
              <input
                type="text"
                id="target_audience"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Target Audience"
                value={inputData.target_audience}
                onChange={handleChange}
                required
              />
            </div>
          )}

          {date && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="date"
              >
                Date
              </label>
              <input
                type="date"
                id="date"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                value={inputData.date}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="tone">
              Choose Tone
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="tone"
              value={inputData.tone}
              required
              onChange={handleChange}
            >
              <option value="">Choose Tone</option>
              <option value="Assertive">Assertive</option>
              <option value="Appreciative">Appreciative</option>
              <option value="Awestruck">Awestruck</option>
              <option value="Candid">Candid</option>
              <option value="Casual">Casual</option>
              <option value="Cautionary">Cautionary</option>
              <option value="Convincing">Convincing</option>
              <option value="Critical">Critical</option>
              <option value="Earnest">Earnest</option>
              <option value="Enthusiastic">Enthusiastic</option>
              <option value="Formal">Formal</option>
              <option value="Funny">Funny</option>
              <option value="Humble">Humble</option>
              <option value="Humorous">Humorous</option>
              <option value="Informative">Informative</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Joyful">Joyful</option>
              <option value="Passionate">Passionate</option>
              <option value="Thoughtful">Thoughtful</option>
              <option value="Urgent">Urgent</option>
              <option value="Worried">Worried</option>
              <option value="Bold">Bold</option>
              <option value="Persuasive">Persuasive</option>
              <option value="Exciting">Exciting</option>
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              required
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default LinkedInComponent;
