/* eslint-disable eqeqeq */
import {
  AccordionHeader,
} from "react-headless-accordion";
import { Chevron } from "./Icons2";
import { useContext } from "react";
import UserContext from "../context/UserContext";

const NestedMenuChat = ({
  nestedchat,
  handleUpdateTree,
  openMenuz,
  from,
  setrenameChat,
  setselectedChat,
  activeChatRef,
  dummyChatRef,
}) => {
  const context = useContext(UserContext);
  // const { loadingChat } = context;
  const printItem = (item, index) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // const id = urlParams.get("id");
    let id = JSON.parse(
      localStorage.getItem("chat_file")
    )?.thread_id?.toString();

    // console.log(id, "query id..."); // This will log the value of the 'id' parameter from the URL

    const buttonClass =
      "w-full flex items-center hover:text-[#097BA9] text-[#2A2A2A] border-bl py-2 px-2 ";

    switch (item.type) {
      case "folder":
        return (
          <>
            <div className="flex items-center w-full text-lg">
              <AccordionHeader
                className={buttonClass}
                onClick={() => {
                  // if(loadingChat){
                  //     return;
                  //   }
                  handleUpdateTree(item?.tree_id, !item?.open, from, item);
                }}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="font-bold px-4">
                    <span className="ml-2">{item?.title}</span>
                  </div>
                  <div>
                    <Chevron
                      className={`w-7 h-7 transform transition duration-300 ${
                        !item?.open ? "" : " rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </AccordionHeader>
            </div>

            {item?.open && (
              <>
                <div className="pl-3">{item?.submenu?.map(printItem)}</div>
              </>
            )}
          </>
        );
      case "file":
        return (
          <>
            {/* {console.log(item, "item")} */}

            <div className="flex w-[100%] flex-start pl-[7.3px] pr-4 ">
              <AccordionHeader
                className={`w-[90%] flex items-center hover:text-[#097BA9] text-${
                  item?.thread_id == id ? "[#097BA9]" : "[#2A2A2A]"
                } border-bl py-2 px-2`}
                onClick={() => {
                  // if(loadingChat){
                  //     return;
                  //   }
                  handleUpdateTree(item?.tree_id, !item?.open, from, item);
                }}
                ref={item?.thread_id == id ? activeChatRef : dummyChatRef}
              >
                <div className="flex active whitespace-nowrap overflow-hidden">
                  <span className="ml-2 max-w-[95%] overflow-hidden  text-ellipsis ">
                    {item?.title}
                  </span>
                </div>
              </AccordionHeader>

              <div className="flex gap-3">
                <button
                  type="button"
                  className="hover:text-[#097BA9]"
                  onClick={(e) => {
                    setrenameChat("dismissible");
                    setselectedChat(item);
                  }}
                >
                  <svg className="icon pointer-events-none">
                    <use href="#icon_edit"></use>
                  </svg>
                </button>
                {/* <button type="button" className="hover:text-[#097BA9]">
                  <svg className="icon pointer-events-none">
                    <use href="#icon_downloadchat"></use>
                  </svg>
                </button> */}
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div
        className=""
        transition={{
          duration: "300ms",
          timingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        }}
      >
        {nestedchat?.map(printItem)}
      </div>
    </>
  );
};

export default NestedMenuChat;
