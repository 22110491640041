/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import MarketingComponent from "../components/MarketingComponent";
import TextEditor from "../components/TextEditor";
import useCommonFunctions from "../hooks/common-functions";

function ProductDescription() {
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const {
    postWebsocketDetails,
    closeFunction,
    loader,
    stringWords,
    setstringWords,
    ws
  } = useWebsocketHook();
  const {getDescription}=useCommonFunctions();
  const selectedFile=JSON.parse(localStorage.getItem("selected_file"));

  useEffect(() => {
    if (selectedFile && selectedFile?.contents) {
      setstringWords(selectedFile?.contents);
    }
  }, []);
  useEffect(() => {
    return () => {
      if (ws) {
        // console.log("unmounting");
        closeFunction();
      }
    };
  }, [ws]);
  return (
    <div className="wrapper">
      <div class="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2
          title="Google By Business- Product Description"
          description={getDescription("Marketing","Google By Business- Product Description")}
        />

        <div
          className="w-full md:w-1/2 px-4 border-r"
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <MarketingComponent
            from="Google By Business- Product Description"
            mainTitle="Google By Business- Product Description"
            loader={loader}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
            product_name
            about
            key_benefits
            target_customers
          />
        </div>

        <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Editor</h2>
              <div className="ms-auto">
                {!fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(true)}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_fullscreen"></use>
                    </svg>
                  </div>
                )}
                {fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(false)}>
                    <svg className="icon text-[14px] cursor-pointer">
                      <use href="#icon_fullexit"></use>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <TextEditor stringOutput={stringWords} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescription;
