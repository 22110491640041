import React from "react";

function ThreeDotsLoader() {
  return (
    <div className="loader">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </div>
  );
}

export default ThreeDotsLoader;
