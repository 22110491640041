import React, { useEffect } from "react";
import mermaid from "mermaid";

function DiagramComponent({ chart }) {
  useEffect(() => {
    mermaid.initialize({
      // theme: "base",
      startOnLoad: true,
      // themeVariables: {
      //   primaryColor: "#fff",
      //   mainBkg: "#eee",
      //   primaryBorderColor: "#aaa",
      //   fontFamily: "monospace",
      // },
      // sequence: {
      //   actorFontFamily: "monospace",
      //   messageFontFamily: "monospace",
      //   messageFontWeight: "100",
      //   messageFontSize: "12px",
      //   boxMargin: 10,
      //   actorMargin: 8,
      //   height: 40,
      //   mirrorActors: false,
      // },
    });
    mermaid.contentLoaded();
  }, []);

  return <div className="mermaid text-center flex justify-center items-center">{chart}</div>;
}
export default DiagramComponent;
