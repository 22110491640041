import { Datepicker, Label, Modal, Select, TextInput } from "flowbite-react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

function ScheduleSocialPostModal({
  schedulePostModal,
  setSchedulePostModal,
  onConfirm,
  loading,
}) {
  let currentTime = moment()
  const [time, setTime] = useState({
    month: currentTime.month() + 1,
    day: currentTime.date(),
    year: currentTime.year(),
    hour: currentTime.format('h'),
    minute: currentTime.minute(),
    ampm: currentTime.format("A"),
    timezone: 'Asia/Kolkata'
  });
  // console.log(time, 'time')
  const [days, setDays] = useState([]);
  useEffect(() => {
    let dayInMonth = moment(
      `${time.year}-${time.month}`,
      "YYYY-MM"
    ).daysInMonth();
    // console.log(dayInMonth,'dayInMonth',time.month)
    setDays([...Array(dayInMonth).keys()].map((day) => day + 1));
  }, [time]);

  return (
    <Modal
      dismissible
      show={schedulePostModal === "dismissible"}
      size="xl"
      onClose={() => setSchedulePostModal(undefined)}
    >
      <Modal.Header className="capitalize border-0 py-2"></Modal.Header>
      <Modal.Body className="pt-0">
        <h6 className="font-bold text-[28px] text-center pb-5">
          Schedule Post
        </h6>

        <label
          htmlFor="Date"
          className="block mb-2 text-[18px] font-medium text-gray-900 dark:text-white"
        >
          Date
        </label>
        <div className="flex justify-between mb-6">
          <div className="relative w-[38%]">
            <select
              value={time.month}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, month: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              MONTH
            </span>
          </div>

          <div className="relative w-[32%]">
            <select
              value={time.day}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, day: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              DAY
            </span>
          </div>

          <div className="relative w-[22%]">
            <select
              value={time.year}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, year: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              {
                //neet to map only 5 year options from current year
                [...Array(5).keys()].map((year) => (
                  <option key={year} value={moment().year() + year}>
                    {moment().year() + year}
                  </option>
                ))
              }
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              YEAR
            </span>
          </div>
        </div>

        <label
          htmlFor="time"
          className="block mb-2 text-[18px] font-medium text-gray-900 dark:text-white"
        >
          Time
        </label>
        <div className="flex justify-between mb-6">
          <div className="relative w-[38%]">
            <select
              value={time.hour}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, hour: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              HOUR
            </span>
          </div>

          <div className="relative w-[32%]">
            <select
              value={time.minute}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, minute: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              MINUTE
            </span>
          </div>

          <div className="relative w-[22%]">
            <select
              value={time.ampm}
              onChange={(e) =>
                setTime((pre) => ({ ...pre, ampm: e.target.value }))
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-[24px]"
            >
              <option selected>PM</option>
              <option value="">AM</option>
            </select>
            <span className="absolute left-[10px] top-[8px] text-[10px]">
              AM/PM
            </span>
          </div>
        </div>

        <div className="mb-6">
          <label
            htmlFor="countries"
            className="block mb-2 text-[18] font-medium text-gray-900 dark:text-white"
          >
            Time zone
          </label>
          <select
            value={time.timezone}
            onChange={(e) =>
              setTime((pre) => ({ ...pre, timezone: e.target.value }))
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-[18px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="Asia/Kolkata">Indian Standard Time</option>
            <option value="America/New_York">Eastern Standard Time</option>
            <option value="America/Los_Angeles">Pacific Standard Time</option>
            <option value="Etc/GMT">Greenwich Mean Time</option>
            <option value="Europe/Berlin">Central European Time</option>
            <option value="Australia/Sydney">Australian Eastern Standard Time</option>
            <option value="Pacific/Auckland">New Zealand Standard Time</option>
            <option value="Pacific/Honolulu">Hawaiian Standard Time</option>
            <option value="America/Anchorage">Alaska Standard Time</option>
            <option value="America/Denver">Mountain Standard Time</option>
            <option value="America/Chicago">Central Standard Time</option>
            <option value="America/Halifax">Atlantic Standard Time</option>
          </select>
        </div>

        <div className="flex items-center">
          <svg className="icon me-2 text-[20px]">
            <use href="#icon_cal"></use>
          </svg>
          <span>
            Will Send On {' '}
            {moment(`${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute} ${time.ampm}`)?.tz(time?.timezone)?.format("dddd, MMMM Do, YYYY [at] h:mm A")}
          </span>
        </div>

        <div className="flex justify-center py-4">
          <button
            onClick={() => onConfirm(
              //send the time string in the format which sequelize DATETIME datatype accepts
              moment(`${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute} ${time.ampm}`)?.tz(time?.timezone)?.format("YYYY-MM-DD HH:mm:ss"),
              time.timezone
            )}
            disabled={loading}
            type="button"
            data-modal-hide="default-modal"
            className="flex items-center justify-center py-2 px-8 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7] w-full"
          >
            <span>{loading ? "Submitting..." : "Submit"}</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ScheduleSocialPostModal;
