import React, { useEffect, useRef } from "react";
import { Modal} from "flowbite-react";
import ThreeDotsLoader from "./ThreeDotsLoader";
function DeletePopup({ openModal, onClose, deleteFile, deleting }) {
  const documentBodyRef = useRef(null);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  return (
    <div>
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={onClose}
      >
        <Modal.Body className="p-0">
          <div className="flex justify-center items-center pt-6">
            <div className="w-[100px] h-[100px] bg-[#F7F7F7] rounded-[50%] flex justify-center items-center">
              <svg className="icon text-[#ED400A] text-[46px]">
                <use href="#icon_delete-pop"></use>
              </svg>
            </div>
          </div>
          <div className="p-4 pb-8">
            <h3 className="text-center font-bold text-[26px]">Are You Sure?</h3>
            <p className="text-center font-bold text-[20px] text-[#7D7D7D]">
              Do You really want To delete These record?
            </p>
            <div className="pt-4 flex justify-between px-4">
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold text-black hover:text-[white] border border-[black] hover:border-[#54BD3A] hover:bg-[#54BD3A]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold text-[white] border border-[#ED400A] bg-[#ED400A]"
                onClick={() => {
                  deleteFile();
                }}
              >
                {deleting ? (
                  <div className="flex items-center">
                    <div>Deleting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  <>Delete</>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeletePopup;
