/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function GoogleComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [inputData, setInputData] = useState({
    no_headlines: "",
    no_description: "",
    company_name: "",
    description: "",
    tone: "",
    language: "English",
    content_title: selectedFile?.content_title,
  });
  const [editFile, seteditFile] = useState(false);
  const chooseSocialMedia="Choose Media";

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    let data = {};
    let titleTemplate = {
      "Google Ads Headlines": "google_ads_short_headlines",
      "Facebook ads Primary Texts": "facebook_ads_primary_texts",
      "Facebook Ads Headlines": "facebook_ads_headlines",
      "Google Ads Long Headlines": "google_ads_long_headlines",
      "Google Ads Description": "google_ads_description",
    };
    if (from == "Google Ads Headlines") {
      data = {
        no_headlines: inputData.no_headlines,
        company_name: inputData.company_name,
        description: inputData.description,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Google Headlines",
      };
      // postWebsocketDetails(data, "google_ads_short_headlines");
    } else if (from == "Facebook ads Primary Texts") {
      data = {
        no_primary_texts: inputData.no_headlines,
        company_name: inputData.company_name,
        description: inputData.description,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Facebook Ads Primary Text",
      };
      // postWebsocketDetails(data, "facebook_ads_primary_texts");
    } else if (from == "Facebook Ads Headlines") {
      data = {
        no_headlines: inputData.no_headlines,
        company_name: inputData.company_name,
        description: inputData.description,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Facebook Ads Headlines",
      };
      // postWebsocketDetails(data, "facebook_ads_headlines");
    } else if (from == "Google Ads Long Headlines") {
      data = {
        no_headlines: inputData.no_headlines,
        company_name: inputData.company_name,
        description: inputData.description,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Google Long Headlines",
      };
      // postWebsocketDetails(data, "google_ads_long_headlines");
    } else if (from == "Google Ads Description") {
      data = {
        no_description: inputData.no_description,
        company_name: inputData.company_name,
        description: inputData.description,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Google Ads Description",
      };
      // postWebsocketDetails(data, "google_ads_description");
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
    }
  };
  useEffect(() => {
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "Google Headlines") {
        setInputData({
          ...inputData,
          no_headlines: getSettingKey("no_headlines"),
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Google Headlines",
        });
      } else if (selectedFile?.content_template == "Google Long Headlines") {
        setInputData({
          ...inputData,
          no_headlines: getSettingKey("no_headlines"),
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Google Long Headlines",
        });
      } else if (selectedFile?.content_template == "Google Ads Description") {
        setInputData({
          ...inputData,
          no_description: getSettingKey("no_description"),
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Google Ads Description",
        });
      }
      if (selectedFile?.content_template == "Facebook Ads Primary Text") {
        setInputData({
          ...inputData,
          no_headlines: getSettingKey("no_primary_texts"),
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Facebook Ads Primary Text",
        });
      }
      if (selectedFile?.content_template == "Facebook Ads Headlines") {
        setInputData({
          ...inputData,
          no_headlines: getSettingKey("no_headlines"),
          company_name: getSettingKey("company_name"),
          description: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Facebook Ads Headlines",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    // if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
  }, []);
  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn();
          }}
        >
          <div className="pb-3">
            <label
              class="block mb-2 font-semibold text-[18px]"
              for="company_name"
            >
              Company / Product Name
            </label>
            <input
              type="text"
              id="company_name"
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Company Name"
              value={inputData.company_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="pb-3">
            <label
              class="block mb-2 font-semibold text-[18px]"
              for="description"
            >
              Description
            </label>

            <textarea
              className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              id="description"
              name="msgarea"
              placeholder="Enter here."
              value={inputData.description}
              required
              onChange={handleChange}
            ></textarea>
          </div>
          {from == "Google Ads Description" ? (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_description"
              >
                Number Of Descriptions
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="no_description"
                id="no_description"
                required
                value={inputData.no_description}
                onChange={handleChange}
              >
                <option value="">Choose number Of Descriptions</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          ) : (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_headlines"
              >
                Number Of Headlines
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="no_headlines"
                id="no_headlines"
                required
                value={inputData.no_headlines}
                onChange={handleChange}
              >
                <option value="">Choose number Of Headlines</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="tone">
              Choose Tone
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="tone"
              value={inputData.tone}
              required
              onChange={handleChange}
            >
              <option value="">Choose Tone</option>
              <option value="Assertive">Assertive</option>
              <option value="Appreciative">Appreciative</option>
              <option value="Awestruck">Awestruck</option>
              <option value="Candid">Candid</option>
              <option value="Casual">Casual</option>
              <option value="Cautionary">Cautionary</option>
              <option value="Convincing">Convincing</option>
              <option value="Critical">Critical</option>
              <option value="Earnest">Earnest</option>
              <option value="Enthusiastic">Enthusiastic</option>
              <option value="Formal">Formal</option>
              <option value="Funny">Funny</option>
              <option value="Humble">Humble</option>
              <option value="Humorous">Humorous</option>
              <option value="Informative">Informative</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Joyful">Joyful</option>
              <option value="Passionate">Passionate</option>
              <option value="Thoughtful">Thoughtful</option>
              <option value="Urgent">Urgent</option>
              <option value="Worried">Worried</option>
              <option value="Bold">Bold</option>
              <option value="Persuasive">Persuasive</option>
              <option value="Exciting">Exciting</option>
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              required
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default GoogleComponent;
