import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/Routing";
import Icons from "./components/Icons";
import UserState from "./context/UserState";
import { BrowserRouter } from "react-router-dom";
import 'shepherd.js/dist/css/shepherd.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserState>
      <BrowserRouter>
        <Routing></Routing>
        <Icons></Icons>
      </BrowserRouter>
    </UserState>
  </React.StrictMode>
);
reportWebVitals();
