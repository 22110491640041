import { useMemo, useRef, useState } from "react";
import UserContext from "./UserContext";

const UserState = (props) => {
  const [selectedWorkspace, setselectedWorkspace] = useState({});
  const [selectedTemplate, setselectedTemplate] = useState({});
  const [selectedFile, setselectedFile] = useState(null);
  const [selectedContentSettings, setselectedContentSettings] = useState({});
  const [recentFilesList, setrecentFilesList] = useState([]);
  const [choosedTemplate, setchoosedTemplate] = useState("Blog");
  const [openModal, setOpenModal] = useState();
  const contentEditableRef = useRef(null);
  const [userSpaces, setuserSpaces] = useState([]);
  const [openFolderCreateModal, setopenFolderCreateModal] = useState();
  const [selectedFolderStructure, setselectedFolderStructure] = useState({
    type: "",
    value: "",
    title: "",
    placeholder: "",
    loading: false,
    error: null,
    mainHeading: "",
  });
  const [chatCalledOnce, setchatCalledOnce] = useState({
    general: false,
    youtube: false,
    general_folders: [],
    youtube_folders: [],
  });
  const [selectedNestedItem, setselectedNestedItem] = useState(null);
  const chatLoadingRef = useRef();
  const [loadingChat, setloadingChat] = useState(false);
  const [ws, setWs] = useState(null);
  const [savingFileData, setsavingFileData] = useState(false);
  const [chatfilesFetched, setchatfilesFetched] = useState(false);
  const [isGlowing, setIsGlowing] = useState(false); // Control the glowing border
  const [trainingHistory, settrainingHistory] = useState([]);
  const [trainingHistoryFetched, settrainingHistoryFetched] = useState(false);
  let json = {
    templates: {
      aside: false,
      template_selected: false,
      content_generated: false,
    },
    youtube: {
      aside: false,
      youtubelinkbox: false,
      youtubeinputbox: false,
    },
    chat: {
      aside: false,
      chatinputbox: false,
    },
    training: {
      aside: false,
      trainingbuttonclicked: false,
    },
  };
  const [trainingJson, settrainingJson] = useState(json);
  const [
    home,
    templates,
    youtube,
    chat,
    training,
    space,
    workspace,
    invite,
    generate,
    feedback,
    subscription,
    totalTemplatesRef,
    youtubelinkbox,
    youtubeinputbox,
    buttonref,
    asideRef
  ] = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allrefs = {
    home,
    templates,
    youtube,
    chat,
    training,
    space,
    workspace,
    invite,
    generate,
    feedback,
    subscription,
    totalTemplatesRef,
    isGlowing,
    buttonref,
    setIsGlowing,
    youtubelinkbox,
    youtubeinputbox,
    trainingJson,
    settrainingJson,
    asideRef
  };

  const contextValue = useMemo(
    () => ({
      selectedWorkspace,
      setselectedWorkspace,
      selectedTemplate,
      setselectedTemplate,
      selectedFile,
      setselectedFile,
      selectedContentSettings,
      setselectedContentSettings,
      recentFilesList,
      setrecentFilesList,
      choosedTemplate,
      setchoosedTemplate,
      openModal,
      setOpenModal,
      contentEditableRef,
      userSpaces,
      setuserSpaces,
      openFolderCreateModal,
      setopenFolderCreateModal,
      selectedFolderStructure,
      setselectedFolderStructure,
      selectedNestedItem,
      setselectedNestedItem,
      chatCalledOnce,
      setchatCalledOnce,
      chatLoadingRef,
      loadingChat,
      setloadingChat,
      ws,
      setWs,
      savingFileData,
      setsavingFileData,
      allrefs,
      chatfilesFetched,
      setchatfilesFetched,
      trainingHistory,
      settrainingHistory,
      trainingHistoryFetched,
      settrainingHistoryFetched,
    }),
    [
      selectedWorkspace,
      setselectedWorkspace,
      selectedTemplate,
      setselectedTemplate,
      selectedFile,
      setselectedFile,
      selectedContentSettings,
      setselectedContentSettings,
      recentFilesList,
      setrecentFilesList,
      choosedTemplate,
      setchoosedTemplate,
      openModal,
      setOpenModal,
      contentEditableRef,
      userSpaces,
      setuserSpaces,
      openFolderCreateModal,
      setopenFolderCreateModal,
      selectedFolderStructure,
      setselectedFolderStructure,
      selectedNestedItem,
      setselectedNestedItem,
      chatCalledOnce,
      setchatCalledOnce,
      chatLoadingRef,
      loadingChat,
      setloadingChat,
      ws,
      setWs,
      savingFileData,
      setsavingFileData,
      allrefs,
      chatfilesFetched,
      setchatfilesFetched,
      trainingHistory,
      settrainingHistory,
      trainingHistoryFetched,
      settrainingHistoryFetched,
    ]
  );
  return (
    <UserContext.Provider value={contextValue}>
      {props?.children}
    </UserContext.Provider>
  );
};
export default UserState;
