import React from "react";
import { Tooltip } from "react-tooltip";
function TooltipComponent({ text, position, postWebsocketDetailsRewrite,postWebsocketDetailsPlagiarism }) {
  const tooltipStyle = {
    position: "absolute",
    top: position && position.top - 50, // Adjust the value as needed
    left: position && position.left,
    // backgroundColor: "#333",
    color: "#fff",
    padding: "5px",
    borderRadius: "5px",
    zIndex: 1000,
  };
  // style={tooltipStyle }
  const getBg = () => {
    if (text && text?.trim()?.split(" ")?.length >= 3) {
      return "white";
    } else {
      return "lightgray";
    }
  };
  return (
    <div style={tooltipStyle}>
      <div
        className={`tooltip_container w-max bg-[white] flex border border-[#d7d7d7] rounded-[6px] shadow shadow-[#00000026]`}
      >
        {/* V-shape design */}
        {/* <div style={vShapeStyle}></div> */}

        <button
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Rewrite"
          style={{
            background: getBg(),
          }}
          className={`rewrite my-[2px] mx-[3px] relative rounded-[5px] p-[4px] hover:before:text-[white] hover:before:p-[4px] hover:before:rounded-[5px] hover:before:top-[-30px] hover:before:left-[0px] hover:before:right-[0px] hover:before:absolute hover:before:w-max`}
          onClick={postWebsocketDetailsRewrite}
          disabled={getBg() === "lightgray"}
        >
          <svg className="icon text-[24px]">
            <use href="#icon_rewrite"></use>
          </svg>
        </button>

        <button
          data-tooltip-id="my-tooltip1"
          data-tooltip-content="Check Plagiarism"
          style={{ background: "lightgray" }}
          className={`rewrite my-[2px] mx-[3px] relative rounded-[5px] p-[4px] hover:before:text-[white] hover:before:p-[4px] hover:before:rounded-[5px] hover:before:top-[-30px] hover:before:left-[0px] hover:before:right-[0px] hover:before:absolute hover:before:w-max`}
          onClick={postWebsocketDetailsPlagiarism}
        >
          <svg className="icon  text-[24px]">
            <use href="#icon_plagiarism"></use>
          </svg>
        </button>
        <button
          data-tooltip-id="my-tooltip2"
          data-tooltip-content="Custom Enhance"
          style={{ background: "lightgray" }}
          className={`rewrite my-[2px] mx-[3px] relative rounded-[5px] p-[4px] hover:before:text-[white] hover:before:p-[4px] hover:before:rounded-[5px] hover:before:top-[-30px] hover:before:left-[0px] hover:before:right-[0px] hover:before:absolute hover:before:w-max`}
          disabled
        >
          <svg className="icon  text-[24px]">
            <use href="#icon_enhance"></use>
          </svg>
        </button>
      </div>
      <Tooltip
        id="my-tooltip"
        // style={{ backgroundColor: "#7b7b7b", color: "white" }}
      />
      <Tooltip
        id="my-tooltip1"
        // style={{ backgroundColor: "#7b7b7b", color: "white" }}
      />
      <Tooltip
        id="my-tooltip2"
        // style={{ backgroundColor: "#7b7b7b", color: "white" }}
      />
    </div>
  );
}

export default TooltipComponent;
