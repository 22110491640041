/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import LoaderNew from "../components/LoaderNew";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

function SocialLogin() {
  const navigate = useNavigate();
  const setExpirationCookie = (expirationDate) => {
    if (expirationDate) {
      Cookies.set("expirationDate", expirationDate, {
        expires: new Date(expirationDate),
      });
    }
  };
  const signUpButton = async (code, email, act) => {
    let data = {
      code: code,
      email: email,
    };
    if (act == 2) {
      let visonsignupplan = localStorage.getItem("visonsignupplan");
      data["plan_id"] = parseInt(visonsignupplan || 1);
    }
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/login/social`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      if (response.data.success) {
        if (act == 2) {
          localStorage.setItem("continue_with_google", "true");
        }
        localStorage.setItem("login_data", JSON.stringify(response.data.data));
        localStorage.setItem("login_token", response.data.token);
        let data = response.data.data;
        console.log(data, "data");
        if (data?.workspacess?.length == 0) {
          localStorage.setItem("workspaces_list", JSON.stringify([]));
          localStorage.setItem("selected_workspace", JSON.stringify({}));
        }
        setExpirationCookie(
          response.data.data.resp_user.subscription_expire_date
        );
        navigate("/template");
      } else {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
      console.log(error);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const email = searchParams.get("email");
    const act = searchParams.get("act");
    // 1=login
    // 2=signup
    // console.log(state, "state");
    if (code && email && act) {
      signUpButton(code, email, act);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <LoaderNew />
    </div>
  );
}

export default SocialLogin;
