import React from 'react'

function PaymentFailed() {
  return (
    <div>
      <h1>Payment Failed</h1>
    </div>
  )
}

export default PaymentFailed
