/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import useCommonFunctions from "../hooks/common-functions";
import PopupModals from "../components/PopupModals";
import socket from "../components/socketService";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import Joyride from "react-joyride";
import UserContext from "../context/UserContext";
import JoyrideComponent from "../components/JoyrideComponent";
import useCommonApi from "../hooks/common-apis";

function ModelTraining() {
  const navigate = useNavigate();
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [marking, setmarking] = useState(false);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [loginData, setloginData] = useState({});
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const { getTrainingHistory } = useCommonApi();
  const { getPlanDetails } = useCommonFunctions();
  const context = useContext(UserContext);
  const {
    allrefs,
    trainingHistory,
    settrainingHistory,
    trainingHistoryFetched,
    settrainingHistoryFetched,
  } = context;
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [steps, setsteps] = useState([]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    // console.log(data, "data in joyride callback");
    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prevIndex) => prevIndex + 1); // Increment stepIndex on "next"
      } else if (action === "prev") {
        setStepIndex((prevIndex) => prevIndex - 1); // Decrement stepIndex on "back"
      }
    }
    // Handle when Joyride is finished or skipped
    if (status === "finished" || status === "skipped" || action === "close") {
      setRun(false); // Stop the tour
      setStepIndex(0); // Optionally reset the step index to 0
      if (allrefs?.asideRef?.current) {
        setTimeout(() => {
          allrefs.asideRef.current.style.overflowY = "auto";
        }, 500);
      }
    }
  };
  // useEffect(() => {
  //   const { trainingJson, settrainingJson } = allrefs;
  //   if (!trainingHistoryFetched) {
  //     return;
  //   }
  //   if (Object.keys(trainingJson || {}).length > 0) {
  //     let stepsArray = [];
  //     let keys = Object.keys(trainingJson);
  //     let { templates, youtube, chat, training } = trainingJson;
  //     let { aside, trainingbuttonclicked } = training;
  //     if (
  //       getPlanDetails().planId == 3 &&
  //       trainingHistoryFetched &&
  //       trainingHistory.length == 0
  //     ) {
  //       stepsArray.push({
  //         target: "#start-training",
  //         content:
  //           "Click here to train and use the model to generate output in your own writing style",
  //         disableBeacon: true,
  //       });
  //     }
  //     setsteps(stepsArray);
  //     setRun(stepsArray.length > 0);
  //   }
  // }, [allrefs?.trainingJson, trainingHistoryFetched, trainingHistory?.length]);
  console.log(steps, "steps");
  const formatDateTime = (dateTimeString) => {
    return moment(dateTimeString).format("D MMMM, YYYY");
  };

  const markDefaultApi = async (model_id) => {
    setmarking(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/model/default`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          model_id: model_id,
        },
      });
      setmarking(false);
      if (!response.data.error) {
        let login_data = JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.model_id = model_id;
        localStorage.setItem("login_data", JSON.stringify(login_data));
        setloginData(login_data);
        getTrainingHistory();
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setmarking(false);
      setsuccessFailureMessage(error?.response?.data?.message);
      setopenFailureModal("dismissible");
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
    }
  };

  const getButton = (history) => {
    let { model_id, job_id } = history;
    if (!model_id && !job_id) {
      return (
        <button
          type="button"
          className="ms-auto bg-[#FF7223] text-[#fff] text-[18px] font-[600] py-[10px] w-[180px] rounded-[10px] flex items-center justify-center me-[34px]"
          onClick={() => {
            window.open("/start-training", "_blank");
          }}
        >
          <svg className="icon me-2 text-[20px]">
            <use href="#icon_training"></use>
          </svg>
          pending
        </button>
      );
    }
    if (!model_id && job_id) {
      return (
        <button
          type="button"
          className="ms-auto bg-[#425EF3] text-[#fff] text-[18px] font-[600] py-[10px] w-[180px] rounded-[10px] flex items-center justify-center me-[34px]"
        >
          <svg className="icon me-2 text-[20px]">
            <use href="#icon_process"></use>
          </svg>
          Processing
        </button>
      );
    }
    return (
      <button
        type="button"
        className="ms-auto bg-[#39C123] text-[#fff] text-[18px] font-[600] py-[10px] w-[180px] rounded-[10px] flex items-center justify-center me-[34px]"
      >
        <svg className="icon me-2 text-[20px]">
          <use href="#icon_check"></use>
        </svg>
        Completed
      </button>
    );
  };
  useEffect(() => {
    if (!trainingHistoryFetched) {
      getTrainingHistory();
    }
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    if (login_data) {
      setloginData(login_data);
    }
  }, []);
  useEffect(() => {
    socket.on("notification", (message) => {
      console.log("Received model ID:", message.model_id);
      let model_id = message.model_id;
      if (model_id) {
        let login_data = JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.model_id = model_id;
        localStorage.setItem("login_data", JSON.stringify(login_data));
        setloginData(login_data);
      }
    });
    // error
    socket.on("error", (err) => {
      console.log("Error: ", err);
    });
    return () => {
      socket.off("notification");
      socket.off("error");
    };
  }, []);
  return (
    <>
      <NavBar />
      <div className="wrapper">
        <div className="w-full flex flex-wrap -mx-4">
          <div className="w-full flex py-5">
            <div className="ms-auto">
              <button
                id="start-training"
                type="button"
                className="flex items-center py-2 px-[60px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                onClick={() => {
                  if (getPlanDetails().planId != 3) {
                    setpopupMessage("Start Training");
                    setshowModal({
                      first: false,
                      second: false,
                      third: true,
                    });
                    return;
                  }
                  window.open("/start-training", "_blank");
                }}
              >
                Start Training
              </button>
            </div>
          </div>
          <div className="w-full flex justify-center items-center text-[18px] pt-1 pb-3">
            Please train and use the model to generate output in your&nbsp;
            <strong>own writing style.</strong>
          </div>
          <div className="w-full md:w-2/3 px-4">
            {trainingHistory?.map((history, history_index) => (
              <div
                className="model-training rounded-[20px] px-[30px] py-[20px] mb-[40px]"
                key={history_index}
              >
                <div className="flex items-center">
                  {loginData?.resp_user?.model_id == history.model_id && (
                    <span className="bg-[#ECF5FF] text-[12px] fpnt-[400] py-1 px-3 rounded-[20px]">
                      CURRENTLY USE
                    </span>
                  )}
                  {/* {history?.model_id && (
                    <button className="ms-auto bg-[#ECF5FF] text-[12px] fpnt-[400] rounded-[50%] w-[32px] h-[32px] flex items-center justify-center hover:text-[red]">
                      <svg className="icon text-[18px]">
                        <use href="#icon_delete-pop"></use>
                      </svg>
                    </button>
                  )} */}
                </div>

                <div>
                  <p className="text-[18px] font-[400] py-4">
                    {formatDateTime(history?.createdAt)}
                  </p>

                  <div className="flex">
                    <p className="text-[18px] font-[700]">
                      Model Number:{" "}
                      <span className="text-[18px] font-[400]">
                        {history?.model_id && history?._id}
                      </span>
                    </p>
                    {history.model_id &&
                      loginData?.resp_user?.model_id != history.model_id && (
                        <button
                          type="button"
                          className="ms-auto bg-[#39C123] text-[#fff] text-[18px] font-[600] py-[10px] px-[15px] rounded-[10px] flex items-center justify-center"
                          onClick={() => {
                            markDefaultApi(history?.model_id);
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div></div>
                            <div>
                              {marking ? "Marking Default" : "Mark Default"}
                            </div>
                            <div>
                              {marking ? <ThreeDotsLoader /> : <> {""}</>}
                            </div>
                          </div>
                        </button>
                      )}

                    {getButton(history)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <JoyrideComponent
        steps={steps}
        stepIndex={stepIndex}
        handleJoyrideCallback={handleJoyrideCallback}
        run={run}
        setStepIndex={setStepIndex}
      />
    </>
  );
}

export default ModelTraining;
