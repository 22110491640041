/* eslint-disable eqeqeq */
import {
  AccordionHeader,
} from "react-headless-accordion";
import { Chevron, CloseFolder, OpenFolder } from "./Icons2";
import { useLocation, useNavigate } from "react-router-dom";

const NestedMenu = ({
  userSpaces,
  getSpaceFolderList,
  handleUpdateTree,
  getSpaceFileList,
  openMenuz,
  navigateFromFile,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getColor = (item) => {
    let content_id =
      JSON.parse(localStorage.getItem("selected_file")).content_id ||
      JSON.parse(localStorage.getItem("selected_file")).id ||
      "";
    // console.log(params, "params");
    if (location.pathname.includes("/template/") && content_id == item?.id) {
      // console.log("came here...");
      return "#097BA9";
    } else {
      return "";
    }
  };

  const printItem = (item, index) => {
    const Title = ({ children }) => <span className="ml-2">{children}</span>;
    // console.log(item, "item")

    const buttonClass =
      "w-full flex items-center hover:text-[#097BA9] border-bl py-3";

    switch (item.type) {
      case "folder":
        return (
          <>
            <div className="flex items-center ">
              <AccordionHeader className="w-full flex items-center text-[#2A2A2A] border-bl py-3">
                <div
                  onClick={() => {
                    if (item?.level == "1" && !item?.open) {
                      getSpaceFolderList(item?.space_id);
                    }
                    if (item?.level == "2" && !item?.open) {
                      getSpaceFileList(item?.space_id, item?.folder_id);
                    }
                    handleUpdateTree(item?.tree_id, !item?.open);
                  }}
                >
                  <Chevron
                    className={`w-7 h-7 transform transition duration-300  hover:text-[#097BA9] ${
                      !item?.open ? "" : " rotate-90"
                    }`}
                  />
                </div>

                <div
                  style={{
                    // backgroundColor: item?.open ? "#e9f2ff" : "",
                    color: item?.open ? "#097BA9" : "",
                  }}
                  className="flex items-center hover:text-[#097BA9]"
                  onClick={() => {
                    if (item?.level == "1" && !item?.open) {
                      getSpaceFolderList(item?.space_id);
                    }
                    if (item?.level == "2" && !item?.open) {
                      getSpaceFileList(item?.space_id, item?.folder_id);
                    }
                    handleUpdateTree(item?.tree_id, true);
                    if (item.level == "1") {
                      navigate(
                        `/template/folders/${item?.user_workspace_space?.space_name}/${item.space_id}`
                      );
                    } else if (item.level == "2") {
                      navigate(
                        `/template/files/${item?.space_name}/${item.space_id}/${item.folder_name}/${item.folder_id}`
                      );
                    }
                  }}
                >
                  {item?.open ? (
                    <OpenFolder className="w-6 h-6" />
                  ) : (
                    <CloseFolder className="w-6 h-6" />
                  )}
                  <div className="flex items-center">
                    {item?.level == "1" ? (
                      <Title>{item?.user_workspace_space?.space_name}</Title>
                    ) : (
                      <Title>{item?.folder_name}</Title>
                    )}
                  </div>
                </div>
              </AccordionHeader>
              {item?.open && (
                <div className="">
                  <button
                    type="button"
                    className="hover:text-[#097BA9]"
                    onClick={(e) => {
                      openMenuz(e, item);
                    }}
                  >
                    <svg className="icon pointer-events-none">
                      <use href="#icon_rowdots"></use>
                    </svg>
                  </button>
                </div>
              )}
            </div>

            {item?.open && (
              <>
                <div className="pl-3">{item?.submenu?.map(printItem)}</div>
              </>
            )}
          </>
        );
      case "file":
        return (
          <>
            <div className="flex items-center justify-between pl-4 w-full">
              <div
                className="w-[80%]"
                style={{
                  color: getColor(item),
                }}
              >
                <AccordionHeader
                  className={buttonClass}
                  onClick={() => {
                    navigateFromFile(item);
                    handleUpdateTree(item?.tree_id, !item?.open);
                  }}
                >
                  <div>
                    <svg className="icon">
                      <use href="#icon_file2"></use>
                    </svg>
                  </div>
                  <div className="flex items-center text-ellipsis overflow-hidden break-words ml-2">
                    {item?.content_title}
                  </div>
                </AccordionHeader>
              </div>

              <div className="">
                <button
                  type="button"
                  className="hover:text-[#097BA9]"
                  onClick={(e) => {
                    openMenuz(e, item);
                  }}
                >
                  <svg className="icon pointer-events-none">
                    <use href="#icon_rowdots"></use>
                  </svg>
                </button>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div
        className=""
        transition={{
          duration: "300ms",
          timingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        }}
      >
        {userSpaces?.map(printItem)}
      </div>
    </>
  );
};

export default NestedMenu;
