import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "./ButtonComponent";
import FileSave from "./FileSave";
import useCommonApi from "../hooks/common-apis";
import UserContext from "../context/UserContext";

function MerMaidComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
  stringOutputModify,
  inputData,
  setInputData
}) {
  const { assignToFolderApi } = useCommonApi();
  const context = useContext(UserContext);
  const { selectedFile, setselectedFile } = context;
  const [editFile, seteditFile] = useState(false);

  const chooseSocialMedia="Choose Media";

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    // console.log(from, "from");
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      // callUpdateToFileApi(data, selectedFile ? selectedFile?.contents : "");
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
    }
  };

  useEffect(() => {
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    // console.log(selectedFile, "selectedFile");
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        // let item = selectedFile?.content_settings?.find(
        //   (item) => item?.content_key == key
        // );
        // return item?.content_value;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      setInputData({
        ...inputData,
        title: getSettingKey("title"),
        steps: getSettingKey("steps"),
        content_title: selectedFile?.content_title,
        content_template: "Flow Chart",
        type: getSettingKey("type"),
      });
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({
          ...inputData,
          language: language,
          content_title: selectedFile?.content_title,
        });
      }
    }
    //  if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(inputData, "inputData");

  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            let data = {};
            data = {
              title: inputData.title,
              steps: inputData.steps,
              type:inputData.type,
              content_title: inputData.content_title,
              content_template: "Flow Chart",
              "model": "model_3",
            };
            stringOutputModify();
            postWebsocketDetails(data, "mermaid");
          }}
        >
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              id="title"
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Title"
              value={inputData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="tone">
              Chart Type
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="type"
              value={inputData.type}
              required
              onChange={handleChange}
            >
              <option value="">Choose Type</option>
              <option value="flowchart">Flow Chart</option>
              <option value="sequence">Sequence diagram</option>
              {/* <option value="gantt">Gantt chart</option> */}
              <option value="class">Class diagram</option>
              <option value="state">State diagram</option>
              <option value="pie">Pie chart</option> 
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" htmlFor="steps">
              Steps
            </label>
            <textarea
              className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              id="steps"
              name="msgarea"
              placeholder="Enter here."
              onChange={handleChange}
              value={inputData.steps}
              required
            ></textarea>
          </div>

          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
    </>
  );
}

export default MerMaidComponent;
