/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import useCommonFunctions from "../hooks/common-functions";
import PopupModals from "./PopupModals";

function EngagingPostCaption({
  from,
  mainTitle,
  aboutTitle,
  media,
  caption,
  info_answer,
  keywords,
  keywordTitle,
  keywordPlaceholder,
  about,
  name,
  nameTitle,
  discountOffer,
  namePlaceHolder,
  loader,
  pointOnView,
  postWebsocketDetails,
  no_of_questions,
  target_audience,
  features_list,
  benefits,
  aim,
  problems,
  solutions,
  serve,
  closeFunction,
  event_topic,
  event_highlights,
  date,
  no_of_caption,
  num_of_bio,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [inputData, setInputData] = useState({
    media: "",
    about: "",
    hashtags: "",
    tone: "",
    language: "English",
    no_captions: "",
    number_of_captions: "",
    info_ans: "",
    name: "",
    discount: "",
    point_of_view: "",
    no_of_faq: "",
    target_audience: "",
    feature_list: "",
    benefits: "",
    aim: "",
    problems: "",
    solutions: "",
    serve: "",
    highlight: "",
    date: "",
    modified_date: "",
    no_of_caption: "",
    num_of_bio: "",
    content_title: selectedFile?.content_title,
  });
  const [editFile, seteditFile] = useState(false);
  const [dropdownopen, setdropdownopen] = useState(false);
  const [chooseSocialMedia, setchooseSocialMedia] = useState("Choose Media");
  const handleSocialMediaClick = (text) => {
    setchooseSocialMedia(text);
    setdropdownopen(false);
  };
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  // console.log(loader, "loader");
  const getModifiedDate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (inputData.date != "") {
      let modifiedDate = getModifiedDate(inputData.date);
      setInputData({ ...inputData, modified_date: modifiedDate });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData.date]);

  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    let data = {};
    let titleTemplate = {
      "engaging post captions": "caption",
      tweet: "tweet",
      // tweet: "tweet_split_test",
      "Tiktok Video Caption": "tiktok_video_caption",
      "Quora Answer": "quora_answer",
      "YouTube Video Description": "YT_description",
      "discount promotion": "discount_promotion",
      "Social Media Bio": "social_bio",
      "FAQ Generator": "faq",
      "Landing Page": "landing_page",
      "About Us": "about_us",
      "Content Rewriter": "content_rewrite",
      "Event Highlight Post": "event_highlight_post",
    };
    if (from == "engaging post captions") {
      data = {
        media: chooseSocialMedia,
        about: inputData.about,
        hashtags: inputData.hashtags,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        num_of_caption: inputData.no_of_caption,
        content_template: "Engaging Post Caption",
        stop: "False",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "caption");
    } else if (from == "tweet") {
      data = {
        no_captions: inputData.no_captions,
        about: inputData.about,
        keywords: inputData.hashtags,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Tweet",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "tweet");
    } else if (from == "Tiktok Video Caption") {
      data = {
        number_of_captions: inputData.no_captions,
        about: inputData.about,
        keywords: inputData.hashtags,
        tone: inputData.tone,
        language: inputData.language,
        no_of_faq: inputData.no_captions,
        content_title: inputData.content_title,
        content_template: "Tiktok Video Caption",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "tiktok_video_caption");
    } else if (from == "Quora Answer") {
      data = {
        question: inputData.about,
        info_ans: inputData.info_ans,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Quora Answers",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "quora_answer");
    } else if (from == "YouTube Video Description") {
      data = {
        about: inputData.about,
        keywords: inputData.hashtags,
        tone: inputData.tone,
        language: inputData.language,
        no_of_faq: "2",
        content_title: inputData.content_title,
        content_template: "Youtube Video Description",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "YT_description");
    } else if (from == "discount promotion") {
      data = {
        product_name: inputData.name,
        discount: inputData.discount,
        description: inputData.about,
        tone: inputData.tone,
        language: inputData.language,
        no_of_faq: "2",
        content_title: inputData.content_title,
        content_template: "Discount Promotion",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "discount_promotion");
    } else if (from == "Social Media Bio") {
      data = {
        info: inputData.about,
        point_of_view: inputData.point_of_view,
        num_of_bio: inputData.num_of_bio,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Social Media Bio",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "social_bio");
    } else if (from == "FAQ Generator") {
      data = {
        no_of_faq: inputData.no_of_faq,
        topic: inputData.name,
        description: inputData.about,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "FAQ Generator",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "faq");
    } else if (from == "Landing Page") {
      data = {
        topic: inputData.name,
        target_audience: inputData.target_audience,
        feature_list: inputData.feature_list,
        benefits: inputData.benefits,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Landing Page",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "landing_page");
    } else if (from == "About Us") {
      data = {
        topic: inputData.name,
        target_audience: inputData.target_audience,
        aim: inputData.aim,
        problems: inputData.problems,
        solutions: inputData.solutions,
        serve: inputData.serve,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "About Us",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "about_us");
    } else if (from == "Content Rewriter") {
      data = {
        content: inputData.about,
        instruction: inputData.hashtags,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Content Rewriter",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "content_rewrite");
    } else if (from == "Event Highlight Post") {
      data = {
        topic: inputData.name,
        highlight: inputData.event_highlights,
        date: inputData.date,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Event Highlight Post",
      };
      // setselectedContentSettings(data);
      // postWebsocketDetails(data, "event_highlight_post");
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
    }
  };
  const getSettingKey = (key) => {
    let content_settings = selectedFile?.content_settings;
    return content_settings[key];
  };
  useEffect(() => {
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    if (selectedFile && selectedFile?.content_settings) {
      // console.log("coming inside useEffect");
      // console.log(selectedFile?.content_settings);

      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "Social Media Bio") {
        setInputData({
          ...inputData,
          about: getSettingKey("info"),
          point_of_view: getSettingKey("point_of_view"),
          num_of_bio: getSettingKey("num_of_bio"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Social Media Bio",
        });
      } else if (selectedFile?.content_template == "Tiktok Video Caption") {
        setInputData({
          ...inputData,
          no_captions: getSettingKey("number_of_captions"),
          about: getSettingKey("about"),
          hashtags: getSettingKey("keywords"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Tiktok Video Caption",
        });
      } else if (selectedFile?.content_template == "Tweet") {
        setInputData({
          ...inputData,
          no_captions: getSettingKey("no_captions"),
          about: getSettingKey("about"),
          hashtags: getSettingKey("keywords"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Tweet",
        });
      } else if (selectedFile?.content_template == "Quora Answers") {
        setInputData({
          ...inputData,
          about: getSettingKey("question"),
          info_ans: getSettingKey("info_ans"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Quora Answers",
        });
      } else if (
        selectedFile?.content_template == "Youtube Video Description"
      ) {
        setInputData({
          ...inputData,
          about: getSettingKey("about"),
          hashtags: getSettingKey("keywords"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Youtube Video Description",
          no_of_faq: "2",
        });
      } else if (selectedFile?.content_template == "Event Highlight Post") {
        setInputData({
          ...inputData,
          name: getSettingKey("topic"),
          event_highlights: getSettingKey("highlight"),
          date: getSettingKey("date"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Event Highlight Post",
        });
      } else if (selectedFile?.content_template == "Discount Promotion") {
        setInputData({
          ...inputData,
          name: getSettingKey("product_name"),
          discount: getSettingKey("discount"),
          about: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          no_of_faq: "2",
          content_title: selectedFile?.content_title,
          content_template: "Discount Promotion",
        });
      } else if (selectedFile?.content_template == "FAQ Generator") {
        // console.log("coming inside faq generator...");
        setInputData({
          no_of_faq: getSettingKey("no_of_faq"),
          name: getSettingKey("topic"),
          about: getSettingKey("description"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "FAQ Generator",
        });
      } else if (selectedFile?.content_template == "Landing Page") {
        setInputData({
          ...inputData,
          name: getSettingKey("topic"),
          target_audience: getSettingKey("target_audience"),
          feature_list: getSettingKey("feature_list"),
          benefits: getSettingKey("benefits"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Landing Page",
        });
      } else if (selectedFile?.content_template == "About Us") {
        setInputData({
          ...inputData,
          name: getSettingKey("topic"),
          target_audience: getSettingKey("target_audience"),
          aim: getSettingKey("aim"),
          problems: getSettingKey("problems"),
          solutions: getSettingKey("solutions"),
          serve: getSettingKey("serve"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "About Us",
        });
      } else if (selectedFile?.content_template == "Content Rewriter") {
        setInputData({
          ...inputData,
          about: getSettingKey("content"),
          hashtags: getSettingKey("instruction"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Content Rewriter",
        });
      } else if (selectedFile?.content_template == "Engaging Post Caption") {
        setInputData({
          ...inputData,
          about: getSettingKey("about"),
          hashtags: getSettingKey("hashtags"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          no_of_caption: getSettingKey("num_of_caption"),
          content_title: selectedFile?.content_title,
          content_template: "Engaging Post Caption",
        });
        setchooseSocialMedia(getSettingKey("media"));
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    // if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
  }, []);
  // console.log(inputData, "input data");
  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn();
          }}
        >
          {name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                {nameTitle}
              </label>
              <input
                type="text"
                id="name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder={namePlaceHolder}
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {event_topic && (
            <div className="pb-3">
              <label class="block mb-2 font-semibold text-[18px]" for="name">
                What Was The Event Topic
              </label>
              <input
                type="text"
                id="name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here ."
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </div>
          )}

          {event_highlights && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Event Highlights
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="event_highlights"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.event_highlights}
                required
              ></textarea>
            </div>
          )}
          {date && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="date"
              >
                Enter Date
              </label>
              <input
                type="text"
                id="date"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.date}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {about && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                {aboutTitle}
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="about"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.about}
                required
              ></textarea>
            </div>
          )}
          {discountOffer && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Discount Offer
              </label>
              <input
                type="text"
                id="discount"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter discount offer"
                value={inputData.discount}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {keywords && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                {keywordTitle ? keywordTitle : "Keywords / Hashtag To Include"}
              </label>
              <input
                type="text"
                id="hashtags"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder={
                  keywordPlaceholder
                    ? keywordPlaceholder
                    : "Enter keywords / hashtag"
                }
                value={inputData.hashtags}
                onChange={handleChange}
                required
              />
            </div>
          )}

          {info_answer && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Information To Add In Your Answer
              </label>
              <input
                type="text"
                id="info_ans"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.info_ans}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {pointOnView && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Point of View (First Person or Third Person)
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="cars"
                id="point_of_view"
                value={inputData.point_of_view}
                onChange={handleChange}
                required
              >
                <option value="">Enter target audience</option>
                <option value="First Person">First Person</option>
                <option value="Third Person">Third Person</option>
              </select>
            </div>
          )}

          {caption && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Number of captions
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="captions"
                id="no_captions"
                value={inputData.no_captions}
                onChange={handleChange}
                required
              >
                <option value="">Number of captions</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
          {no_of_caption && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Number of captions
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="captions"
                id="no_of_caption"
                value={inputData.no_of_caption}
                onChange={handleChange}
                required
              >
                <option value="">Number of captions</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
          {no_of_questions && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_of_faq"
              >
                Number Of Question And Answers
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="Number of faq"
                id="no_of_faq"
                value={inputData.no_of_faq}
                onChange={handleChange}
                required
              >
                <option value="">Choose number of faq</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
          {num_of_bio && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="no_of_faq"
              >
                Number of Bios
              </label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="Number of bio"
                id="num_of_bio"
                value={inputData.num_of_bio}
                onChange={handleChange}
                required
              >
                <option value="">Choose number of bios</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}

          {media && (
            <>
              {" "}
              <div className={dropdownopen ? "pb-1" : "pb-3"}>
                <label for="role" class="block mb-2 font-semibold text-[18px] ">
                  Choose Social Media
                </label>
                <button
                  type="button"
                  className="flex w-full items-center border border-[#E9EBF0] bg-[#FAFBFC] rounded-full py-2 px-4"
                  onClick={() => setdropdownopen(!dropdownopen)}
                >
                  <div className="flex items-center w-full">
                    {chooseSocialMedia}
                    <div className="ms-auto ">
                      {!dropdownopen && (
                        <div>
                          <svg className="icon text-[12px] ">
                            <use href="#icon_arrowdown"></use>
                          </svg>
                        </div>
                      )}
                      {dropdownopen && (
                        <div>
                          <svg className="icon text-[12px]">
                            <use href="#icon_arrowup"></use>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              </div>
              {dropdownopen && (
                <div className="w-full items-center border border-[#E9EBF0] bg-[#FAFBFC] rounded-[20px] py-3 px-3 mt-3 mb-3">
                  <div
                    className="flex items-center mb-3  hover:bg-[#E9F2FF] p-1 rounded-[5px] cursor-pointer"
                    onClick={() => {
                      handleSocialMediaClick("Facebook");
                    }}
                  >
                    <div className="flex items-center">
                      <div className="me-2">
                        <svg className="icon text-[28px]">
                          <use href="#icon_facebook"></use>
                        </svg>
                      </div>
                      Facebook
                    </div>
                  </div>
                  <div
                    className="flex items-center mb-3  hover:bg-[#E9F2FF] p-1 rounded-[5px] cursor-pointer"
                    onClick={() => {
                      handleSocialMediaClick("Instagram");
                    }}
                  >
                    <div className="flex items-center">
                      <div className="me-2">
                        <svg className="icon text-[28px]">
                          <use href="#icon_instagram"></use>
                        </svg>
                      </div>
                      Instagram
                    </div>
                  </div>
                  <div
                    className="flex items-center mb-3  hover:bg-[#E9F2FF] p-1 rounded-[5px] cursor-pointer"
                    onClick={() => {
                      handleSocialMediaClick("Twitter");
                    }}
                  >
                    <div className="flex items-center">
                      <div className="me-2">
                        <svg className="icon text-[28px]">
                          <use href="#icon_twitter"></use>
                        </svg>
                      </div>
                      Twitter
                    </div>
                  </div>
                  <div
                    className="flex items-center mb-3  hover:bg-[#E9F2FF] p-1 rounded-[5px] cursor-pointer"
                    onClick={() => {
                      handleSocialMediaClick("Youtube");
                    }}
                  >
                    <div className="flex items-center">
                      <div className="me-2">
                        <svg className="icon text-[28px]">
                          <use href="#icon_youtube"></use>
                        </svg>
                      </div>
                      Youtube
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {target_audience && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Target Audience
              </label>
              <input
                type="text"
                id="target_audience"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.target_audience}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {features_list && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Features List
              </label>
              <input
                type="text"
                id="feature_list"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.feature_list}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {benefits && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Benefits
              </label>
              <input
                type="text"
                id="benefits"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.benefits}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {aim && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                what Did You Start The Company? or Aim Of The Company
              </label>
              <input
                type="text"
                id="aim"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.aim}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {problems && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                What Problem Are You Solving?
              </label>
              <input
                type="text"
                id="problems"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.problems}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {solutions && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                How Are You Solving The Problem?
              </label>
              <input
                type="text"
                id="solutions"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.solutions}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {serve && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                for="last-name"
              >
                Name Of Customers You Are Serving
              </label>
              <input
                type="text"
                id="serve"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.serve}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="last-name">
              Choose Tone
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="tone"
              value={inputData.tone}
              onChange={handleChange}
              required
            >
              <option value="">Choose Tone</option>
              <option value="Assertive">Assertive</option>
              <option value="Appreciative">Appreciative</option>
              <option value="Awestruck">Awestruck</option>
              <option value="Candid">Candid</option>
              <option value="Casual">Casual</option>
              <option value="Cautionary">Cautionary</option>
              <option value="Convincing">Convincing</option>
              <option value="Critical">Critical</option>
              <option value="Earnest">Earnest</option>
              <option value="Enthusiastic">Enthusiastic</option>
              <option value="Formal">Formal</option>
              <option value="Funny">Funny</option>
              <option value="Humble">Humble</option>
              <option value="Humorous">Humorous</option>
              <option value="Informative">Informative</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Joyful">Joyful</option>
              <option value="Passionate">Passionate</option>
              <option value="Thoughtful">Thoughtful</option>
              <option value="Urgent">Urgent</option>
              <option value="Worried">Worried</option>
              <option value="Bold">Bold</option>
              <option value="Persuasive">Persuasive</option>
              <option value="Exciting">Exciting</option>
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="last-name">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              onChange={handleChange}
              required
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default EngagingPostCaption;
