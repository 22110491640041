import React, { useEffect, useState } from "react";
import { Dropdown, Button, Modal, Tabs } from "flowbite-react";
import useCommonApi from "../hooks/common-apis";
import moment from "moment";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";

function SocialConnect() {
  const [selectedSocialMedia, setselectedSocialMedia] = useState("Twitter");
  const [selectedSocialMediaConnect, setselectedSocialMediaConnect] =
    useState();
  const { checkTwitterAccess } = useCommonApi();
  const [checking, setChecking] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [twitterProfile, setTwitterProfile] = useState();
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  let login_data = JSON.parse(localStorage.getItem("login_data"));

  async function handleTwitterLogin() {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/auth/twitter/${
          login_data?.resp_user?.id
        }?redirect_uri=${window.location.origin + window.location.pathname}`,
        "_self"
      );
    } catch (error) {
      console.error("Error initiating Twitter login", error);
    }
  }

  useEffect(() => {
    if (selectedSocialMedia === "Twitter") {
      checkTwitterAccess(setselectedSocialMediaConnect, setChecking);
    }
    const searchParams = new URLSearchParams(window.location.search);
    let success = searchParams.get("success");
    //parse success to bool
    success = success === "true" ? true : false;
    const error = searchParams.get("error");
    //if searchparams are present then update the twitter access token
    if (searchParams.has("success")) {
      if (!success) {
        console.log("error from query", error);
        setopenFailureModal("dismissible");
        setsuccessFailureMessage(error);
      }
    }
  }, [selectedSocialMedia]);

  return (
    <>
      {/* <div className="flex">
        <div className="ms-auto">
          <button
            type="button"
            className="flex items-center py-2 w-[140px] text-center justify-center rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
          >
            <span>Edit</span>
          </button>
        </div>
      </div> */}
      <h6 className="font-bold text-[24px]">Social Connect</h6>

      <div className="flex gap-[75px] py-4">
        <button
          type="button"
          className={`text-center text-[18px] font-[600] p-3 w-[120px] h-[120px] rounded-[14px] border border-[#E6E6E6] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#2B71B8] ${
            selectedSocialMedia === "Twitter" &&
            "shadow-lg shadow-[#E9F2FF] border-[#2B71B8]"
          }`}
          onClick={() => {
            if (selectedSocialMedia != "Twitter") {
              setselectedSocialMedia("Twitter");
              setselectedSocialMediaConnect(false);
            }
          }}
        >
          <div className="flex justify-center">
            <img
              src="images/twitter.png"
              className="w-[50px] h-[50px] mb-3"
              alt="twitter"
            />
          </div>
          Twitter
        </button>

        {/* <button
          type="button"
          className={`text-center text-[18px] font-[600] p-3 w-[120px] h-[120px] rounded-[14px] border border-[#E6E6E6] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#2B71B8] ${
            selectedSocialMedia === "Facebook" &&
            "shadow-lg shadow-[#E9F2FF] border-[#2B71B8]"
          }`}
          onClick={() => {
            if (selectedSocialMedia != "Facebook") {
              setselectedSocialMedia("Facebook");
              setselectedSocialMediaConnect(false);
            }
          }}
        >
          <div className="flex justify-center">
            <img
              src="images/facebook.png"
              className="w-[50px] h-[50px] mb-3"
              alt="facebook"
            />
          </div>
          Facebook
        </button>

        <button
          type="button"
          className={`text-center text-[18px] font-[600] p-3 w-[120px] h-[120px] rounded-[14px] border border-[#E6E6E6] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#2B71B8] ${
            selectedSocialMedia === "Instagram" &&
            "shadow-lg shadow-[#E9F2FF] border-[#2B71B8]"
          }`}
          onClick={() => {
            if (selectedSocialMedia != "Instagram") {
              setselectedSocialMedia("Instagram");
              setselectedSocialMediaConnect(false);
            }
          }}
        >
          <div className="flex justify-center">
            <img
              src="images/insta.png"
              className="w-[50px] h-[50px] mb-3"
              alt="instagram"
            />
          </div>
          Instagram
        </button>

        <button
          type="button"
          className={`text-center text-[18px] font-[600] p-3 w-[120px] h-[120px] rounded-[14px] border border-[#E6E6E6] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#2B71B8] ${
            selectedSocialMedia === "YouTube" &&
            "shadow-lg shadow-[#E9F2FF] border-[#2B71B8]"
          }`}
          onClick={() => {
            if (selectedSocialMedia != "YouTube") {
              setselectedSocialMedia("YouTube");
              setselectedSocialMediaConnect(false);
            }
          }}
        >
          <div className="flex justify-center">
            <img
              src="images/youtube.png"
              className="w-[70px] h-[50px] mb-3"
              alt="youtube"
            />
          </div>
          YouTube
        </button> */}
      </div>

      {selectedSocialMedia !== "" && !selectedSocialMediaConnect && (
        <div className="py-6 px-[40px] rounded-[25px] bg-[#FAFBFC] border-[1px] border-[#E6E6E6] max-w-[706px] mt-[40px]">
          {checking ? (
            <div>
              <div className="flex justify-center pb-4">
                <img
                  src="images/rocket.svg"
                  className="w-[180px] h-[160px]"
                  alt="Loading"
                />
              </div>
              <h6 className="font-bold text-[24px] text-center pb-4">
                Checking {selectedSocialMedia} Connection
              </h6>
            </div>
          ) : (
            <>
              <div className="flex justify-center pb-4">
                <img
                  src="images/connected.svg"
                  className="w-[180px] h-[160px]"
                  alt="Connected"
                />
              </div>
              <h6 className="font-bold text-[24px] text-center pb-4">
                You Have Not Connected {selectedSocialMedia} To Vison
              </h6>
              <div className="flex justify-center">
                <button
                  type="button"
                  className="flex items-center py-2 px-[80px] text-center justify-center rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                  onClick={handleTwitterLogin}
                >
                  Connect Now
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {selectedSocialMediaConnect && (
        <div className="py-6 px-[40px] rounded-[25px] bg-[#FAFBFC] border-[1px] border-[#E6E6E6] max-w-[706px] mt-[40px]">
          <div className="flex justify-center pb-4">
            <img
              src="images/success.svg"
              className="w-[180px] h-[160px]"
              alt="Successfully"
            />
          </div>
          <h6 className="font-bold text-[24px] text-center">
            You Have Successfully Connected To {selectedSocialMedia} on{" "}
            {moment(selectedSocialMediaConnect?.updatedAt).format(
              "DD MMM YYYY hh:mm A"
            )}
          </h6>
        </div>
      )}
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default SocialConnect;
