import { Modal } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ThreeDotsLoader from "./ThreeDotsLoader";

function ReferalModalPopUp({
  documentBodyRef,
  openModalReferal,
  setopenModalReferal,
  signUpdata,
  setsignUpdata,
  loading,
  afRefCodeFn,
  setloading,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        root={documentBodyRef.current}
        show={openModalReferal === "dismissible"}
        size="lg"
        onClose={() => {
          navigate("/template");
          setopenModalReferal(undefined);
        }}
      >
        {/* <Modal.Header className=" py-2">
          <h6 className="font-bold text-[24px]">Do you have code?</h6>
        </Modal.Header> */}
        <Modal.Body className="pt-0">
          <div className="p-5">
            <img
              src={window.location.origin + "/images/affliate_referal.svg"}
              alt="Referal"
              className="w-100 px-4"
            />
          </div>
          <h6 className="font-bold text-[24px]">
            Do You Have A <span>Referral Code?</span>
          </h6>
          <div className="pb-3 pt-2">
            <label for="name"></label>
            <input
              type="text"
              name="name"
              id="name"
              value={signUpdata.ref_code}
              placeholder="Enter Code"
              onChange={(e) => {
                setsignUpdata({ ...signUpdata, ref_code: e.target.value });
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
            />
          </div>

          <div className="flex justify-center items-center pt-4">
            <div>
              <button
                type="button"
                className="border border-[#2A2A2A] rounded-full px-14 py-2 me-6"
                onClick={() => {
                  setopenModalReferal(undefined);
                  localStorage.removeItem("ref_code");
                  localStorage.removeItem("af_code");
                  localStorage.removeItem("continue_with_google");
                  navigate("/template");
                }}
              >
                Skip
              </button>
              <button
                type="submit"
                disabled={loading}
                className="text-[#fff] px-14 py-2 font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7] rounded-[20px]"
                onClick={async () => {
                  setloading(true);
                  await afRefCodeFn(signUpdata.ref_code);
                  setopenModalReferal(undefined);
                }}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div>Submitting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReferalModalPopUp;
