/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import ThreeDotsLoader from "./ThreeDotsLoader";
import PopupModals from "./PopupModals";
import { useLocation } from "react-router-dom";
import useWebsocketHook from "../hooks/post-websocket-data";
import UserContext from "../context/UserContext";

function ButtonComponent({
  loader,
  mainTitle,
  closeFunction,
  stopGenerateButtonClicked,
}) {
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const context = useContext(UserContext);
  const { allrefs } = context;

  return (
    <>
      {mainTitle !== "One Step Blog" && (
        <div className="flex pb-2">
          {loader && (
            <button
              type="button"
              className="py-2 ms-auto  px-3 rounded-[12px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
              onClick={() => {
                closeFunction();
                if (stopGenerateButtonClicked) {
                  stopGenerateButtonClicked();
                }
              }}
            >
              ⏹️ Stop generating
            </button>
          )}
        </div>
      )}

      <button
        ref={allrefs?.buttonref}
        type="submit"
        disabled={loader}
        className="w-full flex justify-between items-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
      >
        <div></div>
        <div>
          {!loader ? "Generate" : "Generating"} {mainTitle}
        </div>
        <div className="">{loader && <ThreeDotsLoader />}</div>
      </button>
      <PopupModals showModal={showModal} setshowModal={setshowModal} />
    </>
  );
}

export default ButtonComponent;
