import { Modal } from 'flowbite-react'
import React from 'react'

function SocialConnectModal({
    connectModal,
    setConnectModal,
    massage,
    onConfirm
}) {
  return (
    <Modal
    dismissible
    show={connectModal === "dismissible"}
    size="xl"
    onClose={() => setConnectModal(undefined)}
  >
    <Modal.Header className="capitalize border-0 py-2">
    </Modal.Header>
    <Modal.Body className="pt-0">
      <h6 className="font-bold text-[24px] text-center py-5">{massage}</h6>
      <div className="flex justify-center px-[40px] py-4">
        <button  onClick={onConfirm} type="button" data-modal-hide="default-modal" class="flex items-center justify-center py-2 px-8 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7] w-full">
          <span>Connect Now</span>
        </button>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default SocialConnectModal