import { Outlet } from "react-router-dom";
import AsideBar from "./AsideBar";

function LayoutOutlet2() {
  return (
    <>
      <AsideBar></AsideBar>
      <div className="wrapper-setting">
        <Outlet></Outlet>
      </div>
    </>
  );
}
export default LayoutOutlet2;
