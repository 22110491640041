/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import axios from "axios";
import { Modal } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import useCommonFunctions from "../hooks/common-functions";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import moment from "moment";
import Cookies from "js-cookie";
import socket from "../components/socketService";

function Subscription() {
  const navigate = useNavigate();
  const [subscriptionList, setsubscriptionList] = useState([]);
  const [openModal, setOpenModal] = useState(undefined);
  const [openModalSorry, setOpenModalSorry] = useState(undefined);
  const [openModalOtp, setOpenModalOtp] = useState(undefined);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [toggleClicked, settoggleClicked] = useState(false);
  const [loginData, setloginData] = useState({});
  const [subscriptionDetailsInr, setsubscriptionDetailsInr] = useState({});
  const [creatingRazorPay, setcreatingRazorPay] = useState(false);
  const [selectedPlanId, setselectedPlanId] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const otpRefs = useRef(Array.from({ length: 5 }, () => null));
  const documentBodyRef = useRef(null);
  const formatDateTime = (dateTimeString) => {
    return moment(dateTimeString)?.format("D MMMM, YYYY | h:mm A");
  };
  const setExpirationCookie = (expirationDate) => {
    Cookies.set("expirationDate", expirationDate, {
      expires: new Date(expirationDate),
    });
  };
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    const nextIndex = index + 1;
    if (nextIndex < otpRefs.current.length) {
      otpRefs.current[nextIndex].focus();
    }
  };
  // console.log(otp, "otp");
  const [cancelSubscriptionQuestions, setcancelSubscriptionQuestions] =
    useState([
      {
        id: 1,
        question: "Going with a different provider",
        checked: false,
      },
      {
        id: 2,
        question: "Don’t have enough time to use Vison.ai",
        checked: false,
      },
      {
        id: 3,
        question: "Didn’t meet our expectations",
        checked: false,
      },
      {
        id: 4,
        question: "No Longer needed",
        checked: false,
      },
      {
        id: 5,
        question: "Missing Features",
        checked: false,
      },
      {
        id: 6,
        question: "Technical Issues",
        checked: false,
      },
    ]);
  const [cancel_remark, setcancel_remark] = useState("");
  const [count, setCount] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [cancellingRequest, setcancellingRequest] = useState(false);

  const { getPlanDetails, getDateTrial } = useCommonFunctions();

  const [selectedTab, setSelectedTab] = useState("monthly");
  const [selectedcurrency, setselectedcurrency] = useState("USD");

  useEffect(() => {
    const handleTabShown = (event) => {
      const tabEl = document.querySelector("#default-tab");
      const selectedTab = tabEl.querySelector(".selected");
      selectedTab.style.left = event.target.offsetLeft + "px";
    };

    const tabEl = document.querySelector("#default-tab");
    tabEl.addEventListener("click", handleTabShown);

    return () => {
      tabEl.removeEventListener("click", handleTabShown);
    };
  }, []);

  const showTabContent = (event, tabId) => {
    event.preventDefault();

    setSelectedTab(tabId);

    // Hide all tab contents
    const contents = document.querySelectorAll("#default-tab-content > div");
    contents.forEach((content) => {
      content?.classList?.add("hidden");
    });

    // Show the selected tab content
    const selectedTab = document.getElementById(tabId);
    selectedTab?.classList?.remove("hidden");
  };

  const showTabContentCurrency = (event, tabId) => {
    event.preventDefault();

    setselectedcurrency(tabId);
  };
  // currency
  // console.log(selectedcurrency);
  useEffect(() => {
    const handleTabShown = (event) => {
      const tabEl = document.querySelector("#currency-tab");
      const selectedTab = tabEl.querySelector(".selectedcurrency");
      selectedTab.style.left = event.target.offsetLeft + "px";
      selectedTab.style.width = event.target.offsetWidth + "px"; // Adjust width to match the selected tab
    };

    const tabEl = document.querySelector("#currency-tab");
    const buttons = tabEl.querySelectorAll(".nav-link");

    tabEl.addEventListener("click", handleTabShown);

    return () => {
      tabEl.removeEventListener("click", handleTabShown);
    };
  }, []);
  const getSubscriptionList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/subscription/plans`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        setsubscriptionList(response.data.subscriptionplans);
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const getSubscriptionListGraphql = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          query: `
            {
              getsubscriptions(subscription_id: 0) {
                plan_id,
                subscription_name,
                status,
                plandetails {
                  id,
                  description,
                  key_name,
                  key_value
                },
                pricedetails {
                  id,
                  country,
                  currency,
                  subscription_type,
                  price,
                  stripe_price_id
                }
              }
            }
          `,
        },
      });
      console.log(response, "response graph ql");

      // Handle response here
      setsubscriptionList(response.data.data.getsubscriptions);
    } catch (error) {
      // Handle error here
    }
  };
  const getKeyValue = (key_value) => {
    if (key_value === "Y") {
      return "";
    } else if (key_value === "N") {
      return "No ";
    } else {
      return "";
    }
  };
  const checkKeyValueComingSoon = (subscriptionDetails) => {
    let check = subscriptionDetails?.filter((item) => {
      return item.key_value === "C";
    });
    if (check?.length > 0) {
      return (
        <ul className="mt-3">
          <strong>Coming Soon</strong>
          {check.map((plan, plan_index) => (
            <li key={plan_index}>
              <span className="check-card">
                <svg className="icon">
                  <use href="#icon_colortick"></use>
                </svg>
              </span>
              {plan?.description}
            </li>
          ))}
        </ul>
      );
    } else {
      return <></>;
    }
  };
  const cancelSubscriptionFn = async () => {
    setcancellingRequest(true);
    let subscription_id = subscriptionList?.find(
      (subscription) => subscription.plan_id == getPlanDetails().planId
    )?.id;
    let checked_questions = cancelSubscriptionQuestions.filter(
      (question) => question.checked == true
    );
    let checkedQuestionsObj = {};
    checked_questions.forEach((question) => {
      checkedQuestionsObj[question.question] = true;
    });
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let subscription = login_data?.resp_user?.subscription;
    let currency = subscription?.currency?.toUpperCase() || "";
    let gatway = "";
    if (currency == "USD") {
      gatway = "stripe";
    } else if (currency == "INR") {
      gatway = "razorpay";
    } else {
      gatway = "";
    }
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/subscription/cancel`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          subscription_id: JSON.parse(localStorage.getItem("login_data"))
            ?.resp_user?.subscription_id,
          verifytxt: otp.join(""), //OTP
          cancel_remark: cancel_remark,
          cancel_question: checkedQuestionsObj,
          gatway: gatway, //razorpay or stripe
        },
      });
      setcancellingRequest(false);
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        // localStorage.setItem("subscription_upgrade_details", JSON.stringify(details));
        setOpenModalOtp(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        setTimeout(() => {
          setOpenModal(undefined);
        }, 2000);
        setExpirationCookie(details?.subscription?.expire_date);
        let login_data = JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.subscription_expire_date =
          details?.subscription?.expire_date;
        login_data.resp_user.subscription_status =
          details?.subscription?.status;
        setloginData(login_data);
        localStorage.setItem("login_data", JSON.stringify(login_data));
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setcancellingRequest(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const getPlanMonthlyPriceUsd = (pricedetails) => {
    console.log(pricedetails, "pricedetails");
    let price_currency = !toggleClicked ? "USD" : "INR";
    if (pricedetails.length > 0) {
      let price_usd =
        pricedetails.filter(
          (price) =>
            (price?.currency === price_currency &&
              price?.subscription_type === selectedTab) ||
            (price?.currency === price_currency &&
              price?.subscription_type === "all")
        )[0]?.price || 0;
      console.log(price_usd, "price_usd");
      if (selectedTab == "quaterly") {
        price_usd = price_usd / 3;
      } else if (selectedTab == "yearly") {
        price_usd = price_usd / 12;
      }
      return price_usd?.toFixed(2);
    } else {
      return "";
    }
  };
  const getSubscriptionName = () => {
    let planDetails = getPlanDetails();
    if (planDetails.planId === 1) {
      return subscriptionList?.find((subscription) => subscription.plan_id == 1)
        ?.subscription_name;
    } else if (planDetails.planId === 2) {
      return subscriptionList?.find((subscription) => subscription.plan_id == 2)
        ?.subscription_name;
    } else if (planDetails.planId === 3) {
      return subscriptionList?.find((subscription) => subscription.plan_id == 3)
        ?.subscription_name;
    }
  };
  const upgradeSubscriptionNewPlan = async (subscription) => {
    let pricedetails = subscription?.pricedetails;
    let price_id_usd = "";
    if (pricedetails.length > 0) {
      price_id_usd =
        pricedetails.filter(
          (price) =>
            price.currency === "USD" && price.subscription_type === "monthly"
        )[0].stripe_price_id || "";
    }

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/subscription/upgrade`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          subscription_id: JSON.parse(localStorage.getItem("login_data"))
            ?.resp_user?.subscription_id,
          price_id: price_id_usd,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        // localStorage.setItem("subscription_upgrade_details", JSON.stringify(details));
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage?.removeItem("login_token");
        localStorage?.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const downgradeSubscriptionNewPlan = async (subscription) => {
    let pricedetails = subscription?.pricedetails;
    let price_id_usd = "";
    if (pricedetails.length > 0) {
      price_id_usd =
        pricedetails.filter(
          (price) =>
            price.currency === "USD" && price.subscription_type === "monthly"
        )[0].stripe_price_id || "";
    }

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/subscription/upgrade`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          subscription_id: JSON.parse(localStorage.getItem("login_data"))
            ?.resp_user?.subscription_id,
          price_id: price_id_usd,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        // localStorage.setItem("subscription_upgrade_details", JSON.stringify(details));
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const upgradeSubscription = async (subscription) => {
    console.log(subscription, "subscription");

    if (getPlanDetails().planId != 1 && getDateTrial() == "NA") {
      upgradeSubscriptionNewPlan(subscription);
      return;
    }
    let price_currency = !toggleClicked ? "USD" : "INR";
    let pricedetails = subscription?.pricedetails;
    let price_id_usd = "";
    if (pricedetails.length > 0) {
      price_id_usd =
        pricedetails.filter(
          (price) =>
            price?.currency == price_currency &&
            price?.subscription_type === "monthly"
        )[0]?.stripe_price_id || "";
    }
    // if (toggleClicked) {
    setcreatingRazorPay(true);
    setselectedPlanId(subscription?.plan_id);
    // }
    try {
      let response = await axios({
        url: !toggleClicked
          ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/payment/create`
          : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/razor/subscription/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          price_id: price_id_usd,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        if (!toggleClicked) {
          localStorage.setItem(
            "subscription_upgrade_details",
            JSON.stringify(details)
          );
          setTimeout(() => {
            window.location.href = details.redirect_url;
          }, 100);
        } else {
          setsubscriptionDetailsInr(details);
          displayRazorpay(details);
        }
      } else {
        setcreatingRazorPay(false);
        setselectedPlanId("");
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setcreatingRazorPay(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const callOtpFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/subscription/cancel_request`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {},
      });
      console.log(response, "response");
      // if (!response.data.error) {
      //   setsuccessFailureMessage(response.data.message);
      //   setopenSuccessModal("dismissible");
      // } else {
      //   setsuccessFailureMessage(response.data.message);
      //   setopenFailureModal("dismissible");
      // }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const startCountdown = () => {
    setCount(60);
    setIsCounting(true);
    callOtpFn();
  };
  const razorPayPaymentVerification = async (
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature,
    pay_id
  ) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/razor/payment/verification`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          razorpay_payment_id: razorpay_payment_id,
          subscription_id: razorpay_subscription_id,
          razorpay_signature: razorpay_signature,
          pay_id: pay_id,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        let login_data = JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.subscription_id = details?.subscription_id;
        login_data.resp_user.plan_id = details?.plan_id;
        login_data.resp_user.usersetting = details?.usersetting;
        localStorage.setItem("login_data", JSON.stringify(login_data));
        setloginData(login_data);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error?.response?.data?.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(details) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    setcreatingRazorPay(false);
    setselectedPlanId("");
    function handleSubscription() {
      let resp_user = JSON.parse(localStorage.getItem("login_data"))?.resp_user;
      var options = {
        key: "rzp_test_nQGQ1j1UZerVyq",
        subscription_id: details?.subscription_id,
        name: resp_user?.first_name + " " + resp_user?.last_name,
        description: "Monthly Test Plan",
        //  "image": "/your_logo.png",
        handler: function (response) {
          // console.log(response);
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_subscription_id);
          // alert(response.razorpay_signature);
          razorPayPaymentVerification(
            response?.razorpay_payment_id,
            response?.razorpay_subscription_id,
            response?.razorpay_signature,
            details?.pay_id
          );
        },
        prefill: {
          name: resp_user?.first_name + " " + resp_user?.last_name,
          email: resp_user?.email,
          contact: resp_user?.phone,
        },
        //  "notes": {
        //     "note_key_1": "Tea. Earl Grey. Hot",
        //     "note_key_2": "Make it so."
        //  },
        //  "theme": {
        //     "color": "#F37254"
        //  }
      };
      var paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
    handleSubscription();
  }

  useEffect(() => {
    let interval;

    if (isCounting && count > 0) {
      interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);
    }

    if (count === 0 || !isCounting || cancellingRequest) {
      clearInterval(interval);
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [count, isCounting, cancellingRequest]);
  useEffect(() => {
    getSubscriptionListGraphql();
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    if (login_data) {
      let subscription = login_data?.resp_user?.subscription;
      if (subscription) {
        let subscription_type = subscription?.subscription_type;
        if (subscription_type) {
          let split = subscription_type?.split(" ");
          if (split && split[0]) {
            let lowerCase = split[0]?.toLowerCase();
            let tabTypes = ["monthly", "quaterly", "yearly"];
            if (lowerCase && tabTypes.includes(lowerCase)) {
              setSelectedTab(lowerCase);
            } else {
              setSelectedTab("monthly");
            }
          }
        }
      }
      setloginData(login_data);
    } else {
      setloginData({});
    }
  }, []);
  console.log(selectedTab, "selectedTab");
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  useEffect(() => {
    socket.on("upgrade_subscription_notification", (message) => {
      console.log("Received message:", message);
      console.log("Received user:", message.user);
      console.log("Received subscription:", message.subscription);
      console.log("Received usersetting:", message.usersetting);
      let login_data = JSON.parse(localStorage.getItem("login_data"));
      login_data.resp_user.subscription_id = message.user.subscription_id;
      // login_data.resp_user.subscription_status = message.subscription.status;
      login_data.resp_user.plan_id = message.user.plan_id;
      login_data.resp_user.subscription = message.user.subscription;
      login_data.resp_user.usersetting = message.usersetting;
      localStorage.setItem("login_data", JSON.stringify(login_data));
      setloginData(login_data);
    });
    // error
    socket.on("error", (err) => {
      console.log("Error: ", err);
    });
    return () => {
      socket.off("upgrade_subscription_notification");
      socket.off("error");
    };
  }, []);
  console.log("login_data", loginData);

  return (
    <>
      {/* {otp.map((data, index) => {
                return (
                  <input
                    type="text"
                    key={index}
                    value={data}
                    className="form-control otp-box"
                    required
                    maxLength="1"
                    name="otp"
                    onChange={(e) => handleChange(e.target, index)}
                  />
                );
              })} */}
      <div className="flex items-center py-4">
        <h6 className="font-bold text-[24px]">Subscription</h6>
        {/* <div>
          <button onClick={displayRazorpay} className="btn-default-outline">
            Razorpay{" "}
          </button>
        </div> */}
        {getPlanDetails().planId != 1 && (
          <div className="ms-auto">
            {loginData?.resp_user?.subscription_status == 0 &&
            loginData?.resp_user?.subscription_expire_date ? (
              <div className="font-semibold text-[18px]">
                Your subscription will expire on{" "}
                {formatDateTime(loginData?.resp_user?.subscription_expire_date)}
              </div>
            ) : (
              <>
                {getDateTrial() !== "NA" ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    className="px-[20px] btn-default-cancel"
                    onClick={() => {
                      setOpenModalSorry("dismissible");
                      setcancelSubscriptionQuestions((prev) =>
                        prev.map((question) => {
                          return { ...question, checked: false };
                        })
                      );
                      setcancel_remark("");
                      setcancellingRequest(false);
                    }}
                  >
                    Cancel Subscription
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <h6 className="font-bold text-[24px] text-center py-4">
        {JSON.parse(localStorage.getItem("login_data"))?.resp_user?.first_name +
          " " +
          JSON.parse(localStorage.getItem("login_data"))?.resp_user?.last_name}
        , You are on {getSubscriptionName()} Plan{" "}
      </h6>
      {/* months , quater , year */}
      <div className="mb-4 animation-tab">
        <ul className="flex nav-pills py-2" id="default-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${selectedTab == "monthly" && "show"}`}
              id="monthly-tab"
              onClick={(event) => showTabContent(event, "monthly")}
              type="button"
              role="tab"
              aria-controls="monthly"
              aria-selected="false"
            >
              <h5>Monthly</h5>
              <p>No Contracts, Cancel anytime</p>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${selectedTab == "quaterly" && "show"}`}
              id="quaterly-tab"
              onClick={(event) => showTabContent(event, "quaterly")}
              type="button"
              role="tab"
              aria-controls="quaterly"
              aria-selected="false"
            >
              <h5>Quarterly</h5>
              <p>Save 10%</p>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${selectedTab == "yearly" && "show"}`}
              id="yearly-tab"
              onClick={(event) => showTabContent(event, "yearly")}
              type="button"
              role="tab"
              aria-controls="yearly"
              aria-selected="false"
            >
              <h5>Yearly</h5>
              <p>Save 25%</p>
            </button>
          </li>
          <li className="selected"></li>
        </ul>
        <div id="default-tab-content">
          <div
            className="hidden"
            id="monthly"
            role="tabpanel"
            aria-labelledby="monthly-tab"
          ></div>
          <div
            className="hidden"
            id="quaterly"
            role="tabpanel"
            aria-labelledby="quaterly-tab"
          ></div>
          <div
            className="hidden"
            id="yearly"
            role="tabpanel"
            aria-labelledby="yearly-tab"
          ></div>
        </div>
      </div>

      {/* currency */}
      <div className="animation-tabcurrency pb-6">
        <div className="flex flex-row justify-center">
          <div className="basis-1/6">
            <ul
              className="flex nav-pills py-2"
              id="currency-tab"
              data-tabs-toggle="#currency-tab-content"
              role="tablist"
            >
              <li className="nav-itemcurrency" role="presentation">
                <button
                  className={`nav-link ${selectedcurrency == "USD" && "show"}`}
                  id="USD"
                  data-tabs-target="#usd"
                  type="button"
                  role="tab"
                  aria-controls="usd"
                  aria-selected="false"
                  onClick={(e) => {
                    showTabContentCurrency(e, "USD");
                    settoggleClicked(false);
                  }}
                >
                  <h5>USD</h5>
                </button>
              </li>
              <li className="nav-itemcurrency" role="presentation">
                <button
                  className={`nav-link ${selectedcurrency == "INR" && "show"}`}
                  id="INR"
                  data-tabs-target="#inr"
                  type="button"
                  role="tab"
                  aria-controls="inr"
                  aria-selected="false"
                  onClick={(e) => {
                    showTabContentCurrency(e, "INR");
                    settoggleClicked(true);
                  }}
                >
                  <h5>INR</h5>
                </button>
              </li>
              <li className="selectedcurrency"></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full h-full py-4 -mx-4">
        <div className="flex justify-center flex-wrap card-boxes">
          {subscriptionList.map((subscription, subscription_index) => (
            <div className="w-full md:w-1/4 px-4  justify-center">
              <div
                className={`card h-full ${
                  JSON.parse(localStorage.getItem("login_data"))?.resp_user
                    ?.plan_id == subscription?.plan_id && "card-selected"
                }`}
              >
                <div className="card-body">
                  <div className="flex items-center px-4 pb-2">
                    <h5 className="check-card">
                      {subscription?.subscription_name}
                    </h5>
                    {getPlanDetails().planId == subscription?.plan_id && (
                      <span className="lable-box ms-auto">Current Plan</span>
                    )}
                  </div>
                  <hr />

                  <div className="card-content px-4">
                    <div className="rate-box">
                      {subscription?.pricedetails?.length > 0 ? (
                        <p>
                          <small>{!toggleClicked ? "$" : "₹"}</small>
                          <strong className="check-card">
                            {getPlanMonthlyPriceUsd(subscription?.pricedetails)}
                          </strong>
                          <sub>per month</sub>
                        </p>
                      ) : (
                        <p>
                          <small></small>
                          <strong className="check-card"></strong>
                          <sub>Enterprise</sub>
                        </p>
                      )}
                    </div>

                    <hr />

                    <ul>
                      {subscription?.plandetails
                        ?.filter((plan) => plan?.key_value !== "C")
                        ?.map((plan, plan_index) => (
                          <li key={plan_index}>
                            <span className="check-card">
                              <svg className="icon">
                                <use href="#icon_colortick"></use>
                              </svg>
                            </span>
                            {getKeyValue(plan?.key_value)}
                            {plan?.description}
                          </li>
                        ))}
                    </ul>
                    {checkKeyValueComingSoon(subscription?.plandetails)}
                  </div>
                </div>
                {subscription?.plan_id != 1 && (
                  <div className="card-footer">
                    {getPlanDetails().planId == 1 && (
                      <button
                        type="button"
                        className="btn-default"
                        disabled={creatingRazorPay}
                        onClick={() => upgradeSubscription(subscription)}
                      >
                        {creatingRazorPay &&
                        selectedPlanId == subscription?.plan_id ? (
                          <div className="flex justify-center items-center">
                            <div></div>
                            <div>Upgrading</div>
                            <div>
                              <ThreeDotsLoader />
                            </div>
                          </div>
                        ) : (
                          "Upgrade"
                        )}
                      </button>
                    )}
                    {getPlanDetails().planId == 2 &&
                      subscription?.plan_id != 2 && (
                        <button
                          type="button"
                          className="btn-default"
                          onClick={() => upgradeSubscription(subscription)}
                        >
                          Upgrade
                        </button>
                      )}
                    {getPlanDetails().planId == 3 &&
                      subscription?.plan_id == 2 && (
                        <>
                          {getDateTrial() !== "NA" ? (
                            <></>
                          ) : (
                            <button
                              type="button"
                              className="btn-default"
                              onClick={() =>
                                downgradeSubscriptionNewPlan(subscription)
                              }
                            >
                              Downgrade
                            </button>
                          )}
                        </>
                      )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Modal We Are Sorry */}
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModalSorry === "dismissible"}
        size="5xl"
        onClose={() => setOpenModalSorry(undefined)}
      >
        <Modal.Header className="capitalize py-2 border-0"></Modal.Header>
        <Modal.Body className="p-0">
          <div className="w-full">
            <div className="w-full px-[40px] py-4">
              <div className="pb-4 flex items-center">
                <div>
                  <h6 className="font-bold text-[24px]">
                    We Are Sorry To See You Go
                  </h6>
                  <p className="font-[400] text-[16px]">
                    Can you help us understand why?
                  </p>
                </div>
                <svg className="icon text-[38px] ms-8">
                  <use href="#icon_sad"></use>
                </svg>
              </div>

              <div class="flex flex-wrap -mx-6">
                {cancelSubscriptionQuestions?.map(
                  (question, question_index) => (
                    <div
                      class="w-full md:w-1/2 px-6 mb-6 md:mb-0"
                      key={question_index}
                      onClick={() => {
                        setcancelSubscriptionQuestions((prev) =>
                          prev.map((item, index) => {
                            if (item.id == question.id) {
                              return { ...item, checked: !item.checked };
                            }
                            return item;
                          })
                        );
                      }}
                    >
                      <div className="flex items-center gap-1 w-full mb-[20px] text-start p-[10px] rounded-[15px] bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9]">
                        <div>
                          <input
                            id="checkboxOne"
                            type="checkbox"
                            value=""
                            checked={question.checked}
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div className="pt-1">{question.question}</div>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="pb-4">
                <label for="message" class="block mb-2 font-[400] text-[16px]">
                  <h6 className="font-bold text-[24px]">
                    Anything we could have done better?
                  </h6>
                  You honest input is the best way we can improve our product
                </label>
                <textarea
                  id="message"
                  rows="4"
                  value={cancel_remark}
                  onChange={(e) => setcancel_remark(e.target.value)}
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-[#E9EBF0] focus:ring-[#097BA9] focus:border-[#097BA9] dark:bg-gray-700 dark:border-[#097BA9] dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#097BA9] dark:focus:border-[#097BA9]"
                ></textarea>
              </div>

              <div className="flex items-center py-4">
                <div>
                  <button
                    type="button"
                    className="flex items-center py-1 px-8 rounded-[20px] font-semibold border border-[black] opacity-[0.4]"
                    onClick={() => {
                      setOpenModalSorry(undefined);
                      startCountdown();
                      setOtp(new Array(5).fill(""));
                      setOpenModalOtp("dismissible");
                    }}
                  >
                    No, Continue
                  </button>
                </div>

                <div className="ms-6">
                  <button
                    type="button"
                    className="flex items-center py-2 px-10 rounded-[20px] font-semibold border border-[black]"
                    onClick={() => {
                      setOpenModal(undefined);
                      setOpenModalOtp(undefined);
                      setOpenModalSorry(undefined);
                    }}
                  >
                    Keep Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        dismissible
        show={openModal === "dismissible"}
        size="xl"
        onClose={() => setOpenModal(undefined)}
      >
        <Modal.Body className="p-0">
          <div className="w-full">
            <div className="w-full px-[40px] py-4">
              <div className="flex justify-center py-6">
                <svg className="icon text-[50px]">
                  <use href="#icon_cancel"></use>
                </svg>
              </div>
              <p className="font-[600] text-[24px] text-center pb-6">
                Your Subscription Has Been Successfully Cancelled
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        root={documentBodyRef.current}
        show={openModalOtp === "dismissible"}
        size="xl"
        onClose={() => setOpenModalOtp(undefined)}
      >
        <Modal.Header className="capitalize pb-2 border-0"></Modal.Header>
        <Modal.Body className="p-0">
          <div className="w-full">
            <div className="w-full px-[40px] py-4">
              <div className="pb-5">
                <h6 className="font-bold text-[24px] text-center pb-5">
                  Cancel Subscription
                </h6>
                <p className="font-[400] text-[20px] text-center">
                  Please enter 5 digit Verification code sent to&nbsp;
                  {
                    JSON.parse(localStorage.getItem("login_data"))?.resp_user
                      ?.email
                  }{" "}
                </p>
              </div>
              <div className="my-3 otp-input">
                {otp.map((data, index) => {
                  return (
                    <input
                      type="text"
                      key={index}
                      value={data}
                      className="form-control otp-box focus:ring-[#9D3784] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      required
                      maxLength="1"
                      name="otp"
                      ref={(ref) => (otpRefs.current[index] = ref)}
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  );
                })}
              </div>

              <button
                type="button"
                className="w-full my-6 py-2 px-10 rounded-[25px] font-[400] text-[22px] border border-[black]"
                onClick={() => {
                  cancelSubscriptionFn();
                }}
              >
                <div className="flex justify-between items-center">
                  <div></div>
                  <div>{cancellingRequest ? "Submitting" : "Submit"}</div>
                  <div>
                    {cancellingRequest ? <ThreeDotsLoader /> : <> {""}</>}
                  </div>
                </div>
              </button>

              <p className="text-center pb-4 font-[700] text-[20px] cursor-pointer">
                <button
                  disabled={isCounting}
                  className={`group text-transparent bg-clip-text 
                  bg-gradient-to-r ${
                    isCounting
                      ? "from-[#B0B0B0] to-[#B0B0B0]"
                      : "from-[black] to-[black]"
                  }
                `}
                  onClick={startCountdown}
                >
                  Resend Code
                </button>{" "}
                <span className="text-[#014E82]">
                  {isCounting ? <>in {count} sec</> : ""}
                </span>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default Subscription;
