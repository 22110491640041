import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Remarkable } from "remarkable";
import useCommonApi from "../hooks/common-apis";
import EmojiPicker from "emoji-picker-react";
import { Popover } from "flowbite-react";
import SocialConnectModal from "./SocialConnectModal";
import ScheduleSocialPostModal from "./ScheduleSocialPostModal";
import PixelMediaPopup from "./PixelMediaPopup";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function TweetCard({
  stringOutput,
  wholeStringOutput,
  cardNumber,
  profile,
  setopenSuccessModal,
  setopenFailureModal,
  setsuccessFailureMessage,
  handleTwitterLogin,
  mediaObj,
  media_arr,
  loader,
  isTweetPosted,
  content_id,
  setStringOutput,
  setstringWords,
}) {
  const navigate = useNavigate();
  const { getRecentFiles, callUpdateToFileApi } = useCommonApi();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [posting, setPosting] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  // const [originalString, setOriginalString] = useState("");
  const [textLength, setTextLength] = useState(0);
  const [typingTimer, setTypingTimer] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [schedulePostModal, setSchedulePostModal] = useState(undefined);
  const typingTimeout = 300; // Set the timeout value in milliseconds (adjust as needed)
  const [savedSelection, setSavedSelection] = useState(null);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [popupMessage, setpopupMessage] = useState("");
  const contentEditableRef = useRef(null);
  const previousSelection = useRef(null); // Track previous selection
  const pickerRef = useRef(null);
  const textLimit = 280;
  const { getPlanDetails} = useCommonFunctions();
  const [media, setMedia] = useState();

  const [imagesPopup, setImagesPopup] = useState();

  // console.log(stringOutput, "stringOutput in tweet card");

  
  // const getRemarkableText = (as) => {
  //   // let string=stringOutput?.replaceAll(/\n/g, "<br/>");
  //   const md = new Remarkable();
  //   md.set({
  //     html: true,
  //     breaks: true,
  //     linkify: true,
  //     typographer: true,
  //     quotes: "“”‘’",
  //     xhtmlOut: true,
  //     langPrefix: "language-",
  //   });
  //   const html = md.render(stringOutput || "");
  //   return html;
  // };

  async function postNewTweet(datetime, timezone) {
    console.log(datetime);
    setPosting(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/social/twitter/post_tweet`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          tweet: contentEditableRef?.current?.textContent || stringOutput,
          schedule_datetime: datetime || null,
          timezone: timezone || null,
          media: media?.url || null,
          content_id:content_id
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        console.log(response.data, "response");
        handleContentChange({
          target: contentEditableRef.current,
          mediaObj:media,
          tweetPosted:{
            post_id:response?.data?.data?.post?.id || null,
            type: datetime ? 'scheduled' : 'posted',
          }
        });
        setopenSuccessModal("dismissible");
        setsuccessFailureMessage(response?.data?.message);
      } else {
        setopenFailureModal("dismissible");
        setsuccessFailureMessage(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setopenFailureModal("dismissible");
      setsuccessFailureMessage(
        error?.response?.data?.message ||
          "Something went wrong, please try again later."
      );
    } finally {
      setPosting(false);
    }
  }
  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (typingTimer) {
        clearTimeout(typingTimer);
      }
    };
  }, [typingTimer]);

  useEffect(() => {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = stringOutput;
    setTextLength(tempDiv.textContent.length);
  }, [stringOutput]);
  useEffect(() => {
    if(mediaObj?.url && !loader){
      setMedia(mediaObj)
    }else{
      setMedia(null)
    }
  }, [mediaObj,loader])
  

  const handleContentChange = (event) => {
    setUpdating(true);
    // console.log(event.target.innerHTML, "event.target.innerHTML");
    // const updatedContent = event.target.innerHTML;
    // const updatedContent = wholeStringOutput?.replace(
    //   originalString,
    //   event.target.textContent
    // )
    setTextLength(event.target.textContent.length);
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
      langPrefix: "language-",
    });
    const html = md.render(wholeStringOutput || "");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    let listItems = doc.querySelectorAll("p");
    let updatedContent = "";
    if (listItems[cardNumber])
      listItems[cardNumber].textContent = event.target.textContent;
    updatedContent = doc.body.innerHTML;
    // console.log(updatedContent, "updatedContent",originalString,'originalstring')
    // Clear the previous timer
    if (typingTimer) {
      clearTimeout(typingTimer);
    }

    // Set a new timer to call the save API after typingTimeout milliseconds
    const newTypingTimer = setTimeout(() => {
      saveContentToApi(updatedContent, event);
    }, typingTimeout);

    setTypingTimer(newTypingTimer);
  };

  const saveContentToApi = async (content, event) => {
    if (content && content != "") {
      // console.log('string outut updated')
      const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
      let selected_file_data = {
        content_id: selectedFile?.content_id || selectedFile?.id || "",
        space_id: selectedFile?.space_id || "",
        folder_id: selectedFile?.folder_id || "",
      };
      let mediaArray = media_arr;
      if(event?.mediaObj?.url){
        mediaArray[cardNumber] = event?.mediaObj
      }else{
        mediaArray[cardNumber] = {}
      }
      let posted_tweets_arr = selectedFile?.content_settings?.posted_tweets_arr || [];
      posted_tweets_arr[cardNumber] = event?.tweetPosted || isTweetPosted;
      let selectedFileContentSettings = { ...selectedFile?.content_settings,
        media_arr: mediaArray,
        posted_tweets_arr: posted_tweets_arr
       };
      selectedFileContentSettings["content_title"] =
        selectedFile?.content_title;
      selectedFileContentSettings["content_template"] =
        selectedFile?.content_template;
      await callUpdateToFileApi(
        selectedFileContentSettings,
        content,
        {},
        selected_file_data,
        selectedFile
      );
      setUpdating(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
        // && !contentEditableRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
        setSavedSelection(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div className="border-[1px] border-[#E9EBF0] p-[15px] bg-[#FAFBFC] rounded-[16px]">
      {profile && (
        <div className="flex items-center mb-4">
          <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full overflow-hidden me-4">
            <img
              src={profile?.profile_image_url}
              className="w-full h-full object-cover"
              alt="avtar"
            />
          </div>
          <div className="">
            <p className="text-[#4D4E4E] text-[18px]">
              {profile?.social_name}
              {profile?.verified && (
                <svg className="icon text-[18px] text-blue-600 ms-2">
                  <use href="#icon_verify"></use>
                </svg>
              )}
            </p>
            <p className="text-[#A4A4A4]">@{profile?.username}</p>
          </div>
        </div>
      )}

      <div
        onInput={(e)=>handleContentChange({...e,mediaObj:media})}
        contentEditable={!isTweetPosted}
        ref={contentEditableRef}
        // dangerouslySetInnerHTML={{ __html: getRemarkableText("html") }}
        className="text-[#787878] text-editor"
        onFocus={(e) => {
          // setOriginalString(e.target.textContent);
          // console.log(e.target.textContent, "original string");
          // Change the border color to red when focused
          e.target.style.border = "2px solid rgb(56, 140, 197)";
        }}
        onMouseUp={(e) => {
          const selection = window.getSelection();
          if (selection.rangeCount > 0) {
            setSavedSelection(selection.getRangeAt(0));
          }
        }}
        onBlur={(event) => {
          // setOriginalString(event.target.textContent);
          // Reset the border color when focus is lost
          event.target.style.border = "initial";
          const text = event.target.textContent;

          // Handle multi-word replacement (if a word was previously selected)
          if (
            previousSelection.current &&
            text.endsWith(previousSelection.current)
          ) {
            const newText = text.slice(
              0,
              text.length - previousSelection.current.length
            );
            event.target.textContent = newText;
            previousSelection.current = null;
          }
        }}
      >
        {stringOutput}
      </div>

      {media?.url && (
        <div className="flex items-center my-4 ">
         
            <div className=" relative rounded-[10px] overflow-hidden ">
              {media?.type == "image" ? (
                <img src={media?.url} alt="images" />
              ) : (
                <video
                  controls
                  loop
                  autoPlay
                  muted
                  controlsList="nodownload"
                  onMouseOver={(e) => {
                    e.target.muted = false;
                    //make volume 10%
                    e.target.volume = 0.1;
                  }}
                  onMouseOut={(e) => {
                    e.target.muted = true;
                  }}
                  src={media?.url}
                ></video>
              )}
              <button
              onClick={() => {
                setMedia(null);
                handleContentChange({
                target: contentEditableRef.current,
                mediaObj: null,
              })}}
                disabled={posting || isTweetPosted}
                type="button"
                className="rounded-full  text-[16px] font-[700] bg-white bg-opacity-50 px-3  border-none absolute top-[10px] right-[10px]  hover:text-[#1A8CD1] hover:bg-opacity-100"
              >
                X
              </button>
            </div>
          
        </div>
      )}

      <div className="flex items-center my-4">
        <button
          disabled={posting || isTweetPosted}
          className="disabled:opacity-50"
          onClick={() => setImagesPopup("dismissible")}
        >
          <svg className="icon text-[22px]">
            <use href="#icon_image"></use>
          </svg>
        </button>

        <div className="w-[1px] h-full bg-[#E8EAED] mx-4">&nbsp;</div>

        {
          <Popover
            open={showEmojiPicker}
            // draggable={true}
            content={
              <div ref={pickerRef}>
                <EmojiPicker
                  autoFocusSearch={true}
                  previewConfig={{
                    showPreview: false,
                  }}
                  // open={showEmojiPicker}
                  // style={{
                  //   position: "absolute",
                  //   top: "-100px",
                  //   right: "400px",
                  // }}
                  emojiStyle={"native"}
                  onEmojiClick={(emojiObject) => {
                    if (savedSelection) {
                      savedSelection.deleteContents();
                      const textNode = document.createTextNode(
                        emojiObject.emoji
                      );
                      savedSelection.insertNode(textNode);
                      setSavedSelection(null);
                    }
                    setShowEmojiPicker(false);
                    handleContentChange({
                      target: contentEditableRef.current,
                      mediaObj:media
                    });
                  }}
                />
              </div>
            }
          >
            <button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              disabled={!savedSelection || isTweetPosted}
              className="disabled:opacity-50"
            >
              <svg className="icon text-[22px]">
                <use href="#icon_smile"></use>
              </svg>
            </button>
          </Popover>
        }
        <p className={`text-[#A4A4A4] ms-auto `}>
          <span
            className={textLength > textLimit ? "text-red-500" : "text-inherit"}
          >
            {textLength}
          </span>
          /{textLimit}
        </p>
      </div>

      <div className="h-[1px] bg-[#E8EAED]"></div>

      <div className="flex items-center justify-between my-4">
        <button
          disabled={
            !stringOutput?.length ||
            posting ||
            textLength > textLimit ||
            updating || loader || isTweetPosted
          }
          onClick={
            () => { 
              if(getPlanDetails()?.planId > 1){
                if(profile){
                  postNewTweet(null, null)
                }else{
                  setConnectModal("dismissible")
                }
              }else{
                setshowModal({
                  first: true,
                  second: false,
                  third: false,
                });
                setpopupMessage(
                  "post on X (Twitter)."
                );
              }
            }
          }
          type="button"
          className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-2 px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] flex items-center justify-center w-1/2 me-3 hover:border-x-[0px]"
        >
          <svg className="icon me-3">
            <use href="#icon_x"></use>
          </svg>
          {posting ? "Posting..." : isTweetPosted?.type == 'posted' ? "Posted On X (Twitter)": "Post On X (Twitter)"}
        </button>

        <button
          disabled={
            !stringOutput?.length ||
            posting ||
            textLength > textLimit ||
            updating || loader || isTweetPosted
          }
          onClick={
            () => { 
              if(getPlanDetails()?.planId > 1){
                if(profile){
                  setSchedulePostModal("dismissible")
                }else{
                  setConnectModal("dismissible")
                }
              }else{
                setshowModal({
                  first: true,
                  second: false,
                  third: false,
                });
                setpopupMessage(
                  "post on X (Twitter)."
                );
              }
            }
         
          }
          type="button"
          className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-2 px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] flex items-center justify-center w-1/2 ms-3 hover:border-x-[0px]"
        >
          <svg className="icon me-3">
            <use href="#icon_calendar"></use>
          </svg>
          {isTweetPosted?.type == 'scheduled' ? "Added To Calendar" : "Add To Calendar"}
        </button>
      </div>
      <SocialConnectModal
        connectModal={connectModal}
        massage={"You Have Not Connected Twitter To Vison"}
        setConnectModal={setConnectModal}
        onConfirm={handleTwitterLogin}
      />
      <ScheduleSocialPostModal
        loading={posting}
        onConfirm={postNewTweet}
        schedulePostModal={schedulePostModal}
        setSchedulePostModal={setSchedulePostModal}
      />
      <PixelMediaPopup
        imagesPopup={imagesPopup}
        setImagesPopup={setImagesPopup}
        onSelect={(mediaData) => {
          setMedia(mediaData);
          setImagesPopup(undefined);
          handleContentChange({
            target: contentEditableRef.current,
            mediaObj: mediaData,
          });
        }}
      />
      <PopupModals
        showModal={showModal}
        setshowModal={setshowModal}
        popupMessage={popupMessage}
      />
    </div>
  );
}

export default TweetCard;
