/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import EngagingPostCaption from "../components/EngagingPostCaption";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import TextEditor from "../components/TextEditor";
import useCommonFunctions from "../hooks/common-functions";
import TweetCard from "../components/TweetCard";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import { Remarkable } from "remarkable";

function Tweet() {
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const { postWebsocketDetails, closeFunction, loader, stringWords, ws,setstringWords } =
    useWebsocketHook();
  const { getDescription, } = useCommonFunctions();
  const { checkTwitterAccess} = useCommonApi()
  const [stringOutput, setstringOutput] = useState("");
  const [activeView, setActiveView] = useState("tweet");
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [twitterProfile, setTwitterProfile] = useState();
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  let login_data = JSON.parse(localStorage.getItem("login_data"));
  const navigate = useNavigate();
  const {
    contentEditableRef
  } = useContext(UserContext);
  async function handleTwitterLogin() {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/auth/twitter/${login_data?.resp_user?.id}?redirect_uri=${ window.location.origin + window.location.pathname}`,
        "_self"
      );
    } catch (error) {
      console.error("Error initiating Twitter login", error);
    }
  }



  // utility.js
 const extractListItems = (htmlString) => {
    // let string=htmlString?.replaceAll(/\n/g, "<br/>");
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
      langPrefix: "language-",
    });
    const html = md.render(htmlString || "")
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, 'text/html');
   let listItems = doc.querySelectorAll('p');
   if(!listItems.length){
      //if there are no list items then it should create list based on <br/> tag
      return [htmlString]
   }
  //  console.log(html, "htmlString",listItems)
  //right now it is returning the innerText of the list items with p tag but it should not be like this
  return Array.from(listItems).map(li => li.innerText);
  // return Array.from(listItems).map(li => li.innerHTML);
};


  useEffect(() => {
    // let replace1 = stringWords.replaceAll("&&&", "<br/>");
    // let replace = replace1.replace(/####/g, "<br/>");

    setstringOutput(stringWords);
  }, [stringWords]);
  useEffect(() => {
    if (selectedFile && selectedFile?.contents) {
      // let content = selectedFile?.contents?.replaceAll("&&&", "<br/>");
      setstringOutput(selectedFile?.contents);
    }
  }, [activeView]);
  useEffect(() => {
    return () => {
      if (ws) {
        // console.log("unmounting");
        closeFunction();
      }
    };
  }, [ws]);
  useEffect(() => {
    checkTwitterAccess(setTwitterProfile);
    const searchParams = new URLSearchParams(window.location.search);
    let success = searchParams.get("success");
    //parse success to bool
    success = success === "true" ? true : false;
    const error = searchParams.get("error");
    //if searchparams are present then update the twitter access token
    if (searchParams.has("success")) {
      if (!success) {
        console.log("error from query", error);
        setopenFailureModal("dismissible");
        setsuccessFailureMessage(error);
      }
    }
  }, []);

  // useEffect(() => {
  //   setstringOutput(contentEditableRef.current?.innerHTML);
  //   console.log("cccccc")
  // }, [contentEditableRef?.current, contentEditableRef?.current?.innerHTML]);
  

  // console.log(stringOutput, "stringOutput");

  return (
    <div className="wrapper">
      <div class="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2
          title="Tweet"
          description={getDescription("Social Media", "Tweet")}
        />

        <div
          className="w-full md:w-1/2 px-4 border-r"
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <EngagingPostCaption
            from="tweet"
            aboutTitle="What is Your Tweet About?"
            mainTitle="Tweet"
            caption
            keywords
            about
            loader={loader}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
          />
        </div>

        {/* <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Editor</h2>
              <div className="ms-auto">
                {!fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(true)}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_fullscreen"></use>
                    </svg>
                  </div>
                )}
                {fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(false)}>
                    <svg className="icon text-[14px] cursor-pointer">
                      <use href="#icon_fullexit"></use>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <TextEditor
              stringOutput={stringOutput}
              setstringOutput={setstringOutput}
            />
          </div>
        </div> */}
        <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full overflow-x-scroll pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Tweets</h2>
              {/* {activeView == "editor" && (
                <div className="ms-auto">
                  {!fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(true)}>
                      <svg className="icon text-[14px]  cursor-pointer">
                        <use href="#icon_fullscreen"></use>
                      </svg>
                    </div>
                  )}
                  {fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(false)}>
                      <svg className="icon text-[14px] cursor-pointer">
                        <use href="#icon_fullexit"></use>
                      </svg>
                    </div>
                  )}
                </div>
              )} */}
              {/* <div
                className="inline-flex rounded-md shadow-sm ml-2"
                role="group"
              >
                <button
                  type="button"
                  className={`px-4 py-2 text-sm font-medium  border border-gray-200 rounded-s-lg hover:bg-gray-100  focus:z-10  dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700  ${
                    activeView === "editor"
                      ? " bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white"
                      : "text-gray-900 bg-white"
                  }`}
                  onClick={() => setActiveView("editor")}
                >
                  Editor View
                </button>

                <button
                  type="button"
                  className={`px-4 py-2 text-sm font-medium  border border-gray-200 rounded-e-lg hover:bg-gray-100  focus:z-10  dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700  ${
                    activeView === "tweet"
                      ? "bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white"
                      : "text-gray-900 bg-white"
                  }`}
                  onClick={() => setActiveView("tweet")}
                >
                  Tweet View
                </button>
              </div> */}
            </div>
            <div className="mt-2 space-y-2">
              {activeView === "editor" && (
                <TextEditor
                  // stringOutput={stringOutput}
                  stringOutput={stringOutput || selectedFile?.contents}
                  // setstringOutput={setstringOutput}
                  setstringOutput={setstringWords}
                />
              )}
              {/* now it will split the stringOutput into array of strings on the basis of '&&&' and then map over it to render the TweetCard component */}
              {activeView === "tweet" &&
                // (stringOutput ? (
                (stringOutput ||  selectedFile?.contents? (
                  // stringForTweets
                  //   ?.split("&&&")
                  // extractListItems(stringOutput)
                  extractListItems(stringOutput || selectedFile?.contents)
                    ?.map((string, index) => (
                      <TweetCard
                        profile={twitterProfile}
                        stringOutput={string}
                        cardNumber={index}
                        // wholeStringOutput={stringOutput}
                        mediaObj={selectedFile?.content_settings?.media_arr ? selectedFile?.content_settings?.media_arr[index] : null}
                        isTweetPosted={selectedFile?.content_settings?.posted_tweets_arr ? selectedFile?.content_settings?.posted_tweets_arr[index] : null}
                        wholeStringOutput={stringOutput || selectedFile?.contents}
                        media_arr={selectedFile?.content_settings?.media_arr || []}
                        key={index}
                        content_id={selectedFile?.id}
                        loader={loader}
                        setopenFailureModal={setopenFailureModal}
                        setopenSuccessModal={setopenSuccessModal}
                        setsuccessFailureMessage={setsuccessFailureMessage}
                        handleTwitterLogin={handleTwitterLogin}
                        setStringOutput={setstringOutput}
                        setstringWords={setstringWords}
                      />
                    ))
                ) : (
                  <h4 className="text-2xl">click on generate to view.</h4>
                ))}
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </div>
  );
}

export default Tweet;
