import React from "react";
import Joyride from "react-joyride";

function JoyrideComponent({
  steps,
  run,
  stepIndex,
  setStepIndex,
  handleJoyrideCallback,
}) {
  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        continuous={true}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        styles={{
          options: {
            arrowColor: "#F4F8FF",
            backgroundColor: "#F4F8FF",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 300,
            zIndex: 1000,
            padding: 0,
          },
          buttonNext: {
            padding: "7.8px 20px",
            borderRadius: "20px",
            fontWeight: "600",
            color: "black",
            backgroundColor: "#F4F8FF", // Fallback for older browsers
            border: "1px solid black",
            cursor: "pointer",
          },
          buttonBack: {
            padding: "8px 20px",
            borderRadius: "20px",
            fontWeight: "600",
            color: "white",
            backgroundImage: "linear-gradient(to right, #1A71A7, #9D3784)",
            backgroundColor: "#1A71A7", // Fallback for older browsers
            transition: "background-image 0.3s ease",
          },
          spotlight: {
            border: "2px solid",
            borderImage: "linear-gradient(to right, #1A71A7, #9D3784) 1",
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box",
          },
        }}
        locale={{
          last: steps.length === 1 ? "Next" : "Last",
        }}
      />
    </>
  );
}

export default JoyrideComponent;
