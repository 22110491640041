import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "flowbite-react";
import { createClient } from "pexels";
import { Tooltip } from "react-tooltip";

function PixelMediaPopup({ imagesPopup, setImagesPopup, onSelect }) {
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("image");
  const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (isSearching) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isSearching, hasMore]
  );
  const searchPhotos = async (query) => {
    try {
      setIsSearching(true);
      if (searchType == "image") {
        const result = await client.photos.search({
          query,
          per_page: 80,
          // page: currentPage,
        });
        setSearchResult(result.photos);
        // const newList = result.photos.filter((newList) => {
        //   return !searchResult.some(
        //     (existingList) => existingList?.id === newList?.id
        //   );
        // });
        // setSearchResult((pre) => [...pre, ...newList]);
        setHasMore(result.next_page);
      } else {
        const result = await client.videos.search({
          query,
          per_page: 80,
          max_duration: 120,
          size: "small",
          // page:currentPage
        });
        setSearchResult(result.videos);
        // const newList = result.videos.filter((newList) => {
        //   return !searchResult.some(
        //     (existingList) => existingList?.id === newList?.id
        //   );
        // });
        // setSearchResult((pre) => [...pre, ...newList]);
        setHasMore(result.next_page);
      }
    } catch (error) {
      console.error(error);
      setsuccessFailureMessage("Failed to search media");
      setSearchResult([]);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (!searchInput) {
      setSearchResult([]);
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchInput) {
      searchPhotos(searchInput);
    }
  }, [currentPage, searchType]);

  return (
    <>
      <Modal
        // root={documentBodyRef.current}
        dismissible
        show={imagesPopup === "dismissible"}
        size="5xl"
        onClose={() => {
          setImagesPopup(undefined);
          setSearchInput("");
          setSearchResult([]);
          setSearchType("image");
          setsuccessFailureMessage();
          setCurrentPage(1);
          setHasMore(false);
        }}
      >
        <Modal.Header className="capitalize border-0 pt-4">
          <button
            type="button"
            className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] me-6 hover:border-x-[0px]"
          >
            Free Search
          </button>

          <button
            disabled
            type="button"
            className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] me-6 hover:border-x-[0px]"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Coming Soon"
          >
            Paid Search
          </button>

          <button
            disabled
            type="button"
            className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] hover:border-x-[0px]"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Coming Soon"
          >
            Ai Generated
          </button>
        </Modal.Header>
        <Modal.Body className="p-5 tweet-img min-h-[70vh] max-h-[70vh] ">
          {/* <h6 className="pb-4 text-[20px] font-[700]">Suggestive Images</h6> */}
          {/* <div className="grid grid-cols-4 gap-4">
            <div className="card-border">
              <div className="card-box rounded-[10px]">
                <img src="/images/tweetimg.png" alt="images" />
                <button
                  type="button"
                  className="rounded-full py-[5px] text-[16px] font-[700]"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="card-border">
              <div className="card-box rounded-[10px]">
                <img src="/images/tweetimg.png" alt="images" />
                <button
                  type="button"
                  className="rounded-full py-[5px] text-[16px] font-[700]"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="card-border">
              <div className="card-box rounded-[10px]">
                <img src="/images/tweetimg.png" alt="images" />
                <button
                  type="button"
                  className="rounded-full py-[5px] text-[16px] font-[700]"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="card-border">
              <div className="card-box rounded-[10px]">
                <img src="/images/tweetimg.png" alt="images" />
                <button
                  type="button"
                  className="rounded-full py-[5px] text-[16px] font-[700]"
                >
                  Add
                </button>
              </div>
            </div>
          </div> */}

          <div className="py-5 px-[6px] flex items-center">
            <div className="me-8 pb-5">
              <select
                value={searchType}
                onChange={(e) => {
                  setCurrentPage(1);
                  setHasMore(false);
                  setSearchResult([]);
                  setSearchType(e.target.value);
                }}
                id="small"
                className="block w-[200px] p-2 text-[20px] font-[700] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={"image"} selected>
                  Search Image
                </option>
                <option value="video">Search Video</option>
              </select>
            </div>

            <div className="w-full">
              <form
                className="d-flex  w-full "
                onSubmit={(e) => {
                  e.preventDefault();
                  searchPhotos(searchInput);
                }}
              >
                <input
                  type="search"
                  className=" py-[10px] px-4 w-1/2 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="searchinput"
                  placeholder={
                    searchType === "image" ? "Search Image" : "Search Video"
                  }
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[8px] px-[13px] border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] mx-2 hover:border-x-[0px]"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_newsearch"></use>
                  </svg>
                </button>
              </form>
              <small className="text-gray-500 ms-4">
                powered by <b>Pexels</b>
              </small>
            </div>
          </div>

          {searchInput ? (
            <div className="grid grid-cols-4 gap-4">
              {searchResult.map((photo, index) => (
                <div
                  // ref={
                  //   searchResult?.length == index + 1
                  //     ? lastBookElementRef
                  //     : tempRef
                  // }
                  key={photo?.url}
                  className="card-border"
                >
                  <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                    {searchType == "image" ? (
                      <img
                        loading="lazy"
                        src={photo?.src?.medium}
                        alt="images"
                      />
                    ) : (
                      <video
                        controls
                        loop
                        autoPlay
                        muted
                        controlsList="nodownload"
                        onMouseOver={(e) => {
                          e.target.muted = false;
                          //make volume 10%
                          e.target.volume = 0.1;
                        }}
                        onMouseOut={(e) => {
                          e.target.muted = true;
                        }}
                        src={
                          photo?.video_files?.find(
                            (file) => file?.quality == "sd"
                          )?.link
                        }
                      ></video>
                    )}
                    <button
                      type="button"
                      className="rounded-full py-[5px] text-[16px] font-[700]"
                      onClick={() => {
                        onSelect({
                          type: searchType,
                          url:
                            searchType == "image"
                              ? photo?.src?.original
                              : photo?.video_files
                                  ?.sort((a, b) => a.id > b.id)
                                  ?.find((file) => file?.quality == "hd")
                                  ?.link ||
                                photo?.video_files
                                  ?.sort((a, b) => a.id > b.id)
                                  ?.find((file) => file?.quality == "sd")
                                  ?.link ||
                                null,
                        });
                        setSearchInput("");
                        setSearchResult([]);
                        setSearchType("image");
                        setsuccessFailureMessage();
                        setCurrentPage(1);
                        setHasMore(false);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ))}
              {successFailureMessage && (
                <div className="text-center text-red-500">
                  {successFailureMessage}
                </div>
              )}
              {isSearching && (
                <>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                  <div className="card-border ">
                    <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
                      {/* <img src="" alt="images" /> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="text-center">Search to view.</div>
          )}
          <Tooltip
            id="my-tooltip"
            // style={{ backgroundColor: "#7b7b7b", color: "white" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

// function PixelMediaPopup({ imagesPopup, setImagesPopup }) {
//   const [successFailureMessage, setsuccessFailureMessage] = useState("");
//   const [searchResult, setSearchResult] = useState([]);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [isSearching, setIsSearching] = useState(false);
//   const [searchInput, setSearchInput] = useState("");
//   const [hasMore, setHasMore] = useState(false);
//   const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);

//   const observer = useRef();
//   const tempRef = useRef();
//   const lastBookElementRef = useCallback(
//     (node) => {
//       console.log("intersecting");
//       if (isSearching) {
//         return;
//       }
//       if (observer.current) observer.current.disconnect();
//       observer.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && hasMore) {
//           setCurrentPage((prev) => prev + 1);
//         }
//       });
//       if (node) observer.current.observe(node);
//     },
//     [isSearching, hasMore]
//   );
//   const searchPhotos = async (query) => {
//     try {
//       setIsSearching(true);
//       const result = await client.photos.search({
//         query,
//         per_page: 32,
//         page: currentPage,
//       });
//       const newList = result.photos.filter((newList) => {
//         return !searchResult.some(
//           (existingList) => existingList?.id === newList?.id
//         );
//       });
//       setSearchResult((pre) => [...pre, ...newList]);
//       setHasMore(result.next_page);
//     } catch (error) {
//       console.error(error);
//       setsuccessFailureMessage("Failed to search media");
//       setSearchResult([]);
//     } finally {
//       setIsSearching(false);
//     }
//   };

//   // Debounce utility function
//   const debounce = (func, delay) => {
//     let debounceTimer;
//     return function () {
//       const context = this;
//       const args = arguments;
//       clearTimeout(debounceTimer);
//       debounceTimer = setTimeout(() => func.apply(context, args), delay);
//     };
//   };

//   // Modify the searchPhotos function to be debounced
//   const debouncedSearchPhotos = debounce(searchPhotos, 1000); // 500ms delay

//   useEffect(() => {
//     if (searchInput) {
//       searchPhotos(searchInput);
//     }
//   }, [currentPage]);

//   useEffect(() => {
//     if (searchInput) {
//       debouncedSearchPhotos(searchInput);
//     }
//   }, [searchInput]);

//   return (
//     <>
//       <Modal
//         // root={documentBodyRef.current}
//         dismissible
//         show={imagesPopup === "dismissible"}
//         size="5xl"
//         onClose={() => {setImagesPopup(undefined);
//             setSearchInput("");
//             setSearchResult([]);
//             setCurrentPage(0);
//             setHasMore(false);
//         }}
//       >
//         <Modal.Header className="capitalize border-0 pt-4">
//           <button
//             type="button"
//             className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] me-6"
//           >
//             Free Search
//           </button>

//           <button
//             disabled
//             type="button"
//             className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0] me-6"
//           >
//             Paid Search
//           </button>

//           <button
//             disabled
//             type="button"
//             className="disabled:opacity-50 bg-transparent text-[#2A2A2A] font-semibold hover:text-white py-[5px] px-4 border border-[#2A2A2A] hover:border-transparent rounded-full hover:bg-gradient-to-r from-[#547AF6] to-[#F74AE0]"
//           >
//             Ai Generated
//           </button>
//         </Modal.Header>
//         <Modal.Body className="p-5 tweet-img min-h-[70vh] max-h-[70vh]">
//           <h6 className="pb-4 text-[20px] font-[700]">Suggestive Images</h6>
//           <div className="grid grid-cols-4 gap-4">
//             <div className="card-border">
//               <div className="card-box rounded-[10px]">
//                 <img src="/images/tweetimg.png" alt="images" />
//                 <button
//                   type="button"
//                   className="rounded-full py-[5px] text-[16px] font-[700]"
//                 >
//                   Add
//                 </button>
//               </div>
//             </div>

//             <div className="card-border">
//               <div className="card-box rounded-[10px]">
//                 <img src="/images/tweetimg.png" alt="images" />
//                 <button
//                   type="button"
//                   className="rounded-full py-[5px] text-[16px] font-[700]"
//                 >
//                   Add
//                 </button>
//               </div>
//             </div>

//             <div className="card-border">
//               <div className="card-box rounded-[10px]">
//                 <img src="/images/tweetimg.png" alt="images" />
//                 <button
//                   type="button"
//                   className="rounded-full py-[5px] text-[16px] font-[700]"
//                 >
//                   Add
//                 </button>
//               </div>
//             </div>

//             <div className="card-border">
//               <div className="card-box rounded-[10px]">
//                 <img src="/images/tweetimg.png" alt="images" />
//                 <button
//                   type="button"
//                   className="rounded-full py-[5px] text-[16px] font-[700]"
//                 >
//                   Add
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="py-5 px-[6px] flex items-center">
//             <div className="me-8">
//               <select
//                 id="small"
//                 className="block w-[200px] p-2 text-[20px] font-[700] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//               >
//                 <option selected>Search Image</option>
//                 <option value="video">Search Video</option>
//               </select>
//             </div>

//             <div className="w-full">
//               <form className="w-full">
//                 <label
//                   for="default-search"
//                   className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
//                 >
//                   Search
//                 </label>
//                 <div className="relative w-full">
//                   <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                     <svg
//                       className="w-4 h-4 text-gray-500 dark:text-gray-400"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 20 20"
//                     >
//                       <path
//                         stroke="currentColor"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         stroke-width="2"
//                         d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                       />
//                     </svg>
//                   </div>
//                   <input
//                     value={searchInput}
//                     onChange={(e) => setSearchInput(e.target.value)}
//                     type="search"
//                     id="default-search"
//                     className="block w-full py-[10px] px-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                     placeholder="Search Mockups, Logos..."
//                     required
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>

//           {searchInput ? (
//             <div className="grid grid-cols-4 gap-4">
//               {searchResult.map((photo, index) => (
//                 <div
//                   ref={
//                     searchResult?.length == index + 1
//                       ? lastBookElementRef
//                       : tempRef
//                   }
//                   key={photo?.url}
//                   className="card-border"
//                 >
//                   <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
//                     <img loading="lazy" src={photo?.src?.medium} alt="images" />
//                     <button
//                       type="button"
//                       className="rounded-full py-[5px] text-[16px] font-[700]"
//                     >
//                       Add
//                     </button>
//                   </div>
//                 </div>
//               ))}
//               {isSearching && (<>
//                 <div className="card-border ">
//                   <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
//                     {/* <img src="" alt="images" /> */}
//                   </div>
//                 </div>
//                 <div className="card-border ">
//                   <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
//                     {/* <img src="" alt="images" /> */}
//                   </div>
//                 </div>
//                 <div className="card-border ">
//                   <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
//                     {/* <img src="" alt="images" /> */}
//                   </div>
//                 </div>
//                 <div className="card-border ">
//                   <div className="card-box animate-skeleton bg-gray-300 rounded-[10px]">
//                     {/* <img src="" alt="images" /> */}
//                   </div>
//                 </div>
//               </>)}
//             </div>
//           ) : (
//             <div className="text-center">Search to view.</div>
//           )}
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

export default PixelMediaPopup;
