/* eslint-disable eqeqeq */
import React from "react";
import { Modal } from "flowbite-react";
import ThreeDotsLoader from "./ThreeDotsLoader";

function RemoveMember({
  selectedMember,
  documentBodyRef,
  openModalRemove,
  setOpenModalRemove,
  removeMemberApi,
  onClose,
  removingMember,
  from="Workspace"
}) {
  return (
    <div>
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModalRemove === "dismissible"}
        size="lg"
        onClose={onClose}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Remove Member</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="flex justify-center">
            <img src="images/remove.png" alt="..." />
          </div>

          <div>
            <p className="font-[400] text-[16px] pb-2">
              Are you sure you want to remove{" "}
              {from=="Workspace"?<strong>
                {selectedMember?.user_workspace?.first_name?.toUpperCase()}
                &nbsp;
                {selectedMember?.user_workspace?.last_name?.toUpperCase()}
              </strong>:<strong>
                {selectedMember?.space_user?.first_name?.toUpperCase()}
                &nbsp;
                {selectedMember?.space_user?.last_name?.toUpperCase()}
              </strong>}
              {" "}
              From{" "}
              <strong>
                {JSON.parse(localStorage.getItem("selected_workspace"))
                  ?.workspace?.workspace_name?.toUpperCase()
                  ?.trim()}
                ‘s
              </strong>{" "}
              {from}?
            </p>
            <p>
              <strong>Important:</strong> This person will be removed but their
              folder, documents and everything else will not be deleted. This
              deleted user still may appear in Searches
            </p>
          </div>
          <div className="flex pt-4">
            <div className="ms-auto">
              <button
                type="buttonn"
                className="bg-[#85D977] hover:bg-[#55b943] rounded-full text-[#fff] px-8 py-2"
                onClick={() => setOpenModalRemove(undefined)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-[#EB5050] text-[#fff] rounded-full px-8 py-2 ms-6 hover:bg-[#c53535]"
                onClick={() => {
                  removeMemberApi();
                }}
              >
                {removingMember ? (
                  <div className="flex items-center">
                    <div>Removing</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  <>Remove</>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RemoveMember;
