/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useCommonFunctions from "../hooks/common-functions";
import useCommonApi from "../hooks/common-apis";

function Billing() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState();
  const [openModalAddDetail, setOpenModalAddDetail] = useState();
  const [billingData, setbillingData] = useState({});
  const { getPlanDetails } = useCommonFunctions();
  const { getBillingDetails } = useCommonApi();

  const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("DD MMMM, YYYY");
  };

  // console.log(billingData, "billingData");
  const getBillingDetailsFn = async () => {
    try {
      const response = await getBillingDetails();
      setbillingData(response || {});
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getBillingDetailsFn();
  }, []);
  return (
    <>
      <div className="px-2">
        <h6 className="font-bold text-[24px]">Billing</h6>
        <p className="font-semibold text-[18px]">
          You Are On {billingData?.plan?.subscription_name}{" "}
          {billingData?.subscription?.subscription_type} Plan
        </p>
      </div>
      {getPlanDetails().planId != 1 && (
        <div className="h-full">
          <div className="w-full h-full py-4">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/3 p-2 flex justify-center">
                <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9]">
                  <div className="me-4">
                    <div className="flex items-center justify-center w-[50px] h-[44px] bg-[#E9F2FF] rounded-tl-[15px] rounded-br-[15px] rounded-tr-[6px] rounded-bl-[6px]">
                      <svg className="icon">
                        <use href="#icon_cycle"></use>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold">CYCLE</p>
                    <p className="font-semibold text-[24px] pt-2">
                      {billingData?.plan?.subscription_name}{" "}
                      {billingData?.subscription?.subscription_type}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-2 flex justify-center">
                <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9]">
                  <div className="me-4">
                    <div className="flex items-center justify-center w-[50px] h-[44px] bg-[#E9F2FF] rounded-tl-[15px] rounded-br-[15px] rounded-tr-[6px] rounded-bl-[6px]">
                      <svg className="icon">
                        <use href="#icon_bill"></use>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold">NEXT BILLING PERIOD</p>
                    <p className="font-semibold text-[24px] pt-2">
                      {billingData?.subscription?.expire_date
                        ? formatDate(billingData?.subscription?.expire_date)
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-2 flex justify-center">
                <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9]">
                  <div className="me-4">
                    <div className="flex items-center justify-center w-[50px] h-[44px] bg-[#E9F2FF] rounded-tl-[15px] rounded-br-[15px] rounded-tr-[6px] rounded-bl-[6px]">
                      <svg className="icon">
                        <use href="#icon_calculator"></use>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold">TOTAL</p>
                    <p className="font-semibold text-[24px] pt-2">
                    <span>{billingData?.subscription?.currency?.toUpperCase()==="USD"?"$":"₹"}</span>
                      {billingData?.subscription?.amount}{" "}
                      {billingData?.subscription?.currency?.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full pb-4">
        <p className="font-semibold px-2 text-[20px] pb-2">
          Consumption Till {formatDate(new Date())}
        </p>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-2">
            <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0]">
              <div className="w-full border p-[10px] rounded-[12px] bg-[#fff] text-center  font-semibold">
                {
                  billingData?.usersetting?.find(
                    (item) => item?.key_name == "work_space"
                  )?.current_value
                }{" "}
                Work Spaces
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2">
            <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0]">
              <div className="w-full border p-[10px] rounded-[12px] bg-[#fff] text-center  font-semibold">
                {
                  billingData?.usersetting?.find(
                    (item) => item?.key_name == "template_word"
                  )?.current_value
                }{" "}
                Words Generated
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-2">
        <h6 className="font-bold text-[24px]">Billing Details</h6>
        <div className="flex">
          <p className="font-semibold text-[18px]">
            Each Time You Are Billed, An Email WIll Be Sent To:{" "}
            {JSON.parse(localStorage.getItem("login_data")).resp_user?.email}
          </p>
        </div>
      </div>

      <div className="tbl-scroller px-2 py-4">
        <table className="table">
          <thead className="bg-[red]">
            <tr>
              <th>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Paid Date</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody className="tbody-gap">
            <td colSpan="7"></td>
          </tbody>
          <tbody>
            {billingData?.payment?.map((payment, payment_index) => (
              <tr key={payment_index}>
                <td style={{ borderRadius: "25px" }}>
                  {payment?.invoice_number}
                </td>
                <td>{formatDate(payment?.taxn_date)}</td>
                <td>{formatDate(payment?.taxn_date)}</td>
                <td><span>{billingData?.subscription?.currency?.toUpperCase()==="USD"?"$":"₹"}</span>{payment?.amount}</td>
                <td>Monthly Subscription</td>
                <td style={{ borderRadius: "25px" }}>
                  <button
                    type="button"
                    className="hover:text-[#9D3784]"
                    onClick={() => {
                      window.open(payment?.invoice_pdf, "_blank");
                    }}
                  >
                    <svg className="icon text-[22px]">
                      <use href="#icon_download"></use>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
            {billingData?.payment?.length === 0 && (
              <tr>
                <td
                  colSpan="7"
                  className="text-center"
                  style={{ borderRadius: "25px" }}
                >
                  No Billing Details
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal Add Card */}
      <Modal
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={() => setOpenModal(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Add New Card</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form class="w-full">
            <div className="pt-3">
              <label class="block mb-2 font-[400] text-[18px]" for="first-name">
                Card Number
              </label>
              <input
                type="number"
                className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                placeholder="Enter card number"
              />
            </div>
            <div class="flex flex-wrap -mx-6">
              <div class="w-full md:w-1/2 px-6 md:mb-0">
                <label
                  class="block mb-2 font-[400] text-[18px]"
                  for="first-name"
                >
                  Expiry Date
                </label>
                <input
                  type="text"
                  className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                  placeholder="MM/YY"
                />
              </div>
              <div class="w-full md:w-1/2 px-6">
                <label
                  class="block mb-2 font-[400] text-[18px]"
                  for="first-name"
                >
                  CVV
                </label>
                <input
                  type="number"
                  className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                  placeholder="Enter cvv"
                />
              </div>
            </div>
            <div className="pb-3">
              <label class="block mb-2 font-[400] text-[18px]" for="first-name">
                Card Holder Name
              </label>
              <input
                type="text"
                className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                placeholder="Enter card holder name"
              />
            </div>
          </form>
          <div className="flex">
            <button
              type="buttonn"
              className="w-full justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => setOpenModal(undefined)}
            >
              Add Card
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Add Detail */}
      <Modal
        dismissible
        show={openModalAddDetail === "dismissible"}
        size="lg"
        onClose={() => setOpenModalAddDetail(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Add Details</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form class="w-full">
            <div className="pt-3">
              <label class="block mb-2 font-[400] text-[18px]" for="first-name">
                Company Name
              </label>
              <input
                type="text"
                className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                placeholder="Enter company name"
              />
            </div>
            <div class="flex flex-wrap -mx-6">
              <div class="w-full md:w-1/2 px-6 md:mb-0">
                <label
                  class="block mb-2 font-[400] text-[18px]"
                  for="first-name"
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                  placeholder="Enter first name"
                />
              </div>
              <div class="w-full md:w-1/2 px-6">
                <label
                  class="block mb-2 font-[400] text-[18px]"
                  for="first-name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                  placeholder="Enter last name"
                />
              </div>
            </div>
            <div className="">
              <label class="block mb-2 font-[400] text-[18px]" for="first-name">
                VAT / GST
              </label>
              <input
                type="text"
                className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                placeholder="Enter VAT / GST"
              />
            </div>
            <div className="pb-3">
              <label class="block mb-2 font-[400] text-[18px]" for="first-name">
                Phone
              </label>
              <input
                type="tel"
                className="w-full border border-[#E9EBF0] rounded-full mb-[20px] font-[400] text-[16px] bg-[#FAFBFC]"
                placeholder="Enter phone"
              />
            </div>
          </form>
          <div className="flex">
            <button
              type="buttonn"
              className="w-full justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => setOpenModalAddDetail(undefined)}
            >
              Add Details
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Billing;
