/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import { initialState, reducer } from "../components/ReducerComponent";
import axios from "axios";
import Cookies from "js-cookie";

function Login2() {
  const location = useLocation();
  const [loadingState, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [signUpdata, setsignUpdata] = useState({
    email: location?.state?.email || "",
    verifytxt: "",
  });
  // console.log(loadingState, "loading state");
  const [count, setCount] = useState(60);
  const [isCounting, setIsCounting] = useState(true);

  const setExpirationCookie = (expirationDate) => {
    if (expirationDate) {
      Cookies.set("expirationDate", expirationDate, {
        expires: new Date(expirationDate),
      });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setsignUpdata({ ...signUpdata, [id]: value });
  };
  const signUpButton = async (e) => {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_data");
    localStorage.removeItem("workspaces_list");
    localStorage.removeItem("selected_workspace");
    localStorage.removeItem("selectedContentSettings");
    localStorage.removeItem("template_selected");
    localStorage.removeItem("assigning_template_to_file");
    localStorage.removeItem("selected_file");
    localStorage.removeItem("chat_details");
    localStorage.removeItem("chat_file");
    e.preventDefault();
    dispatch({ type: "LOADING" });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/otp/verify`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: location?.state?.email,
          verifytxt: signUpdata.verifytxt,
        },
      });
      if (response.data.success) {
        localStorage.setItem("login_data", JSON.stringify(response.data.data));
        localStorage.setItem("login_token", response.data.token);
        let data = response.data.data;
        if (data?.workspacess?.length === 0) {
          localStorage.setItem("workspaces_list", JSON.stringify([]));
          localStorage.setItem("selected_workspace", JSON.stringify({}));
        } else {
          let workspace_id = data.resp_user.workspace_id;
          if (workspace_id) {
            let workspace = data.workspacess.find(
              (item) => item.id == workspace_id
            );
            if (workspace) {
              localStorage.setItem(
                "selected_workspace",
                JSON.stringify(workspace || {})
              );
            }
          }
        }
        setExpirationCookie(
          response.data.data.resp_user.subscription_expire_date
        );
        navigate(`/${location?.state?.url}`, {
          replace: true,
        });
      } else {
        dispatch({
          type: "ERROR",
          data: {
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };
  const sendOtpFn = async (e) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: location?.state?.email,
        },
      });
      if (response.data.success) {
        dispatch({
          type: "SUCCESS",
          data: {
            successMessage: response.data.message,
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          data: {
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };
  const startCountdown = () => {
    dispatch({ type: "RESET" });
    setCount(60);
    setIsCounting(true);
    sendOtpFn();
  };

  useEffect(() => {
    let interval;

    if (isCounting && count > 0) {
      interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);
    }

    if (count === 0 || !isCounting || loadingState.loading) {
      clearInterval(interval);
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [count, isCounting, loadingState]);
  return (
    <>
      <div className="h-screen">
        <img
          className="loginfram w-full h-full absolute z-[-10]"
          src="images/frame.png"
          alt="..."
        />
        <div class="flex flex-wrap -mx-6l h-screen">
          <div class="w-full md:w-1/2 px-[80px] flex items-center justify-center">
            <div className="w-[420px]">
              <img className="w-full" src="images/signin-left.png" alt="..." />
            </div>
          </div>
          <div class="w-full md:w-1/2 px-[80px] flex items-center">
            <form className="w-full" onSubmit={signUpButton}>
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => window.open(process.env.REACT_APP_LANDING_URL)}
              >
                {/* <img src="images/logo.png" alt="..." /> */}
                <img
                  src="images/VisonWithOReplaced.png"
                  width={150}
                  height={50}
                  alt="..."
                />
              </div>
              <h5 className="font-extrabold text-[40px] py-6">Sign In</h5>
              <div className="mb-[20px]">
                <label class="block mb-2 font-semibold text-[20px]" for="email">
                  Email Address
                </label>
                <input
                  type="email"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter email"
                  required
                  disabled
                  id="email"
                  value={signUpdata.email}
                  onChange={handleChange}
                />
              </div>
              <p className="font-bold text-[20px] mb-[20px]">
                Enter the code we sent to {location?.state?.email || ""}
              </p>
              <div className="mb-[20px]">
                <label
                  class="block mb-2 font-semibold text-[20px]"
                  htmlFor="verifytxt"
                >
                  Enter Verification Code
                </label>
                <input
                  type="number"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Code"
                  required
                  id="verifytxt"
                  onChange={handleChange}
                />
              </div>
              {loadingState.successMessage != "" && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.successMessage}
                </div>
              )}
              {loadingState.error && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.errorMessage}
                </div>
              )}
              <button
                type="submit"
                className="w-full mt-22 justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              >
                <div className="flex justify-between items-center">
                  <div></div>
                  <div>{loadingState.loading ? "Signing In" : "Sign In"}</div>
                  <div>
                    {loadingState.loading ? <ThreeDotsLoader /> : <> {""}</>}
                  </div>
                </div>
              </button>
              <div className="py-5 text-center font-bold text-[18px] cursor-pointer flex justify-center items-center gap-2">
                <button
                  onClick={startCountdown}
                  disabled={isCounting}
                  className={`group text-transparent bg-clip-text 
                  bg-gradient-to-r ${
                    isCounting
                      ? "from-[#B0B0B0] to-[#B0B0B0]"
                      : "from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7]"
                  }
                `}
                >
                  Resend Code
                </button>
                {isCounting ? <>{count}</> : ""}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login2;
