/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import { Dropdown, Modal } from "flowbite-react";
import useCommonApi from "../hooks/common-apis";
import { useEffect } from "react";
import useCommonFunctions from "../hooks/common-functions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import moment from "moment";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import AreYouSure from "../components/AreYouSure";
import RemoveMember from "../components/RemoveMember";
import PopupModals from "../components/PopupModals";

function Member() {
  const [openModal, setOpenModal] = useState();
  const [openModalAreYouSure, setOpenModalAreYouSure] = useState();
  const [openModalRemove, setOpenModalRemove] = useState();
  const [submittingAccess, setsubmittingAccess] = useState(false);
  const [selectedAccess, setselectedAccess] = useState({
    from: "",
    to: "",
  });
  const [modelopen, setModelopen] = useState({});
  const [membersList, setmembersList] = useState([]);
  const [membersListTotal, setmembersListTotal] = useState({});
  const [selectedMember, setselectedMember] = useState({});
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedUserTypeId, setselectedUserTypeId] = useState("");
  const containerRef = useRef([]);
  const { getWorkSpaceMembers } = useCommonApi();
  const { getLetters, getBackgroundColor,checkInviteEnabled } =
    useCommonFunctions();
  const navigate = useNavigate();
  const documentBodyRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [search, setsearch] = useState("");
  const [removingMember, setremovingMember] = useState(false);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [popupMessage, setpopupMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const handleInputChange = (event) => {
    setCurrentEmail(event.target.value);
    // Validate email format
    if (event.target.value == "") {
      setIsValidEmail(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(event.target.value));
  };

  const handleInputKeyDown = (event) => {
    if (!isValidEmail) {
      return;
    }
    // Handle "Enter" key to add the email
    if (event.key === "Enter" && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };
  const inviteEmail = () => {
    if (isValidEmail && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };
  const submitAccess = async () => {
    try {
      setsubmittingAccess(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/member/change_access`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          guest_user_id: selectedMember?.user_id,
          user_type_id: selectedAccess?.to_user_type_id,
          workspace_id: selectedMember?.workspace_id,
        },
      });
      setsubmittingAccess(false);
      console.log(response, "response");
      if (!response.data.error) {
        // navigate("/template");
        setOpenModalAreYouSure(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        getMembers();
      } else {
        setOpenModalAreYouSure(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setsubmittingAccess(false);

      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const getMembers = async () => {
    let membersResult = await getWorkSpaceMembers(
      JSON.parse(localStorage.getItem("selected_workspace"))?.workspace_id
    );
    let members = membersResult?.members?.members;
    members = members?.map((item, index) => {
      return {
        ...item,
        member_index: index,
      };
    });
    setmembersList(members);
    setmembersListTotal(membersResult?.members);
  };
  const getUserType = (id) => {
    let types = membersListTotal?.membertypes;
    for (let i = 0; i < types.length; i++) {
      const element = types[i];
      if (element?.user_type_id == id) {
        return element?.member_type;
      }
    }
  };
  const submitWorkspaceTeamMembers = async () => {
    try {
      setloading(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/invite`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          invitation_emails: emails,
          type: "workspace", //"workspace_space"
          user_type_id: selectedUserTypeId, //null
          workspace_id: JSON.parse(localStorage.getItem("selected_workspace"))
            ?.workspace_id,
          // space_id
        },
      });
      setloading(false);
      console.log(response, "response");
      if (!response.data.error) {
        // navigate("/template");
        setOpenModal(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setloading(false);

      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const removeMemberApi = async () => {
    setremovingMember(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/member/remove`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          guest_user_id: selectedMember?.user_id,
          workspace_id: selectedMember?.workspace_id,
        },
      });
      // console.log(response, "response");
      setremovingMember(false);
      if (!response.data.error) {
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        setOpenModalRemove(undefined);
        getMembers();
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setremovingMember(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const getFormattedDate = (date) => {
    const dateString = date;
    const formattedDate = moment(dateString).format("MMMM D, YYYY"); // Format the date
    return formattedDate;
  };
  useEffect(() => {
    getMembers();
  }, []);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      containerRef.current.forEach((ref, index) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setModelopen((prevState) => ({
            ...prevState,
            [index]: false,
          }));
          // setModelopen(false);
        }
      });
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  // console.log(membersList, "membersList...");
  // console.log(membersListTotal, "meme total");
  return (
    <>
      <h6 className="font-bold text-[24px]">Manage Members</h6>
      <div className="flex py-4">
        <div>
          <input
            type="text"
            name="search"
            className="mt-1 w-[400px] rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
            placeholder="Search By Name or Email"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="ms-auto flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
          onClick={() => {
            if (!checkInviteEnabled()) {
              setpopupMessage("Invite a Member")
              setshowModal({
                first: false,
                second: true,
                third: false,
              });
              return;
            }
            setOpenModal("dismissible");
          }}
        >
          <span>Invite Member</span>
          <svg className="icon ms-2 text-[26px]">
            <use href="#icon_arrow"></use>
          </svg>
        </button>
      </div>

      <p className="font-bold text-[16px] py-3">
        Members{" "}
        {
          membersList?.filter(
            (member) =>
              member?.user_workspace?.first_name
                ?.toLowerCase()
                ?.includes(search.toLowerCase()) ||
              member?.user_workspace?.last_name
                ?.toLowerCase()
                ?.includes(search.toLowerCase()) ||
              member?.user_workspace?.email
                ?.toLowerCase()
                ?.includes(search.toLowerCase())
          )?.length
        }
      </p>
      <div className="tbl-scroller">
        <table className="table">
          <thead className="bg-[red]">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody className="tbody-gap">
            <td colSpan="4"></td>
          </tbody>
          <tbody>
            {membersList
              ?.filter(
                (member) =>
                  member?.user_workspace?.first_name
                    ?.toLowerCase()
                    ?.includes(search.toLowerCase()) ||
                  member?.user_workspace?.last_name
                    ?.toLowerCase()
                    ?.includes(search.toLowerCase()) ||
                  member?.user_workspace?.email
                    ?.toLowerCase()
                    ?.includes(search.toLowerCase())
              )
              ?.map((member, member_index) => {
                const index = member_index; // Ensure stable reference for closure
                containerRef.current[index] =
                  containerRef.current[index] || React.createRef();
                return (
                  <tr key={member_index}>
                    <td textAlign="left" style={{ borderRadius: "25px" }}>
                      <div className="text-left ms-5">
                        <button type="button">
                          <div
                            style={{
                              backgroundColor: getBackgroundColor(
                                member?.user_workspace?.first_name?.charAt(0)
                              ),
                            }}
                            className="w-[40px] h-[40px] bg-[#60CFFF] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center me-3"
                          >
                            {getLetters(
                              member?.user_workspace?.first_name +
                                " " +
                                member?.user_workspace?.last_name
                            )}
                          </div>
                        </button>
                        {member?.user_workspace?.first_name}{" "}
                        {member?.user_workspace?.last_name}
                      </div>
                    </td>
                    <td>{member?.user_workspace?.email}</td>
                    <td>
                      <div className="flex justify-center">
                        <Dropdown
                          className="tbl-dropdown"
                          disabled={member?.created_user_id != null}
                          label={getUserType(member?.user_type_id)}
                        >
                          <ul>
                            {membersListTotal?.membertypes
                              ?.filter(
                                (item) =>
                                  item?.user_type_id != member?.user_type_id
                              )
                              ?.map((item) => (
                                <li
                                  onClick={() => {
                                    setselectedAccess({
                                      from: getUserType(member?.user_type_id),
                                      to: item?.member_type,
                                      to_user_type_id: item?.user_type_id,
                                    });
                                    setselectedMember(member);
                                    setOpenModalAreYouSure("dismissible");
                                    console.log(
                                      item?.member_type,
                                      member,
                                      item,
                                      "member type"
                                    );
                                  }}
                                >
                                  <button type="button">
                                    {item?.member_type}
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </Dropdown>
                      </div>
                    </td>
                    <td style={{ borderRadius: "25px" }}>
                      <div className="flex items-center relative">
                        <div className="text-start text-[16px]">
                          <div>
                            <strong>Access Given:</strong>{" "}
                            <span>{getFormattedDate(member?.createdAt)}</span>
                          </div>
                          {/* <div>
                          <strong>Last Active:</strong>{" "}
                          <span>June 5, 2023</span>
                        </div> */}
                        </div>
                        <div className="mx-auto">
                          {!member?.created_user_id && (
                            <button
                              type="button"
                              className="hover:text-[#097BA9]"
                              onClick={() => {
                                setModelopen((prevState) => ({
                                  ...prevState,
                                  [index]: !prevState[index],
                                }));
                                setselectedMember(member);
                              }}
                              ref={containerRef.current[index]}
                            >
                              <svg className="icon pointer-events-none">
                                <use href="#icon_threedot"></use>
                              </svg>
                            </button>
                          )}

                          {/* {console.log(selectedMember, "selectedMember")}
                        {console.log(modelopen, "modelopen")} */}
                          <ul
                            style={{
                              display:
                                modelopen[index] &&
                                selectedMember?.member_index == member_index
                                  ? "block"
                                  : "none",
                            }}
                            className="absolute right-[24%] top-[10px]"
                          >
                            <li>
                              <button
                                type="button"
                                className="text-[#EB5050] shadow bg-[#fff] py-1 px-6 rounded-lg"
                                onClick={() => {
                                  setOpenModalRemove("dismissible");
                                  setModelopen({
                                    ...modelopen,
                                    [index]: false,
                                  });
                                }}
                              >
                                Remove Member
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* Modal Invite */}
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModal === "dismissible"}
        size="lg"
        onClose={() => setOpenModal(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Invite member</h6>
          <p className="font-[400] text-[16px]">
            you are inviting members to{" "}
            {JSON.parse(
              localStorage.getItem("selected_workspace")
            )?.workspace?.workspace_name?.toUpperCase()}
          </p>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="flex justify-center">
            <img src="images/invite.png" className="w-[280px]" alt="..." />
          </div>
          <div className="pb-3">
            <label for="email">Email Address</label>
            <div
              className={`flex flex-wrap gap-2 items-center ${
                emails?.length > 0 && "py-2"
              }`}
            >
              {emails.map((email, index) => (
                <div
                  key={index}
                  className="border rounded-md p-1 flex items-center bg-gray-200"
                >
                  <span>{email}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail(index)}
                    className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    &#x2715;
                  </button>
                </div>
              ))}
            </div>
            <input
              type="email"
              name="email"
              value={currentEmail}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onBlur={() => {
                if (currentEmail == "") {
                  setIsValidEmail(true);
                }
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Invite team members"
              required
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-1">
                Please enter a valid email address
              </p>
            )}
            {currentEmail !== "" && isValidEmail && (
              <p
                className="border rounded-md pt-1 pb-1 pl-2 pr-2 inline-block bg-gray-200 cursor-pointer mt-1"
                onClick={inviteEmail}
              >
                Invite {currentEmail}
              </p>
            )}
          </div>
          <div>
            <label for="role">Roles</label>
            <select
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              name="cars"
              id="point_of_view"
              value={selectedUserTypeId}
              onChange={(e) => setselectedUserTypeId(e.target.value)}
              required
            >
              <option value="">Select Role</option>
              {membersListTotal?.membertypes?.map((item) => (
                <option value={item?.user_type_id}>{item?.member_type}</option>
              ))}
            </select>
          </div>
          <div className="flex pt-4">
            <div className="ms-auto">
              <button
                type="buttonn"
                className="border border-[#2A2A2A] rounded-full px-8 py-2 me-6 hover:text-[red] hover:border-[red]"
                onClick={() => {
                  setEmails([]);
                  setCurrentEmail("");
                  setIsValidEmail(true);
                  setOpenModal(undefined);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="bg-[#85D977] hover:bg-[#55b943] rounded-full text-[#fff] px-8 py-2"
                onClick={() => {
                  if (emails.length == 0||selectedUserTypeId=="") return;
                  if (emails?.length > 0) {
                    submitWorkspaceTeamMembers();
                  }
                }}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div>Inviting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Invite"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Remove */}
      <RemoveMember
        selectedMember={selectedMember}
        documentBodyRef={documentBodyRef}
        openModalRemove={openModalRemove}
        setOpenModalRemove={setOpenModalRemove}
        removeMemberApi={removeMemberApi}
        onClose={() => {
          setremovingMember(false);
          setOpenModalRemove(undefined);
        }}
        removingMember={removingMember}
        from="Workspace"
      />

      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <AreYouSure
        openModal={openModalAreYouSure}
        onClose={() => setOpenModalAreYouSure(undefined)}
        submitAccess={submitAccess}
        submitting={submittingAccess}
        access={selectedAccess}
      />
      <PopupModals showModal={showModal} setshowModal={setshowModal} popupMessage={popupMessage} />
    </>
  );
}

export default Member;
