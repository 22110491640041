import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer } from "flowbite-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useCommonFunctions from "../hooks/common-functions";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import ThreeDotsLoader from "./ThreeDotsLoader";

function Feedback({ isOpen, setIsOpen }) {
  const { getFileIcon } = useCommonFunctions();
  const [feedbackParams, setfeedbackParams] = useState({
    feedback_type: "report_a_problem",
    feedback_subject: "",
    feedback_description: "",
    feedback_file: "",
  });
  const [updating, setupdating] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setfeedbackParams({
      ...feedbackParams,
      [e.target.id]: e.target.value,
    });
  };
  const uploadIconFn = async () => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/feedback`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  console.log(rawFile, "rawfile");
  const getPreviewImage = () => {
    if (rawFile) {
      if (
        getFileIcon(rawFile.name).ext === "jpg" ||
        getFileIcon(rawFile.name).ext === "jpeg" ||
        getFileIcon(rawFile.name).ext === "png"
      ) {
        return previewUrl;
      } else {
        return getFileIcon(rawFile.name).icon;
      }
    } else {
      return null;
    }
  };
  const handleChangeFileFun = (e) => {
    let file = e.target.files[0];

    setrawFile(file);
    e.target.value = "";
  };
  const getDescriptionLable = () => {
    if (feedbackParams.feedback_type === "report_a_problem") {
      return {
        label: "Describe The Issue",
        placeholder: "Explain the issue in detail",
      };
    } else if (feedbackParams.feedback_type === "ask_a_question") {
      return {
        label: "Describe Your Question",
        placeholder: "Explain your question in detail",
      };
    } else {
      return {
        label: "Describe The Feature",
        placeholder: "Explain your feature suggested in details",
      };
    }
  };
  const handleSubmit = async () => {
    setupdating(true);
    try {
      let profile_photo = "";
      if (rawFile) {
        let response = await uploadIconFn();
        console.log(response);
        if (!response.error) {
          profile_photo = response.data;
        } else {
          setopenFailureModal(true);
          setsuccessFailureMessage(response.message);
          setupdating(false);
          setrawFile(null);
          setpreviewUrl(null);
          handleClose();
          return;
        }
      }
      let data = {
        ...feedbackParams,
      };
      data.feedback_file = profile_photo;
      // axios
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/feedback`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      setupdating(false);
      if (!response.data.error) {
        console.log(response.data);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
      handleClose();
      setrawFile(null);
      setpreviewUrl(null);
    } catch (error) {
      setupdating(false);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      handleClose();
      setrawFile(null);
      setpreviewUrl(null);
    }
  };

  const handleClose = () => {
    setfeedbackParams({
      feedback_type: "report_a_problem",
      feedback_subject: "",
      feedback_description: "",
      feedback_file: "",
    });
    setIsOpen(false);
  };
  useEffect(() => {
    if (!rawFile) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  return (
    <>
      {/* <div className="flex min-h-[50vh] items-center justify-center">
            <Button onClick={() => setIsOpen(true)}>Show right drawer</Button>
        </div> */}

      <Drawer
        open={isOpen}
        onClose={handleClose}
        position="right"
        style={{ width: "50%" }}
        className="feedback-aside"
      >
        <Drawer.Header title="Feedback" className="canvas-heading" />
        <Drawer.Items>
          <div className="px-[40px] pt-6">
            <div class="grid grid-cols-3 gap-5">
              <button
                type="button"
                onClick={() => {
                  setfeedbackParams({
                    ...feedbackParams,
                    feedback_type: "report_a_problem",
                  });
                }}
                className={`py-8 rounded-[20px] bg-gradient-to-r ${
                  feedbackParams.feedback_type === "report_a_problem" &&
                  "bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white"
                } hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 shadow-btn`}
              >
                <svg className="icon text-[24px]">
                  <use href="#icon_report"></use>
                </svg>
                <p className="pt-4 font-[600] text-[18px]">Report A Problem</p>
              </button>

              <button
                type="button"
                onClick={() => {
                  setfeedbackParams({
                    ...feedbackParams,
                    feedback_type: "ask_a_question",
                  });
                }}
                className={`py-8 rounded-[20px] bg-gradient-to-r ${
                  feedbackParams.feedback_type === "ask_a_question" &&
                  "bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white"
                } hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 shadow-btn`}
              >
                <svg className="icon text-[24px]">
                  <use href="#icon_ask"></use>
                </svg>
                <p className="pt-4 font-[600] text-[18px]">Ask A Question</p>
              </button>

              <button
                type="button"
                onClick={() => {
                  setfeedbackParams({
                    ...feedbackParams,
                    feedback_type: "suggest_a_feature",
                  });
                }}
                className={`py-8 rounded-[20px] bg-gradient-to-r ${
                  feedbackParams.feedback_type === "suggest_a_feature" &&
                  "bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white"
                } hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 shadow-btn`}
              >
                <svg className="icon text-[24px]">
                  <use href="#icon_feature"></use>
                </svg>
                <p className="pt-4 font-[600] text-[18px]">Suggest A Feature</p>
              </button>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log(feedbackParams);
                handleSubmit();
              }}
            >
              <div className="py-4">
                <label
                  className="block mb-2 font-[600] text-[18px]"
                  htmlFor="feedback_subject"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="feedback_subject"
                  onChange={handleChange}
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Please enter a subject"
                  required
                  value={feedbackParams.feedback_subject}
                />
              </div>

              <div className="pb-4">
                <label
                  className="block mb-2 font-[600] text-[18px]"
                  htmlFor="feedback_description"
                >
                  {getDescriptionLable().label}
                </label>
                <textarea
                  type="text"
                  id="feedback_description"
                  onChange={handleChange}
                  className="w-full h-[140px] rounded-lg font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder={getDescriptionLable().placeholder}
                  required
                  value={feedbackParams.feedback_description}
                />
              </div>
              <div>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                  onChange={handleChangeFileFun}
                />
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer hover:text-[#1A71A7] font-[600] text-[18px]"
                >
                  <svg className="icon me-2 text-[20px]">
                    <use href="#icon_attach"></use>
                  </svg>
                  <span>Attach File</span>
                </label>
              </div>
              {rawFile && (
                <div>
                  <div
                    className="flex flex-col justify-center mt-4 relative"
                    style={{
                      width: "100px",
                    }}
                  >
                    <div className="me-4 ">
                      <img
                        src={getPreviewImage()}
                        alt="preview"
                        style={{ width: "60px", height: "60px" }}
                      />
                    </div>
                    <label
                      htmlFor="file-upload"
                      className="absolute w-[25px] h-[25px] bg-[#5DE56A] bottom-0 right-0 rounded-[50%] text-[white] hover:text-[red] flex items-center justify-center cursor-pointer"
                    >
                      <svg className="icon">
                        <use href="#icon_edit"></use>
                      </svg>
                    </label>
                  </div>
                  <div className="font-[600] text-[18px]">{rawFile.name}</div>
                </div>
              )}
              <div className="py-4">
                <button
                  type="submit"
                  className="py-2 me-8 px-[100px] rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset ring-[none] bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white hover:transition hover:delay-75"
                >
                  <span>
                    {updating ? (
                      <div className="flex items-center">
                        <div>Submitting</div>
                        <div>
                          <ThreeDotsLoader />
                        </div>
                      </div>
                    ) : (
                      <>Submit</>
                    )}
                  </span>
                </button>

                <button
                  type="button"
                  className="py-2 px-[100px] rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
                  onClick={handleClose}
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        </Drawer.Items>
      </Drawer>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default Feedback;
