/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../context/UserContext";
import Joyride from "react-joyride";
import JoyrideComponent from "./JoyrideComponent";

function NavBar2({ title, description }) {
  const context = useContext(UserContext);
  const { allrefs } = context;
  const navbarref = useRef();
  const [steps, setsteps] = useState([]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    console.log(data, "data in joyride callback");
    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prevIndex) => prevIndex + 1); // Increment stepIndex on "next"
      } else if (action === "prev") {
        setStepIndex((prevIndex) => prevIndex - 1); // Decrement stepIndex on "back"
      }
    }
    // Handle when Joyride is finished or skipped
    if (status === "finished" || status === "skipped" || action === "close") {
      setRun(false); // Stop the tour
      setStepIndex(0); // Optionally reset the step index to 0
      if (allrefs?.asideRef?.current) {
        setTimeout(() => {
          allrefs.asideRef.current.style.overflowY = "auto";
        }, 500);
      }
    }
  };
  useEffect(() => {
    const { trainingJson, settrainingJson } = allrefs;
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    if (selectedFile && selectedFile?.contents) {
      return;
    }
    if (Object.keys(trainingJson || {}).length > 0) {
      let stepsArray = [];
      let keys = Object.keys(trainingJson);
      let { templates, youtube, chat, training } = trainingJson;
      let { aside, content_generated } = templates;
      if (!content_generated && navbarref?.current) {
        stepsArray.push({
          target: navbarref?.current,
          content:
            "Please fill the required field to generate your first content on Vison AI",
          disableBeacon: true,
        });
        stepsArray.push({
          target: allrefs?.buttonref?.current,
          content: "Click on this button to generate your first content",
          disableBeacon: true,
        });
      }
      setsteps(stepsArray);
      setRun(stepsArray.length > 0);
    }
  }, [allrefs?.trainingJson]);
  return (
    <>
      <header className="border-b bg-[#fff]">
        <div ref={navbarref}>
          <div className="flex w-full">
            <div className="font-bold text-[18px] flex items-center">
              {title}
              <svg className="icon ms-6">
                <use href="#icon_starborder"></use>
              </svg>
            </div>
          </div>
          <p>{description}</p>
        </div>
      </header>
      <JoyrideComponent
        steps={steps}
        stepIndex={stepIndex}
        handleJoyrideCallback={handleJoyrideCallback}
        run={run}
        setStepIndex={setStepIndex}
      />
    </>
  );
}

export default NavBar2;
