/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../components/SuccessModal';
import FailureModal from '../components/FailureModal';
import LoaderNew from '../components/LoaderNew';

function PaymentSuccess() {
  const navigate = useNavigate();
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const upgradeSubscription = async (subscription) => {  
    let details = JSON.parse(localStorage.getItem("subscription_upgrade_details"));
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/payment/success`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          session_id: details.session_id,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        // setsuccessFailureMessage(response.data.message);
        let plan_id=response.data.plan_id;
        let subscription_id=response.data.subscription_id;
        let login_data=JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.subscription_id=subscription_id;
        login_data.resp_user.plan_id=plan_id;
        login_data.resp_user.usersetting=response.data.usersetting;
        localStorage.setItem("login_data", JSON.stringify(login_data));
        setTimeout(() => {
          navigate("/subscription");
        }, 500);
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
        setTimeout(() => {
          navigate("/subscription");
        }, 2000);
      }
    }catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  }
  useEffect(() => {
    upgradeSubscription();
  }, []);
  return (
    <>
      <LoaderNew />
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  )
}

export default PaymentSuccess
