import React, { useEffect, useState } from "react";
import useCommonApi from "../hooks/common-apis";
import useCommonFunctions from "../hooks/common-functions";
import { useNavigate } from "react-router-dom";
import PopupModals from "../components/PopupModals";

function Myworkspace() {
  const { getWorkspaces } = useCommonApi();
  const {
    getLetters,
    getBackgroundColor,
    checkWorkspaceEnabled,
  } = useCommonFunctions();
  const navigate = useNavigate();
  const [workspaceList, setworkspaceList] = useState([]);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [popupMessage, setpopupMessage] = useState("");
  const getWorkspaceGraphql = async () => {
    try {
      let workspaces = await getWorkspaces();
      // console.log(workspaces,"works")
      // Handle the response data as needed
      setworkspaceList(workspaces);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getWorkspaceGraphql();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h6 className="font-bold text-[24px] mb-8">My Workspaces</h6>

      <div class="w-full">
        <div class="flex flex-wrap -mx-6 mb-6">
          {workspaceList?.map((workspace, index) => (
            <div class="w-full md:w-1/6 px-6 mb-6 md:mb-0">
              <button
                type="button"
                className="text-center hover:text-[#1D70A6]"
                onClick={() => {
                  localStorage.setItem(
                    "selected_workspace",
                    JSON.stringify(workspace)
                  );
                  navigate("/template");
                }}
              >
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="flex items-center hover:text-[#1A71A7]"
                  >
                    <div
                      style={{
                        backgroundColor: getBackgroundColor(
                          workspace?.workspace?.workspace_name?.charAt(0) || ""
                        ),
                      }}
                      className="w-[50px] h-[50px] text-[#fff] font-semibold text-[20px] rounded-full flex items-center justify-center "
                    >
                      {getLetters(workspace?.workspace?.workspace_name)}
                    </div>
                  </button>
                </div>
                <p className="pt-4">
                  {workspace?.workspace?.workspace_name}'s workspace
                </p>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center">
        <button
          type="button"
          className="flex items-center p-[10px] rounded-full bg-[#EEF5FF] font-semibold text-[22px] hover:bg-[#dde4ed] transition duration-700"
          onClick={() => {
            if (!checkWorkspaceEnabled()) {
              setpopupMessage("Add New Workspace");
              setshowModal({
                first:false,
                second:true,
                third:false,
              })
              return;
            } else {
              navigate("/add-workspace");
            }
          }}
        >
          <div className="flex items-center justify-center rounded-[25px] w-[50px] h-[50px] text-[#fff] bg-gradient-to-r from-[#097BA9] to-[#9B3785] me-3">
            <svg className="icon">
              <use href="#icon_plus"></use>
            </svg>
          </div>
          Add New Workspace
        </button>
      </div>
      <PopupModals showModal={showModal} setshowModal={setshowModal} popupMessage={popupMessage} />
    </>
  );
}

export default Myworkspace;
