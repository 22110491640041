import { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../context/UserContext";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import axios from "axios";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import { useNavigate } from "react-router-dom";

function AccountSetting() {
  const navigate = useNavigate();
  const [editCancel, setEditCancel] = useState(false);
  const context = useContext(UserContext);
  const { setselectedWorkspace } = context;
  // console.log(selectedWorkspace, "selected work space...");
  const [workspaceDetail, setworkspaceDetail] = useState({
    workspace_id: JSON.parse(localStorage.getItem("selected_workspace"))
      ?.workspace?.workspace_id,
    workspace_name: JSON.parse(localStorage.getItem("selected_workspace"))
      ?.workspace?.workspace_name,
    workspace_avtar: JSON.parse(localStorage.getItem("selected_workspace"))
      ?.workspace?.workspace_avtar,
    workspace_icon: JSON.parse(localStorage.getItem("selected_workspace"))
      ?.workspace?.workspace_icon,
    invitation_emails: [],
  });
  const [sendingDetails, setsendingDetails] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);

  const profilePicRef = useRef(null);
  const getImageIcon = () => {
    let imagePath = workspaceDetail?.workspace_avtar;
    if (!imagePath) return null;
    // let baseUrl = process.env.REACT_APP_BASE_SIGNIN_URL;

    // Remove "public" from the imagePath
    // let modifiedPath = imagePath?.replace("public/", "");

    // Concatenate the baseUrl and modifiedPath
    // let imageUrl = `${baseUrl}/${modifiedPath}`;
    return imagePath;
  };
  const uploadIconFn = async () => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/icons`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.response.data.message,
      };
    }
  };
  const handleChangeFileFun = (e) => {
    if (!editCancel) {
      return;
    }
    let file = e.target.files[0];

    setrawFile(file);
    e.target.files = null;
  };
  const updateWorkspace = async () => {
    setsendingDetails({
      loading: true,
      error: false,
      message: "",
    });
    try {
      let profile_photo = "";
      if (rawFile) {
        let response = await uploadIconFn();
        console.log(response);
        if (!response.data.error) {
          profile_photo = response.data;
        }else{
          setsendingDetails({
            loading: false,
            error: false,
            message: "",
          });
          setopenFailureModal("dismissible");
          setsuccessFailureMessage(response.data.message);
          return;
        }
      } else {
        profile_photo = JSON.parse(localStorage.getItem("selected_workspace"))
          ?.workspace?.workspace_avtar;
      }
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          ...workspaceDetail,
          workspace_avtar: profile_photo,
        },
      });

      if (!response.data.error) {
        setsendingDetails({
          loading: false,
          error: false,
          message: response.data.message,
        });
        setworkspaceDetail({
          ...workspaceDetail,
          workspace_avtar: profile_photo,
        });
        let wsl = JSON.parse(localStorage.getItem("selected_workspace"));
        setselectedWorkspace({
          ...wsl,
          workspace: {
            ...workspaceDetail,
            workspace_avtar: profile_photo,
          },
        });
        localStorage.setItem(
          "selected_workspace",
          JSON.stringify({
            ...wsl,
            workspace: {
              ...workspaceDetail,
              workspace_avtar: profile_photo,
            },
          })
        );
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsendingDetails({
          loading: false,
          error: true,
          message: response.data.message,
        });
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setsendingDetails({
        loading: false,
        error: false,
        message: error?.message,
      });
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
    }
  };
  useEffect(() => {
    if (!rawFile) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    setpreviewUrl(getImageIcon());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(previewUrl, "preview url");
  // console.log(workspaceDetail, "workspace detail");
  // console.log(rawFile, "raw file");

  return (
    <>
      <div className="flex">
        <div className="ms-auto">
          {editCancel ? (
            <div className="flex items-center">
              <button
                type="button"
                className="py-2 w-[140px] rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 me-[20px]"
                onClick={() => setEditCancel(false)}
              >
                Discard
              </button>

              <button
                type="button"
                className="flex items-center text-center justify-center py-2 w-[140px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                disabled={sendingDetails?.loading}
                onClick={() => {
                  updateWorkspace();
                }}
              >
                <span>
                  {sendingDetails?.loading ? (
                    <div className="flex items-center">
                      <div>Saving</div>
                      <div>
                        <ThreeDotsLoader />
                      </div>
                    </div>
                  ) : (
                    <>Save</>
                  )}
                </span>
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="flex items-center py-2 w-[140px] text-center justify-center rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => setEditCancel(!editCancel)}
            >
              <span>Edit</span>
            </button>
          )}
        </div>
      </div>
      <h6 className="font-bold text-[24px]">Workspace Settings</h6>
      <p className="font-semibold text-[22px] py-6">Workspace Name</p>
      <div className="w-[60%]">
        <input
          type="text"
          className="w-full rounded-full mb-[20px]"
          value={workspaceDetail?.workspace_name}
          placeholder="Your Workspace"
          disabled={!editCancel}
          onChange={(e) =>
            setworkspaceDetail({
              ...workspaceDetail,
              workspace_name: e.target.value,
            })
          }
        />
        <div className="w-[170px]">
          <span className="font-semibold text-[22px]">Workspace Logo</span>
          <div className="flex justify-center my-6">
            <div className="">
              {/* <img
                src={getImageIcon()}
                className="w-full object-cover"
                alt="..."
              /> */}
              <div className="flex flex-column items-center">
                <div>
                  <form
                    id="file-upload-form"
                    className="uploader w-[120px] h-[120px]"
                    style={{ width: "120px", height: "120px" }}
                  >
                    <input
                      id="file-upload"
                      type="file"
                      name="fileUpload"
                      accept="image/*"
                      disabled={!editCancel}
                      onChange={(e) => {
                        handleChangeFileFun(e);
                      }}
                    />

                    <label
                      className="flex justify-center items-center "
                      htmlFor="file-upload"
                      id="file-drag"
                      style={{
                        width: "120px",
                        height: "120px",
                        background: previewUrl && "none",
                      }}
                      ref={profilePicRef}
                    >
                      <div id="start">
                        {previewUrl ? (
                          <div className="relative">
                            <div className="rounded-[50%] w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                              <img
                                src={previewUrl}
                                className="w-full object-cover"
                                alt="..."
                              />
                            </div>
                            {editCancel && (
                              <button
                                type="button"
                                className="absolute w-[25px] h-[25px] bg-[#5DE56A] right-[5px] bottom-[5px] rounded-[50%] text-[white] hover:text-[red]"
                                onClick={() => profilePicRef?.current?.click()}
                              >
                                <svg className="icon">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>
                            )}
                          </div>
                        ) : (
                          <>
                            <svg className="icon text-[24px]">
                              <use href="#icon_upload"></use>
                            </svg>
                            <div id="notimage" className="text-sm">
                              Please select an image
                            </div>
                          </>
                        )}
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages"></div>
                      </div>
                    </label>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <p className="font-semibold text-center">
            {
              JSON.parse(localStorage.getItem("selected_workspace"))?.workspace
                ?.workspace_name
            }
          </p>
        </div>
      </div>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default AccountSetting;
