/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import { initialState, reducer } from "../components/ReducerComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import axios from "axios";

function Login() {
  const [loadingState, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [signUpdata, setsignUpdata] = useState({
    email: "",
  });
  const location = useLocation();
  // console.log(location, "location...");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setsignUpdata({ ...signUpdata, [id]: value });
  };
  const loginButton = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOADING" });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: signUpdata.email,
        },
      });
      if (response.data.success) {
        navigate("/login-otp", {
          replace: true,
          state: {
            email: signUpdata.email,
            from: location?.state ? location?.state?.from : "login",
            url: location?.state ? location?.state?.url : "template",
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          data: {
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("login_token")) {
      navigate("/template");
    }
  }, []);

  return (
    <>
      <div className="h-screen">
        <img
          className="loginfram w-full h-full absolute z-[-10]"
          src="images/frame.png"
          alt="..."
        />
        <div class="flex flex-wrap -mx-6l h-screen">
          <div class="w-full md:w-1/2 px-[80px] flex items-center justify-center">
            <div className="w-[420px]">
              <img className="w-full" src="images/signin-left.png" alt="..." />
            </div>
          </div>
          <div class="w-full md:w-1/2 px-[80px] flex items-center">
            <form className="w-full" onSubmit={loginButton}>
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => window.open(process.env.REACT_APP_LANDING_URL)}
              >
                {/* <img src="images/logo.png" alt="..." /> */}
                <img src="images/VisonWithOReplaced.png" width={150} height={50} alt="..." />
              </div>
              <h5 className="font-extrabold text-[40px] py-6">Sign In</h5>
              <label
                className="block mb-2 font-semibold text-[20px]"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Abc@gmail.com"
                required
                onChange={handleChange}
                value={signUpdata.email}
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+" // Email validation
                title="Please enter a valid email address"
              />
              {loadingState.error && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.errorMessage}
                </div>
              )}
              <button
                type="submit"
                disabled={loadingState.loading}
                className="w-full mt-2 justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              >
                {loadingState.loading ? (
                  <div className="flex justify-between items-center">
                    <div></div>
                    <div>Logging In</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
              <div
                className="py-6 text-center font-bold text-[18px] cursor-pointer"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <span>Not A Member </span>
               {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7]">
                  Sign Up
                </a>
              </div>
              {/* <hr /> */}
              <h3 className="title-around-span mb-3">
                <span>Or</span>
              </h3>

              <button
                type="button"
                className="w-full my-6 mt-2 justify-center py-2 px-[20px] rounded-[20px] font-semibold border border-[#D73939] text-[#D73939] relative hover:bg-[#D73939] hover:text-[#ffffff] transition duration-700"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BASE_SIGNIN_URL}/auth/google`,
                    "_self"
                  );
                }}
              >
                <span className="absolute left-[20px] top-[6px]">
                  <svg className="icon text-[28px]">
                    <use href="#icon_google"></use>
                  </svg>
                </span>
                Continue With Google
              </button>

            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
