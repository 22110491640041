import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import { useNavigate } from "react-router-dom";
import { languages } from "../data/languages";

function Mysetting() {
  const navigate = useNavigate();
  const [editCancel, setEditCancel] = useState(false);
  const [profileSettings, setprofileSettings] = useState({});
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const profilePicRef = useRef(null);
  const [updating, setupdating] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  // console.log(profileSettings, "settings...");
  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileSettings({ ...profileSettings, [id]: value });
  };
  const uploadIconFn = async () => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/userprofiles`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  const submitProfileDetails = async () => {
    setupdating(true);
    try {
      let profile_photo = "";
      if (rawFile) {
        let response = await uploadIconFn(rawFile, "userprofiles");
        console.log(response);
        if (!response.error) {
          profile_photo = response.data;
        } else {
          setopenFailureModal(true);
          setsuccessFailureMessage(response.message);
          setupdating(false);
          return;
        }
      }
      let data = {
        first_name: profileSettings?.first_name,
        last_name: profileSettings?.last_name,
        phone: profileSettings?.phone,
        language: profileSettings?.language,
      };
      if (rawFile) {
        data.profile_photo = profile_photo;
      }
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/profile/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      setupdating(false);
      console.log(response, "response");
      if (!response.data.error) {
        let details = JSON.parse(localStorage.getItem("login_data"));
        details.resp_user.first_name = profileSettings?.first_name;
        details.resp_user.last_name = profileSettings?.last_name;
        details.resp_user.phone = profileSettings?.phone;
        details.resp_user.language = profileSettings?.language;
        if (profile_photo !== "") {
          details.resp_user.profile_photo = profile_photo;
        }
        localStorage.setItem("login_data", JSON.stringify(details));
        setEditCancel(false);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setupdating(false);
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const handleChangeFileFun = (e) => {
    if (!editCancel) {
      return;
    }
    let file = e.target.files[0];

    setrawFile(file);
    e.target.files = null;
  };
  useEffect(() => {
    let details = JSON.parse(localStorage.getItem("login_data"));
    if (!details?.resp_user?.language) {
      details.resp_user.language = "English";
    }
    let profile_photo = details?.resp_user?.profile_photo;
    // console.log(details,"details...")
    if (profile_photo) {
      setpreviewUrl(profile_photo);
    } else {
      setpreviewUrl(null);
    }
    setprofileSettings(details?.resp_user);
  }, []);
  useEffect(() => {
    if (!rawFile) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  // console.log(previewUrl, "preview url");
  return (
    <>
      <h6 className="font-bold text-[24px] mb-8">My Settings</h6>
      <div className="flex items-center pb-4">
        <div className="flex flex-column items-center">
          <div>
            <form
              id="file-upload-form"
              className="uploader w-[120px] h-[120px]"
              style={{ width: "120px", height: "120px" }}
            >
              <input
                id="file-upload"
                type="file"
                name="fileUpload"
                accept="image/*"
                disabled={!editCancel}
                onChange={(e) => {
                  if (!editCancel) {
                    return;
                  }
                  handleChangeFileFun(e);
                }}
              />

              <label
                className="flex justify-center items-center "
                htmlFor="file-upload"
                id="file-drag"
                style={{
                  width: "120px",
                  height: "120px",
                  background: previewUrl && "none",
                }}
                onClick={() => {
                  if (!editCancel) {
                    return;
                  }
                }}
                ref={profilePicRef}
              >
                <div id="start">
                  {previewUrl ? (
                    <div className="relative">
                      <div className="rounded-[50%] w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                        <img
                          src={previewUrl}
                          className="w-full object-cover"
                          alt="..."
                        />
                      </div>
                      {editCancel && (
                        <button
                          type="button"
                          className="absolute w-[25px] h-[25px] bg-[#5DE56A] right-[5px] bottom-[5px] rounded-[50%] text-[white] hover:text-[red]"
                          onClick={() => profilePicRef?.current?.click()}
                        >
                          <svg className="icon">
                            <use href="#icon_edit"></use>
                          </svg>
                        </button>
                      )}
                    </div>
                  ) : (
                    <>
                      <svg className="icon text-[24px]">
                        <use href="#icon_upload"></use>
                      </svg>
                      <div id="notimage" className="text-sm">
                        Please select an image
                      </div>
                    </>
                  )}
                </div>
                <div id="response" className="hidden">
                  <div id="messages"></div>
                </div>
              </label>
            </form>
          </div>
        </div>

        <div className="ms-auto">
          {editCancel ? (
            <button
              type="button"
              className="underline font-semibold text-[20px] hover:text-[#1A71A7]"
              onClick={() => setEditCancel(false)}
            >
              Cancel
            </button>
          ) : (
            <button
              type="button"
              className="underline font-semibold text-[20px] hover:text-[#1A71A7]"
              onClick={() => setEditCancel(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>

      <form class="w-full">
        <div class="flex flex-wrap -mx-6 mb-6">
          <div class="w-full md:w-1/2 px-6 mb-6 md:mb-0">
            <label
              class="block mb-2 font-semibold text-[22px]"
              for="first_name"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Your First Name"
              disabled={!editCancel}
              value={profileSettings?.first_name}
              onChange={handleChange}
            />
          </div>
          <div class="w-full md:w-1/2 px-6">
            <label class="block mb-2 font-semibold text-[22px]" for="last_name">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Your Last Name"
              disabled={!editCancel}
              value={profileSettings?.last_name}
              onChange={handleChange}
            />
          </div>
          <div class="w-full md:w-1/2 px-6">
            <label class="block mb-2 font-semibold text-[22px]" for="phone">
              Phone Number
            </label>
            <input
              type="number"
              id="phone"
              className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Your Phone Number"
              disabled={!editCancel}
              value={profileSettings?.phone}
              onChange={handleChange}
            />
          </div>
          <div class="w-full md:w-1/2 px-6">
            <label class="block mb-2 font-semibold text-[22px]" for="last-name">
              Email
            </label>
            <input
              type="email"
              className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
              placeholder="Enter Your Email Id"
              disabled
              value={profileSettings?.email}
            />
          </div>
          <div class="w-full md:w-1/2 px-6">
            <label class="block mb-2 font-semibold text-[22px]" for="language">
              Default Data Generation Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={profileSettings?.language}
              disabled={!editCancel}
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>

      <div className="flex pb-4">
        <div className="ms-auto">
          {editCancel ? (
            <div className="flex items-center">
              <button
                type="button"
                className="py-2 w-[140px] rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 me-[20px]"
                onClick={() => setEditCancel(false)}
              >
                Discard
              </button>

              <button
                type="button"
                className="flex items-center text-center justify-center py-2 w-[140px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                onClick={() => submitProfileDetails()}
              >
                <span>
                  {updating ? (
                    <div className="flex items-center">
                      <div>Saving</div>
                      <div>
                        <ThreeDotsLoader />
                      </div>
                    </div>
                  ) : (
                    <>Save</>
                  )}
                </span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default Mysetting;
