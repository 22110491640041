/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UserContext from "../context/UserContext";
import {
  allTemplates,
  templatesAndNavigation,
} from "../constants/TemplateAndNavigation";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useCommonApi from "../hooks/common-apis";
import { Button, Tooltip, Modal, TextInput, Accordion } from "flowbite-react";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import DeletePopup from "../components/DeletePopup";
import axios, { all } from "axios";
import Loader_new from "../components/LoaderNew";
import AsideBar from "../components/AsideBar";
import NavBar from "../components/NavBar";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import TourComponent from "../components/TourComponent";
import { memo } from "react";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import JoyrideComponent from "../components/JoyrideComponent";
import useCommonFunctions from "../hooks/common-functions";
const Template = memo(function Template() {
  const context = useContext(UserContext);
  const addToFolderRef = useRef(null);
  const [addFolderCreateModal, setaddFolderCreateModal] = useState();
  const [folders, setfolders] = useState([]);
  const [recentFiles, setrecentFiles] = useState([]);
  const params = useParams();
  const { space_id, folder_id, space_name, folder_name } = params;
  const [selectedFolder, setselectedFolder] = useState({});
  const {
    setselectedWorkspace,
    recentFilesList,
    setrecentFilesList,
    choosedTemplate,
    setchoosedTemplate,
    openModal,
    setOpenModal,
    setselectedFile,
    userSpaces,
    openFolderCreateModal,
    setopenFolderCreateModal,
    selectedFolderStructure,
    setselectedFolderStructure,
    selectedNestedItem,
    setselectedNestedItem,
    selectedWorkspace,
    setselectedContentSettings,
    setworkspaceList,
    savingFileData,
    setsavingFileData,
    allrefs,
    chatfilesFetched,
    chatCalledOnce,
    trainingHistory,
    trainingHistoryFetched,
  } = context;
  const selectedContentSettings = JSON.parse(
    localStorage.getItem("selectedContentSettings")
  );
  const location = useLocation();
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const [addtofolder, setaddtofolder] = useState({
    space_id: "",
    folder_id: "",
    error: "",
    loading: false,
    content_title: "",
  });
  const [threeSteps, setthreeSteps] = useState({
    first: false,
    second: false,
    three: false,
  });
  const [recentFilesFetched, setrecentFilesFetched] = useState(false);
  const [findFolders, setfindFolders] = useState([]);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [openModalMobile, setopenModalMobile] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const templateRef = useRef();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // const steps = [
  //   {
  //     target: allrefs?.templates?.current,
  //     content: "This is my first step!",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: allrefs?.totalTemplatesRef?.current,
  //     content: "This is my second step!",
  //     disableBeacon: true,
  //   },
  //   {
  //     target:allrefs?.youtube?.current,
  //     content: "This is my third step!",
  //   },
  //   // {
  //   //   target: allrefs?.chat?.current,
  //   //   content: "This is my fourth step!",
  //   // },
  //   // {
  //   //   target: allrefs?.training?.current,
  //   //   content: "This is my fifth step!",
  //   // },
  //   // {
  //   //   target: allrefs?.space?.current,
  //   //   content: "This is my sixth step!",
  //   // },
  //   // {
  //   //   target: allrefs?.invite?.current,
  //   //   content: "This is my seventh step!",
  //   // },
  //   // {
  //   //   target:allrefs?.workspace?.current,
  //   //   content: "This is my eighth step!",
  //   // },
  //   // {
  //   //   target:allrefs?.subscription?.current,
  //   //   content: "This is my ninth step!",
  //   // },
  //   // {
  //   //   target: allrefs?.generate?.current,
  //   //   content: "This is my tenth step!",
  //   // },
  //   // {
  //   //   target: allrefs?.feedback?.current,
  //   //   content: "This is my eleventh step!",
  //   // },
  // ];
  const [steps, setsteps] = useState([]);
  const [currentTraining, setcurrentTraining] = useState("");

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: steps,
  };
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    console.log(data, "data in joyride callback");
    if (action === "next" && index === 0 && currentTraining === "templates") {
      console.log("next clicked template ref");
      allrefs?.setIsGlowing(true);
      setRun(false);
      allrefs?.templates?.current?.click();
      setTimeout(() => {
        setsteps([
          {
            target: allrefs?.templates?.current,
            content: "Generate content in 3 simple steps",
            disableBeacon: true,
          },
          {
            target: allrefs?.totalTemplatesRef?.current,
            content: `Choose a template, to start with we would recommend to go with "One Step Blog"`,
            disableBeacon: true,
          },
        ]);
        setRun(true);
        setStepIndex(1);
      }, 250);
      setTimeout(() => {
        allrefs?.setIsGlowing(false);
      }, 2500);
    } else if (
      action === "next" &&
      index === 0 &&
      currentTraining === "youtube"
    ) {
      localStorage.removeItem("chat_file");
      localStorage.setItem("chat_details", JSON.stringify([]));
      navigate("/template/youtube-chat");
    } else if (action === "next" && index === 0 && currentTraining === "chat") {
      localStorage.removeItem("chat_file");
      localStorage.setItem("chat_details", JSON.stringify([]));
      navigate("/template/chat");
    } else if (
      +index === 0 &&
      action === "next" &&
      currentTraining === "training"
    ) {
      navigate("/model-training");
    }
    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prevIndex) => prevIndex + 1); // Increment stepIndex on "next"
      } else if (action === "prev") {
        setStepIndex((prevIndex) => prevIndex - 1); // Decrement stepIndex on "back"
      }
    }
    // Handle when Joyride is finished or skipped
    if (status === "finished" || status === "skipped" || action === "close") {
      setRun(false); // Stop the tour
      setStepIndex(0); // Optionally reset the step index to 0
      if(allrefs?.asideRef?.current){
        setTimeout(()=>{
          allrefs.asideRef.current.style.overflowY = "auto";
        }, 500)
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    // Conditionally reset folder_id to an empty string when id is "space_id"
    const updatedFolderValue = id === "space_id" ? "" : value;

    setaddtofolder({
      ...addtofolder,
      [id]: value,
      folder_id: updatedFolderValue,
    });
  };
  // console.log(addtofolder, "add to folder");
  // console.log(typeof location.pathname, "location.pathname");
  const {
    getRecentFiles,
    getSpaceFolderList,
    callUpdateToFileApi,
    deleteFileApi,
    assignToFolderApi,
    getSpaceList,
    getWorkspaces,
    getSpaceFileList,
  } = useCommonApi();
  const { getPlanDetails } = useCommonFunctions();
  const [viewFilesClicked, setviewFilesClicked] = useState(false);
  const [browseAllClicked, setbrowseAllClicked] = useState(false);
  const navigate = useNavigate();
  // console.log(openFolderCreateModal, "openFolderCreateModal...");
  // console.log(recentFilesList, "recentFilesList");
  const getRecentFiles1 = () => {
    if (viewFilesClicked) {
      return recentFiles;
    } else {
      return recentFiles?.slice(0, 3);
    }
  };
  const getAllTemplates = () => {
    if (browseAllClicked) {
      return allTemplates;
    } else {
      return allTemplates?.slice(0, 3);
    }
  };
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setOpenModalDelete(undefined);
    setopenFailureModal(undefined);
  };
  // Function to open the modal
  const openModalHandlerDelete = () => {
    setOpenModalDelete("dismissible");
  };
  // Function to close the modal
  const closeModalHandlerDelete = () => {
    setOpenModalDelete(undefined);
  };

  const deleteFile = async () => {
    try {
      setdeleting(true);
      let response = await deleteFileApi(selectedFile?.id, "file");
      setdeleting(false);
      if (!response?.error) {
        closeModalHandlerDelete();
        setsuccessFailureMessage(response.message);
        setopenSuccessModal("dismissible");
        let result = await getRecentFiles();
        setrecentFiles(result);
      } else {
        setopenFailureModal("dismissible");
        setsuccessFailureMessage(response?.message);
      }
    } catch (error) {
      setdeleting(false);
      console.log(error, "error");
      if (error?.response?.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setopenFailureModal("dismissible");
      setsuccessFailureMessage(error?.response?.data?.message);
    }
  };
  const assignToFolder = async () => {
    try {
      setaddtofolder({ ...addtofolder, loading: true, error: null });

      let response = await assignToFolderApi(
        selectedFile?.id,
        addtofolder?.space_id,
        addtofolder?.folder_id,
        addtofolder?.content_title
      );

      if (!response?.error) {
        setaddtofolder({ ...addtofolder, loading: false, error: null });
        let result = await getRecentFiles();
        setrecentFiles(result);
        setaddFolderCreateModal(undefined);
        setopenSuccessModal("dismissible");
        setsuccessFailureMessage(response.message);
      } else {
        setaddtofolder({
          ...addtofolder,
          loading: false,
          error: response?.message,
        });
        setopenFailureModal("dismissible");
        setsuccessFailureMessage(response?.message);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setopenFailureModal("dismissible");
      setsuccessFailureMessage(error?.response?.data?.message);
      console.log(error);
    }
  };
  const getFolders = async (space_id) => {
    // console.log("calling in template.js");
    let data = await getSpaceFolderList(space_id);
    setfindFolders(data);
    // console.log(data, "folders data in template.js");
  };
  useEffect(() => {
    addToFolderRef.current = document.body;
  }, []);
  useEffect(() => {
    if (addtofolder?.space_id != "") {
      getFolders(addtofolder?.space_id);
    }
  }, [addtofolder?.space_id]);
  // console.log(findFolders, "find folders...");
  useEffect(() => {
    if (addtofolder?.folder_id == "create_folder") {
      setselectedFolderStructure({
        type: "folder",
        value: "",
        title: "Folder Name",
        placeholder: "Enter your folder name",
        mainHeading: "Create Folder",
        loading: false,
        error: null,
      });
      setselectedNestedItem({
        ...selectedNestedItem,
        space_id: addtofolder?.space_id,
        level: "1",
      });
      setopenFolderCreateModal("dismissible");
    }
  }, [addtofolder?.folder_id]);
  useEffect(() => {
    if (addtofolder?.space_id == "create_space") {
      setselectedFolderStructure({
        type: "space",
        value: "",
        title: "Space Name",
        placeholder: "Enter your space name",
        mainHeading: "Create Space",
        loading: false,
        error: null,
      });

      setopenFolderCreateModal("dismissible");
    }
  }, [addtofolder?.space_id]);
  useEffect(() => {
    if (!openFolderCreateModal) {
      setaddtofolder({
        ...addtofolder,
        folder_id: "",
      });
      if (addtofolder?.space_id != "") {
        getFolders(addtofolder?.space_id);
      }
      // if (selectedWorkspace) {
      //   getSpaceList();
      // }
    }
  }, [openFolderCreateModal]);
  useEffect(() => {
    if (recentFilesList?.length > 0) {
      setthreeSteps({
        ...threeSteps,
        first: true,
      });
    } else {
      setthreeSteps({
        ...threeSteps,
        first: false,
      });
    }
  }, [recentFilesList?.length]);
  const getWidth = () => {
    let divideBy = 100;
    let { first, second, third } = threeSteps;
    let count = 0;
    if (recentFilesList?.length > 0) {
      count++;
    }
    if (checkStepTwo()) {
      count++;
    }
    if (checkStepThree()) {
      count++;
    }
    if (count == 3) {
      divideBy = 1;
    } else if (count == 2) {
      divideBy = 2;
    } else if (count == 1) {
      divideBy = 3;
    } else {
      divideBy = 100;
    }

    let perc = 100 / divideBy;
    return `${perc}%`;
  };

  const stepsCompleted = () => {
    let { first, second, third } = threeSteps;
    let count = 0;
    if (recentFilesList?.length > 0) {
      count++;
    }
    if (checkStepTwo()) {
      count++;
    }
    if (checkStepThree()) {
      count++;
    }
    if (count == 3) {
      return 3;
    } else if (count == 2) {
      return 2;
    } else if (count == 1) {
      return 1;
    } else {
      return 0;
    }
  };

  const checkStepTwo = () => {
    for (let i = 0; i < recentFilesList?.length; i++) {
      const element = recentFilesList[i];
      if (element?.content_template && element?.content_template !== "") {
        return true;
      }
    }
    return false;
  };
  const checkStepThree = () => {
    for (let i = 0; i < recentFilesList?.length; i++) {
      const element = recentFilesList[i];
      if (element?.contents && element?.contents !== "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (selectedWorkspace && selectedWorkspace.workspace_id) {
      if (location.pathname.includes("folders")) {
        getFolders(parseInt(space_id));
      }
    };

    fetchData();
  }, [selectedWorkspace, location.pathname]);

  useEffect(() => {
    if (savingFileData) {
      return;
    }
    const fetchData = async () => {
      try {
        setrecentFilesFetched(false);
        // console.log("calling from here");
        let result = await getRecentFiles();
        // // console.log(result, "recent files");
        setrecentFiles(result);
        setrecentFilesFetched(true);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching recent files:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace?.workspace_id, savingFileData]);

  // console.log(recentFilesFetched, "recent files fetched");
  console.log(savingFileData, "saving file data");
  // useEffect(() => {
  //   getWorkspaceGraphql();
  // }, []);

  // console.log(selectedFile, "selected file");

  // console.log(userSpaces, "user spaces in template file");
  const renderFn = () => {
    if (location.pathname.includes("folders")) {
    } else if (location.pathname.includes("files")) {
    } else {
    }
  };
  const getFoldersMap = () => {
    let folders = [];
    for (let i = 0; i < userSpaces.length; i++) {
      let space = userSpaces[i];
      if (space.space_id == space_id) {
        folders = space?.submenu;
        break;
      }
    }
    return folders;
  };
  const getFoldersFilesMap = () => {
    let files = [];
    for (let i = 0; i < userSpaces.length; i++) {
      let space = userSpaces[i];
      if (space.space_id == space_id) {
        let folders = space?.submenu;
        for (let j = 0; j < folders.length; j++) {
          let folder = folders[j];
          if (folder.folder_id == folder_id) {
            files = folder?.submenu;
            break;
          }
        }
      }
    }
    return files;
  };
  useEffect(() => {
    // Check if the user is on a mobile device
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      setopenModalMobile(true);
      return;
    } else {
      setopenModalMobile(false);
    }
    if (!recentFilesFetched || !chatfilesFetched) {
      return;
    }
    if (getPlanDetails().planId == 3 && !trainingHistoryFetched) {
      return;
    }
    const { trainingJson, settrainingJson } = allrefs;
    if (Object.keys(trainingJson || {}).length > 0) {
      let stepsArray = [];
      let keys = Object.keys(trainingJson);
      let { templates, youtube, chat, training } = trainingJson;
      if (recentFiles?.length > 0) {
        let content = recentFiles[0]?.contents;
        allrefs?.settrainingJson({
          ...trainingJson,
          templates: {
            aside: true,
            template_selected: true,
            content_generated: content?.trim() !== "" ? true : false,
          },
        });
      }
      let general_folders = chatCalledOnce?.general_folders;
      let youtube_folders = chatCalledOnce?.youtube_folders;
      let login_user = JSON.parse(localStorage.getItem("login_data"));
      let modal_id = login_user?.resp_user?.modal_id;
      if (recentFiles?.length == 0) {
        stepsArray.push({
          target: allrefs?.templates?.current,
          content: "Generate content in 3 simple steps",
          disableBeacon: true,
        });
        setcurrentTraining("templates");
      } else if (
        getPlanDetails().planId != 1 &&
        chatfilesFetched &&
        youtube_folders?.length == 0
      ) {
        stepsArray.push({
          target: allrefs?.youtube?.current,
          content: "Start a YouTube Chat Thread to chat with a video",
          disableBeacon: true,
        });
        setcurrentTraining("youtube");
      }
      // else if (
      //   getPlanDetails().planId == 3 &&
      //   trainingHistoryFetched &&
      //   trainingHistory?.length == 0
      // ) {
      //   stepsArray.push({
      //     target: allrefs?.training?.current,
      //     content: "Click here to start training",
      //     disableBeacon: true,
      //   });
      //   setcurrentTraining("training");
      // }
      else {
      }
      setsteps(stepsArray);
      setRun(stepsArray.length > 0); // Start tour only if there are steps
    }
  }, [recentFilesFetched, recentFiles?.length, chatfilesFetched, chatCalledOnce?.general_folders?.length, chatCalledOnce?.youtube_folders?.length, trainingHistory?.length, trainingHistoryFetched]);
  const navigateFromFile = (file) => {
    const getNavigation = (itemTitle) => {
      for (const category in templatesAndNavigation) {
        const categoryItems = templatesAndNavigation[category];
        const selectedItem = categoryItems.find(
          (item) => item.title === itemTitle
        );

        if (selectedItem) {
          return selectedItem.navigation;
        }
      }

      return "Navigation not found";
    };
    let titleObjNav = getNavigation(file?.content_template);
    if (titleObjNav == "Navigation not found") {
      setselectedFile({
        ...file,
        newFile: true,
      });
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...file,
          newFile: true,
        })
      );
      setselectedContentSettings({
        // ...selectedContentSettings,
        content_template: file?.title,
        content_title: file?.content_title,
      });
      localStorage.setItem(
        "selectedContentSettings",
        JSON.stringify({
          content_template: file?.title,
          content_title: file?.content_title,
        })
      );
      localStorage.setItem("template_selected", "from_template_file");
      setchoosedTemplate("Blog");
      setOpenModal("dismissible");
    } else {
      localStorage.setItem("selected_file", JSON.stringify(file));
      setselectedFile(file);
      navigate(titleObjNav);
    }
  };

  useEffect(() => {
    setselectedFolder({});
  }, [location.search, location.pathname]);

  // console.log(selectedFolder, "selected folder");

  // console.log(getFoldersMap(), "get folders map");
  return (
    <>
      {/* <AsideBar /> */}
      <NavBar />

      <div className="wrapper">
        {!location.pathname.includes("folders") &&
          !location.pathname.includes("files") && (
            <>
              {stepsCompleted() != 3 && (
                <div className="max-w-[100%]">
                  <div
                    class={`mb-4 dark:text-white flex ${
                      !recentFilesFetched && "skeleton"
                    }`}
                  >
                    <span className="text-[20px] font-bold text-[#2A2A2A]">
                      Get started with vison in two steps
                    </span>
                    <span className="ms-auto text-[#BFBFBF]">
                      {stepsCompleted()}/2
                    </span>
                  </div>
                  <div className="w-full bg-[#F1F1F1] rounded-full h-1.5 mb-8 dark:bg-[#F1F1F1] skeleton">
                    <div
                      className="bg-[#5DE56A] h-1.5 rounded-full dark:bg-[#5DE56A]"
                      style={{ width: getWidth() }}
                    ></div>
                  </div>
                  <ul className="text-btn">
                    <li className={`${!recentFilesFetched && "skeleton"}`}>
                      <button
                        type="button"
                        className="flex items-center w-full hover:text-[#1E70A6]"
                      >
                        <div>
                          <svg className="icon text-[22px]">
                            <use href="#icon_choose"></use>
                          </svg>
                          <span className="ms-8 text-[20px] font-semibold">
                            Choose A Template
                          </span>
                        </div>
                        <div className="ms-auto">
                          {recentFilesList?.length > 0 && checkStepTwo() ? (
                            <div>
                              <svg className="icon text-[24px] text-[#5DE56A]">
                                <use href="#icon_check"></use>
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg className="icon text-[24px] text-[#DADADA]">
                                <use href="#icon_check"></use>
                              </svg>
                            </div>
                          )}
                        </div>
                      </button>
                    </li>
                    <li className={`${!recentFilesFetched && "skeleton"}`}>
                      <button
                        type="button"
                        className="flex items-center w-full hover:text-[#1E70A6]"
                      >
                        <div>
                          <svg className="icon text-[22px]">
                            <use href="#icon_generate"></use>
                          </svg>
                          <span className="ms-8 text-[20px] font-semibold">
                            Generate Your First Copy
                          </span>
                        </div>
                        <div className="ms-auto">
                          {recentFilesList?.length > 0 && checkStepThree() ? (
                            <div>
                              <svg className="icon text-[24px] text-[#5DE56A]">
                                <use href="#icon_check"></use>
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg className="icon text-[24px] text-[#DADADA]">
                                <use href="#icon_check"></use>
                              </svg>
                            </div>
                          )}
                        </div>
                      </button>
                    </li>
                  </ul>
                  {recentFiles?.length == 0 && (
                    <div className="flex justify-center">
                      <img
                        className="w-full max-w-[400px]"
                        src="images/home-img.png"
                        alt="...."
                      />
                    </div>
                  )}
                </div>
              )}
              {stepsCompleted() == 3 && (
                <>
                  <div className="flex items-center px-2">
                    <div ref={templateRef}>
                      <h6 className="font-semibold text-[24px]">Templates</h6>
                    </div>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="underline underline-offset-1 font-semibold hover:text-[#097BA9]"
                        onClick={() => setbrowseAllClicked(!browseAllClicked)}
                      >
                        {browseAllClicked
                          ? "Browse Less Templates"
                          : "Browse All Templates"}
                      </button>
                    </div>
                  </div>
                  <div className="h-full">
                    <div className="w-full h-full py-4">
                      <div className="flex flex-wrap">
                        {getAllTemplates().map((template, template_index) => (
                          <div
                            className="w-full md:w-1/3 p-2 flex justify-center"
                            onClick={() => {
                              setchoosedTemplate(template.title);
                              setOpenModal("dismissible");
                            }}
                          >
                            <div className="w-full p-[10px] rounded-[15px] flex bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9]">
                              <div className="me-4">
                                <div className="flex items-center justify-center w-[50px] h-[44px] bg-[#E9F2FF] rounded-tl-[15px] rounded-br-[15px] rounded-tr-[6px] rounded-bl-[6px]">
                                  <svg className="icon">
                                    <use href={template.icon}></use>
                                  </svg>
                                </div>
                              </div>
                              <div>
                                <p className="font-semibold">
                                  {template.title}
                                </p>
                                <p className="text-[12px]">
                                  {template.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={`${!recentFilesFetched && "skeleton"}`}>
                {recentFiles?.length > 0 && (
                  <>
                    <div className="flex items-center px-2 mt-5">
                      <div>
                        <h6 className="font-semibold text-[24px]">
                          Recently created files{" "}
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              localStorage.setItem(
                                "template_selected",
                                "from_asidebar"
                              );
                              setchoosedTemplate("Blog");
                              setOpenModal("dismissible");
                            }}
                          >
                            +
                          </span>
                        </h6>
                      </div>
                      {recentFiles?.length > 3 && (
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="underline underline-offset-1 font-semibold hover:text-[#097BA9]"
                            onClick={() =>
                              setviewFilesClicked(!viewFilesClicked)
                            }
                          >
                            {viewFilesClicked
                              ? "View Less Files"
                              : "View All Files"}
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="h-full">
                      <div className="w-full h-full py-4">
                        <div className="flex flex-wrap">
                          {getRecentFiles1()?.map((file, file_index) => (
                            <div className="w-full md:w-1/3 p-2  justify-center cursor-pointer">
                              <div
                                onClick={() => {
                                  const getNavigation = (itemTitle) => {
                                    for (const category in templatesAndNavigation) {
                                      const categoryItems =
                                        templatesAndNavigation[category];
                                      const selectedItem = categoryItems.find(
                                        (item) => item.title === itemTitle
                                      );

                                      if (selectedItem) {
                                        return selectedItem.navigation;
                                      }
                                    }

                                    return "Navigation not found";
                                  };
                                  let titleObjNav = getNavigation(
                                    file?.content_template
                                  );
                                  if (titleObjNav == "Navigation not found") {
                                    localStorage.setItem(
                                      "selected_file",
                                      JSON.stringify({
                                        ...file,
                                        newFile: true,
                                      })
                                    );
                                    setselectedFile({
                                      ...file,
                                      newFile: true,
                                    });
                                    setselectedContentSettings({});
                                    localStorage.setItem(
                                      "selectedContentSettings",
                                      JSON.stringify({})
                                    );
                                    localStorage.setItem(
                                      "template_selected",
                                      "from_template_file"
                                    );
                                    localStorage.setItem(
                                      "assigning_template_to_file",
                                      JSON.stringify(file)
                                    );
                                    setchoosedTemplate("Blog");
                                    setOpenModal("dismissible");
                                  } else {
                                    localStorage.setItem(
                                      "selected_file",
                                      JSON.stringify({
                                        ...file,
                                      })
                                    );
                                    setselectedFile(file);
                                    setTimeout(() => {
                                      navigate(titleObjNav);
                                    }, 500);
                                  }
                                }}
                              >
                                <div className="bg-[#E9F2FF] p-[10px] rounded-t-[15px] font-normal">
                                  <p>
                                    {file?.content_title &&
                                    file?.content_title != ""
                                      ? file?.content_title
                                      : "Untitled"}
                                  </p>
                                </div>
                                <div className="w-full border-l-[1px] border-r-[1px] p-4 flex">
                                  <div className="w-[50%] border-r-[1px] text-center">
                                    <p className="font-normal text-[14px]">
                                      Template
                                    </p>
                                    <p className="font-semibold text-[20px]">
                                      {file?.content_template}
                                    </p>
                                  </div>
                                  <div className="w-[50%] text-center">
                                    <p className="font-normal text-[14px]">
                                      Words
                                    </p>
                                    <p className="font-semibold text-[20px]">
                                      {file?.content_count}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="p-[10px] rounded-b-[15px] flex items-center font-normal border-[1px]">
                                {!file?.folder_id && (
                                  <div className="w-[50%] border-r-[1px] flex items-center justify-center">
                                    <button
                                      type="button"
                                      className="flex items-center text-[#C6C6C6] hover:text-[#266AA6]"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "selected_file",
                                          JSON.stringify({
                                            ...file,
                                          })
                                        );
                                        setselectedFile(file);
                                        setaddtofolder({
                                          ...addtofolder,
                                          content_title: file?.content_title,
                                        });
                                        setaddFolderCreateModal("dismissible");
                                      }}
                                    >
                                      <svg className="icon me-2 text-[24px]">
                                        <use href="#icon_folderplus"></use>
                                      </svg>
                                      <span className="font-medium text-[10px]">
                                        Add To Folder
                                      </span>
                                    </button>
                                  </div>
                                )}

                                <div className="w-[50%] flex items-center justify-center">
                                  <button
                                    type="button"
                                    className="flex items-center text-[#C6C6C6] hover:text-[#9E3782]"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "selected_file",
                                        JSON.stringify({
                                          ...file,
                                        })
                                      );
                                      setselectedFile(file);
                                      openModalHandlerDelete();
                                    }}
                                  >
                                    <svg className="icon me-2 text-[24px]">
                                      <use href="#icon_delete"></use>
                                    </svg>
                                    <span className="font-medium text-[10px]">
                                      Delete
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                {/* <button onClick={() => setRun(true)}>Start Tour</button> */}
              </div>
            </>
          )}

        {location.pathname && location.pathname.includes("folders") && (
          <>
            <div>
              <h6 className="font-semibold text-[24px] pb-5 px-2">
                Space : {space_name}
              </h6>
            </div>
            <div className="flex items-center px-2">
              <div>
                <h6 className="font-semibold text-[24px]">Folders</h6>
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="underline underline-offset-1 font-semibold hover:text-[#097BA9]"
                >
                  View All Folders
                </button>
              </div>
            </div>
            <div className="h-full">
              <div className="w-full h-full py-4">
                {/* Accordian */}
                {/* <Accordion collapseAll>
                  {getFoldersMap()
                    ?.filter((folder) => folder?.type === "folder")
                    ?.map((folder, folder_index) => (
                      <Accordion.Panel>
                        <div
                          onClick={() => {
                            if (
                              selectedFolder?.folder_id === folder?.folder_id
                            ) {
                              setselectedFolder(null);
                            } else {
                              setselectedFolder(folder);
                            }
                          }}
                        >
                          <Accordion.Title>
                            {" "}
                            <div
                              style={{
                                color:
                                  selectedFolder?.folder_id ===
                                  folder?.folder_id
                                    ? "#097BA9"
                                    : "#2A2A2A",
                              }}
                              className="flex items-center gap-1 hover:text-[#097BA9] text-[#2A2A2A]"
                            >
                              <div>
                                <svg className="icon me-2 text-[24px]">
                                  <use href="#icon_folder"></use>
                                </svg>
                              </div>
                              <div>{folder?.folder_name}</div>
                            </div>
                          </Accordion.Title>
                          <Accordion.Content>
                            <div>Folder {folder_index + 1} Files</div>
                          </Accordion.Content>
                        </div>
                      </Accordion.Panel>
                    ))}
                </Accordion> */}

                <div id="accordion-collapse" data-accordion="collapse">
                  {getFoldersMap()
                    ?.filter((folder) => folder?.type === "folder")
                    ?.map((folder, folder_index) => (
                      <div style={{ marginBottom: "30px" }}>
                        <h2 id="accordion-collapse-heading-1">
                          <button
                            style={{}}
                            type="button"
                            className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border-none  border-gray-200 ${
                              selectedFolder?.folder_id === folder?.folder_id
                                ? "rounded-t-xl"
                                : "rounded-xl"
                            } dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 bg-[#FAFBFC]`}
                            data-accordion-target="#accordion-collapse-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-collapse-body-1"
                            onClick={() => {
                              if (
                                selectedFolder?.folder_id === folder?.folder_id
                              ) {
                                setselectedFolder(null);
                              } else {
                                getSpaceFileList(space_id, folder?.folder_id);
                                setselectedFolder(folder);
                              }
                            }}
                          >
                            <div
                              style={{
                                color:
                                  selectedFolder?.folder_id ===
                                  folder?.folder_id
                                    ? "#097BA9"
                                    : "#2A2A2A",
                              }}
                              className="flex items-center gap-1 hover:text-[#097BA9] text-[#2A2A2A]"
                            >
                              <div>
                                <svg className="icon me-2 text-[24px]">
                                  <use href="#icon_folder"></use>
                                </svg>
                              </div>
                              <div>{folder?.folder_name}</div>
                            </div>
                            <svg
                              data-accordion-icon
                              className="w-3 h-3 rotate-180 shrink-0"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5 5 1 1 5"
                              />
                            </svg>
                          </button>
                        </h2>
                        <div
                          id="accordion-collapse-body-1"
                          style={{
                            borderBottom: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          className={`
                            border border-gray-200 rounded-xl p-5 rounded-t-none bg-[#FAFBFC] border-t
                            ${
                              selectedFolder?.folder_id === folder?.folder_id
                                ? "block"
                                : "hidden"
                            }
                          `}
                          aria-labelledby="accordion-collapse-heading-1"
                        >
                          {folder?.submenu?.map((file, file_index) => (
                            <div
                              className="flex items-center gap-1 hover:text-[#097BA9] text-[#2A2A2A] pb-5 cursor-pointer"
                              onClick={() => {
                                navigateFromFile(file);
                              }}
                            >
                              <div>
                                <svg className="icon me-2 text-[24px]">
                                  <use href="#icon_file2"></use>
                                </svg>
                              </div>
                              <div>{file?.content_title}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="flex items-center px-2">
              <div>
                <h6 className="font-semibold text-[24px]">Files</h6>
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="underline underline-offset-1 font-semibold hover:text-[#097BA9]"
                >
                  View All Files
                </button>
              </div>
            </div>
            <div className="h-full">
              <div className="w-full h-full py-4">
                <div className="flex flex-wrap">
                  {getFoldersMap()
                    ?.filter((folder) => folder?.type == "file")
                    ?.map((file, file_index) => (
                      <div className="w-full md:w-1/3 p-2  justify-center cursor-pointer">
                        <div
                          onClick={() => {
                            const getNavigation = (itemTitle) => {
                              for (const category in templatesAndNavigation) {
                                const categoryItems =
                                  templatesAndNavigation[category];
                                const selectedItem = categoryItems.find(
                                  (item) => item.title === itemTitle
                                );

                                if (selectedItem) {
                                  return selectedItem.navigation;
                                }
                              }

                              return "Navigation not found";
                            };
                            let titleObjNav = getNavigation(
                              file?.content_template
                            );
                            if (titleObjNav == "Navigation not found") {
                              localStorage.setItem(
                                "selected_file",
                                JSON.stringify({
                                  ...file,
                                  newFile: true,
                                })
                              );
                              setselectedFile({
                                ...file,
                                newFile: true,
                              });
                              setselectedContentSettings({
                                // ...selectedContentSettings,
                                content_template: file?.title,
                                content_title: file?.content_title,
                              });
                              localStorage.setItem(
                                "selectedContentSettings",
                                JSON.stringify({
                                  // ...selectedContentSettings,
                                  content_template: file?.title,
                                  content_title: file?.content_title,
                                })
                              );
                              localStorage.setItem(
                                "template_selected",
                                "from_template_file"
                              );
                              setchoosedTemplate("Blog");
                              setOpenModal("dismissible");
                            } else {
                              localStorage.setItem(
                                "selected_file",
                                JSON.stringify({
                                  ...file,
                                })
                              );
                              setselectedFile(file);
                              setTimeout(() => {
                                navigate(titleObjNav);
                              }, 500);
                            }
                          }}
                        >
                          <div className="bg-[#E9F2FF] p-[10px] rounded-t-[15px] font-normal">
                            <p>
                              {file?.content_title && file?.content_title != ""
                                ? file?.content_title
                                : "Untitled"}
                            </p>
                          </div>
                          <div className="w-full border-l-[1px] border-r-[1px] p-4 flex">
                            <div className="w-[50%] border-r-[1px] text-center">
                              <p className="font-normal text-[14px]">
                                Template
                              </p>
                              <p className="font-semibold text-[20px]">
                                {file?.content_template}
                              </p>
                            </div>
                            <div className="w-[50%] text-center">
                              <p className="font-normal text-[14px]">Words</p>
                              <p className="font-semibold text-[20px]">
                                {file?.content_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="p-[10px] rounded-b-[15px] flex items-center font-normal border-[1px]">
                          {!file?.folder_id && (
                            <div className="w-[50%] border-r-[1px] flex items-center justify-center">
                              <button
                                type="button"
                                className="flex items-center text-[#C6C6C6] hover:text-[#266AA6]"
                                onClick={() => {
                                  localStorage.setItem(
                                    "selected_file",
                                    JSON.stringify({
                                      ...file,
                                    })
                                  );
                                  setselectedFile(file);
                                  setaddtofolder({
                                    ...addtofolder,
                                    content_title: file?.content_title,
                                  });
                                  setaddFolderCreateModal("dismissible");
                                }}
                              >
                                <svg className="icon me-2 text-[24px]">
                                  <use href="#icon_folderplus"></use>
                                </svg>
                                <span className="font-medium text-[10px]">
                                  Add To Folder
                                </span>
                              </button>
                            </div>
                          )}

                          <div className="w-[50%] flex items-center justify-center">
                            <button
                              type="button"
                              className="flex items-center text-[#C6C6C6] hover:text-[#9E3782]"
                              onClick={() => {
                                localStorage.setItem(
                                  "selected_file",
                                  JSON.stringify({
                                    ...file,
                                  })
                                );
                                setselectedFile(file);
                                openModalHandlerDelete();
                              }}
                            >
                              <svg className="icon me-2 text-[24px]">
                                <use href="#icon_delete"></use>
                              </svg>
                              <span className="font-medium text-[10px]">
                                Delete
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        {location.pathname && location?.pathname?.includes("files") && (
          <>
            <div>
              <h6 className="font-semibold text-[24px] pb-5 px-2">
                Space : {space_name}
              </h6>
            </div>
            <div>
              <h6 className="font-semibold text-[24px] pb-5 px-2">
                Folder : {folder_name}
              </h6>
            </div>
            <div className="flex items-center px-2">
              <div>
                <h6 className="font-semibold text-[24px]">Files</h6>
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="underline underline-offset-1 font-semibold hover:text-[#097BA9]"
                >
                  View All Files
                </button>
              </div>
            </div>
            <div className="h-full">
              <div className="w-full h-full py-4">
                <div className="flex flex-wrap">
                  {getFoldersFilesMap()
                    ?.filter((folder) => folder?.type == "file")
                    ?.map((file, file_index) => (
                      <div className="w-full md:w-1/3 p-2  justify-center cursor-pointer">
                        <div
                          onClick={() => {
                            const getNavigation = (itemTitle) => {
                              for (const category in templatesAndNavigation) {
                                const categoryItems =
                                  templatesAndNavigation[category];
                                const selectedItem = categoryItems.find(
                                  (item) => item.title === itemTitle
                                );

                                if (selectedItem) {
                                  return selectedItem.navigation;
                                }
                              }

                              return "Navigation not found";
                            };
                            let titleObjNav = getNavigation(
                              file?.content_template
                            );
                            if (titleObjNav == "Navigation not found") {
                              localStorage.setItem(
                                "selected_file",
                                JSON.stringify({
                                  ...file,
                                  newFile: true,
                                })
                              );
                              setselectedFile({
                                ...file,
                                newFile: true,
                              });
                              setselectedContentSettings({
                                // ...selectedContentSettings,
                                content_template: file?.title,
                                content_title: file?.content_title,
                              });
                              localStorage.setItem(
                                "selectedContentSettings",
                                JSON.stringify({
                                  // ...selectedContentSettings,
                                  content_template: file?.title,
                                  content_title: file?.content_title,
                                })
                              );
                              localStorage.setItem(
                                "template_selected",
                                "from_template_file"
                              );
                              setchoosedTemplate("Blog");
                              setOpenModal("dismissible");
                            } else {
                              localStorage.setItem(
                                "selected_file",
                                JSON.stringify({
                                  ...file,
                                })
                              );
                              setselectedFile(file);
                              setTimeout(() => {
                                navigate(titleObjNav);
                              }, 500);
                            }
                          }}
                        >
                          <div className="bg-[#E9F2FF] p-[10px] rounded-t-[15px] font-normal">
                            <p>
                              {file?.content_title && file?.content_title != ""
                                ? file?.content_title
                                : "Untitled"}
                            </p>
                          </div>
                          <div className="w-full border-l-[1px] border-r-[1px] p-4 flex">
                            <div className="w-[50%] border-r-[1px] text-center">
                              <p className="font-normal text-[14px]">
                                Template
                              </p>
                              <p className="font-semibold text-[20px]">
                                {file?.content_template}
                              </p>
                            </div>
                            <div className="w-[50%] text-center">
                              <p className="font-normal text-[14px]">Words</p>
                              <p className="font-semibold text-[20px]">
                                {file?.content_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="p-[10px] rounded-b-[15px] flex items-center font-normal border-[1px]">
                          {!file?.folder_id && (
                            <div className="w-[50%] border-r-[1px] flex items-center justify-center">
                              <button
                                type="button"
                                className="flex items-center text-[#C6C6C6] hover:text-[#266AA6]"
                                onClick={() => {
                                  localStorage.setItem(
                                    "selected_file",
                                    JSON.stringify({
                                      ...file,
                                    })
                                  );
                                  setselectedFile(file);
                                  setaddtofolder({
                                    ...addtofolder,
                                    content_title: file?.content_title,
                                  });
                                  setaddFolderCreateModal("dismissible");
                                }}
                              >
                                <svg className="icon me-2 text-[24px]">
                                  <use href="#icon_folderplus"></use>
                                </svg>
                                <span className="font-medium text-[10px]">
                                  Add To Folder
                                </span>
                              </button>
                            </div>
                          )}

                          <div className="w-[50%] flex items-center justify-center">
                            <button
                              type="button"
                              className="flex items-center text-[#C6C6C6] hover:text-[#9E3782]"
                              onClick={() => {
                                localStorage.setItem(
                                  "selected_file",
                                  JSON.stringify({
                                    ...file,
                                  })
                                );
                                setselectedFile(file);
                                openModalHandlerDelete();
                              }}
                            >
                              <svg className="icon me-2 text-[24px]">
                                <use href="#icon_delete"></use>
                              </svg>
                              <span className="font-medium text-[10px]">
                                Delete
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        root={addToFolderRef.current}
        show={addFolderCreateModal === "dismissible"}
        size="lg"
        onClose={() => setaddFolderCreateModal(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Add to Folder</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form>
            <div className="pb-3 pt-4">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="content_title"
              >
                Title
              </label>
              <input
                type="text"
                id="content_title"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Title"
                value={addtofolder?.content_title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="pb-3 pt-4">
              <label htmlFor="space_id">Select Space</label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="cars"
                id="space_id"
                value={addtofolder?.space_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Space</option>
                {userSpaces?.map((item) => (
                  <option value={item?.space_id}>
                    {item?.user_workspace_space?.space_name}
                  </option>
                ))}
                <option value="create_space">Create Space</option>
              </select>
            </div>
            <div className="pb-3 pt-4">
              <label htmlFor="folder_id">Select Folder</label>
              <select
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                name="cars"
                id="folder_id"
                value={addtofolder?.folder_id}
                onChange={handleChange}
              >
                <option value="">Select Folder</option>
                {findFolders?.map((item) => (
                  <option value={item?.folder_id}>{item?.folder_name}</option>
                ))}
                <option value="create_folder">Create Folder</option>
              </select>
            </div>

            <div className="pt-4">
              <button
                type="submit"
                className="w-full flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                disabled={addtofolder?.loading}
                onClick={() => {
                  assignToFolder();
                }}
              >
                {addtofolder?.loading ? (
                  <div className="flex items-center">
                    <div>Adding to Folder</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Add to Folder"
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <JoyrideComponent
        steps={steps}
        stepIndex={stepIndex}
        handleJoyrideCallback={handleJoyrideCallback}
        run={run}
        setStepIndex={setStepIndex}
      />
      <DeletePopup
        openModal={openModalDelete}
        onClose={closeModalHandlerDelete}
        deleteFile={deleteFile}
        deleting={deleting}
      />
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      {/* <TourComponent templateRef={templateRef} /> */}
      <Modal
        dismissible
        show={openModalMobile}
        onClose={() => setopenModalMobile(false)}
        className="flex items-center justify-center h-screen"
      >
        <Modal.Header className="p-0 m-0 border-0"></Modal.Header>
        <Modal.Body className="flex justify-center items-center h-screen">
          <div className="">
            <div className="flex justify-center">
              <div className="w-[120px] h-[120px] modal-grd rounded-[50%] flex justify-center items-center p-[30px]">
                <img
                  src="/images/experience2.svg"
                  className="w-full h-full"
                  alt="Experience"
                />
              </div>
            </div>
            <p className="text-2xl text-center py-[15px]">
              Best Experience on{" "}
              <span className="font-[600]">Laptop/Desktop</span>
            </p>
            <p className="text-center text-xl">
              For an optimal user experience, we recommend accessing Vison on a
              laptop or desktop. This ensures smoother navigation and better
              functionality, allowing you to fully leverage all the features of
              our tool.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default Template;
