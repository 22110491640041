/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar2 from "../components/NavBar2";
import WebSocket from "isomorphic-ws";
import moment from "moment";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import TextEditor from "../components/TextEditor.js";
import { Tooltip } from "react-tooltip";
import useCommonFunctions from "../hooks/common-functions.js";
import UserContext from "../context/UserContext.js";
import useCommonApi from "../hooks/common-apis.js";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Remarkable } from "remarkable";
import axios from "axios";
import PopupModals from "../components/PopupModals.js";
import Joyride from "react-joyride";
import JoyrideComponent from "../components/JoyrideComponent.js";
function YtChat() {
  const textareaRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [displayEditor, setdisplayEditor] = useState(false);
  const [popupMessage, setpopupMessage] = useState("");

  const [videoUrl, setvideoUrl] = useState("");
  const [chatData, setChatData] = useState([]);
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const [latestData, setlatestData] = useState(null);
  const { callSaveNewChat } = useCommonApi();
  const { getPlanDetails, checkEnabled, gettemplateWordCount } =
    useCommonFunctions();
  const context = useContext(UserContext);
  const { chatCalledOnce, setloadingChat, allrefs } = context;
  const [mounted, setmounted] = useState(false);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  let socketRef = useRef(null);
  const youtubelinkref = useRef();
  const [steps, setsteps] = useState([]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    console.log(data, "data in joyride callback");
    if (action === "next" && index === 0) {
      document.getElementById("YouTubeVideoLink").focus();
    }
    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prevIndex) => prevIndex + 1); // Increment stepIndex on "next"
      } else if (action === "prev") {
        setStepIndex((prevIndex) => prevIndex - 1); // Decrement stepIndex on "back"
      }
    }
    // Handle when Joyride is finished or skipped
    if (status === "finished" || status === "skipped" || action === "close") {
      setRun(false); // Stop the tour
      setStepIndex(0); // Optionally reset the step index to 0
      if (allrefs?.asideRef?.current) {
        setTimeout(() => {
          allrefs.asideRef.current.style.overflowY = "auto";
        }, 500);
      }
    }
  };
  useEffect(() => {
    const { trainingJson, settrainingJson } = allrefs;
    if (Object.keys(trainingJson || {}).length > 0) {
      let stepsArray = [];
      let keys = Object.keys(trainingJson);
      let { templates, youtube, chat, training } = trainingJson;
      let { aside, youtubelinkbox, youtubeinputbox } = youtube;
      if (!youtubelinkbox) {
        stepsArray.push({
          target: youtubelinkref?.current,
          content: "Paste the link of the YouTube video you want to chat with.",
          disableBeacon: true,
        });
      }
      setsteps(stepsArray);
      setRun(stepsArray.length > 0);
    }
  }, [allrefs?.trainingJson]);
  // const [chatData, setChatData] = useState([
  //   {
  //     type: "sender",
  //     message: "Hello",
  //     timestamp: "2024-02-21T08:41:42.340Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Hi there! How can I assist you today?",
  //     timestamp: "2024-02-21T08:42:22.745Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you tel me the summary of the video?",
  //     timestamp: "2024-02-21T08:42:43.306Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'Based on the context provided, the video seems to be a tutorial on creating a progress bar in a web application. The speaker demonstrates how to add a progress bar that transitions from one step to another. They explain the process of creating a div with the class name "progress bar" and another div inside it for the actual progress bar. The speaker also mentions aligning the progress bar behind the steps and ensuring it remains aligned even when the page is resized. Additionally, they mention a link in the video description to check out Odin School and a card above their head to access the complete playlist.',
  //     timestamp: "2024-02-21T08:43:09.717Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you give one example based on he given?",
  //     timestamp: "2024-02-21T08:43:32.937Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Based on the given context, let's say we have a progress bar with 4 steps. The current step is 2 and the progress for step 2 is complete. The progress for step 1 is also complete. The progress for steps 3 and 4 is not complete yet.\n\nIn this example, the progress bar would look like this:\n\nStep 1: Complete\nStep 2: Complete\nStep 3: Incomplete\nStep 4: Incomplete\n\nThe progress bar would have a gray background color and the progress for completed steps would be green. The text color would be white. Additionally, there would be a tick mark next to the completed steps.",
  //     timestamp: "2024-02-21T08:44:03.408Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "j",
  //     timestamp: "2024-02-21T08:45:26.629Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I'm sorry, but I'm not sure what you're asking. Can you please provide more information or ask a specific question?",
  //     timestamp: "2024-02-21T08:45:34.389Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "kio",
  //     timestamp: "2024-02-21T08:45:41.354Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I'm sorry, but I'm not sure what you're asking. Can you please provide more information or ask a specific question?",
  //     timestamp: "2024-02-21T08:45:50.764Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "hello world",
  //     timestamp: "2024-02-21T08:46:40.813Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Hi there! How can I assist you today?",
  //     timestamp: "2024-02-21T08:46:45.132Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "what is your name?",
  //     timestamp: "2024-02-21T08:46:54.009Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I am an AI language model developed by OpenAI. I don't have a personal name, but you can call me Assistant. How can I assist you today?",
  //     timestamp: "2024-02-21T08:47:03.285Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can I name you?",
  //     timestamp: "2024-02-21T08:48:06.937Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "I am an AI language model developed by OpenAI. I don't have a personal name, but you can call me Assistant. How can I assist you today?",
  //     timestamp: "2024-02-21T08:48:15.983Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "I will name you as akmal",
  //     timestamp: "2024-02-21T08:48:38.298Z",
  //   },
  //   {
  //     type: "receiver",
  //     message: "Sure, you can call me Akmal. How can I assist you today?",
  //     timestamp: "2024-02-21T08:48:44.514Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "can you tell me summary in points?",
  //     timestamp: "2024-02-21T08:51:08.556Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'Sure! Here is a summary of the video in points:\n\n1. The video is a tutorial on creating a progress bar in a web application.\n2. The speaker demonstrates how to add a progress bar that transitions from one step to another.\n3. The process involves creating a div with the class name "progress bar" and another div inside it for the actual progress bar.\n4. The progress bar is aligned behind the steps and remains aligned even when the page is resized.\n5. The video description includes a link to check out Odin School and there is a card above the speaker\'s head to access the complete playlist.',
  //     timestamp: "2024-02-21T08:51:35.962Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "how to align progress bar in points?",
  //     timestamp: "2024-02-21T08:52:37.195Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       'To align the progress bar, you can follow these steps:\n\n1. Create a div with the class name "progress bar".\n2. Inside the "progress bar" div, create another div for the actual progress bar.\n3. Apply the following styles to the "progress bar" div:\n   - Set the position to absolute.\n   - Use the top and margin-right properties to align it behind the steps.\n4. Resize the page and ensure that the progress bar remains aligned.\n\nBy following these steps, you should be able to align the progress bar in your web application.',
  //     timestamp: "2024-02-21T08:53:03.114Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "explain payment in points?",
  //     timestamp: "2024-02-21T08:55:37.838Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Here is an explanation of the payment step in points:\n\n1. The payment step is a part of the checkout process in e-commerce websites.\n2. After the customer enters their information and shipping details, they proceed to the payment step.\n3. In this step, the customer selects their preferred payment method, such as credit card, debit card, or online payment platforms like PayPal.\n4. The customer provides the necessary payment details, including card number, expiration date, and CVV code.\n5. Once the payment information is entered, the website securely processes the payment transaction.\n6. The customer may also have the option to save their payment information for future purchases.\n7. After the payment is successfully processed, the customer moves on to the final step of the checkout process, which is the product delivery.\n\nI hope this helps! Let me know if you have any more questions.",
  //     timestamp: "2024-02-21T08:56:16.292Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "explain customer info in points from video?",
  //     timestamp: "2024-02-21T08:58:53.012Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "Sure! Here is an explanation of the customer information step in points from the video:<br/><br/>1. The video does not specifically mention the customer information step.<br/>2. However, based on common e-commerce practices, the customer information step typically involves collecting details such as name, email address, and shipping address.<br/>3. This information is necessary for processing the order and delivering the product to the customer.<br/>4. The customer may also have the option to create an account or log in if they are a returning customer.<br/>5. The customer information step usually comes before the payment step in the checkout process.<br/><br/>I hope this helps! Let me know if you have any more questions.",
  //     timestamp: "2024-02-21T08:59:22.216Z",
  //   },
  //   {
  //     type: "sender",
  //     message: "It helps thank you",
  //     timestamp: "2024-02-21T08:59:52.620Z",
  //   },
  //   {
  //     type: "receiver",
  //     message:
  //       "You're welcome! I'm glad I could help. If you have any more questions, feel free to ask.",
  //     timestamp: "2024-02-21T09:00:01.040Z",
  //   },
  // ]);
  const [chat_lang, setChat_lang] = useState("");
  const [chatQuery, setChatQuery] = useState("");
  const [videoId, setvideoId] = useState("");
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingChatCompleted, setisLoadingChatCompleted] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("mtm_user_data") || "{}")
  );
  const [stringWords, setstringWords] = useState("");
  const [ws, setWs] = useState(null);
  const [submitButtonClicked, setsubmitButtonClicked] = useState(false);
  const [generateButtonClicked, setgenerateButtonClicked] = useState(false);
  const chatLoadingRef = useRef();
  const lastChatRef = useRef();
  const lastChatTempRef = useRef();
  const { getDate } = useCommonFunctions();
  const [videoDetails, setVideoDetails] = useState(null);
  const [loadingVideoDetails, setloadingVideoDetails] = useState(false);
  const [noCaption, setnoCaption] = useState(false);
  // console.log(location, "location ...");

  const checkCaptions = async (videoId) => {
    setloadingVideoDetails(true);
    const checkCaptionsInside = async (videoId) => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/captions?part=id&videoId=${videoId}&key=AIzaSyAmM0USR1v4RpcPTkECRNAk3e8Up4pyKbY`
        );
        return response.data.items.length > 0;
      } catch (error) {
        console.error("Error checking captions:", error);
      }
    };
    setnoCaption(false);
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=AIzaSyAmM0USR1v4RpcPTkECRNAk3e8Up4pyKbY`
    );
    setVideoDetails(response.data.items[0].snippet);
    let captions = await checkCaptionsInside(videoId);
    // setloadingVideoDetails(false);
    if (captions) {
      // setsubmitButtonClicked(true);
      // setgenerateButtonClicked(false);
      // setnoCaption(false);
      let newWs = new WebSocket(
        `${process.env.REACT_APP_YTCHAT_URL}/video_insert`
      );

      console.log(newWs, "ready state...");
      if (newWs?.readyState == newWs?.OPEN) {
        let new_data = {
          video_id: videoId,
        };
        const dataToSend = JSON.stringify(new_data);
        newWs.send(dataToSend);
      }
      newWs.onopen = function open() {
        console.log("websocket opened");
        let new_data = {
          video_id: videoId,
        };
        const dataToSend = JSON.stringify(new_data);
        newWs.send(dataToSend);
        console.log("sent to websocket on open", dataToSend);
      };

      newWs.onmessage = function incoming(data) {
        // console.log("received", data.data);
        const word = data.data;
        const correctedJsonString = word.replace(/'/g, '"');
        const jsonData = JSON.parse(correctedJsonString);
        // console.log(jsonData, "json data...");
        if (jsonData.status == "completed") {
          setloadingVideoDetails(false);
          setsubmitButtonClicked(true);
          setgenerateButtonClicked(false);
        } else {
          setgenerateButtonClicked(false);
          setloadingVideoDetails(false);
          setnoCaption(true);
        }
      };

      newWs.onclose = () => {
        setloadingVideoDetails(false);
        setgenerateButtonClicked(false);
        console.log("WebSocket connection closed.");
        setnoCaption(true);
      };
      newWs.onerror = (error) => {
        setloadingVideoDetails(false);
        setgenerateButtonClicked(false);
        setnoCaption(true);
        console.error("WebSocket error:", error);
      };
    } else {
      setnoCaption(true);
      setloadingVideoDetails(false);
    }

    // const checkCaptionsInside = async (videoId) => {
    //   try {
    //     const response = await axios.get(
    //       `https://www.googleapis.com/youtube/v3/captions?part=id&videoId=${videoId}&key=AIzaSyAmM0USR1v4RpcPTkECRNAk3e8Up4pyKbY`
    //     );
    //     return response.data.items.length > 0;
    //   } catch (error) {
    //     console.error("Error checking captions:", error);
    //   }
    // };
    // const fetchVideoDetails = async () => {
    // setloadingVideoDetails(true);
    //   setnoCaption(false);
    //   let captions = await checkCaptionsInside(videoId);
    //   // setloadingVideoDetails(false);
    //   if (captions) {
    //     setsubmitButtonClicked(true);
    //     setgenerateButtonClicked(false);
    //     setnoCaption(false);
    //   } else {
    //     setnoCaption(true);
    //   }
    // };

    // fetchVideoDetails();
  };

  // console.log(videoDetails, "videoDetails");

  function getYouTubeVideoId(url) {
    setvideoUrl(url);
    if (!url || url == "") return "";
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i;
    const match = url.match(regex);
    // console.log(regex);
    // Check if there is a match and return the video ID
    if (match) {
      checkCaptions(match[1]);
    }
    return match ? match[1] : null;
  }
  async function handleChatSend(from = "normal", message = "") {
    setgenerateButtonClicked(true);
    // if (chatQuery?.trim() === "") return;
    setIsLoadingChat(true);
    setisLoadingChatCompleted(true);
    setloadingChat(true);
    let chatQueryMessage = "";
    if (from === "generate") {
      chatQueryMessage = message;
    } else {
      chatQueryMessage = chatQuery?.trim();
    }
    let newChatData1 = [...chatData];
    // newChatData1 = newChatData1.filter((item) => item?.type !== "loader");
    newChatData1.push({
      type: "sender",
      message: chatQueryMessage,
      timestamp: new Date(),
    });
    let length = newChatData1.length;
    // newChatData1.push({
    //   type: "loader",
    //   message: chatQueryMessage,
    //   timestamp: new Date(),
    // });
    setChatData(newChatData1);
    let localFile = JSON.parse(localStorage.getItem("chat_file"));
    if (!localFile) {
      let generalFolders = chatCalledOnce?.youtube_folders;
      let todays_date = getDate();
      let todaysDateFolders = generalFolders?.find(
        (item) => item?.title == todays_date
      );
      let files = todaysDateFolders?.submenu;
      console.log(files, "submenu fies...");
      let fileName = "Untitled Chat_";
      let count = 0;
      for (let i = 0; i < files?.length; i++) {
        const element = files[i];
        if (element.title?.includes("Untitled")) {
          let split = element.title?.split("_");
          let fileNumber = parseInt(split[1]);
          count = Math.max(count, fileNumber);
        }
      }
      count = count + 1;
      fileName = fileName + count;
      // console.log(count,"count latest...")
      // return;
      let data = {
        thread_folder: getDate(),
        // thread_name: fileName,
        thread_name: videoDetails?.title || fileName || "",
        thread_id: "",
        chat_time: new Date(),
        chat_type: "ask",
        chat_content: chatQueryMessage,
        thread_type: "youtube",
        video_id: videoId,
      };

      await callSaveNewChat(data, "youtube");
    } else {
      let localFile = JSON.parse(localStorage.getItem("chat_file"));
      let data = {
        thread_folder: getDate(),
        thread_name: localFile?.thread_name,
        thread_id: localFile?.thread_id,
        chat_time: new Date(),
        chat_type: "ask",
        chat_content: chatQueryMessage,
        thread_type: "youtube",
        video_id: videoId,
      };
      callSaveNewChat(data, "youtube");
    }
    setChatQuery("");
    let newWs = ws;
    if (
      !newWs ||
      newWs?.readyState == newWs?.CLOSED ||
      newWs?.readyState == newWs?.CLOSING ||
      newWs?.readyState == newWs?.CONNECTING
    ) {
      // ws.close(); // Close the existing WebSocket connection if it exists
      // setWs(null); // Reset the WebSocket state
      console.log("websocket is closed condition");
      newWs = new WebSocket(`${process.env.REACT_APP_YTCHAT_URL}/chat_yt`);
      setWs(newWs);
    }
    console.log(newWs, "ready state...");
    if (newWs?.readyState == newWs?.OPEN) {
      console.log("websocket opened");
      let id = JSON.parse(
        localStorage.getItem("chat_file")
      )?.thread_id?.toString();
      let new_data = {
        "usr id": JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: id,
        query: chatQueryMessage,
        video_id: videoId,
        token: localStorage.getItem("login_token"),
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          new_data["model"] = model_id;
        } else {
          new_data["model"] = "model_3";
        }
      }
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    }
    let string = "";
    let thread_name = JSON.parse(
      localStorage.getItem("chat_file")
    )?.thread_name;
    let thread_id = JSON.parse(localStorage.getItem("chat_file"))?.thread_id;
    // if (newWs?.readyState == newWs?.OPEN) {
    newWs.onopen = function open() {
      console.log("websocket opened");
      let id = JSON.parse(
        localStorage.getItem("chat_file")
      )?.thread_id?.toString();
      let new_data = {
        "usr id": JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user?.id?.toString(),
        thread_id: id,
        query: chatQueryMessage,
        video_id: videoId,
        token: localStorage.getItem("login_token"),
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          new_data["model"] = model_id;
        } else {
          new_data["model"] = "model_3";
        }
      }
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
      console.log("sent to websocket on open", dataToSend);
    };
    // }

    newWs.onmessage = function incoming(data) {
      setgenerateButtonClicked(true);
      // console.log("received", data.data);
      const word = data.data;
      if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
        // console.log("coming to if", typeof word);
        if (word.includes("msg")) {
          // console.log("coming to mesg");
          let obj = JSON.parse(word);
          let msgString = obj.msg;
          setChatData((prev) => {
            // const updatedChatData = [...prev].filter(
            //   (item) => item != "loader"
            // ); // Create a copy of the existing array
            const updatedChatData = [...prev]; // Create a copy of the existing array
            updatedChatData[length] = {
              type: "error",
              message: msgString,
              timestamp: new Date(),
            };
            return updatedChatData;
          });
          return;
        }
        const correctedJsonString = word.replace(/'/g, '"');
        let data = {
          thread_folder: getDate(),
          thread_name: thread_name,
          thread_id: thread_id,
          chat_time: new Date(),
          chat_type: "ans",
          chat_content: string,
          thread_type: "youtube",
          video_id: videoId,
          ...JSON.parse(correctedJsonString),
        };
        callSaveNewChat(data, "youtube");
        setIsLoadingChat(false);
        setisLoadingChatCompleted(false);
        setloadingChat(false);
        // newWs.close();
        // setWs(null);
      } else {
        // console.log("coming to else");
        string = string + word;
        if (string?.trim()?.length > 0) {
          setmounted(true);
          // string = string.replaceAll("\n", "<br/>");
          // console.log(string, "string...");
          setChatData((prev) => {
            // const updatedChatData = [...prev].filter((item) => item != "loader"); // Create a copy of the existing array
            const updatedChatData = [...prev]; // Create a copy of the existing array
            updatedChatData[length] = {
              type: "receiver",
              message: string,
              timestamp: new Date(),
            };
            return updatedChatData;
          });
          setIsLoadingChat(false);
        }
      }

      // Scroll to the bottom
    };

    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
      setIsLoadingChat(false);
      setisLoadingChatCompleted(false);
      setWs(null);
      setChatData((prev) => prev.filter((item) => item?.type !== "loader"));
    };
    newWs.onerror = (error) => {
      setChatData((prev) => prev.filter((item) => item?.type !== "loader"));
      setIsLoadingChat(false);
      setisLoadingChatCompleted(false);
      setWs(null);
      console.error("WebSocket error:", error);
    };
  }
  // console.log(chatLoadingRef.current.scrollHeight, "scroll height...");
  // console.log(console.log(chatData, "chat data"));
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    return id;
  };
  console.log(ws, "ws");

  useEffect(() => {
    // Scroll to the last point of the container
    if (chatLoadingRef.current) {
      const container = chatLoadingRef.current;
      const lastElement = container.lastElementChild;
      lastElement?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatData, chatData.length, lastChatRef, location.search]);

  useEffect(() => {
    setmounted(false);
  }, [location.search]);

  useEffect(() => {
    if (localStorage.getItem("chat_details")) {
      let chatData = JSON.parse(localStorage.getItem("chat_details")) || [];
      if (chatData?.length == 0) {
        setChatData([]);
        setvideoId("");
        setvideoUrl("");
        setsubmitButtonClicked(false);
        setgenerateButtonClicked(false);
        console.log("coming to chat data... in useEffect");
        return;
      }
      if (mounted) {
        return;
      }
      // console.log(localStorage.getItem("chat_details"), "came outside...");
      let modifiedChatData = chatData?.map((item) => {
        return {
          type: item?.chat_type == "ask" ? "sender" : "receiver",
          message: item?.chat_content,
          timestamp: item?.chat_time,
        };
      });

      setvideoId(chatData[0]?.video_id);
      setsubmitButtonClicked(true);
      setgenerateButtonClicked(true);
      setChatData(modifiedChatData);
      let localFile = JSON.parse(localStorage.getItem("chat_file"));
      setVideoDetails({
        title: localFile?.thread_name,
      });
    }
  }, [location.search, mounted]);
  // console.log(submitButtonClicked, "submit button clicked...");
  // console.log(generateButtonClicked, "generate button clicked...");
  // Initialize WebSocket connection and event listeners
  const isToday = (date) => {
    const today = moment().format("DD-MM-YYYY");
    return today == date;
  };

  const isYesterday = (date) => {
    const yesterday = moment().subtract(1, "days")?.format("DD-MM-YYYY");
    return yesterday == date;
  };

  const getChatDates = () => {
    let chatDates = [];
    for (let i = 0; i < chatData.length; i++) {
      let date = moment(chatData[i]?.timestamp)?.format("DD-MM-YYYY");
      if (chatDates.indexOf(date) == -1) {
        chatDates.push(date);
      }
    }
    return chatDates;
  };
  const getChatsData = (date) => {
    let chatsData = [];
    for (let i = 0; i < chatData.length; i++) {
      let dateChat = moment(chatData[i]?.timestamp)?.format("DD-MM-YYYY");
      if (dateChat == date) {
        chatsData.push(chatData[i]);
      }
    }
    return chatsData;
  };
  // console.log(mounted, "mounted...");
  const getDayOfWeek = (date) => {
    // console.log(moment(date).format("MMMM Do, YYYY"), "date...");

    let dateString;
    if (isToday(date)) {
      dateString = "Today";
    } else if (isYesterday(date)) {
      dateString = "Yesterday";
    } else {
      dateString = date;
    }
    return dateString;
  };
  const getContent = (message) => {
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
    });

    const rendered = md.render(message || "");

    // Wrap code blocks with a div, add language label, and a copy button
    const wrappedCode = rendered.replace(
      /<pre><code class="language-([^"]+)">([\s\S]*?)<\/code><\/pre>/g,
      (match, p1, p2) => {
        // const encodedCode = encodeURIComponent(p2);
        const encodedCode = p2;
        return `
          <div class="code-block">
            <div class="code-header">
              <span class="language-label">${p1}</span>

              <button class="copy-btn" data-code="${encodedCode}">
                <svg class="icon">
                  <use href="#icon_copy"></use>
                </svg>
                Copy code
              </button>
            </div>
            <pre><code class="language-${p1}">${p2}</code></pre>
          </div>
        `;
      }
    );

    return wrappedCode;
  };
  // useEffect(() => {
  //   return () => {
  //     if (ws) {
  //       ws.close();
  //     }
  //   };
  // }, [ws]);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  }, [chatQuery]);
  useEffect(() => {
    const handleCopyButtonClick = (e) => {
      if (e.target.classList.contains("copy-btn")) {
        const code = decodeURIComponent(e.target.getAttribute("data-code"));
        const textarea = document.createElement("textarea");
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("Code copied to clipboard!");
      }
    };

    document.body.addEventListener("click", handleCopyButtonClick);

    return () => {
      document.body.removeEventListener("click", handleCopyButtonClick);
    };
  }, []);

  return (
    <div className="wrapper">
      <div className="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2 title="YT Chat" />
        <div
          className={`w-full h-[75%] ${
            displayEditor && "md:w-1/2  border-r"
          } px-4 border-r pb-2`}
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          {!submitButtonClicked && chatData?.length <= 0 && (
            <div className="h-[75%] pr-2 pb-2">
              <div className="overflow-auto h-full px-[2px]">
                <div className="pb-3 font-[500] text-[18px]">
                  Tired Of Watching A Video Or <strong>Don’t Have Time</strong>{" "}
                  To Complete The Video Now <strong>Chat</strong> With{" "}
                  <strong>The Video</strong>
                </div>
                <form onSubmit={(e) => e.preventDefault()} ref={youtubelinkref}>
                  <div className="pb-3">
                    <label
                      class="block mb-2 font-semibold text-[18px]"
                      for="YouTubeVideoLink"
                    >
                      YouTube Video Link
                    </label>
                    {/* FFDBDB */}
                    <input
                      type="search"
                      id="YouTubeVideoLink"
                      className={`w-full rounded-full font-[400] text-[16px] border ${
                        noCaption ? "border-[red]" : "border-[#FFDBDB]"
                      } bg-[#FFF6F6]`}
                      placeholder="Paste Link Here"
                      value={videoUrl}
                      onChange={(e) => setvideoUrl(e.target.value)}
                    />
                  </div>
                  {noCaption && (
                    <div className="text-[red]">This video has no caption.</div>
                  )}
                  <button
                    type="button"
                    className="w-full flex justify-between items-center py-2 my-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                    // disabled={loadingVideoDetails}
                    // disabled={!checkEnabled("youtube")}
                    onClick={() => {
                      if (!checkEnabled("youtube")) {
                        setshowModal({
                          first: true,
                          second: false,
                          third: false,
                        });
                        return;
                      }
                      if (gettemplateWordCount() <= 0) {
                        setpopupMessage("Generate Content");
                        setshowModal({
                          first: true,
                          second: false,
                          third: false,
                        });
                        return;
                      }
                      if (getYouTubeVideoId(videoUrl)) {
                        setvideoId(getYouTubeVideoId(videoUrl));
                      }
                    }}
                  >
                    <div></div>
                    <div>{!loadingVideoDetails ? "Submit" : "Submitting"}</div>
                    <div className="">
                      {loadingVideoDetails && <ThreeDotsLoader />}
                    </div>
                  </button>
                </form>
              </div>
            </div>
          )}
          {submitButtonClicked &&
            !generateButtonClicked &&
            chatData?.length <= 0 && (
              <div className="h-[75%] pr-2 pb-2">
                <div className="overflow-auto h-full px-[2px]">
                  <div className="pb-3 font-[500] text-[18px]">
                    Tired Of Watching A Video Or{" "}
                    <strong>Don’t Have Time</strong> To Complete The Video Now{" "}
                    <strong>Chat</strong> With <strong>The Video</strong>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="pb-3">
                      <label
                        class="block mb-2 font-semibold text-[18px]"
                        for="YouTubeVideoLink"
                      >
                        YouTube Video Link
                      </label>
                      <div
                        className="flex text-[14px] cursor-pointer"
                        onClick={() => {
                          setsubmitButtonClicked(false);
                          setvideoUrl("");
                          setvideoId("");
                          setnoCaption(false);
                        }}
                      >
                        <p className="underline ms-auto">Clear Link</p>
                      </div>
                    </div>

                    <div className="pb-3 w-full rounded-[20px] font-[400] text-[16px] border border-[#33B505]">
                      <div className="w-full px-5 py-4 flex items-center">
                        <div>{videoUrl}</div>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="w-[35px] h-[35px] bg-[#33B505] rounded-[50%] flex justify-center items-center text-white"
                          >
                            <svg className="icon text-[26px]">
                              <use href="#icon_check2"></use>
                            </svg>
                          </button>
                        </div>
                      </div>

                      <hr />
                      <div className="w-full flex flex-wrap gap-2 justify-between items-center px-5 py-4">
                        <button
                          type="button"
                          className="w-full py-2 px-[20px] rounded-[20px] font-semibold border border-[black]"
                          onClick={() => {
                            setgenerateButtonClicked(true);
                            handleChatSend("generate", "Generate Summary");
                          }}
                        >
                          Generate Summary
                        </button>
                        {/* <button
                        type="button"
                        className="py-2 px-[20px] rounded-[20px] text-white font-semibold bg-[#64479D]"
                        onClick={() => {
                          setgenerateButtonClicked(true);
                          handleChatSend(
                            "generate",
                            "Generate Video Time Stamp"
                          );
                        }}
                      >
                        Generate Video Time Stamp
                      </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

          {submitButtonClicked && videoId && chatData.length > 0 && (
            <>
              <div className="text-center h-[8%]">
                Chatting With (
                <span className="font-bold">{videoDetails?.title}</span>)
              </div>
              <div className="overflow-auto h-[66%]">
                <>
                  {getChatDates()?.map((date, date_index, date_arr) => (
                    <>
                      <div className="title title--center-line flex font-normal text-[12px] my-2">
                        {getDayOfWeek(date)}
                      </div>
                      <div ref={chatLoadingRef}>
                        {getChatsData(date)
                          ?.filter((chat) => chat?.message !== "")
                          ?.map((chat, chat_index) => {
                            if (chat?.type == "sender") {
                              return (
                                <div
                                  className="pb-2 sender"
                                  ref={
                                    chat_index === chatData.length - 1
                                      ? lastChatRef
                                      : undefined
                                  }
                                >
                                  <div className="flex justify-end">
                                    <div
                                      className="max-w-[75%] bg-[#FAFBFC] py-[8px] px-[12px] rounded-tr-[15px] rounded-l-[15px] text-[14px] font-semibold pt-[0.5em] pb-[0.5em]"
                                      data-time={moment(chat?.timestamp).format(
                                        "h:mm A"
                                      )}
                                      dangerouslySetInnerHTML={{
                                        __html: getContent(
                                          chat?.message?.replace(
                                            /\n/g,
                                            "<br />"
                                          )
                                        ),
                                      }}
                                    />
                                    {/* {chat?.message}
                                    </div> */}
                                  </div>
                                  <div className="flex">
                                    <p className="ms-auto text-[10px] font-semibold pt-1">
                                      {moment(chat?.timestamp).format("h:mm A")}
                                    </p>
                                  </div>
                                </div>
                              );
                            } else if (chat?.type == "receiver") {
                              return (
                                <div
                                  className="pb-2 receiver"
                                  ref={
                                    chat_index === chatData.length - 1
                                      ? lastChatRef
                                      : undefined
                                  }
                                >
                                  <div className="flex justify-start">
                                    <div
                                      className="w-[75%] bg-[#F2FAFF] py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-editor"
                                      data-time={moment(chat?.timestamp).format(
                                        "h:mm A"
                                      )}
                                      dangerouslySetInnerHTML={{
                                        __html: getContent(chat?.message || ""),
                                      }}
                                    />
                                  </div>
                                  <div className="flex w-[75%]">
                                    <p className="me-auto text-[10px] font-semibold pt-1">
                                      {moment(chat?.timestamp).format("h:mm A")}
                                    </p>
                                    <p>
                                      <button
                                        data-tooltip-id="pencil"
                                        data-tooltip-content="Copy to Editor"
                                        type="button"
                                        onClick={() => {
                                          setdisplayEditor(true);
                                          setstringWords(chat?.message);
                                        }}
                                      >
                                        <svg className="icon">
                                          <use href="#icon_pencil"></use>
                                        </svg>
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              );
                            } else if (chat?.type == "error") {
                              return (
                                <div
                                  className="pb-2 receiver"
                                  ref={
                                    chat_index === chatData.length - 1
                                      ? lastChatRef
                                      : undefined
                                  }
                                >
                                  <div className="flex justify-start">
                                    <div className="w-[70%]  py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                                      {chat?.message}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (chat?.type == "loader") {
                              return (
                                <div
                                  className="pb-2 receiver"
                                  ref={
                                    chat_index === chatData.length - 1
                                      ? lastChatRef
                                      : undefined
                                  }
                                >
                                  <div className="flex justify-start">
                                    <div className="py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                                      <ThreeDotsLoader />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        {isLoadingChat &&
                          date_arr.length - 1 === date_index && (
                            <div className="pb-2 receiver">
                              <div className="flex justify-start">
                                <div className="py-[8px] px-[12px] rounded-tl-[15px] rounded-r-[15px] text-[14px] font-semibold text-[red]">
                                  <ThreeDotsLoader />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  ))}
                </>
              </div>
            </>
          )}
          {/* <div
            className="relative h-[25%]"
            style={{
              position: "sticky",
              bottom: "0",
              zIndex: "1",
            }}
          >
            <div className="chat-field w-full h-[130px] bg-[#FAFBFC] rounded-[15px] p-[8px] absolute bottom-0 border border-[#E9EBF0]">
              <div>
                <textarea
                  ref={textareaRef}
                  className="w-full h-[auto] m-0 rounded-[15px] custom-textarea"
                  placeholder="Enter your question here..."
                  id="textofComment"
                  value={chatQuery}
                  onChange={(e) => setChatQuery(e.target.value)}
                  style={{ resize: "none",maxHeight:"300px",overflow:"auto" }}
                ></textarea>
              </div>
              <button
                type="button"
                className="sendbtn w-[34px] h-[34px] absolute flex justify-center items-center top-[16px] right-[16px] cursor-pointer border-none text-white rounded-full bg-[#4158D0] transition duration-700 ease-in-out"
                title="Send"
                id="sendComment"
                onClick={() => {
                  if (!checkEnabled("youtube")) {
                    setshowModal({
                      first: true,
                      second: false,
                      third: false,
                    });
                    return;
                  }
                  if (gettemplateWordCount() <= 0) {
                    setpopupMessage("Generate Content");
                    setshowModal({
                      first: true,
                      second: false,
                      third: false,
                    });
                    return;
                  }
                  if (chatQuery?.trim()?.length == 0) {
                    return;
                  }
                  setgenerateButtonClicked(true);
                  handleChatSend();
                }}
              >
                <svg className="icon">
                  <use href="#icon_send"></use>
                </svg>
              </button>
              <button
                type="button"
                className="w-full justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-[#DE2C2C] text-white  hover:bg-[#c21818]"
                disabled={!checkEnabled("youtube")}
                onClick={() => {
                  localStorage.removeItem("chat_file");
                  localStorage.setItem("chat_details", JSON.stringify([]));
                  setsubmitButtonClicked(false);
                  setChatQuery("");
                  setChatData([]);
                  setvideoId("");
                  setvideoUrl("");
                  navigate("/template/youtube-chat");
                }}
              >
                <svg className="icon me-2">
                  <use href="#icon_schat"></use>
                </svg>
                New YouTube Video Chat
              </button>
 
            </div>
          </div> */}
          <div className=" relative h-[25%]">
            <div className="chat-field w-full  bg-[#FAFBFC] rounded-[15px] p-[8px] absolute bottom-0 border border-[#E9EBF0]">
              <textarea
                ref={textareaRef}
                className="w-full h-[auto] m-0 rounded-[15px] custom-textarea"
                placeholder="Enter your question here..."
                id="textofComment"
                value={chatQuery}
                onChange={(e) => setChatQuery(e.target.value)}
                style={{ resize: "none", maxHeight: "300px", overflow: "auto" }} // Disable manual resizing
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (!checkEnabled("youtube")) {
                      setshowModal({
                        first: true,
                        second: false,
                        third: false,
                      });
                      return;
                    }
                    if (gettemplateWordCount() <= 0) {
                      setpopupMessage("Generate Content");
                      setshowModal({
                        first: true,
                        second: false,
                        third: false,
                      });
                      return;
                    }
                    if (chatQuery?.trim()?.length == 0) {
                      return;
                    }
                    setgenerateButtonClicked(true);
                    handleChatSend();
                  }
                }}
              ></textarea>
              <button
                type="button"
                className="sendbtn w-[34px] h-[34px] absolute flex justify-center items-center top-[16px] right-[16px] cursor-pointer border-none text-white rounded-full bg-[#4158D0] transition duration-700 ease-in-out"
                title="Send"
                id="sendComment"
                disabled={isLoadingChatCompleted}
                onClick={() => {
                  if (!checkEnabled("youtube")) {
                    setshowModal({
                      first: true,
                      second: false,
                      third: false,
                    });
                    return;
                  }
                  if (gettemplateWordCount() <= 0) {
                    setpopupMessage("Generate Content");
                    setshowModal({
                      first: true,
                      second: false,
                      third: false,
                    });
                    return;
                  }
                  if (chatQuery?.trim()?.length == 0) {
                    return;
                  }
                  setgenerateButtonClicked(true);
                  handleChatSend();
                }}
              >
                <svg className="icon">
                  <use href="#icon_send"></use>
                </svg>
              </button>
              <button
                type="button"
                className="w-full justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-[#DE2C2C] text-white  hover:bg-[#c21818]"
                disabled={!checkEnabled("youtube")}
                onClick={() => {
                  localStorage.removeItem("chat_file");
                  localStorage.setItem("chat_details", JSON.stringify([]));
                  setsubmitButtonClicked(false);
                  setChatQuery("");
                  setChatData([]);
                  setvideoId("");
                  setvideoUrl("");
                  navigate("/template/youtube-chat");
                }}
              >
                <svg className="icon me-2">
                  <use href="#icon_schat"></use>
                </svg>
                New YouTube Video Chat
              </button>
            </div>
          </div>
        </div>
        {displayEditor && (
          <div
            className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
            style={{ height: "calc(100vh - 98px)" }}
          >
            <div className=" h-full overflow-auto pb-2">
              <div className="w-full flex justify-between items-center">
                <h2 className="font-bold text-[20px]">Editor</h2>
                <div className="ms-auto flex items-center gap-2">
                  <div
                    onClick={() => {
                      setdisplayEditor(false);
                      setfullscreenClicked(false);
                    }}
                  >
                    <svg className="icon text-[16px]  cursor-pointer">
                      <use href="#icon_close1"></use>
                    </svg>
                  </div>
                  {!fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(true)}>
                      <svg className="icon text-[14px]  cursor-pointer">
                        <use href="#icon_fullscreen"></use>
                      </svg>
                    </div>
                  )}
                  {fullscreenClicked && (
                    <div onClick={() => setfullscreenClicked(false)}>
                      <svg className="icon text-[14px] cursor-pointer">
                        <use href="#icon_fullexit"></use>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <TextEditor stringOutput={stringWords} from="chat" />
            </div>
          </div>
        )}
      </div>
      <Tooltip
        id="pencil"
        // style={{ backgroundColor: "#7b7b7b", color: "white" }}
      />
      <PopupModals
        showModal={showModal}
        setshowModal={setshowModal}
        popupMessage={popupMessage}
      />
      <JoyrideComponent
        steps={steps}
        stepIndex={stepIndex}
        handleJoyrideCallback={handleJoyrideCallback}
        run={run}
        setStepIndex={setStepIndex}
      />
    </div>
  );
}

export default YtChat;
