/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import BlogComponent from "../components/BlogComponent";
import TextEditor from "../components/TextEditor";
import UserContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import useCommonApi from "../hooks/common-apis";
import useCommonFunctions from "../hooks/common-functions";

function OneStepBlog() {
  const [inputBoxDescription, setinputBoxDescription] = useState("Description");
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const [outlinesArray, setoutlinesArray] = useState([]);
  const [selectedInputData, setselectedInputData] = useState({});
  const [stringOutput, setstringOutput] = useState("");
  const [stringOutputOutlines, setstringOutputOutlines] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const { postWebsocketDetails, closeFunction, loader, stringWords, ws } =
    useWebsocketHook();
  const [outlinesCompleted, setoutlinesCompleted] = useState(false);
  const [oneByOneCompleted, setoneByOneCompleted] = useState(false);
  const [totalLoadingCompleted, settotalLoadingCompleted] = useState(false);
  const [outlinesTotal, setoutlinesTotal] = useState({});
  const navigate = useNavigate();

  const onchangeDescription = (e) => {
    setinputBoxDescription(e.target.value);
  };
  const context = useContext(UserContext);
  const {
    selectedWorkspace,
    setselectedWorkspace,
    selectedTemplate,
    setselectedTemplate,
    setselectedFile,
    setselectedContentSettings,
    contentEditableRef,
    setsavingFileData,
  } = context;
  const selectedContentSettings = JSON.parse(
    localStorage.getItem("selectedContentSettings")
  );
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { getRecentFiles, callUpdateToFileApi } = useCommonApi();
  const { getPlanDetails, getDescription } = useCommonFunctions();
  const [finalOutputString, setfinalOutputString] = useState("");
  const [wsOutline, setWswsOutline] = useState(null);
  const [onestepWS, setonestepWS] = useState(null);
  const [number_of_words, setnumber_of_words] = useState("");
  const postWebsocketDetailsStepTwoOutlines = (replace, parseAttempts = 0) => {
    setoutlinesCompleted(false);
    if (wsOutline) {
      wsOutline.close(); // Close the existing WebSocket connection if it exists
      setWswsOutline(null); // Reset the WebSocket state
    }

    // const newWs = new WebSocket(
    //   `${process.env.REACT_APP_BASE_URL}/get_structured_outline`
    // );
    const newWs = new WebSocket(
      `${process.env.REACT_APP_BASE_URL}/get_structured_outline_2`
    );

    setWswsOutline(newWs);
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    let selected_file_data = {
      content_id: selectedFile?.content_id || selectedFile?.id || "",
      space_id: selectedFile?.space_id || "",
      folder_id: selectedFile?.folder_id || "",
    };

    // let parseAttempts = 0;

    newWs.onopen = function open() {
      let data = {
        no_of_outlines: "5",
        title: replace?.replace(/\d+\./g, ""),
        type: "Type 2",
        tone: selectedInputData?.tone,
        language: selectedInputData?.language,
        add_info: selectedInputData?.add_info,
        keywords:
          selectedInputData?.keywords && selectedInputData?.keywords?.length > 0
            ? JSON.stringify(selectedInputData?.keywords?.split(","))
            : "[]",
        audience: selectedInputData?.target_audience,
        conclusion: selectedInputData?.conclusion,
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          data["model"] = model_id;
        } else {
          data["model"] = "model_3";
        }
      }
      let new_data = { ...data, token: localStorage.getItem("login_token") };
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      // console.log("received", data.data);

      const word = data.data;
      if (word?.trim() == "Token Expired") {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      if (
        word?.charAt(0) === "{" &&
        word.charAt(word.length - 1) === "}" &&
        word?.includes("query_word_count")
      ) {
        setoutlinesCompleted(true);
        const correctedJsonString = word.replace(/'/g, '"');
        let selectedFileContentSettings = { ...selectedFile?.content_settings };
        selectedFileContentSettings["content_title"] =
          selectedFile?.content_title;
        selectedFileContentSettings["content_template"] =
          selectedFile?.content_template;
        callUpdateToFileApi(
          selectedFileContentSettings,
          contentEditableRef?.current?.innerHTML || "",
          JSON.parse(correctedJsonString),
          selected_file_data,
          selectedFile
        );
      } else {
        // setstringOutputOutlines((prev) => (prev += word));
        try {
          // console.log(word, "word...");
          let parsed = JSON.parse(word);
          // Proceed with parsed data
          setoutlinesTotal(parsed);
          // console.log(parsed, "parsed on outlines");
        } catch (error) {
          // Retry parsing once more
          console.log(error);
          if (parseAttempts < 1) {
            parseAttempts++;
            // postWebsocketDetailsStepTwoOutlines(replace);
            newWs?.close();
            if (wsOutline) {
              wsOutline.close(); // Close the existing WebSocket connection if it exists
              setWswsOutline(null); // Reset the WebSocket state
            }
            postWebsocketDetailsStepTwoOutlines(replace, parseAttempts + 1);
            console.error("Error parsing data, retrying once more...");
            return;
          } else {
            console.error("Failed to parse data after retrying twice:", error);
            // Log the error to the console or handle it as needed
          }
        }
      }
    };

    newWs.onclose = () => {
      console.log("closed");
    };

    newWs.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };

  const getInputData = (data) => {
    setselectedInputData(data);
  };
  const stopGenerateButtonClicked = () => {
    if (wsOutline) {
      wsOutline.close(); // Close the existing WebSocket connection if it exists
    }
    settotalLoadingCompleted(false);
  };
  // Define your outlines array

  useEffect(() => {
    if (stringWords == "") {
      if (wsOutline) {
        wsOutline.close(); // Close the existing WebSocket connection if it exists
      }
      setoutlinesArray([]);
      setSelectedTitle("");
      setfinalOutputString("");
    }
    let replace = stringWords.replaceAll("####", "");

    if (replace != "" && !loader) {
      setoutlinesArray([]);
      setSelectedTitle(replace);
      postWebsocketDetailsStepTwoOutlines(replace);
    }
    setstringOutput(replace);
  }, [stringWords, loader]);
  // console.log(loader, "loader");
  useEffect(() => {
    if (loader) {
      settotalLoadingCompleted(true);
    }
  }, [loader]);
  // console.log(stringWords, "string words... in one step");
  function getKeywordsAndPercentage(obj, key) {
    const value = obj[key];
    if (value) {
      let percentage = parseFloat(value[1]?.percentage || 0);
      let keywords = value[0];
      return {
        percentage,
        keywords,
      };
    } else {
      return null; // Key not found in the object
    }
  }
  console.log(outlinesCompleted, "outlinesCompleted...");

  useEffect(() => {
    if (Object.keys(outlinesTotal || {}).length === 0) {
      return;
    }

    let keys = Object.keys(outlinesTotal);
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    let selected_file_data = {
      content_id: selectedFile?.content_id || selectedFile?.id || "",
      space_id: selectedFile?.space_id || "",
      folder_id: selectedFile?.folder_id || "",
    };


    if (keys.length > 0 && outlinesCompleted) {
      let currentWs = null;
      let isUnmounted = false;
      let selectedTitleReplace = selectedTitle.replace(/\d\.?/g, "");


      function sendOutlinesSequentially(outlines, index = 0, string) {
        let string2 = string;
        if (index >= outlines.length || isUnmounted) {
          console.log("All outlines sent successfully or component unmounted.");
          return Promise.resolve();
        }

        const outline = outlines[index];


        return new Promise((resolve, reject) => {
          if (currentWs) {
            currentWs.close();
            currentWs = null;
          }

          const newWs = new WebSocket(
            `${process.env.REACT_APP_BASE_URL}/outline_blog_generate_2`
          );
          currentWs = newWs;
          setonestepWS(newWs);


          const keyWordsAndPercentage = getKeywordsAndPercentage(
            outlinesTotal,
            outline
          );
          let keywords = keyWordsAndPercentage?.keywords;
          let percentage = keyWordsAndPercentage?.percentage;
          let no_of_words = parseInt(number_of_words || 0);
          let words = Math.round((percentage * no_of_words) / 100);

          let data = {
            title: selectedTitle,
            outline: outline,
            words: words?.toString(),
            tone: selectedInputData?.tone,
            keywords: JSON.stringify(keywords || []),
            all_outlines: JSON.stringify(outlinesTotal || {}),
            add_info: selectedInputData?.add_info,
            temprature: 0.4,
          };

          newWs.onopen = function open() {
            let new_data = {
              ...data,
              token: localStorage.getItem("login_token"),
            };
            if (getPlanDetails().planId === 3) {
              let login_data = JSON.parse(
                localStorage.getItem("login_data")
              )?.resp_user;
              let model_id = login_data?.model_id;
              new_data["model"] = model_id || "model_3";
            }

            if(index===0){
              string2 += `\n\n ### ${selectedTitleReplace} \n\n`;
              setfinalOutputString(`\n\n ### ${selectedTitleReplace} \n\n`);
              setfinalOutputString((prev) => (prev += "\n\n"));
            }
            string2 += `\n\n ### ${outline} \n\n`;
            setfinalOutputString(
              (prev) => (prev += `\n\n ### ${outline} \n\n`)
            );
            setfinalOutputString((prev) => (prev += "\n\n"));

            const dataToSend = JSON.stringify(new_data);
            newWs.send(dataToSend);
          };

          newWs.onmessage = function incoming(data) {
            const word = data.data;
            if (word?.trim() == "Token Expired") {
              localStorage.removeItem("login_token");
              localStorage.removeItem("login_data");
              navigate("/");
            }
            if (
              word?.charAt(0) === "{" &&
              word.charAt(word.length - 1) === "}"
            ) {
              newWs.close();
              currentWs = null;
              setoneByOneCompleted(false);
              string2 += "\n\n";
              setfinalOutputString((prev) => (prev += "\n\n"));
              const correctedJsonString = word.replace(/'/g, '"');
              let data = {};
              data["content_title"] = selectedFile?.content_title || "";
              data["content_template"] = selectedFile?.content_template || "";
              data = { ...data, ...selectedFile?.content_settings };
              if (index === outlines.length - 1) {
                if (contentEditableRef && contentEditableRef.current) {
                  contentEditableRef.current.style.border = "2px solid #ccc";
                }
                callUpdateToFileApi(
                  data,
                  string2,
                  JSON.parse(correctedJsonString),
                  selected_file_data,
                  selectedFile
                );
              } else {
                callUpdateToFileApi(
                  data,
                  string2,
                  JSON.parse(correctedJsonString),
                  selected_file_data,
                  selectedFile
                );
              }
              resolve();
            } else {
              string2 += word;
              setfinalOutputString((prev) => (prev += word));
            }
          };

          newWs.onclose = () => {
            setoneByOneCompleted(false);
            console.log("closed");
          };

          newWs.onerror = (error) => {
            setoneByOneCompleted(false);
            reject(error);
            console.error("WebSocket error:", error);
          };
        }).then(() => {
          return sendOutlinesSequentially(outlines, index + 1, string2);
        });
      }

      let string = "";
      setfinalOutputString("");

      sendOutlinesSequentially(keys, 0, string)
        .then(() => {
          settotalLoadingCompleted(false);
          console.log("All outlines sent successfully.");
        })
        .catch((error) => {
          settotalLoadingCompleted(false);
          console.error("Error sending outlines:", error);
        });

      return () => {
        isUnmounted = true;
        if (currentWs && currentWs.readyState === WebSocket.OPEN) {
          const stopMessage = JSON.stringify({ stop: "True" });
          currentWs.send(stopMessage);
          setsavingFileData(true);
          // currentWs.close();
          currentWs = null;
          setonestepWS(null);
        }
        settotalLoadingCompleted(false);
      };
    }
  }, [outlinesArray?.length, outlinesCompleted]);

  // console.log(outlinesTotal, "tyeptotal outlines generated...");
  useEffect(() => {
    if (stringOutputOutlines == "") {
      return;
    }
    let replace = stringOutputOutlines.split("\n");

    setoutlinesArray(replace);
  }, [stringOutputOutlines]);
  useEffect(() => {
    if (selectedFile && selectedFile?.contents) {
      setfinalOutputString(selectedFile?.contents);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (wsOutline && wsOutline.readyState === WebSocket.OPEN) {
        const dataToSend = JSON.stringify({
          stop: "True",
        });
        wsOutline.send(dataToSend);
        setsavingFileData(true);
        // wsOutline.close(); // Close the existing WebSocket connection if it exists
      }
    };
  }, [wsOutline]);
  useEffect(() => {
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        closeFunction(); // Close the existing WebSocket connection if it exists
      }
    };
  }, [ws]);

  // console.log(outlinesArray, "outlinesArray");
  // console.log(finalOutputString, "final output string");
  // console.log(selectedInputData, "selectedInputData");

  return (
    <div className="wrapper">
      <div class="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2
          title="One Step Blog"
          description={getDescription("Blog", "One Step Blog")}
        />

        <div
          className="w-full md:w-1/2 px-4 border-r"
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <BlogComponent
            onchangeDescription={onchangeDescription}
            from="One Step Blog"
            mainTitle="One Step Blog"
            loader={totalLoadingCompleted}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
            blog_topic
            keyword_include
            target_audience
            additional_information
            conclusion
            no_of_words
            number_of_words={number_of_words}
            setnumber_of_words={setnumber_of_words}
            getInputData={getInputData}
            stopGenerateButtonClicked={stopGenerateButtonClicked}
          />
        </div>

        <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Editor</h2>
              <div className="ms-auto">
                {!fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(true)}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_fullscreen"></use>
                    </svg>
                  </div>
                )}
                {fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(false)}>
                    <svg className="icon text-[14px] cursor-pointer">
                      <use href="#icon_fullexit"></use>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <TextEditor stringOutput={finalOutputString} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneStepBlog;
