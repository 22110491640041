/* eslint-disable eqeqeq */
import React, {  useEffect, useRef, useState } from "react";
import { Dropdown, Modal} from "flowbite-react";
import useCommonFunctions from "../hooks/common-functions";
import ThreeDotsLoader from "./ThreeDotsLoader";
function CreateNewSpaceModal({
  openModalRemove,
  handleCloseSpaceModal,
  privatepublic,
  setprivatepublic,
  selectedFolderStructure,
  setselectedFolderStructure,
  membersList,
  submitWorkspace,
  selectedMemberList,
  setselectedMemberList,
}) {
  const modalRef = useRef(null);

  const {
    getLetters,
    getBackgroundColor,
    checkInviteEnabled,
  } = useCommonFunctions();
  const [search, setSearch] = useState("");



  const checkFound = (item) => {
    let found = false;
    for (let i = 0; i < selectedMemberList.length; i++) {
      const element = selectedMemberList[i];
      if (element?.user_workspace?.email == item?.user_workspace?.email) {
        found = true;
        break;
      }
    }
    return found;
  };
  const deleteFromSelectedMemberList = (item) => {
    setselectedMemberList((prev) =>
      prev?.filter(
        (member) => member?.user_workspace?.email != item?.user_workspace?.email
      )
    );
  };
  // const getBackgroundColor = (letter) => {
  //   console.log(letter, "letter");
  //   switch (letter.toUpperCase()) {
  //     case "M":
  //       return "#FBC575";
  //     case "V":
  //       return "#60CFFF";
  //     case "B":
  //       return "#F99A71";
  //     // Add more cases for other letters as needed
  //     default:
  //       // Default color if the letter doesn't match any case
  //       return "red"; // You can set any default color here
  //   }
  // };

  // console.log(selectedMemberList, "selected member list...");
  useEffect(() => {
    modalRef.current = document.body;
  }, []);
  return (
    <>
      <Modal
        root={modalRef.current}
        dismissible
        show={openModalRemove === "dismissible"}
        size="lg"
        onClose={() => handleCloseSpaceModal()}
        // className="z-[1000]"
        style={{ overflow: "hidden" }}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">
            {selectedFolderStructure?.mainHeading}
          </h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="pb-3 pt-2">
            <label for="name">{selectedFolderStructure?.title}</label>
            <input
              type="text"
              name="name"
              id="name"
              value={selectedFolderStructure?.value}
              onChange={(e) => {
                setselectedFolderStructure((prev) => ({
                  ...prev,
                  value: e.target.value,
                }));
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder={selectedFolderStructure?.placeholder}
            />
          </div>
          <div className="flex gap-[50px] justify-center pt-4 pb-6">
            <button
              onClick={() => {
                setprivatepublic({
                  public: true,
                  private: false,
                });
                setselectedFolderStructure({
                  ...selectedFolderStructure,
                  space_type: 0,
                });
              }}
              type="button"
              className={`shadow border text-center w-[100px] h-[100px] rounded-[15px] hover:border-[#5BBFDE] hover:text-[#5BBFDE] ${
                privatepublic.public
                  ? "border-[#5BBFDE] text-[#5BBFDE]"
                  : "border-[#BDBDBD] text-[#BDBDBD]"
              }`}
            >
              <div>
                <svg className="icon text-[40px]">
                  <use href="#icon_unlock"></use>
                </svg>
              </div>
              <p className="text-center pt-2">Public</p>
            </button>
            <button
              onClick={() => {
                setprivatepublic({
                  public: false,
                  private: true,
                });
                setselectedFolderStructure({
                  ...selectedFolderStructure,
                  space_type: 1,
                });
              }}
              type="button"
              className={`shadow border text-center w-[100px] h-[100px] rounded-[15px] hover:border-[#5BBFDE] hover:text-[#5BBFDE] ${
                privatepublic.private
                  ? "border-[#5BBFDE] text-[#5BBFDE]"
                  : "border-[#BDBDBD] text-[#BDBDBD]"
              }`}
            >
              <div>
                <svg className="icon text-[40px]">
                  <use href="#icon_lock"></use>
                </svg>
              </div>
              <p className="text-center pt-2">Private</p>
            </button>
          </div>
          <hr />
          {privatepublic.public && (
            <p class="italic font-semibold text-[14px] text-center py-4">
              This Space Will Be Shared With All Member Of This Work Space
            </p>
          )}

          {privatepublic.private && (
            <>
              <p className="pb-2 font-[400]">Shared With</p>
              <div className="flex w-full items-center border border-[#E9EBF0] bg-[#FAFBFC] rounded-full py-1 px-4 mb-4">
                <div className="flex">
                  {selectedMemberList?.slice(0, 4)?.map((item, index) => (
                    <div
                      style={{
                        backgroundColor: getBackgroundColor(
                          item?.user_workspace?.first_name?.charAt(0)
                        ),
                      }}
                      className={`w-[35px] h-[35px] bg-[${getBackgroundColor(
                        item?.user_workspace?.first_name?.charAt(0)
                      )}] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ${
                        index != 0 && "ml-[-10px]"
                      } `}
                    >
                      {/* {console.log(
                        getBackgroundColor(
                          item?.user_workspace?.first_name?.charAt(0)
                        ),
                        "background color"
                      )} */}
                      {getLetters(
                        item?.user_workspace?.first_name +
                          " " +
                          item?.user_workspace?.last_name
                      )}
                    </div>
                  ))}

                  {/* <div className="w-[35px] h-[35px] bg-[#BA83E4] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ml-[-10px]">
                    VJ
                  </div> */}
                  {selectedMemberList?.length > 4 && (
                    <div className="w-[35px] h-[35px] bg-[#6AC89B] text-[#fff] font-semibold text-[14px] rounded-full flex items-center justify-center ml-[-10px]">
                      +{selectedMemberList?.slice(4)?.length}
                    </div>
                  )}
                </div>
                <div className="ms-auto flex justify-center modal-dropdown">
                  <Dropdown className="" label="+ Add Member" disabled={!checkInviteEnabled()}>
                    <div className="w-full min-w-[220px] px-3">
                      <div className="flex items-center pb-1">
                        <svg className="icon text-[14px] me-2">
                          <use href="#icon_search"></use>
                        </svg>
                        <input
                          type="text"
                          className="p-0 m-0 border-0 focus:ring-0 focus:ring-offset-0"
                          placeholder="Search By Name"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div className="max-h-[130px] overflow-y-auto pr-2">
                        {membersList
                          ?.filter((item) => {
                            let loginData = JSON.parse(
                              localStorage.getItem("login_data")
                            );
                            let user_id = loginData?.resp_user?.id;
                            if (
                              item?.user_id != user_id &&
                              item?.user_workspace?.first_name
                                ?.toLowerCase()
                                ?.includes(search.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                          ?.map((item, index) => (
                            <div
                              className="flex items-center cursor-pointer mb-2"
                              onClick={() => {
                                if (!checkFound(item)) {
                                  setselectedMemberList([
                                    ...selectedMemberList,
                                    item,
                                  ]);
                                } else {
                                  deleteFromSelectedMemberList(item);
                                }
                              }}
                            >
                              <div className="flex items-center">
                                <div
                                  className={`rounded-full flex items-center justify-center p-1 border ${
                                    checkFound(item) && "border-[#63DA76]"
                                  } me-2`}
                                >
                                  <div
                                    style={{
                                      backgroundColor: getBackgroundColor(
                                        item?.user_workspace?.first_name?.charAt(
                                          0
                                        )
                                      ),
                                    }}
                                    className={`w-[30px] h-[30px] bg-[${getBackgroundColor(
                                      item?.user_workspace?.first_name?.charAt(
                                        0
                                      )
                                    )}] text-[#fff] font-semibold text-[12px] rounded-full flex items-center justify-center`}
                                  >
                                    {getLetters(
                                      item?.user_workspace?.first_name +
                                        " " +
                                        item?.user_workspace?.last_name
                                    )}
                                  </div>
                                </div>
                                <span className="text-[14px]">
                                  {item?.user_workspace?.first_name}{" "}
                                  {item?.user_workspace?.last_name}
                                </span>
                              </div>
                              {checkFound(item) ? (
                                <div className="ms-auto">
                                  <button
                                    type="button"
                                    className=""
                                    // onClick={() => {
                                    //   deleteFromSelectedMemberList(item);
                                    // }}
                                  >
                                    <svg className="icon text-[18px]">
                                      <use href="#icon_closered"></use>
                                    </svg>
                                  </button>
                                </div>
                              ) : (
                                <div className="ms-auto">
                                  <button
                                    type="button"
                                    className=""
                                    onClick={() => {
                                      deleteFromSelectedMemberList(item);
                                    }}
                                  >
                                    <svg className="icon text-[18px]">
                                      <use href="#icon_addgreen"></use>
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </Dropdown>
                </div>
                {/* 
                <div className="ms-auto flex justify-center modal-dropdown relative z-[1001]">
                  <div class="relative group">
                    <button
                      id="dropdown-button"
                      class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                    >
                      <span class="mr-2">Open Dropdown</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 ml-2 -mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <div
                      id="dropdown-menu"
                      class="w-full absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-[10002]"
                    >
                      <input
                        id="search-input"
                        class="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                        type="text"
                        placeholder="Search items"
                        autocomplete="off"
                      />
                      <div className="max-h-[130px] overflow-y-auto pr-2">
                        {membersList?.map((item, index) => (
                          <div
                            className="flex items-center cursor-pointer mb-2"
                            onClick={() => {
                              if (!checkFound(item)) {
                                setselectedMemberList([
                                  ...selectedMemberList,
                                  item,
                                ]);
                              } else {
                                deleteFromSelectedMemberList(item);
                              }
                            }}
                          >
                            <div className="flex items-center">
                              <div className="rounded-full flex items-center justify-center p-1 border border-[#63DA76] me-2">
                                <div
                                  className={`w-[30px] h-[30px] bg-[${getBackgroundColor(
                                    item?.user_workspace?.first_name?.charAt(0)
                                  )}] text-[#fff] font-semibold text-[12px] rounded-full flex items-center justify-center`}
                                >
                                  {getLetters(
                                    item?.user_workspace?.first_name +
                                      " " +
                                      item?.user_workspace?.last_name
                                  )}
                                </div>
                              </div>
                              <span className="text-[14px]">
                                {item?.user_workspace?.first_name}{" "}
                                {item?.user_workspace?.last_name}
                              </span>
                            </div>
                            {checkFound(item) ? (
                              <div className="ms-auto">
                                <button
                                  type="button"
                                  className=""
                                  // onClick={() => {
                                  //   deleteFromSelectedMemberList(item);
                                  // }}
                                >
                                  <svg className="icon text-[18px]">
                                    <use href="#icon_closered"></use>
                                  </svg>
                                </button>
                              </div>
                            ) : (
                              <div className="ms-auto">
                                <button
                                  type="button"
                                  className=""
                                  onClick={() => {
                                    deleteFromSelectedMemberList(item);
                                  }}
                                >
                                  <svg className="icon text-[18px]">
                                    <use href="#icon_addgreen"></use>
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="ms-auto flex justify-center modal-dropdown relative z-[1001]">
                  <div className="">
                    <button
                      className="ms-auto flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                      onClick={toggleDropdown}
                    >
                      + Add Member
                    </button>

                    {isOpen && (
                      <div className="absolute top-full left-0 mt-2 bg-white border border-gray-300 shadow-md rounded-md w-full min-w-[220px] z-[1002]">
                        <div className="p-3">
                          <div className="flex items-center pb-1">
                            <svg className="text-gray-500 icon text-[14px] me-2">
                              <use href="#icon_search"></use>
                            </svg>
                            <input
                              type="text"
                              className="p-2 border border-gray-300 rounded w-full focus:outline-none"
                              placeholder="Search By Name"
                              value={search}
                              style={{ border: "none", outline: "none" }}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>

                          <div className="max-h-[130px] overflow-y-auto pr-2">
                            {membersList.map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center cursor-pointer mb-2"
                              >
                                <div
                                  className="flex items-center"
                                  onClick={() => {
                                    if (!checkFound(item)) {
                                      setselectedMemberList([
                                        ...selectedMemberList,
                                        item,
                                      ]);
                                    }
                                  }}
                                >
                                  <div className="rounded-full flex items-center justify-center p-1 border border-[#63DA76] me-2">
                                    <div className="w-[30px] h-[30px] bg-[#FBC575] text-[#fff] font-semibold text-[12px] rounded-full flex items-center justify-center">
                                      {getLetters(
                                        item?.user_workspace?.first_name +
                                          " " +
                                          item?.user_workspace?.last_name
                                      )}
                                    </div>
                                  </div>
                                  <span className="text-[14px]">
                                    {item?.user_workspace?.first_name}{" "}
                                    {item?.user_workspace?.last_name}
                                  </span>
                                </div>
                                {checkFound(item) && (
                                  <div className="ms-auto">
                                    <button
                                      type="button"
                                      className=""
                                      onClick={() => {
                                        deleteFromSelectedMemberList(item);
                                      }}
                                    >
                                      <svg className="icon text-[18px]">
                                        <use href="#icon_closered"></use>
                                      </svg>
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </>
          )}
          <div className="flex">
            <button
              type="button"
              className="flex justify-center items-center bg-[#85D977] hover:bg-[#55b943] rounded-full text-[#fff] px-8 py-2 w-full"
              onClick={() => {
                if (selectedFolderStructure?.value?.trim() == "") {
                  return;
                }
                submitWorkspace();
              }}
            >
              {selectedFolderStructure.loading ? (
                <div className="flex items-center">
                  <div></div>
                  <div>
                    {selectedFolderStructure?.mainHeading === "Update Space"
                      ? "Updating"
                      : "Creating"}
                  </div>
                  <div>
                    <ThreeDotsLoader />
                  </div>
                </div>
              ) : (
                <>
                  {selectedFolderStructure?.mainHeading === "Update Space"
                    ? "Update"
                    : "Create"}
                </>
              )}
              {/* {selectedFolderStructure?.mainHeading === "Update Space"
                ? "Update"
                : "Create"} */}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateNewSpaceModal;
