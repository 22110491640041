/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../data/languages";
import ButtonComponent from "./ButtonComponent";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import FileSave from "./FileSave";
import PopupModals from "./PopupModals";
import useCommonFunctions from "../hooks/common-functions";

function EmailComponent({
  loader,
  postWebsocketDetails,
  mainTitle,
  from,
  closeFunction,
  product_name,
  product_type,
  benefit_of_service,
  what_do_you_expect,
  product_service_name,
  product_service_details,
  discount,
  claim,
  event_topic_name,
  benefits_to_attend,
  event_value,
  event_registration,
  brand_name,
  brand_description,
  benefits,
  target_customers,
}) {
  const context = useContext(UserContext);
  const { setselectedFile, setselectedContentSettings } = context;
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  const { assignToFolderApi } = useCommonApi();
  const { gettemplateWordCount } = useCommonFunctions();
  const [inputData, setInputData] = useState({
    company_name: "",
    products: "",
    expect: "",
    features: "",
    tone: "",
    language: "English",
    product: "",
    descrition: "",
    discount: "",
    claim: "",
    topic: "",
    benefits: "",
    venue: "",
    date: "",
    modified_date: "",
    product_name: "",
    target_audience: "",
    content_title: selectedFile?.content_title,
  });
  const [editFile, seteditFile] = useState(false);
  const chooseSocialMedia="Choose Media";
  const [popupMessage, setpopupMessage] = useState("");
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  console.log(loader, "loader");
  const getModifiedDate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (inputData.date != "") {
      let modifiedDate = getModifiedDate(inputData.date);
      setInputData({ ...inputData, modified_date: modifiedDate });
    }
  }, [inputData.date]);
  const onClickSubmitFn = (typeOfSubmit = "generate") => {
    let data = {};
    let titleTemplate = {
      "Subscriber Welcome Email": "subscriber_welcome_mail",
      "Discount Email": "discount_mail",
      "Event Promotion Email": "event_promotional_mail",
      "Product Launch Email": "product_launch_mail",
    };
    if (from == "Subscriber Welcome Email") {
      data = {
        company_name: inputData.company_name,
        products: inputData.products,
        expect: inputData.expect,
        features: inputData.features,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Subscriber Welcome Email",
      };
      // postWebsocketDetails(data, "subscriber_welcome_mail");
    } else if (from == "Discount Email") {
      data = {
        company_name: inputData.company_name,
        product: inputData.product,
        description: inputData.descrition,
        discount: inputData.discount,
        claim: inputData.claim,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Discount Email",
      };
      // postWebsocketDetails(data, "discount_mail");
    } else if (from == "Event Promotion Email") {
      data = {
        topic: inputData.topic,
        benefits: inputData.benefits,
        venue: inputData.venue,
        date: inputData.modified_date,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Event Promotion Email",
      };
      // postWebsocketDetails(data, "event_promotional_mail");
    } else if (from == "Product Launch Email") {
      data = {
        product_name: inputData.product_name,
        benefits: inputData.benefits,
        description: inputData.descrition,
        target_audience: inputData.target_audience,
        tone: inputData.tone,
        language: inputData.language,
        content_title: inputData.content_title,
        content_template: "Product Launch Email",
      };
      // postWebsocketDetails(data, "product_launch_mail");
    }
    if (typeOfSubmit === "save") {
      assignToFolderApi(
        selectedFile?.id,
        selectedFile?.space_id,
        selectedFile?.folder_id,
        inputData?.content_title
      );
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...selectedFile,
          content_title: inputData?.content_title || "",
        })
      );
      setselectedFile({
        ...selectedFile,
        content_title: inputData?.content_title || "",
      });
    } else {
      let template = titleTemplate[from];
      postWebsocketDetails(data, template);
    }
  };
  useEffect(() => {
    if (selectedFile && selectedFile?.content_settings) {
      const getSettingKey = (key) => {
        let content_settings = selectedFile?.content_settings;
        return content_settings[key];
      };
      let obj = {};
      for (let i = 0; i < selectedFile?.content_settings?.length; i++) {
        const element = selectedFile?.content_settings[i];
        obj[element?.content_key] = element?.content_value;
      }
      obj["content_title"] = selectedFile?.content_title;
      obj["content_template"] = selectedFile?.content_template;
      if (selectedFile?.content_template == "Subscriber Welcome Email") {
        setInputData({
          ...inputData,
          company_name: getSettingKey("company_name"),
          products: getSettingKey("products"),
          expect: getSettingKey("expect"),
          features: getSettingKey("features"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Subscriber Welcome Email",
        });
      } else if (selectedFile?.content_template == "Discount Email") {
        setInputData({
          ...inputData,
          company_name: getSettingKey("company_name"),
          product: getSettingKey("product"),
          descrition: getSettingKey("description"),
          discount: getSettingKey("discount"),
          claim: getSettingKey("claim"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Discount Email",
        });
      }
      if (selectedFile?.content_template == "Event Promotion Email") {
        setInputData({
          ...inputData,
          topic: getSettingKey("topic"),
          benefits: getSettingKey("benefits"),
          venue: getSettingKey("venue"),
          modified_date: getSettingKey("date"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Event Promotion Email",
        });
      }
      if (selectedFile?.content_template == "Product Launch Email") {
        setInputData({
          ...inputData,
          product_name: getSettingKey("product_name"),
          benefits: getSettingKey("benefits"),
          descrition: getSettingKey("descrition"),
          target_audience: getSettingKey("target_audience"),
          tone: getSettingKey("tone"),
          language: getSettingKey("language"),
          content_title: selectedFile?.content_title,
          content_template: "Product Launch Email",
        });
      }
      setselectedContentSettings(obj);
      localStorage.setItem("selectedContentSettings", JSON.stringify(obj));
    } else {
      let language = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.language;
      if (language) {
        setInputData({ ...inputData, language: language });
      }
    }
    // if (selectedFile) {
    //   if (selectedFile?.content_title) {
    //     setInputData({
    //       ...inputData,
    //       content_title: selectedFile.content_title,
    //     });
    //   }
    // }
  }, []);
  return (
    <>
      <div className="h-full overflow-auto pr-2 pb-2 pl-[2px]">
        <FileSave
          editFile={editFile}
          seteditFile={seteditFile}
          inputData={inputData}
          handleChange={handleChange}
          onClickSubmitFn={onClickSubmitFn}
        />
        <h1 className="font-bold text-[20px] pb-2">{mainTitle}</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (gettemplateWordCount() <= 0) {
              setpopupMessage("Generate Content");
              setshowModal({
                first: true,
                second: false,
                third: false,
              });
              return;
            }
            onClickSubmitFn("generate");
          }}
        >
          {product_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="company_name"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company_name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company Name"
                value={inputData.company_name}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {product_service_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="product"
              >
                Product / Service Name
              </label>
              <input
                type="text"
                id="product"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.product}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {event_topic_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="topic"
              >
                Event Topic / Name
              </label>
              <input
                type="text"
                id="topic"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Event Topic / Name"
                value={inputData.topic}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {brand_name && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="product_name"
              >
                Product / Service / Brand Name
              </label>
              <input
                type="text"
                id="product_name"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Product / Service / Brand Name"
                value={inputData.product_name}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {brand_description && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="descrition"
              >
                Product / Service / Brand Description
              </label>

              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="descrition"
                name="msgarea"
                placeholder="Enter here."
                value={inputData.descrition}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          )}
          {benefits && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="benefits"
              >
                Benefits
              </label>

              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="benefits"
                name="msgarea"
                placeholder="Enter here."
                value={inputData.benefits}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          )}
          {target_customers && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="target_audience"
              >
                Target Customers
              </label>
              <input
                type="text"
                id="target_audience"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here."
                value={inputData.target_audience}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {benefits_to_attend && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="benefits"
              >
                Benefits To Attend The Event
              </label>
              <input
                type="text"
                id="benefits"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter here"
                value={inputData.benefits}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {event_value && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="venue"
              >
                Event Venue
              </label>
              <input
                type="text"
                id="venue"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter location, date, time"
                value={inputData.venue}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {event_registration && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="date"
              >
                Event Registration End Date
              </label>
              <input
                type="date"
                id="date"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Registration End Date"
                value={inputData.date}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {product_type && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="products"
              >
                Product / Service
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="products"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.products}
                required
              ></textarea>
            </div>
          )}
          {benefit_of_service && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="features"
              >
                Benefit Of Service / Product
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="features"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.features}
                required
              ></textarea>
            </div>
          )}
          {product_service_details && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="descrition"
              >
                Product / Service Description
              </label>
              <textarea
                className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                id="descrition"
                name="msgarea"
                placeholder="Enter here."
                onChange={handleChange}
                value={inputData.descrition}
                required
              ></textarea>
            </div>
          )}
          {discount && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="discount"
              >
                Discount Offer
              </label>
              <input
                type="text"
                id="discount"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company Name"
                value={inputData.discount}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {claim && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="claim"
              >
                How To Claim
              </label>
              <input
                type="text"
                id="claim"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Enter Company Name"
                value={inputData.claim}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {what_do_you_expect && (
            <div className="pb-3">
              <label
                class="block mb-2 font-semibold text-[18px]"
                htmlFor="expect"
              >
                What Do Expect Next
              </label>
              <input
                type="text"
                id="expect"
                className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                placeholder="Add additional Information you want to convey to your Subscriber"
                value={inputData.expect}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="tone">
              Choose Tone
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="cars"
              id="tone"
              value={inputData.tone}
              required
              onChange={handleChange}
            >
              <option value="">Choose Tone</option>
              <option value="Assertive">Assertive</option>
              <option value="Appreciative">Appreciative</option>
              <option value="Awestruck">Awestruck</option>
              <option value="Candid">Candid</option>
              <option value="Casual">Casual</option>
              <option value="Cautionary">Cautionary</option>
              <option value="Convincing">Convincing</option>
              <option value="Critical">Critical</option>
              <option value="Earnest">Earnest</option>
              <option value="Enthusiastic">Enthusiastic</option>
              <option value="Formal">Formal</option>
              <option value="Funny">Funny</option>
              <option value="Humble">Humble</option>
              <option value="Humorous">Humorous</option>
              <option value="Informative">Informative</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Joyful">Joyful</option>
              <option value="Passionate">Passionate</option>
              <option value="Thoughtful">Thoughtful</option>
              <option value="Urgent">Urgent</option>
              <option value="Worried">Worried</option>
              <option value="Bold">Bold</option>
              <option value="Persuasive">Persuasive</option>
              <option value="Exciting">Exciting</option>
            </select>
          </div>
          <div className="pb-3">
            <label class="block mb-2 font-semibold text-[18px]" for="language">
              Language
            </label>
            <select
              className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
              name="lang"
              id="language"
              value={inputData.language}
              required
              onChange={handleChange}
            >
              {languages?.map((lang, lang_index) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-3  mb-2  text-[18px]">
            {JSON.parse(localStorage.getItem("login_data"))?.resp_user
              ?.language || "English"}{" "}
            has set as default language, you can change it in settings
          </div>
          <ButtonComponent
            loader={loader}
            inputData={inputData}
            from={from}
            postWebsocketDetails={postWebsocketDetails}
            mainTitle={mainTitle}
            chooseSocialMedia={chooseSocialMedia}
            closeFunction={closeFunction}
          />
        </form>
      </div>
      <PopupModals
        setshowModal={setshowModal}
        showModal={showModal}
        popupMessage={popupMessage}
      />
    </>
  );
}

export default EmailComponent;
