/* eslint-disable eqeqeq */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import axios from "axios";
import useCommonFunctions from "./common-functions";
const useCommonApi = () => {
  const context = useContext(UserContext);
  const {
    setrecentFilesList,
    selectedWorkspace,
    userSpaces,
    setuserSpaces,
    setselectedFile,
    setchatCalledOnce,
    savingFileData,
    setsavingFileData,
    setchatfilesFetched,
    allrefs,
    settrainingHistoryFetched,
    settrainingHistory
  } = context;
  const { replacingWordCount } = useCommonFunctions();
  const navigate = useNavigate();
  // console.log(selectedWorkspace, "s;ecetd workkk");

  const getRecentFilesAll = async (space_id, folder_id) => {
    if (savingFileData) return;
    try {
      // Get the token from local storage
      const token = localStorage.getItem("login_token"); // Replace with your actual key

      // Dynamic workspace_id based on selectedworkspace
      let selectedWorkspacels = JSON.parse(
        localStorage.getItem("selected_workspace") || "{}"
      );
      const workspaceId = JSON.parse(
        localStorage.getItem("selected_workspace") || "{}"
      )?.workspace_id;
      if (Object.keys(selectedWorkspacels || {})?.length == 0) {
        return;
      }

      // Make a GraphQL request using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        {
          query: `
            query ($workspace_id: Int!, $space_id: Int, $folder_id: Int) {
              usercontents(workspace_id: $workspace_id, space_id: $space_id, folder_id: $folder_id) {
                id
                user_id
                workspace_id
                space_id
                folder_id
                content_title
                content_count
                content_template
                contents
                content_settings
                status
                outline_text
                blog_step
              }
            }
          `,
          variables: {
            workspace_id: parseInt(workspaceId),
            space_id: parseInt(space_id),
            folder_id: parseInt(folder_id),
          },
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );
      return response.data.data.usercontents;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.error("Error fetching data:", error);
    }
  };
  const getRecentFiles = async (
    space_id = null,
    folder_id = null,
    from = "normal"
  ) => {
    if (from == "folder_file") {
      const response = await getRecentFilesAll(space_id, folder_id);
      return response;
    }
    try {
      // Get the token from local storage
      const token = localStorage.getItem("login_token"); // Replace with your actual key

      // Dynamic workspace_id based on selectedworkspace
      let selectedWorkspacels = JSON.parse(
        localStorage.getItem("selected_workspace") || "{}"
      );
      const workspaceId = JSON.parse(
        localStorage.getItem("selected_workspace") || "{}"
      )?.workspace_id;
      if (Object.keys(selectedWorkspacels || {})?.length == 0) {
        return;
      }

      // Make a GraphQL request using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        {
          query: `
            query ($workspace_id: Int!, $space_id: Int, $folder_id: Int) {
              usercontents(workspace_id: $workspace_id, space_id: $space_id, folder_id: $folder_id) {
                id
                user_id
                workspace_id
                space_id
                folder_id
                content_title
                content_count
                content_template
                contents
                content_settings
                status
                outline_text
                blog_step
              }
            }
          `,
          variables: {
            workspace_id: parseInt(workspaceId),
            space_id: null,
            folder_id: null,
          },
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );
      setrecentFilesList(response.data.data.usercontents);
      // if (space_id && folder_id) {
      return response.data.data.usercontents;
      // }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.error("Error fetching data:", error);
    }
  };
  const getSpaceFolderList = async (space_id) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/folder/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          space_id: space_id,
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        let folders = response?.data?.findfolders;
        folders = folders?.map((item, index) => {
          return {
            ...item,
            type: "folder",
            level: "2",
            open: false,
            tree_id: new Date().getTime().toString() + index + 2,
          };
        });
        let recentFiles = await getRecentFiles(space_id, null, "folder_file");
        console.log(recentFiles, "recent files from folder lists");
        recentFiles = recentFiles
          ?.filter((item) => !item?.folder_id)
          ?.map((item, index) => {
            return {
              ...item,
              type: "file",
              level: "2",
              open: false,
              tree_id: new Date().getTime().toString() + index + 2,
            };
          });
        for (let i = 0; i < recentFiles?.length; i++) {
          const element = recentFiles[i];
          folders?.push(element);
        }
        const getFolders = (space_name) => {
          return folders?.map((item, index) => {
            return {
              ...item,
              space_name: space_name,
            };
          });
        };

        if (folders?.length > 0) {
          setuserSpaces((prev) =>
            prev?.map((item) => {
              if (item?.space_id == space_id) {
                return {
                  ...item,
                  submenu: getFolders(item?.user_workspace_space?.space_name),
                };
              } else {
                return item;
              }
            })
          );
        }
        return response?.data?.findfolders;
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const callUpdateToFileApi = async (
    data,
    stringWords,
    correctedJsonString = {},
    selected_file_data,
    selectedFile,
    blog_step = "",
    outline_text = {}
  ) => {
    try {
      let data_incoming1 = { ...data };
      delete data_incoming1?.content_title;
      delete data_incoming1?.content_template;
      data_incoming1.language = data_incoming1.language || "English";

      let contentData = {
        user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user?.id,
        content_id:
          selected_file_data.content_id || selected_file_data.id || "",
        workspace_id: JSON.parse(
          localStorage.getItem("selected_workspace") || "{}"
        )?.workspace_id, //mandatory of selected workspace
        space_id: selected_file_data?.space_id || "", // if available other wise send blank
        folder_id: selected_file_data?.folder_id || "", // if available other wise send blank
        content_title: data?.content_title,
        content_template: data?.content_template,
        contents: stringWords,
        content_settings: data_incoming1,
        input_contents: "",
        output_contents: "",
        ...correctedJsonString,
        blog_step: blog_step,
        outline_type: "",
        no_of_outline: "",
        outline_text: outline_text,
      };
      // console.log(contentData, "content data");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/update`,
        // JSON.parse(localStorage.getItem("selectedFile"))
        // ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/update`
        // : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: contentData,
      });
      // console.log(response, "response");
      if (!response.data.error) {
        replacingWordCount(response.data.bal_template_word);
        localStorage.setItem(
          "selected_file",
          JSON.stringify({
            ...selectedFile,
            ...response.data,
            contents: stringWords,
            content_settings: data,
            content_title: data?.content_title,
            content_template: data?.content_template,
          })
        );
        setselectedFile((prev) => ({
          ...prev,
          ...response.data,
          contents: stringWords,
        }));
        setsavingFileData(false);
        return {
          error: false,
          message: response?.data?.message,
        };
      } else {
        setsavingFileData(false);
        return {
          error: true,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      setsavingFileData(false);
      console.log(error, "error");
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const deleteFileApi = async (id, type) => {
    let url = "";
    let data = {};
    if (type == "file") {
      url = `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/delete`;
      data = {
        content_id: id,
        user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user?.id,
      };
    } else if (type == "folder") {
      url = `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/folder/delete`;
      data = {
        folder_id: id,
      };
    } else if (type == "space") {
      url = `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/delete`;
      data = {
        space_id: id,
      };
    }
    try {
      let response = await axios({
        url: url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      // console.log(response, "response");
      if (!response.data.error) {
        return {
          error: false,
          message: response?.data?.message,
        };
      } else {
        return {
          error: true,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      return {
        error: true,
        message: error?.message,
      };
    }
  };
  const assignToFolderApi = async (
    content_id,
    space_id,
    folder_id,
    content_title
  ) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/assign/space_folder`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          // user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user?.id,
          // content_id: content_id,
          content_id:
            JSON.parse(localStorage.getItem("selected_file")).id ||
            JSON.parse(localStorage.getItem("selected_file")).content_id,
          space_id: space_id,
          folder_id: folder_id,
          content_title: content_title,
        },
      });
      if (!response.data.error) {
        return {
          error: false,
          message: response?.data?.message,
        };
      } else {
        return {
          error: true,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      return {
        error: true,
        message: error?.message,
      };
    }
  };
  const getWorkSpaceMembers = async (workspace_id) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/members`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          workspace_id: workspace_id,
        },
      });
      if (!response.data.error) {
        return {
          error: false,
          message: response?.data?.message,
          members: response?.data,
        };
      } else {
        return {
          error: true,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      return {
        error: true,
        message: error?.message,
      };
    }
  };
  const getSpaceList = async () => {
    try {
      // let workspaceId = JSON.parse(
      //   localStorage.getItem("selected_workspace")
      // )?.workspace_id;
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          workspace_id: selectedWorkspace?.workspace_id,
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let userspaces = response.data.userspaces;
        userspaces = userspaces?.map((item, index) => {
          return {
            ...item,
            type: "folder",
            level: "1",
            submenu: [],
            open: false,
            tree_id: new Date().getTime().toString() + index + 1,
          };
        });
        setuserSpaces(userspaces);
        // setTimeout(() => {
        //   const asideContainer = asideRef.current;

        //   // Scroll to the bottom
        //   asideContainer.scrollTop = asideContainer.scrollHeight;
        // }, 10);
      } else {
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }

      console.log(error);
    }
  };
  const getWorkspaces = async () => {
    try {
      // Get the token from local storage
      const token = localStorage.getItem("login_token"); // Replace with your actual key

      // Dynamic workspace_id based on selectedworkspace

      // Make a GraphQL request using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        {
          query: `
            query{
              getUserworkspaces{
                user_id,
                workspace_id,
                user_type_id,
                workspace{
                    workspace_id,
                    workspace_name,
                    uniq_id,
                    workspace_avtar,
                    workspace_icon,
                }
              }
            }
          `,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      localStorage.setItem(
        "workspaces_list",
        JSON.stringify(response?.data?.data?.getUserworkspaces)
      );
      // console.log(response.data, "graph ql workspace list");
      return response?.data?.data?.getUserworkspaces;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.error("Error fetching data:", error);
    }
  };
  const getChatDetails = async (type, thread_folder = "") => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/chat_folder/file_group?thread_type=${type}&thread_folder=${thread_folder}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let chat_folder_files = response?.data?.chat_folder_files;
        let folders_dates = [];
        for (let i = 0; i < chat_folder_files.length; i++) {
          const element = chat_folder_files[i];
          if (!folders_dates.includes(element?.thread_folder)) {
            folders_dates.push(element?.thread_folder);
          }
        }
        console.log(folders_dates, "folders date");
        let foldersModify = folders_dates?.map((date) => {
          return {
            type: "folder",
            tree_id: date,
            title: date,
            open: false,
          };
        });
        console.log(foldersModify, "folders modify");
        // folder with files
        const getFiles = (title) => {
          return chat_folder_files
            ?.filter((item) => item.thread_folder === title)
            ?.map((file, index) => {
              return {
                ...file,
                type: "file",
                tree_id: title + file?.thread_id + index,
                title: file?.thread_name,
                open: false,
              };
            });
        };
        let folder_files = foldersModify?.map((item, index) => {
          return {
            ...item,
            submenu: getFiles(item?.title),
          };
        });

        // console.log(folder_files, "folder files");
        if (type == "general") {
          setchatCalledOnce((prev) => ({
            ...prev,
            [`general_folders`]: folder_files,
          }));
        } else {
          setchatCalledOnce((prev) => ({
            ...prev,
            [`youtube_folders`]: folder_files,
          }));
        }
        if(folder_files?.length>0&&type=="general"){
          allrefs?.settrainingJson(prev=>{
            return {
              ...prev,
              chat: {  aside: true,chatinputbox: true },
            }
          })
        }
        if(folder_files?.length>0&&type==="youtube"){
          allrefs?.settrainingJson(prev=>{
            return {
              ...prev,
              youtube: {  aside: true,youtubelinkbox: true,youtubeinputbox: true },
            }
          })
        }
        setchatfilesFetched(true);
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const callFiles = async (title) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/chat_folder/file_group`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          thread_folder: title,
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        return response?.data?.chat_folder_files;
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const handleButtonClick = (type, id) => {
    const navigateLink =
      type === "general" ? "/template/chat" : "/template/youtube-chat";
    const queryString = `?id=${id}`;
    // window.location.href = `${navigateLink}${queryString}`;
    // navigate(`${navigateLink}${queryString}`);
    navigate(navigateLink);
  };

  const chatDetails = async (thread_id, type) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/thread/chat_details`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          thread_id: thread_id,
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        let data = response.data.chat_details;
        if (type == "youtube") {
          handleButtonClick("youtube", thread_id);
        } else {
          handleButtonClick("general", thread_id);
        }
        localStorage.setItem("chat_details", JSON.stringify(data));
        return data;
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };

  const callSaveNewChat = async (data, type) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/chat/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      // console.log(response, "response");
      if (!response.data.error) {
        let file = response?.data?.dbins;
        replacingWordCount(response.data.bal_template_word);
        // handleButtonClick(type, file?.thread_id);
        if (!JSON.parse(localStorage.getItem("chat_file"))) {
          getChatDetails(type);
          localStorage.setItem(
            "chat_file",
            JSON.stringify({
              thread_id: file?.thread_id,
              thread_name: data?.thread_name,
            })
          );
        }
        let chat_details = localStorage.getItem("chat_details") || [];
        if (!chat_details?.length == 0) {
          let chat_details_data = JSON.parse(chat_details);
          chat_details_data.push(file);
          localStorage.setItem(
            "chat_details",
            JSON.stringify(chat_details_data)
          );
        } else {
          localStorage.setItem("chat_details", JSON.stringify([file]));
        }
        return {
          thread_id: file?.thread_id,
          data: file,
        };
      } else {
        return "";
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
      return "";
    }
  };
  const getSpaceFileList = async (space_id, folder_id) => {
    // console.log(space_id, folder_id, "space id and folder id");
    let recentFiles = await getRecentFiles(
      space_id != null ? space_id : null,
      folder_id != null ? folder_id : null,
      "folder_file"
    );

    recentFiles = recentFiles?.map((item, index) => {
      return {
        ...item,
        type: "file",
        level: "3",
        open: false,
        tree_id: new Date().getTime().toString() + index + 3,
        submenu: [],
      };
    });

    let userspaces1 = [...userSpaces];

    for (let i = 0; i < userspaces1.length; i++) {
      let spaces = userspaces1[i];
      // console.log(spaces, "sspaces...");

      if (
        spaces.space_id == space_id &&
        spaces?.submenu &&
        spaces?.submenu?.length > 0
      ) {
        // console.log("came here");
        for (let j = 0; j < spaces.submenu.length; j++) {
          // Fix: Change spaces.length to spaces.submenu.length
          let folders = spaces.submenu[j]; // Fix: Change spaces[j] to spaces.submenu[j]
          if (folders?.folder_id == folder_id) {
            folders.submenu = recentFiles;
            folders.open = true;
          }
        }
      }
    }
    setuserSpaces(userspaces1);
  };
  const callExpireDateCheckApi = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/subscription/check`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        let login_data = JSON.parse(localStorage.getItem("login_data"));
        login_data.resp_user.plan_id = response?.data?.user?.plan_id;
        login_data.resp_user.subscription_id =
          response?.data?.user?.subscription_id;
        login_data.resp_user.language = response?.data?.user?.language;
        login_data.resp_user.workspace_id = response?.data?.user?.workspace_id;
        login_data.resp_user.usersetting = response?.data?.usersetting;
        localStorage.setItem("login_data", JSON.stringify(login_data));
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const afRefCodeFn = async (ref_code) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/update/affliate_referral`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          ref_code: ref_code,
        },
      });
      localStorage.removeItem("ref_code");
      localStorage.removeItem("af_code");
      localStorage.removeItem("continue_with_google");
      if (!response.data.error) {
        navigate("/template", {
          replace: true,
        });
        return "success";
      } else {
        return "error";
      }
    } catch (error) {
      console.log(error, "error");
      return "error";
    }
  };

  async function checkTwitterAccess(setTwitterProfile, setChecking) {
    try {
      setChecking && setChecking(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/social/user/twitter_access`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        setTwitterProfile(response?.data?.data);
      } else {
        setTwitterProfile(null);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setTwitterProfile(null);
    } finally {
      setChecking && setChecking(false);
    }
  }
  const getBillingDetails = async (subscription) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/billing_details`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      console.log(response, "response");
      if (!response.data.error) {
        let details = response.data;
        return details;
      } else {
        return {};
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const getTrainingHistory = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/training/module/history`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
      });
      if (!response.data.error) {
        settrainingHistory(response.data.training_history);
      } else {
      }
      settrainingHistoryFetched(true);
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      settrainingHistoryFetched(true);
    }
  };

  return {
    getRecentFiles,
    getSpaceFolderList,
    callUpdateToFileApi,
    deleteFileApi,
    assignToFolderApi,
    getWorkSpaceMembers,
    getSpaceList,
    getWorkspaces,
    getRecentFilesAll,
    getChatDetails,
    callFiles,
    callSaveNewChat,
    chatDetails,
    getSpaceFileList,
    callExpireDateCheckApi,
    afRefCodeFn,
    checkTwitterAccess,
    getBillingDetails,
    getTrainingHistory
  };
};
export default useCommonApi;
