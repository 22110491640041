/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import axios from "axios";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";

function Workspace3() {
  const [currentStep, setcurrentStep] = useState("name");
  const navigate = useNavigate();
  const mainRef = useRef();
  const nameRef = useRef();
  const imageRef = useRef();
  const inviteRef = useRef();
  const lastRef = useRef();
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [name, setname] = useState("");
  const [loading, setloading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [applyNavClass, setapplyNavClass] = useState(true);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };

  const handleInputChange = (event) => {
    setCurrentEmail(event.target.value);
    // Validate email format
    if (event.target.value == "") {
      setIsValidEmail(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(event.target.value));
  };

  const handleInputKeyDown = (event) => {
    if (!isValidEmail) {
      return;
    }
    // Handle "Enter" key to add the email
    if (event.key === "Enter" && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };
  const inviteEmail = () => {
    if (isValidEmail && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleNext = (nextSection) => {
    setcurrentStep(nextSection);

    // Scroll to the next section with centering adjustment
    if (nextSection === "name") {
      mainRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (nextSection === "image") {
      imageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (nextSection === "invite") {
      inviteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (nextSection === "last") {
      lastRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const uploadIcon = async () => {
    if (!rawFile) {
      return;
    }
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/icons`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("login_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.response.data.message,
      };
    }
  };
  const submitWorkspace = async () => {
    try {
      setloading(true);
      let image = await uploadIcon();
      if (image.error) {
        setsuccessFailureMessage(image.data);
        setopenFailureModal("dismissible");
        setloading(false);
        return;
      }
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user
            ?.id,
          workspace_name: name,
          workspace_icon: "",
          workspace_avtar: image.data,
          invitation_emails: emails,
        },
      });
      setloading(false);
      console.log(response, "response");
      if (!response.data.error) {
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
        setTimeout(() => {
          navigate("/template");
        }, 2000);
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setloading(false);
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const handleChange = (option) => {
    // console.log(option, "option...");
    setSelectedOption(option);
  };
  const handleChangeFileFun = (e) => {
    let file = e.target.files[0];

    setrawFile(file);
  };
  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);

  return (
    <>
      <div className="workspace-main">
        <nav className={`border-b py-4 workspace_nav w-full`} ref={mainRef}>
          <div className="container mx-auto px-6 flex justify-between items-center">
            <div>
              <button
                type="button"
                className="flex items-center hover:text-[red]"
                onClick={() => navigate("/template")}
              >
                <svg className="icon text-[26px] me-1">
                  <use href="#icon_close"></use>
                </svg>
                <span className="font-semibold">Cancel</span>
              </button>
            </div>
            <div>
              <img
                // src="images/logo.png"
                src="images/VisonWithOReplaced.png"
                alt="workspace_img1"
                className="w-full h-[40px]"
              />
            </div>
          </div>
        </nav>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap ">
            <div className=" p-2 pl-20 pr-20 w-full">
              <section
                ref={nameRef}
                className={`${
                  currentStep != "name"
                    ? "opacity-[0.5] pointer-events-none"
                    : "opacity-[1]"
                }`}
              >
                <div className="flex flex-wrap flex-row">
                  <div className="w-1/2  workspace  ">
                    <div className="workspace-main__space"></div>

                    <div className="mt-10">
                      <label className="text-[30px] font-semibold ff-reemkufi">
                        Name Your Workspace
                      </label>
                    </div>
                    <input
                      className="border-[0] py-2 border-b-[1px] w-full input-line focus:ring-0 focus:ring-offset-0 pl-0 font-[400] text-[24px]"
                      type="text"
                      placeholder="Enter your workspace name"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                    <p className="py-2 font-[400] text-[16px]">
                      You Can Use your name or organization’s name as workspace
                    </p>
                    <div className="flex py-8">
                      <button
                        type="button"
                        className="py-2 me-8 px-10 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
                        onClick={() => navigate("/home")}
                      >
                        Back to Home
                      </button>
                      <button
                        type="button"
                        className="flex items-center py-2 px-14 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                        onClick={() => {
                          setapplyNavClass(true);
                          handleNext("image");
                        }}
                        disabled={name == "" ? true : false}
                      >
                        <span>Next</span>
                        <svg className="icon ms-2 text-[20px]">
                          <use href="#icon_arrow"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {currentStep == "name" && (
                    <div className="w-1/2  flex justify-center items-center">
                      <div className="w-[540px] h-[400px] mt-8">
                        <img
                          src="images/workspace_img1.svg"
                          alt="workspace_img1"
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section
                ref={imageRef}
                className={`${
                  currentStep != "image"
                    ? "opacity-[0.5] pointer-events-none"
                    : "opacity-[1]"
                }`}
              >
                <div className="flex flex-wrap flex-row">
                  <div className="w-1/2">
                    <div className="flex items-center mt-20 pt-10">
                      <label className="text-[30px] font-[400] ff-reemkufi">
                        Choose Your Workspace Icon or Avtar
                      </label>
                    </div>
                    <div className="grid grid-cols-2 py-4 w-[70%]">
                      <div className="border-r-2 border-[#E9EBF0] flex justify-center">
                        <div className="">
                          <div className="flex justify-center">
                            <form id="file-upload-form" className="uploader">
                              <input
                                id="file-upload"
                                type="file"
                                name="fileUpload"
                                accept="image/*"
                                onChange={handleChangeFileFun}
                              />

                              <label
                                className="flex justify-center items-center"
                                for="file-upload"
                                id="file-drag"
                              >
                                <img
                                  id="file-image"
                                  src="#"
                                  alt="Preview"
                                  className="hidden"
                                />
                                <div id="start">
                                  <svg className="icon text-[24px]">
                                    <use href="#icon_upload"></use>
                                  </svg>
                                  <div id="notimage" className="hidden text-xl">
                                    Please select an image
                                  </div>
                                </div>
                                <div id="response" className="hidden">
                                  <div id="messages"></div>
                                </div>
                              </label>
                            </form>
                          </div>
                          <h5 className="py-4 text-xl font-[400] text-[16px]">
                            Choose Your Icon
                          </h5>
                        </div>
                      </div>
                      <div className="flex justify-center relative">
                        {/* <div className="">
                    <div className="flex justify-center">
                      <div
                        for="img-upload"
                        className="flex justify-center items-center rounded-full bg-[#F2F7FF] w-[80px] h-[80px] max-w-[80px] max-h-[80px]"
                      >
                        <button>
                          <svg className="icon text-[24px] text-[#097BA9]">
                            <use href="#icon_blueplus"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <h5 className="py-4 text-xl">Choose Your Avtar</h5>
                  </div> */}
                        {/* className="flex justify-center items-center  w-[80px] h-[80px] max-w-[80px] max-h-[80px]" */}

                        <div className="">
                          {previewUrl && (
                            <div className="flex justify-center">
                              <div for="img-upload">
                                <img
                                  src={previewUrl}
                                  className="rounded-full w-[80px] h-[80px]"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex mb-20 pb-10">
                      <button
                        type="button"
                        className="py-2 me-8 px-10 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
                        onClick={() => {
                          setapplyNavClass(false);
                          handleNext("name");
                        }}
                      >
                        Back to Previous Step
                      </button>
                      <button
                        type="button"
                        className="flex items-center py-2 px-14 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                        onClick={() => handleNext("invite")}
                        disabled={previewUrl ? false : true}
                      >
                        <span>Next</span>
                        <svg className="icon ms-2 text-[20px]">
                          <use href="#icon_arrow"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {currentStep == "image" && (
                    <div className="w-1/2  flex justify-center ">
                      <div className="w-[540px] h-[400px] ">
                        <img
                          src="images/workspace_img2.svg"
                          alt="workspace_img2"
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section
                ref={inviteRef}
                className={`${
                  currentStep != "invite"
                    ? "opacity-[0.5] pointer-events-none mt-[-100px]"
                    : "opacity-[1] mt-[-40px]"
                } `}
              >
                <div className="flex flex-wrap flex-row">
                  <div className="w-1/2">
                    <div className="flex items-center mt-20 pt-10">
                      <label className="text-[30px] font-[400] ff-reemkufi">
                        Invite Team Members
                      </label>
                    </div>
                    <div
                      className={`flex flex-wrap gap-2 items-center ${
                        emails?.length > 0 && "py-2"
                      }`}
                    >
                      {emails.map((email, index) => (
                        <div
                          key={index}
                          className="border rounded-md p-1 flex items-center bg-gray-200"
                        >
                          <span>{email}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveEmail(index)}
                            className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          >
                            &#x2715;
                          </button>
                        </div>
                      ))}
                    </div>
                    <input
                      className={`border-[0] py-2 border-b-[1px] w-full input-line focus:ring-0 focus:ring-offset-0 pl-0 font-[400] text-[24px]`}
                      type="email"
                      placeholder="Invite team members"
                      value={currentEmail}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      onBlur={() => {
                        if (currentEmail == "") {
                          setIsValidEmail(true);
                        }
                      }}
                    />
                    {!isValidEmail && (
                      <p className="text-red-500 text-sm mt-1">
                        Please enter a valid email address
                      </p>
                    )}
                    {currentEmail !== "" && isValidEmail && (
                      <p
                        className="border rounded-md pt-1 pb-1 pl-2 pr-2 inline-block bg-gray-200 cursor-pointer mt-1"
                        onClick={inviteEmail}
                      >
                        Invite {currentEmail}
                      </p>
                    )}
                    <p className="py-2 text-xl">
                      {/* Add One Or More Email Ids with Comma Separate{" "} */}
                    </p>
                    <div className="flex py-8 mb-20 pb-20">
                      <button
                        type="button"
                        className="py-2 me-8 px-10 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
                        onClick={() => handleNext("image")}
                      >
                        Back to Previous Step
                      </button>
                      <button
                        type="button"
                        className="flex items-center py-2 px-14 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                        onClick={() => handleNext("last")}
                      >
                        <span>Next</span>
                        <svg className="icon ms-2 text-[20px]">
                          <use href="#icon_arrow"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {currentStep == "invite" && (
                    <div className="w-1/2  flex justify-center mt-[-50px]">
                      <div className="w-[540px] h-[400px] ">
                        <img
                          src="images/workspace_img3.svg"
                          alt="workspace_img1"
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section
                ref={lastRef}
                className={`${
                  currentStep != "last"
                    ? "opacity-[0.5] pointer-events-none"
                    : "opacity-[1]"
                }`}
              >
                <div className="flex flex-wrap flex-row">
                  <div className="w-1/2">
                    <div className="pb-8">
                      <div className="flex">
                        <div className="w-[20px] min-w-[20px] h-[20px] flex items-center justify-center bg-[#88F5A0] rounded-full mt-1">
                          <svg className="icon text-[16px] text-[#fff]">
                            <use href="#icon_tick"></use>
                          </svg>
                        </div>
                        <label className="ms-2 text-[30px] font-[600]">
                          <strong>You’re are set.</strong> Now Go and Explore
                          The Potential of <strong>Vison</strong> And Don’t
                          Forgot To <strong>Have Fun</strong>
                        </label>
                      </div>
                      <div className="flex py-8">
                        <button
                          type="button"
                          className="py-2 me-8 px-10 rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75"
                          onClick={() => handleNext("invite")}
                        >
                          Back to Previous Step
                        </button>
                        <button
                          type="button"
                          className="flex items-center py-2 px-14 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                          onClick={() => {
                            submitWorkspace();
                          }}
                          // disabled={name == "" || !previewUrl}
                        >
                          <span>Have fun with vison</span>
                          {loading ? (
                            <span className="ms-4">
                              <ThreeDotsLoader />
                            </span>
                          ) : (
                            <svg className="icon ms-2 text-[20px]">
                              <use href="#icon_arrow"></use>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {currentStep == "last" && (
                    <div className="w-1/2  flex justify-center mt-[-100px]">
                      <div className="w-[540px] h-[400px] mt-[-90px]">
                        <img
                          src="images/workspace_img4.svg"
                          alt="workspace_img1"
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="workspace-main__space_last"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default Workspace3;
