import { Modal } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";
function PopupModals({ showModal, setshowModal, popupMessage }) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal dismissible show={showModal?.first} size="xl">
        <div class="relative   w-full  ">
          <div class="relative bg-cloud rounded-[8px] shadow-modal">
            <div class="flex items-center">
              <button
                type="button"
                class=" justify-center items-center mt-[-18px] ml-[-18px] transition duration-700 ease-in-out hover:scale-125"
                data-modal-hide="default-modalOne"
                onClick={() => {
                  setshowModal({ ...showModal, first: false });
                }}
              >
                <span>
                  <svg className="icon text-[36px]">
                    <use href="#icon_modal-close"></use>
                  </svg>
                </span>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="flex justify-end">
              <img
                src={window.location.origin + "/images/rocket.svg"}
                className="w-full max-w-[180px] absolute mt-[-80px] mr-[-60px]"
                alt="upgrade"
              />
            </div>

            <div className="p-4 text-start pt-[136px]">
              <p className="text-[40px] font-[400] pb-4">
                Please <strong>Upgrade</strong> <br /> To{" "}
                {popupMessage ? popupMessage : "Use YouTube Chat"}
              </p>

              <p className="text-[26px] font-[400]">
                Fore more Features and better experience please upgrade One
              </p>
            </div>

            <div className="px-12 py-4 pb-6">
              <button
                type="button"
                data-modal-hide="default-modalOne"
                className="flex items-center justify-center py-2 px-10 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7] w-full"
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                <svg className="icon me-4 text-[20px]">
                  <use href="#icon_taaj"></use>
                </svg>
                <span>UPGRADE NOW</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal dismissible show={showModal?.second} size="xl">
        <div class="relative w-full ">
          <div class="relative bg-white rounded-[8px] shadow-modal">
            <div class="flex items-center">
              <button
                type="button"
                class=" justify-center items-center ms-auto mt-[-18px] mr-[-18px] transition duration-700 ease-in-out hover:scale-125"
                data-modal-hide="default-modal"
                onClick={() => {
                  setshowModal({ ...showModal, second: false });
                }}
              >
                <span>
                  <svg className="icon text-[36px]">
                    <use href="#icon_modal-close"></use>
                  </svg>
                </span>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="flex justify-center py-4">
              <img
                src="/images/upgrate.png"
                className="w-full max-w-[280px]"
                alt="upgrade"
              />
            </div>

            <div className="px-4 text-center">
              <p className="text-[36px] font-[400] pb-2">
                Please <strong>Upgrade</strong> <br /> To {popupMessage || ""}
              </p>

              <p className="text-[24px] font-[400]">
                Fore more Features and better experience please upgrade
              </p>
            </div>

            <div className="px-12 py-2 pb-6">
              <button
                type="button"
                data-modal-hide="default-modal"
                className="flex items-center justify-center py-2 px-10 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7] w-full"
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                <svg className="icon me-4 text-[20px]">
                  <use href="#icon_taaj"></use>
                </svg>
                <span>UPGRADE NOW</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal dismissible show={showModal?.third} size="xl">
        <div class="relative  w-full ">
          <div class="relative bg-gradient rounded-[8px] shadow-modal">
            <div class="flex items-center">
              <button
                type="button"
                class=" justify-center items-center ms-auto mt-[-18px] mr-[-18px] transition duration-700 ease-in-out hover:scale-125"
                data-modal-hide="default-modalTwo"
                onClick={() => {
                  setshowModal({ ...showModal, third: false });
                }}
              >
                <span>
                  <svg className="icon text-[36px]">
                    <use href="#icon_modal-close"></use>
                  </svg>
                </span>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="flex justify-center pt-2 mb-[-10px]">
              <img
                src="/images/second-rocket.svg"
                className="w-full"
                alt="upgrade"
              />
            </div>

            <div className="bg-white rounded-b-[8px]">
              <div className="px-4 text-center">
                <p className="text-[36px] font-[400] pb-2">
                  Please <strong>Upgrade</strong> <br /> To {popupMessage}
                </p>

                <p className="text-[24px] font-[400]">
                  Fore more Features and better experience please upgrade
                </p>
              </div>

              <div className="px-12 py-2 pb-6">
                <button
                  type="button"
                  data-modal-hide="default-modalTwo"
                  className="flex items-center justify-center py-2 px-10 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7] w-full"
                  onClick={() => {
                    navigate("/subscription");
                  }}
                >
                  <svg className="icon me-4 text-[20px]">
                    <use href="#icon_taaj"></use>
                  </svg>
                  <span>UPGRADE NOW</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PopupModals;
