/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import LinkedInComponent from "../components/LinkedInComponent";
import TextEditor from "../components/TextEditor";
import useCommonFunctions from "../hooks/common-functions";

function LinkedInPersonalPost() {
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const {
    postWebsocketDetails,
    closeFunction,
    loader,
    stringWords,
    setstringWords,
    ws
  } = useWebsocketHook();
  const { getDescription } = useCommonFunctions();
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  useEffect(() => {
    if (selectedFile && selectedFile?.contents) {
      setstringWords(selectedFile?.contents);
    }
  }, []);
  useEffect(() => {
    return () => {
      if (ws) {
        // console.log("unmounting");
        closeFunction();
      }
    };
  }, [ws]);
  return (
    <div className="wrapper">
      <div class="w-full flex flex-wrap -mx-4 px-1">
        <NavBar2
          title="LinkedIn Personal Post (Get Max Reach And Engagement)"
          description={getDescription(
            "Marketing",
            "LinkedIn Personal Post (Get Max Reach And Engagement)"
          )}
        />

        <div
          className="w-full md:w-1/2 px-4 border-r"
          style={{
            height: "calc(100vh - 98px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <LinkedInComponent
            from="LinkedIn Personal Post (Get Max Reach And Engagement)"
            mainTitle="LinkedIn Personal Post"
            loader={loader}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
            problem
            solution
            other_info
            target
            cta
          />
        </div>

        <div
          className={`w-full ${!fullscreenClicked && "md:w-1/2"}  px-4`}
          style={{ height: "calc(100vh - 98px)" }}
        >
          <div className=" h-full pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Editor</h2>
              <div className="ms-auto">
                {!fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(true)}>
                    <svg className="icon text-[14px]  cursor-pointer">
                      <use href="#icon_fullscreen"></use>
                    </svg>
                  </div>
                )}
                {fullscreenClicked && (
                  <div onClick={() => setfullscreenClicked(false)}>
                    <svg className="icon text-[14px] cursor-pointer">
                      <use href="#icon_fullexit"></use>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <TextEditor stringOutput={stringWords} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkedInPersonalPost;
